import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { GET_EXH_BADGES_APPLICATION_ACTION_TYPE, GET_EXH_BADGES_SUMMARY_ACTION_TYPE, GET_EXH_BADGE_EXPORT_ACTION_TYPE, UPDATE_EXH_BADGES_APPROVAL_ACTION_TYPE, getExhBadgeExportUrl, getExhBadgesApplicationsUrl, getExhBadgesSummaryUrl, headers, updateExhBadgeApprovalUrl } from "../../../../utils/constants";

export const getExhBadgeSummary_action = (companyName, status, exhibitorTypeId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyName,
            Status: status,
            ExhibitorTypeID: exhibitorTypeId,
        }
        console.log("getExhBadgeSummary_action Action------->", params)

        const res = await axiosGet(getExhBadgesSummaryUrl, params, headers)

        dispatch({
            type: GET_EXH_BADGES_SUMMARY_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getExhBadeApplications_action = (companyname) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyname,
        }
        console.log("getExhBadeApplications_action Action------->", params)

        const res = await axiosGet(getExhBadgesApplicationsUrl, params, headers)

        dispatch({
            type: GET_EXH_BADGES_APPLICATION_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateExhBadgeApproval_action = (isApproved, list) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            Is_Approved: isApproved,
            approvalList: list
        }
        console.log("updateExhBadgeApproval_action updateExhBadgeApprovalUrl------->", updateExhBadgeApprovalUrl)
        console.log("updateExhBadgeApproval_action mapData------->", mapData)
        console.log("updateExhBadgeApproval_action headers------->", headers)

        const res = await axiosPost(updateExhBadgeApprovalUrl, mapData, headers)

        dispatch({
            type: UPDATE_EXH_BADGES_APPROVAL_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getExhBadgeExportData_action = (status) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            Status: status,
        }
        console.log("getExhBadgeExportData_action Action------->", params)

        const res = await axiosGet(getExhBadgeExportUrl, params, headers)

        dispatch({
            type: GET_EXH_BADGE_EXPORT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}