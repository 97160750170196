import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "../../../../../utils/constants";
// import { MeetingRoomsList } from "../../../../../utils/dummyJson";
import { getMeetingRoomDetails_action, insertUpdateMeetingRoom_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/meetingRoom_action/meetingRoom_action'
import Modal from "react-modal";
import { data } from "jquery";
import $ from 'jquery'
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import moment from "moment";
// 
class MeetingRoomRequestForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            PowerConnectionList: [],
            Is_Agree: false,
            Remark: '',
            CGST: '',
            SGST: '',
            Is_AgreeErr: '',
            DeadlineDate: "",
            Is_Required: true,
            carPassessId: '',
            currentDate: moment().format('DD-MM-YYYY'),
            meetingRoomsList: [],
            totalAmount: 0,
            meetingRoomListHeader: [],
            currency: '',
            CGST: '',
            SGST: '',
            is_View: false,
            StatusID: ''
        }
        // this.getPowerConnectionList = this.getPowerConnectionList.bind(this)

    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ? moment(this.props.location.state.Deadline).format('DD-MM-YYYY') : '',
            is_View: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.is_View != null ? this.props.location.state.is_View : false,
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != null ? this.props.location.state.StatusID : '',
        })

        this.getMeetingRoomDetails()
        this.loadscript()

    }

    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }

    handleCancel() {
        if (this.state.is_View) {
            const CompanyName = this.props.location.state != null && this.props.location.state.companyName ? this.props.location.state.companyName : ''
            this.props.navigate('/meetingRoomRequestFormApproval', { state: { companyName: CompanyName } })
        } else {
            this.props.navigate('/manualForms')
        }
    }

    inputValidate() {
        const { Is_Agree } = this.state

        if (Is_Agree == 0 || Is_Agree == false) {
            this.setState({ Is_AgreeErr: 'kindly agree the above mentioned clauses & consequences' })
        }
    }

    arraySortForInsert() {
        const { meetingRoomsList, meetingRoomListHeader } = this.state
        const array = []
        meetingRoomsList.map((data) => {
            if (data[`${meetingRoomListHeader[4]}`] == true) {
                if (!data.isDisable_1) {
                    array.push(
                        {
                            "ExhMeetingRoomDateTimeSlotID": data.ExhMeetingRoomDateTimeSlotIDs[0],
                            "Status": true
                        }
                    )
                }
            }
            if (data[`${meetingRoomListHeader[5]}`] == true) {
                if (!data.isDisable_2) {
                    array.push(
                        {
                            "ExhMeetingRoomDateTimeSlotID": data.ExhMeetingRoomDateTimeSlotIDs[1],
                            "Status": true
                        }
                    )
                }
            }
            if (data[`${meetingRoomListHeader[6]}`] == true) {
                if (!data.isDisable_3) {
                    array.push(
                        {
                            "ExhMeetingRoomDateTimeSlotID": data.ExhMeetingRoomDateTimeSlotIDs[2],
                            "Status": true
                        }
                    )
                }
            }
            if (data[`${meetingRoomListHeader[7]}`] == true) {
                if (!data.isDisable_4) {
                    array.push(
                        {
                            "ExhMeetingRoomDateTimeSlotID": data.ExhMeetingRoomDateTimeSlotIDs[3],
                            "Status": true
                        }
                    )
                }
            }
            if (data[`${meetingRoomListHeader[4]}`] == false && data.isSelected_1) {
                if (!data.isDisable_1) {
                    array.push(
                        {
                            "ExhMeetingRoomDateTimeSlotID": data.ExhMeetingRoomDateTimeSlotIDs[0],
                            "Status": false
                        }
                    )
                }
            }
            if (data[`${meetingRoomListHeader[5]}`] == false && data.isSelected_2) {
                if (!data.isDisable_2) {
                    array.push(
                        {
                            "ExhMeetingRoomDateTimeSlotID": data.ExhMeetingRoomDateTimeSlotIDs[1],
                            "Status": false
                        }
                    )
                }
            }
            if (data[`${meetingRoomListHeader[6]}`] == false && data.isSelected_3) {
                if (!data.isDisable_3) {
                    array.push(
                        {
                            "ExhMeetingRoomDateTimeSlotID": data.ExhMeetingRoomDateTimeSlotIDs[2],
                            "Status": false
                        }
                    )
                }
            }
            if (data[`${meetingRoomListHeader[7]}`] == false && data.isSelected_4) {
                if (!data.isDisable_4) {
                    array.push(
                        {
                            "ExhMeetingRoomDateTimeSlotID": data.ExhMeetingRoomDateTimeSlotIDs[3],
                            "Status": false
                        }
                    )
                }
            }
        })

        return array
    }

    insertUpdateMeetingRoom() {
        const { Remark, Is_Agree, totalAmount, meetingRoomsList, meetingRoomListHeader } = this.state

        const array = this.arraySortForInsert()

        console.log("array------", array)

        this.inputValidate()

        this.setState({ isLoading: true })
        if (Is_Agree == true) {
            this.props
                .insertUpdateMeetingRoom_action(totalAmount, Remark, Is_Agree, array)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            toastError("Plase Enter mandatory feilds!")
            this.inputValidate()
            this.setState({ isLoading: false })
        }

    }

    getMeetingRoomDetails() {
        const { Remark, Is_Agree, isQuantity, Is_Required } = this.state
        this.setState({ isLoading: true })
        this.props
            .getMeetingRoomDetails_action()
            .then((data) => {
                if (data.error != 1) {

                    if (data.data != '') {
                        const allKeys = Object.keys(data.data[0]);

                        const keysToExclude = ["ExhMeetingRoomDateTimeSlotIDs", "ExhMeetingRoomBookingID"];
                        const keysArray = allKeys.filter(key => !keysToExclude.includes(key));

                        data.data.map((item, i) => {
                            item.ExhMeetingRoomDateTimeSlotIDs = item.ExhMeetingRoomDateTimeSlotIDs.split(',')
                            if (item.ExhMeetingRoomBookingID != null) {
                                item.ExhMeetingRoomBookingID = item.ExhMeetingRoomBookingID.split(',')
                            }
                            item.Amount = 0
                            item.array = []

                            if (item.ExhMeetingRoomBookingID != null) {
                                item.ExhMeetingRoomDateTimeSlotIDs.map((obj, i) => {
                                    var checked = item.ExhMeetingRoomBookingID.includes(obj)
                                    if (checked) {
                                        item.array.push(obj)
                                    } else {
                                        item.array.push(0)
                                    }
                                })


                                if (item.array[0] == item.ExhMeetingRoomDateTimeSlotIDs[0]) {
                                    if (item[`${keysArray[4]}`]) {
                                        item.isSelected_1 = true
                                        item.isDisable_1 = false
                                    }
                                } else {
                                    if (item[`${keysArray[4]}`]) {
                                        item.isDisable_1 = true
                                        item[`${keysArray[4]}`] = false
                                    }
                                }
                                if (item.array[1] == item.ExhMeetingRoomDateTimeSlotIDs[1]) {
                                    if (item[`${keysArray[5]}`]) {
                                        item.isSelected_2 = true
                                        item.isDisable_2 = false
                                    }
                                } else {
                                    if (item[`${keysArray[5]}`]) {
                                        item.isDisable_2 = true
                                        item[`${keysArray[5]}`] = false
                                    }
                                }
                                if (item.array[2] == item.ExhMeetingRoomDateTimeSlotIDs[2]) {
                                    if (item[`${keysArray[6]}`]) {
                                        item.isSelected_3 = true
                                        item.isDisable_3 = false
                                    }
                                } else {
                                    if (item[`${keysArray[6]}`]) {
                                        item.isDisable_3 = true
                                        item[`${keysArray[6]}`] = false
                                    }
                                }
                                if (item.array[3] == item.ExhMeetingRoomDateTimeSlotIDs[3]) {
                                    if (item[`${keysArray[7]}`]) {
                                        item.isSelected_4 = true
                                        item.isDisable_4 = false
                                    }
                                } else {
                                    if (item[`${keysArray[7]}`]) {
                                        item.isDisable_4 = true
                                        item[`${keysArray[7]}`] = false
                                    }
                                }

                            } else {
                                item.isSelected_1 = item[`${keysArray[4]}`]
                                item.isSelected_2 = item[`${keysArray[5]}`]
                                item.isSelected_3 = item[`${keysArray[6]}`]
                                item.isSelected_4 = item[`${keysArray[7]}`]
                                item.isDisable_1 = item[`${keysArray[4]}`]
                                item.isDisable_2 = item[`${keysArray[5]}`]
                                item.isDisable_3 = item[`${keysArray[6]}`]
                                item.isDisable_4 = item[`${keysArray[7]}`]
                                item[`${keysArray[4]}`] = false
                                item[`${keysArray[5]}`] = false
                                item[`${keysArray[6]}`] = false
                                item[`${keysArray[7]}`] = false
                            }

                        })
                        console.log('meetingRoomsList--', data.data)

                        this.setState({ isLoading: false, meetingRoomListHeader: keysArray, meetingRoomsList: data.data, currency: data.currencyAndGst[0]['Currency'] })
                    } else {
                        this.setState({ isLoading: false, meetingRoomsList: [] })
                    }
                    setTimeout(() => {
                        this.calulationAmount()
                    }, 100);

                    if (data.inputData != '') {
                        this.setState({
                            Is_Agree: data.inputData[0]['is_agree'],
                            Remark: data.inputData[0]['Remark'],
                        })
                    }

                    this.setState({
                        isLoading: false,
                        CGST: data.currencyAndGst[0]['CGST'] * 100,
                        SGST: data.currencyAndGst[0]['SGST'] * 100
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    isSelected_1(e, index) {
        const { meetingRoomsList, meetingRoomListHeader } = this.state
        const value = e.target.checked

        console.log("meetingRoomsList--------", meetingRoomsList)

        meetingRoomsList != null && meetingRoomsList != '' ?
            meetingRoomsList.map((data, i) => {
                if (i === index) {
                    if (value) {
                        data[`${meetingRoomListHeader[4]}`] = true
                    } else {
                        data[`${meetingRoomListHeader[4]}`] = false
                    }
                    this.calulationAmount(i)
                }
            })
            : <></>

        this.setState({ meetingRoomsList: meetingRoomsList })

    }

    isSelected_2(e, index) {
        const { meetingRoomsList, meetingRoomListHeader } = this.state
        const value = e.target.checked

        meetingRoomsList != null && meetingRoomsList != '' ?
            meetingRoomsList.map((data, i) => {
                if (i === index) {
                    if (value) {
                        data[`${meetingRoomListHeader[5]}`] = true
                    } else {
                        data[`${meetingRoomListHeader[5]}`] = false
                    }
                    this.calulationAmount(i)
                }
            })
            : <></>
        this.setState({ meetingRoomsList: meetingRoomsList })

    }

    isSelected_3(e, index) {
        const { meetingRoomsList, meetingRoomListHeader } = this.state
        const value = e.target.checked

        meetingRoomsList != null && meetingRoomsList != '' ?
            meetingRoomsList.map((data, i) => {
                if (i === index) {
                    if (value) {
                        data[`${meetingRoomListHeader[6]}`] = true
                    } else {
                        data[`${meetingRoomListHeader[6]}`] = false
                    }
                    this.calulationAmount(i)
                }
            })
            : <></>

        this.setState({ meetingRoomsList: meetingRoomsList })

    }

    isSelected_4(e, index) {
        const { meetingRoomsList, meetingRoomListHeader } = this.state
        const value = e.target.checked

        meetingRoomsList != null && meetingRoomsList != '' ?
            meetingRoomsList.map((data, i) => {
                if (i === index) {
                    if (value) {
                        data[`${meetingRoomListHeader[7]}`] = true
                    } else {
                        data[`${meetingRoomListHeader[7]}`] = false
                    }
                    this.calulationAmount(i)
                }
            })
            : <></>

        this.setState({ meetingRoomsList: meetingRoomsList })

    }

    calulationAmount(i) {
        const { meetingRoomsList, meetingRoomListHeader, currency } = this.state

        meetingRoomsList != null && meetingRoomsList != '' ?
            meetingRoomsList.map((data, index) => {
                // if (i === index) {
                if (data[`${meetingRoomListHeader[4]}`] == false && data[`${meetingRoomListHeader[5]}`] == false && data[`${meetingRoomListHeader[6]}`] == false && data[`${meetingRoomListHeader[7]}`] == false) {

                    data.Amount = 0

                } else if (data[`${meetingRoomListHeader[4]}`] == true && data[`${meetingRoomListHeader[5]}`] == false && data[`${meetingRoomListHeader[6]}`] == false && data[`${meetingRoomListHeader[7]}`] == false) {

                    data.Amount = data[`${meetingRoomListHeader[1]}`]

                } else if (data[`${meetingRoomListHeader[4]}`] == true && data[`${meetingRoomListHeader[5]}`] == true && data[`${meetingRoomListHeader[6]}`] == false && data[`${meetingRoomListHeader[7]}`] == false) {

                    data.Amount = data[`${meetingRoomListHeader[3]}`]

                } else if (data[`${meetingRoomListHeader[4]}`] == true && data[`${meetingRoomListHeader[5]}`] == true && data[`${meetingRoomListHeader[6]}`] == true && data[`${meetingRoomListHeader[7]}`] == false) {

                    data.Amount = data[`${meetingRoomListHeader[1]}`] + data[`${meetingRoomListHeader[3]}`]

                } else if (data[`${meetingRoomListHeader[4]}`] == false && data[`${meetingRoomListHeader[5]}`] == true && data[`${meetingRoomListHeader[6]}`] == false && data[`${meetingRoomListHeader[7]}`] == false) {

                    data.Amount = data[`${meetingRoomListHeader[2]}`]

                } else if (data[`${meetingRoomListHeader[4]}`] == false && data[`${meetingRoomListHeader[5]}`] == true && data[`${meetingRoomListHeader[6]}`] == true && data[`${meetingRoomListHeader[7]}`] == false) {

                    data.Amount = data[`${meetingRoomListHeader[3]}`]

                } else if (data[`${meetingRoomListHeader[4]}`] == false && data[`${meetingRoomListHeader[5]}`] == true && data[`${meetingRoomListHeader[6]}`] == true && data[`${meetingRoomListHeader[7]}`] == true) {

                    data.Amount = data[`${meetingRoomListHeader[1]}`] + data[`${meetingRoomListHeader[3]}`]

                } else if (data[`${meetingRoomListHeader[4]}`] == false && data[`${meetingRoomListHeader[5]}`] == false && data[`${meetingRoomListHeader[6]}`] == true && data[`${meetingRoomListHeader[7]}`] == false
                ) {

                    data.Amount = data[`${meetingRoomListHeader[1]}`]

                } else if (data[`${meetingRoomListHeader[4]}`] == false && data[`${meetingRoomListHeader[5]}`] == false && data[`${meetingRoomListHeader[6]}`] == true && data[`${meetingRoomListHeader[7]}`] == true
                ) {

                    data.Amount = data[`${meetingRoomListHeader[1]}`] + data[`${meetingRoomListHeader[1]}`]

                } else if (data[`${meetingRoomListHeader[4]}`] == true && data[`${meetingRoomListHeader[5]}`] == false && data[`${meetingRoomListHeader[6]}`] == true && data[`${meetingRoomListHeader[7]}`] == false
                ) {

                    data.Amount = data[`${meetingRoomListHeader[1]}`] + data[`${meetingRoomListHeader[1]}`]

                } else if (data[`${meetingRoomListHeader[4]}`] == false && data[`${meetingRoomListHeader[5]}`] == false && data[`${meetingRoomListHeader[6]}`] == false && data[`${meetingRoomListHeader[7]}`] == true
                ) {

                    data.Amount = data[`${meetingRoomListHeader[1]}`]

                } else if (data[`${meetingRoomListHeader[4]}`] == true && data[`${meetingRoomListHeader[5]}`] == false && data[`${meetingRoomListHeader[6]}`] == false && data[`${meetingRoomListHeader[7]}`] == true
                ) {

                    data.Amount = data[`${meetingRoomListHeader[1]}`] + data[`${meetingRoomListHeader[1]}`]

                } else if (data[`${meetingRoomListHeader[4]}`] == false && data[`${meetingRoomListHeader[5]}`] == true && data[`${meetingRoomListHeader[6]}`] == false && data[`${meetingRoomListHeader[7]}`] == true
                ) {
                    data.Amount = data[`${meetingRoomListHeader[3]}`]

                } else if (data[`${meetingRoomListHeader[4]}`] == true && data[`${meetingRoomListHeader[5]}`] == false && data[`${meetingRoomListHeader[6]}`] == true && data[`${meetingRoomListHeader[7]}`] == true
                ) {
                    data.Amount = data[`${meetingRoomListHeader[1]}`] + data[`${meetingRoomListHeader[1]}`] + data[`${meetingRoomListHeader[1]}`]

                } else if (data[`${meetingRoomListHeader[4]}`] == true && data[`${meetingRoomListHeader[5]}`] == true && data[`${meetingRoomListHeader[6]}`] == false && data[`${meetingRoomListHeader[7]}`] == true
                ) {
                    data.Amount = data[`${meetingRoomListHeader[1]}`] + data[`${meetingRoomListHeader[3]}`]

                } else {
                    data.Amount = data[`${meetingRoomListHeader[1]}`] + data[`${meetingRoomListHeader[1]}`] + data[`${meetingRoomListHeader[3]}`]
                }
                // }
            })
            : <></>
        let sumOfObject = meetingRoomsList.reduce((acc, obj) => acc + obj.Amount, 0);
        if (currency == '(INR)') {
            this.setState({ totalAmount: sumOfObject })
        } else {
            this.setState({ totalAmount: sumOfObject })
        }
    }

    render() {
        const { isLoading, Is_Agree, Remark, Is_AgreeErr, currentDate, DeadlineDate, meetingRoomsList, totalAmount, meetingRoomListHeader, currency, CGST, SGST, is_View, StatusID } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : <HeaderWithLogo />
                }
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorDrawer /> : <AdminDrawer />
                }
                <div className="main-content app-content  with-dr-container" style={{ marginTop: !is_View ? '0px' : '' }}>
                    <div className="">
                        <div className="row">
                            {
                                JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                    <ExhibitorTopMenu /> : null
                            }
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1 m-0">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Meeting Room Request Form</h3>
                                    </div>
                                    <p class="card-body p-1 m-2">
                                        <ul style={{ lineHeight: '25px', textAlign: 'left', padding: 0 }}>
                                            <li> 1) As a part of the venue facility, meeting rooms are made available for hire to the exhibitors at a basic price.</li>
                                            <li> 2) Please find below the charges for booking the meeting rooms for your meetings or any other programs </li>
                                            <li> 3) Furnished meeting rooms will have table, chairs, carpet, lighting, dustbin as standard furniture. Any additional requirements like AV Photography etc. will be on actual costs by venue service provider. </li>
                                            <li> 4) Tea, coffee, cookies will be on actual costs, to be taken from venue catering service provider.</li>
                                            <li> 5) In case, you would like to book these meeting rooms kindly fill the request form giving the date and time slot. </li>
                                            <li> 6) Allotment of meeting rooms is based on first come first serve basis on submission of 100% payment & subject to availability. </li>
                                        </ul>
                                        <p style={{ lineHeight: '25px' }}>
                                            <p className="m-0"><strong>Note:</strong></p>
                                            <ul style={{ listStyleType: 'disc' }}>
                                                <li>Last date of submission of application for the booking of meeting rooms with full payment is xx.xx.xxx.</li>
                                                <li>20% Advance to be paid at the time of booking meeting room, balance amount to be paid on or before xx.xx.xxx.</li>
                                                <li>In case of last-minute cancellation, the Taxes paid, advance extended to the various service providers will be forfeited. </li>
                                                <li>Rules & Guidelines of Participation in India ITME-xxxx is available on website: </li>
                                                <li>The following decorations are specifically prohibited at venue like, glitter, stickers, spray paint, liquid paint, burning objects, helium balloons etc. </li>
                                                <ul style={{ listStyleType: 'circle' }}>
                                                    <li>a) No furniture or decorative objects belonging to ITME can be moved from their respective positions without the permission of the ITME.</li>
                                                    <li>b) Nothing may be nailed, tacked, taped, hung, stapled or affixed in any way to the facility, inside or out.</li>
                                                </ul>
                                                <li>If applicant’s Staff, volunteers, participants, invitees, licensees, Vendor causes damage to India ITME Society’s or Venue Facilities and Equipment, applicant shall pay any costs associated with repairing the damage and restoring the Facilities and Equipment to their condition prior to applicant’s use thereof. </li>
                                                <li>Liquor permits must be taken from Government, if served during meetings/ inside Exhibition Venue. </li>
                                                <li>The applicant agrees to indemnify the Exhibition Authority and its Office-Bearers, Steering Committee Members, Executive Director, Officers, Employees and Members, and hold them and each of them harmless from and against any and all claims arising out of the acts of the exhibitor, his agents, representatives and employees, and all contractors, erectors, delivery persons, suppliers and material persons, furnishing services, delivery work or materials at the instance of the exhibitor. </li>
                                                <li>All hostess/ support staff helping during meeting engaged by you shall be with proper entry pass. </li>
                                                <li>Non-compliance of the above terms conditions will result in cancellation of your booking as well as gives full rights to exhibition authority to take appropriate action legally or financially. </li>
                                            </ul>
                                        </p>
                                    </p>
                                </div>

                                <div class="table-responsive">
                                    <table class="table border text-nowrap text-md-nowrap table-bordered m-2 mt-1">
                                        <thead>
                                            <tr style={{ textAlign: 'center' }}>
                                                {
                                                    meetingRoomListHeader != '' && meetingRoomListHeader != null && meetingRoomListHeader.length > 0 ?
                                                        meetingRoomListHeader.map((data, i) => (
                                                            <th className="addone" >{data} {i != 0 && i <= 3 ? currency : ''} </th>
                                                        ))
                                                        : <></>
                                                }
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                meetingRoomsList != '' && meetingRoomsList != null && meetingRoomsList.length > 0 ?
                                                    meetingRoomsList.map((data, i) => (
                                                        <tr style={{ textAlign: 'center' }}>
                                                            <td className="d-flex d-flex-dammy" style={{ flexDirection: 'column', textAlign: 'left' }}>
                                                                <span><strong>{data[`${meetingRoomListHeader[0]}`]}</strong></span>
                                                            </td>
                                                            <td >
                                                                {data[`${meetingRoomListHeader[1]}`]}
                                                            </td>
                                                            <td >
                                                                {data[`${meetingRoomListHeader[2]}`]}
                                                            </td>
                                                            <td >
                                                                {data[`${meetingRoomListHeader[3]}`]}
                                                            </td>
                                                            <td >
                                                                <input
                                                                    checked={!data.isDisable_1 ? data[`${meetingRoomListHeader[4]}`] : data.isDisable_1}
                                                                    onChange={(e) => !is_View ? this.isSelected_1(e, i) : ''}
                                                                    disabled={data.isDisable_1}
                                                                    type='checkbox' />
                                                            </td>
                                                            <td >
                                                                <input
                                                                    onChange={(e) => !is_View ? this.isSelected_2(e, i) : ''}
                                                                    checked={!data.isDisable_2 ? data[`${meetingRoomListHeader[5]}`] : data.isDisable_2}
                                                                    disabled={data.isDisable_2}
                                                                    type='checkbox' />
                                                            </td>
                                                            <td >
                                                                <input
                                                                    onChange={(e) => !is_View ? this.isSelected_3(e, i) : ''}
                                                                    checked={!data.isDisable_3 ? data[`${meetingRoomListHeader[6]}`] : data.isDisable_3}
                                                                    disabled={data.isDisable_3}
                                                                    type='checkbox' />
                                                            </td>
                                                            <td >
                                                                <input
                                                                    onChange={(e) => !is_View ? this.isSelected_4(e, i) : ''}
                                                                    checked={!data.isDisable_4 ? data[`${meetingRoomListHeader[7]}`] : data.isDisable_4}
                                                                    disabled={data.isDisable_4}
                                                                    type='checkbox' />
                                                            </td>
                                                            <td >
                                                                {data.Amount}
                                                            </td>
                                                        </tr>
                                                    ))
                                                    : <tr><td className="text-center" colSpan={8}>No record found</td></tr>
                                            }

                                            <tr style={{ textAlign: 'right' }}>
                                                <td colSpan={8}><strong>Total</strong></td>
                                                <td><strong>{totalAmount}</strong></td>
                                            </tr>
                                            <tr style={{ textAlign: 'right' }}>
                                                <td colSpan={8}><strong>CGST @ {CGST}%</strong></td>
                                                <td><strong>{(CGST / 100) * totalAmount}</strong></td>
                                            </tr>
                                            <tr style={{ textAlign: 'right' }}>
                                                <td colSpan={8}><strong>SGST @ {SGST}%</strong></td>
                                                <td><strong>{(SGST / 100) * totalAmount}</strong></td>
                                            </tr>
                                            <tr style={{ textAlign: 'right' }}>
                                                <td colSpan={8}><strong>Grand Total</strong></td>
                                                <td><strong>{
                                                    (CGST / 100) * totalAmount + (SGST / 100) * totalAmount + totalAmount
                                                }</strong></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                                <div class="row m-1">
                                    <form>
                                        <strong> <label>Remark</label></strong>
                                        <textarea class="form-control"
                                            value={Remark}
                                            disabled={is_View}
                                            onChange={(e) => this.setState({ Remark: e.target.value })}
                                            maxLength={300}
                                            rows="3">
                                        </textarea>
                                        <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                            <input type="checkbox"
                                                checked={Is_Agree}
                                                disabled={is_View}
                                                onChange={(e) => this.setState({ Is_Agree: e.target.checked, Is_AgreeErr: '' })}
                                            />
                                            <strong>We hereby read & understood the above mentioned clauses & consequences. We accept & agree to abide by the same<span className="required">*</span>.</strong></label>{<span className='text-danger text-start text-left d-block mt-1'>{Is_AgreeErr}</span>}
                                    </form>
                                </div>

                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>
                            <div class='d-flex m-3'>
                                {!is_View ?
                                    <button
                                        type="button"
                                        className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                        style={{ marginRight: 10 }}
                                        disabled={currentDate > DeadlineDate }
                                        onClick={() => this.insertUpdateMeetingRoom()}
                                    >
                                        Submit
                                    </button>
                                    : <></>}
                                <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { getMeetingRoomDetails_action, insertUpdateMeetingRoom_action })(MeetingRoomRequestForm));