import { Component } from "react";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import Pagination from "../../../../utils/pagination";
import { getPowerFormApprovalDetails_action, getPowerConnectionExport_action } from "../../../../actions/admin_module_action/manual_forms_admin_action/power_approval_action/power_approval_action";
import { exhibitorTypeActionId, toastError } from "../../../../utils/constants";
import { fill_action } from "../../../../actions/fillCombo_action";
import Workbook from 'react-excel-workbook'

class PowerConnectionAdmin extends Component {

    // Initialized Constructor 
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            statusList: [],
            applicationList: [],
            companyName: "",
            currentPage: 1,
            exhibitorTypeId: "",
            exhibitorTypeList: [],
            exportData: [],
            loginAccesslist: []
        }
        this.getPowerFormApprovalDetails = this.getPowerFormApprovalDetails.bind(this)
        this.updateItem = this.updateItem.bind(this)
        this.onAppliedClick = this.onAppliedClick.bind(this)
        this.onApprovedClick = this.onApprovedClick.bind(this)
        this.onDeclinedClick = this.onDeclinedClick.bind(this)
        this.onPendingClick = this.onPendingClick.bind(this)
        this.onNameClick = this.onNameClick.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.onCreateExcel = this.onCreateExcel.bind(this)
        this.onCreateExcel2 = this.onCreateExcel2.bind(this)
        this.onExportData = this.onExportData.bind(this)
        this.getWirteAccess = this.getWirteAccess.bind(this)
        this.getDownloadAccess = this.getDownloadAccess.bind(this)
    }

    // On Component Load
    componentDidMount() {
        this.setState({ loginAccesslist: JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist'] })
        this.getFillList(exhibitorTypeActionId, '', '', '', '', '');
        this.getPowerFormApprovalDetails(null)
        this.onExportData()
    }

    // Get Fill Lists
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // Exhibitor Type list
                    if (actionID === exhibitorTypeActionId) {
                        console.log("Exhibitor Type list data", data.data)
                        this.setState({
                            exhibitorTypeList: data.data.length > 0 ? data.data : [],
                            isLoading: false
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // get Exh Badge Summary
    getPowerFormApprovalDetails(key) {
        const { companyName, exhibitorTypeId } = this.state
        this.setState({ isLoading: true })
        this.props.getPowerFormApprovalDetails_action(companyName, key != undefined && key != null ? key : null,
            exhibitorTypeId != "" && exhibitorTypeId != "Select Exhibitor Type" ? exhibitorTypeId : "")
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        statusList: data.data['applicationStatusList'],
                        applicationList: data.data['applicationList'],
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On Export Data
    onExportData(key) {
        this.setState({ isLoading: true })
        this.props.getPowerConnectionExport_action(key != undefined && key != null ? key:null)
            .then((data) => {
                if (data.error != 1) {
                    console.log("onExportData Data", data.data)
                    this.setState({
                        exportData: data.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On Applied Click
    onAppliedClick(key) {
        this.getPowerFormApprovalDetails(key)
        this.onExportData(key)
    }

    // On Approved Click
    onApprovedClick(key) {
        this.getPowerFormApprovalDetails(key)
        this.onExportData(key)
    }

    // On Declined Click
    onDeclinedClick(key) {
        this.getPowerFormApprovalDetails(key)
        this.onExportData(key)
    }

    // On Pending Click
    onPendingClick(key) {
        this.getPowerFormApprovalDetails(key)
        this.onExportData(key)
    }

    // curent page update Item
    updateItem(item) {
        const { applicationList } = this.state
        this.setState({ currentPage: item });
        console.log("xyxyxyxyyxyxyyyxyx", item)
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = applicationList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(applicationList.length / recordsPerPage)
    }

    // On Name Click 
    onNameClick(e, name, exhRegId) {
        sessionStorage.setItem("ExhRegistrationIDSS", exhRegId)
        this.props.navigate('/powerConnectionApproval', { state: { companyName: name } })
    }

    // to create excel file
    onCreateExcel() {
        const { applicationList } = this.state
        var listData = []
        var mapData
        applicationList.length > 0 && applicationList.map((item, index) => {
            mapData = {
                "Sr No": index + 1,
                "Company Name": item.CompanyName,
                "Type": item.Type,
                "Used": item.Used,
                "Pending": item.Pending,
                "Approved": item.Approved,
                "Declined": item.Declined
            }
            listData.push(mapData)
        })
        var list = [...listData]
        var columnArray = [],
            rowData = [];
        // for column data 
        list.map((data, index) => {
            if (index == 0) {
                Object.keys(data).map((key, index) => {
                    columnArray.push(key)
                })
            }
        })


        // for row data
        list.map((data, index) => {
            rowData.push(data)
        })

        // console.log("rowData", rowData)
        // this.setState({
        //     columnArrayData: columnArray,
        //     rowData: rowData
        // })

        // { this.renderList() }

        return (
            <div className="row text-center">
                {applicationList.length > 0 ? <Workbook filename="ExhibitorList.xlsx" style={{}} element={
                    <button type="button" className="btn btn-dark m-0"
                        disabled={!this.getDownloadAccess()}
                    >
                        Export to Excel
                    </button>
                }>
                    <Workbook.Sheet data={rowData} name="Sheet A">
                        {columnArray.map((value, index) =>
                            <Workbook.Column label={value} value={value} />
                        )
                        }
                    </Workbook.Sheet>
                </Workbook> : <button type="button" className="btn btn-dark m-0" disabled={true}>
                    Export to Excel
                </button>}
            </div>
        );
    }

    // to create excel file
    onCreateExcel2() {
        const { exportData } = this.state
        var listData = []
        var list = [...exportData]
        var columnArray = [],
            rowData = [];
        // for column data 
        list.map((data, index) => {
            if (index == 0) {
                Object.keys(data).map((key, index) => {
                    columnArray.push(key)
                })
            }
        })

        // for row data
        list.map((data, index) => {
            rowData.push(data)
        })

        return (
            <div className="row text-center">
                {<Workbook filename="PowerConnection_Data.xlsx" style={{}} element={
                    <button type="button" className="btn btn-dark m-0"
                        disabled={!this.getDownloadAccess()}
                    >
                        Export Data
                    </button>
                }>
                    <Workbook.Sheet data={rowData} name="Sheet A">
                        {columnArray.map((value, index) =>
                            <Workbook.Column label={value} value={value} />
                        )
                        }
                    </Workbook.Sheet>
                </Workbook>}
            </div>
        );
    }

    getDownloadAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '18');
        if (moduleAccess) {
            return moduleAccess.DownloadAccess;
        } else {
            return false
        }
        return false;
    }

    getWirteAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '18');
        if (moduleAccess) {
            return moduleAccess.WriteAccess;
        } else {
            return false
        }
        return false;
    }

    render() {
        const { isLoading, statusList, applicationList, companyName, currentPage, exhibitorTypeId, exhibitorTypeList } = this.state
        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = applicationList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(applicationList.length / recordsPerPage)
        return (

            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <AdminDrawer />
                <div className="main-content with-dr-container com-height">
                    <div className="">
                        <div className="row">
                            <div className="step-name">
                                <p>Power Connection</p>
                            </div>
                        </div>
                        <div>
                            <div className="row  pt-3 p-0 m-0">
                                <div className="table-responsive mt-1 ">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                        <thead>
                                            <tr>
                                                <th>Application Received</th>
                                                <th className="text-center">Applied</th>
                                                <th className="text-center">Approved</th>
                                                <th className="text-center">Declined</th>
                                                <th className="text-center">Pending</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-center" style={{ cursor: "pointer" }}
                                                    onClick={(e) => this.getPowerFormApprovalDetails(null)}
                                                >{statusList.length > 0 ? statusList[0]['ApplicationReceived'] : 0}</td>
                                                <td className="text-center" style={{ cursor: "pointer" }}
                                                    onClick={(e) => this.onAppliedClick(1)}
                                                >{statusList.length > 0 ? statusList[0]['Applied'] : 0}</td>
                                                <td className="text-center" style={{ cursor: "pointer" }}
                                                    onClick={(e) => this.onApprovedClick(2)}
                                                >{statusList.length > 0 ? statusList[0]['Approved'] : 0}</td>
                                                <td className="text-center" style={{ cursor: "pointer" }}
                                                    onClick={(e) => this.onDeclinedClick(3)}
                                                >{statusList.length > 0 ? statusList[0]['Declined'] : 0}</td>
                                                <td className="text-center" style={{ cursor: "pointer" }}
                                                    onClick={(e) => this.onPendingClick(4)}
                                                >{statusList.length > 0 ? statusList[0]['Pending'] : 0}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row mt-4 mb-4 m-0">
                                <div className="col-md-4">
                                    <label>Company Name</label>
                                    <input type="text" className="form-control"
                                        value={companyName}
                                        onChange={(e) => this.setState({ companyName: e.target.value })}
                                    />
                                </div>
                                <div className="col-md-4 ">
                                    <label htmlFor="validationCustom03">Exhibitor Type</label>
                                    <select className="form-control"
                                        value={exhibitorTypeId}
                                        onChange={(e) => this.setState({ exhibitorTypeId: e.target.value })}
                                    >
                                        <option>Select Exhibitor Type</option>
                                        {
                                            exhibitorTypeList.length > 0 && exhibitorTypeList.map((data) => (
                                                <option key={data.ID} value={data.ID}>{data.ExhibitorType}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-md-1 text-left d-flex mt-4">
                                    <label>&nbsp;</label>
                                    <br />
                                    <button
                                        className="btn rounded-0 btn-dark rounded-2"
                                        style={{ padding: "7px 10px" }}
                                        onClick={(e) => this.getPowerFormApprovalDetails(null)}
                                    >
                                        Search
                                    </button>
                                    <div className="ml-2">
                                        {this.onCreateExcel()}
                                    </div>
                                    <div className="ml-2">
                                        {this.onCreateExcel2()}
                                    </div>

                                </div>
                            </div>
                            <div className="row mb-6 m-0">
                                <div className="table-responsive mt-1 ">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                        <thead>
                                            <tr>
                                                <th className="text-center">Sr No</th>
                                                <th className="text-center">Company</th>
                                                <th className="text-center">Type</th>
                                                <th className="text-center">Used</th>
                                                <th className="text-center">Pending</th>
                                                <th>Approved</th>
                                                <th>Declined</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                applicationList.length > 0 ? currentRecords.map((data, index) => (
                                                    <tr>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td className="text-center text-primary"
                                                            onClick={(e) => this.getWirteAccess() && this.onNameClick(e, data.CompanyName, data.ExhregistrationID)}
                                                        >{data.CompanyName}</td>
                                                        <td className="text-center">{data.Type}</td>
                                                        <td className="text-center">{data.Used}</td>
                                                        <td className="text-center">{data.Pending}</td>
                                                        <td className="text-center">{data.Approved}</td>
                                                        <td className="text-center">{data.Declined}</td>
                                                    </tr>
                                                )) : <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-2 float-left">
                                    {
                                        applicationList.length > 0 ?
                                            <Pagination
                                                nPages={nPages}
                                                currentPage={currentPage}
                                                setCurrentPage={this.updateItem}
                                            />
                                            : <></>
                                    }
                                    <div className="tab-rec " style={{ float: "right" }}>
                                        <p>{`${currentPage} of ${nPages} pages`}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}
export default withRouter(connect(null, { fill_action, getPowerFormApprovalDetails_action, getPowerConnectionExport_action })(PowerConnectionAdmin))