import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "../../../../../utils/constants";
import { insertUpdateInternetConnection_action, getInternetConnectionDetails_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/internetConnection_action/internetConnection_action'
import Modal from "react-modal";
import { data } from "jquery";
import { ToastContainer, toast } from 'react-toastify';
import { mobileNoValidator } from "../../../../../utils/Validator";
import $ from 'jquery'
import moment from "moment";
// 
class InternetConnection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            PowerConnectionList: [],
            Is_Agree: false,
            Remark: '',
            CGST: '',
            SGST: '',
            Is_AgreeErr: '',
            DeadlineDate: "",
            Is_Required: true,
            carPassessId: '',
            currentDate: moment().format('DD-MM-YYYY'),
            personName: '',
            personNameErr: '',
            designation: '',
            designationErr: '',
            mobileNo: '',
            mobileNoErr: '',
            voucherRequirement: '',
            voucherRequirementErr: '',
            noOfPublicIP: '',
            duration: '',
            durationErr: "",

            ExhibitorCompanyName: '',
            HallName: '',
            StallName: '',
            Address1: '',
            Address2: '',
            City: '',
            CountryName: '',
            Telephone: '',
            PrimaryEmail: '',

            AccountNumber: '',
            BankName: '',
            Branch: '',
            BankAddress: '',
            RTGS_IFSC: '',
            StatusID: '',

            contactDetailsList: [],
        }
        // this.getPowerConnectionList = this.getPowerConnectionList.bind(this)

    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                moment(this.props.location.state.Deadline).format('DD-MM-YYYY') : '',
                StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ?
                this.props.location.state.StatusID : '',
        })

        this.getInternetConnectionDetails()
        this.loadscript()
    }
    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }


    handleCancel() {
        this.props.navigate('/manualForms')
    }

    inputValidate() {
        const { Is_Agree, personName, designation, mobileNo, voucherRequirement, noOfPublicIP, duration } = this.state

        if (personName == '' || personName == null) {
            this.setState({ personNameErr: 'Please enter person name' })
        }
        if (designation == '' || designation == '') {
            this.setState({ designationErr: 'Please enter designation' })
        }
        if (mobileNo == '' || mobileNo == '') {
            this.setState({ mobileNoErr: 'Please enter mobile no' })
        }
        if (voucherRequirement == '' || voucherRequirement == '') {
            this.setState({ voucherRequirementErr: 'Please enter no. of port or voucher requirement' })
        }
        if (duration == '' || duration == '') {
            this.setState({ durationErr: 'Please enter duration' })
        }
        if (Is_Agree == 0 || Is_Agree == false || Is_Agree == '' || Is_Agree == null) {
            this.setState({ Is_AgreeErr: 'kindly agree to disclaimer mentioned in this form for Car park passes' })
        }
    }

    getInternetConnectionDetails() {
        const { Remark, Is_Agree, isQuantity, Is_Required } = this.state
        this.setState({ isLoading: true })
        this.props
            .getInternetConnectionDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    this.setState({
                        ExhibitorCompanyName: data.ExhibitorDetails[0]['ExhibitorCompanyName'],
                        HallName: data.ExhibitorDetails[0]['HallName'],
                        StallName: data.ExhibitorDetails[0]['StallName'],
                        Address1: data.ExhibitorDetails[0]['Address1'],
                        Address2: data.ExhibitorDetails[0]['Address2'],
                        City: data.ExhibitorDetails[0]['City'],
                        CountryName: data.ExhibitorDetails[0]['CountryName'],
                        Telephone: data.ExhibitorDetails[0]['Telephone'],
                        PrimaryEmail: data.ExhibitorDetails[0]['PrimaryEmail'],

                        AccountNumber: data.BankDetails[0]['AccountNumber'] != null ? data.BankDetails[0]['AccountNumber'] : '-',
                        BankName: data.BankDetails[0]['BankName'] != null ? data.BankDetails[0]['BankName'] : '-',
                        Branch: data.BankDetails[0]['Branch'] != null ? data.BankDetails[0]['Branch'] : '-',
                        BankAddress: data.BankDetails[0]['Address'] != null ? data.BankDetails[0]['Address'] : '-',
                        RTGS_IFSC: data.BankDetails[0]['RTGS_IFSC'] != null ? data.BankDetails[0]['RTGS_IFSC'] : '-',

                        contactDetailsList: data.contactDetails,
                    })

                    if (data.inputData != '') {
                        this.setState({
                            personName: data.inputData[0]['PersonName'],
                            designation: data.inputData[0]['Designation'],
                            mobileNo: data.inputData[0]['Mobile'],
                            voucherRequirement: data.inputData[0]['PortVoucherRequirement'],
                            noOfPublicIP: data.inputData[0]['NoOfPublicIP'],
                            duration: data.inputData[0]['Duration'],
                            Remark: data.inputData[0]['Remark'],
                            Is_Agree: data.inputData[0]['Is_Agree']
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    handleSubmit() {
        const { Remark, Is_Agree, personName, designation, mobileNo, voucherRequirement, noOfPublicIP, duration } = this.state
        this.inputValidate()
        this.setState({ isLoading: true })
        if (personName != '' && personName != null && designation != '' && designation != null && mobileNo != '' && mobileNo != null && voucherRequirement != '' && voucherRequirement != null && duration != '' && duration != null && Is_Agree) {

            var errMsg1 = sessionStorage.getItem('CurrencyID') == 1 ? mobileNoValidator(mobileNo) : ''

            if (errMsg1 != '') {
                toast.error(
                    errMsg1 != '' ? errMsg1 :
                        '',
                    {
                        theme: 'colored',
                        autoClose: 1000,
                        hideProgressBar: true,
                    })
                this.setState({ isLoading: false })

            } else {
                this.props
                    .insertUpdateInternetConnection_action(personName, designation, mobileNo, noOfPublicIP, duration, voucherRequirement, Remark, Is_Agree)
                    .then((data) => {
                        if (data.error != 1) {
                            this.setState({ isLoading: false })
                            this.handleCancel()
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        } else {
            toastError("Please enter mandatory fields!")
            this.setState({ isLoading: false })
        }
    }

    render() {
        const { isLoading, Is_Agree, Remark, Is_AgreeErr, currentDate, DeadlineDate, personName, personNameErr, designation, designationErr, mobileNo, mobileNoErr, voucherRequirement, voucherRequirementErr, noOfPublicIP, duration, durationErr, ExhibitorCompanyName,
            HallName,
            StallName,
            Address1,
            Address2,
            City,
            CountryName,
            Telephone,
            PrimaryEmail,

            AccountNumber,
            BankName,
            Branch,
            BankAddress,
            RTGS_IFSC,
            StatusID,
            contactDetailsList } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content app-content mt-0 with-dr-container">
                    <div className="">
                        <div className="row">
                            <ExhibitorTopMenu />
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Internet Connection</h3>
                                    </div>
                                    <div class="card-body p-1 m-2">
                                        <ul style={{ lineHeight: '25px', textAlign: 'left', padding: 0 }}>
                                            <li>1) The cost/rates and terms & conditions of utilizing the individual services from the given service provider is solely discretion of exhibitors.
                                            </li>
                                            <li>2) India ITME Society is only information facilitator for the service provider & In no event shall INDIA ITME SOCIETY be liable for any direct, indirect, punitive, incidental, special consequential issues, whatsoever arising out of taking services with the above given service provider.
                                            </li>
                                        </ul>

                                        <strong><p className="m-0">Contact Details:.</p></strong>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {contactDetailsList != '' && contactDetailsList.length > 0 ?
                                                contactDetailsList.map((data) => (
                                                    <>
                                                        <div class="table-responsive w-50" style={{ backgroundColor: '#ebebeb', marginBottom: '14px', borderRight: '1px solid lightgray' }}>
                                                            <div style={{ margin: '10px 0 10px 10px', lineHeight: '23px' }}>
                                                                <p className="m-0"><strong>Name:</strong>  {data.Name} </p>
                                                                <p className="m-0"><strong>Designation:</strong>  {data.Designation} </p>
                                                                <p className="m-0"><strong>Phone:</strong>  {data.Phone}</p>
                                                                <p className="m-0"><strong>Fax:</strong>  {data.Fax}</p>
                                                                <p className="m-0"><strong>Mobile No.</strong>  {data.Mobile}</p>
                                                                <p className="m-0"><strong>Email:</strong>  {data.Email}</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                                :
                                                <div class="table-responsive w-50" style={{ backgroundColor: '#ebebeb', marginBottom: '14px', borderRight: '1px solid lightgray' }}>
                                                    <div style={{ margin: '10px 0 10px 10px', lineHeight: '23px' }}>
                                                        <p className="m-0">No record found </p>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-3 mt-1">
                                                {/* <thead>
                                                    <th className="addone"></th>
                                                    <th className="addone"></th>
                                                </thead> */}
                                                <tbody className="addone" >
                                                    <tr>
                                                        <td style={{ width: '60%' }}>
                                                            Wi-Fi Voucher (Per voucher speed up to 1 Port*)
                                                        </td>
                                                        <td>
                                                            Wi-Fi Voucher (Per voucher speed up to 1 Port*)	Rs. 1,100 + Tax as applicable (In 1 Wi-Fi Voucher 2 Device can be connected at a time for entire event days)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            In 1 Wi-Fi Voucher 2 Device can be connected at a time for entire event days
                                                        </td>
                                                        <td>
                                                            Rs. On Demand Basis
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="table-responsive" style={{ backgroundColor: '#ebebeb', marginBottom: '14px' }}>
                                            <div style={{ margin: '0px 0 10px 10px', lineHeight: '23px' }}>
                                                <strong><p className="m-0">Terms and Condition:.</p></strong>
                                                <p className="m-0"> • Issued voucher is non-refundable and non-exchangeable.</p>
                                                <p className="m-0"> • In accordance with policies of Govt. of India / TRAI / DOT, IEML shall carry out periodic inspections/request for documents pertaining to usage to ensure that there is no misuse of circuit.</p>
                                                <p className="m-0"> • The above charges do not include the internal networking wiring at stall. (We are liable only to give wired connectivity drop at a single point)</p>
                                                <p className="m-0"> • Wi-Fi Services may not be accessible at certain spots or at certain point of time within the premises due to the atmospheric and radio interference and any technical limitations applicable to our equipment or your equipment.</p>

                                            </div>
                                        </div>
                                        <div class="table-responsive" style={{ backgroundColor: '#ebebeb', marginBottom: '14px' }}>
                                            <div style={{ margin: '0px 0 10px 10px', lineHeight: '23px' }}>
                                                <strong><p className="m-0">Mandatory KYC Documents (Required by DOT, in absence service will not be commissioned)</p></strong>
                                                <p className="m-0"> 1.	MOA of the company or Certificate of Incorporation</p>
                                                <p className="m-0"> 2.	Company PAN Card</p>
                                                <p className="m-0"> 3.	Billing Address Proof</p>
                                                <p className="m-0"> 4.	ID Proof of Signing Authority</p>
                                                <p className="m-0"> 5.	GST Detail</p>
                                            </div>
                                        </div>
                                        <div class="table-responsive" style={{ backgroundColor: '#ebebeb', marginBottom: '14px' }}>
                                            <div style={{ margin: '0px 0 10px 10px', lineHeight: '23px' }}>
                                                <strong><p className="m-0">Payment Terms:.</p></strong>
                                                <p className="m-0"> Order Form shall be valid/entertained only when the same be accompanied with full remittance, in favour of India Exposition Mart Limited Payable at Greater Noida, For IMPS/NEFT/RTGS/ Demand Draft/ Cheque bank details is as follows: -</p>
                                                <p className="m-0"><strong>Please Note</strong>: 1 Port is equivalent to 1 Mbps</p>
                                            </div>
                                        </div>

                                        <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-3 mt-1">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}> <strong>Bank Detail:   </strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    <tr>
                                                        <td style={{ width: '50%' }}>
                                                            <strong>
                                                                Bank Account Number
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            {AccountNumber}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                Name of Bank
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            {BankName}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                Bank Branch
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            {Branch}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                Bank Address
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            {BankAddress}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                RTGS/IFSC code
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            {RTGS_IFSC}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div style={{ margin: '10px 0 5px 0', lineHeight: '23px', marginBottom: '14px' }}>
                                            <div >
                                                <strong><p className="m-0">Exhibitor Company Name</p></strong>
                                                <p className="m-0 p-1" style={{ backgroundColor: '#ebebeb' }}>{ExhibitorCompanyName && ExhibitorCompanyName != null ? ExhibitorCompanyName : '-'}</p>
                                            </div>
                                            <div >
                                                <strong><p className="m-0">Hall No</p></strong>
                                                <p className="m-0 p-1" style={{ backgroundColor: '#ebebeb' }}>{HallName && HallName != null ? HallName : '-'}</p>
                                            </div>
                                            <div >
                                                <strong><p className="m-0">Stall No</p></strong>
                                                <p className="m-0 p-1" style={{ backgroundColor: '#ebebeb' }}>{StallName && StallName != null ? StallName : '-'}</p>
                                            </div>
                                            <div >
                                                <strong><p className="m-0">Address 1</p></strong>
                                                <p className="m-0 p-1" style={{ backgroundColor: '#ebebeb' }}>{Address1 && Address1 != null ? Address1 : '-'}</p>
                                            </div>
                                            <div >
                                                <strong><p className="m-0">Address 2</p></strong>
                                                <p className="m-0 p-1" style={{ backgroundColor: '#ebebeb' }}>{Address2 && Address2 != null ? Address2 : '-'}</p>
                                            </div>
                                            <div >
                                                <strong><p className="m-0">City</p></strong>
                                                <p className="m-0 p-1" style={{ backgroundColor: '#ebebeb' }}>{City}</p>
                                            </div>
                                            <div >
                                                <strong><p className="m-0">Country</p></strong>
                                                <p className="m-0 p-1" style={{ backgroundColor: '#ebebeb' }}>{CountryName}</p>
                                            </div>
                                            <div >
                                                <strong><p className="m-0">Telephone</p></strong>
                                                <p className="m-0 p-1" style={{ backgroundColor: '#ebebeb' }}>{Telephone}</p>
                                            </div>
                                            <div >
                                                <strong><p className="m-0">Email</p></strong>
                                                <p className="m-0 p-1" style={{ backgroundColor: '#ebebeb' }}>{PrimaryEmail}</p>
                                            </div>
                                            <div >
                                                <strong><p className="m-0"><span className="required">*</span>Person Name</p></strong>
                                                <p className="m-0 p-1" style={{ backgroundColor: '#ebebeb' }}>
                                                    <input type='text' className="form-control w-30"
                                                        value={personName}
                                                        onChange={(e) => this.setState({ personName: e.target.value, personNameErr: '' })}
                                                        maxLength={60}
                                                    />
                                                </p>
                                                {<span className='text-danger text-start text-left d-block'>{personNameErr}</span>}
                                            </div>
                                            <div >
                                                <strong><p className="m-0"><span className="required">*</span>Designation</p></strong>
                                                <p className="m-0 p-1" style={{ backgroundColor: '#ebebeb' }}>
                                                    <input type='text' className="form-control w-30"
                                                        value={designation}
                                                        onChange={(e) => this.setState({ designation: e.target.value, designationErr: '' })}
                                                        maxLength={50}
                                                    />
                                                </p>
                                                {<span className='text-danger text-start text-left d-block'>{designationErr}</span>}
                                            </div>
                                            <div >
                                                <strong><p className="m-0"><span className="required">*</span>Mobile No</p></strong>
                                                <p className="m-0 p-1" style={{ backgroundColor: '#ebebeb' }}>
                                                    <input type='text' className="form-control w-30"
                                                        value={mobileNo}
                                                        onChange={(e) => this.setState({ mobileNo: e.target.value.replace(/\D/, ''), mobileNoErr: '' })}
                                                        maxLength={sessionStorage.getItem('CurrencyID') == 1 ? 10 : 11}
                                                    />
                                                </p>
                                                {<span className='text-danger text-start text-left d-block'>{mobileNoErr}</span>}
                                            </div>
                                            <div >
                                                <strong><p className="m-0"><span className="required">*</span>No. of Port or Voucher Requirement</p></strong>
                                                <p className="m-0 p-1" style={{ backgroundColor: '#ebebeb' }}>
                                                    <input type='text' className="form-control w-30"
                                                        value={voucherRequirement}
                                                        onChange={(e) => this.setState({ voucherRequirement: e.target.value.replace(/\D/, ''), voucherRequirementErr: '' })}
                                                        maxLength={30}
                                                    />
                                                </p>
                                                {<span className='text-danger text-start text-left d-block'>{voucherRequirementErr}</span>}
                                            </div>
                                            <div >
                                                <strong><p className="m-0">No. Of .Public IP (If Any)</p></strong>
                                                <p className="m-0 p-1" style={{ backgroundColor: '#ebebeb' }}>
                                                    <input type='text' className="form-control w-30"
                                                        value={noOfPublicIP}
                                                        onChange={(e) => this.setState({ noOfPublicIP: e.target.value.replace(/\D/, '') })}
                                                        maxLength={30}
                                                    />
                                                </p>
                                            </div>
                                            <div >
                                                <strong><p className="m-0"><span className="required">*</span>Duration</p></strong>
                                                <p className="m-0 p-1" style={{ backgroundColor: '#ebebeb' }}>
                                                    <input type='text' className="form-control w-30"
                                                        value={duration}
                                                        onChange={(e) => this.setState({ duration: e.target.value, durationErr: '' })}
                                                        maxLength={50}
                                                    />
                                                </p>
                                                {<span className='text-danger text-start text-left d-block'>{durationErr}</span>}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row m-1">
                                    <form>
                                        <strong> <label>Remark</label></strong>
                                        <textarea class="form-control"
                                            value={Remark}
                                            onChange={(e) => this.setState({ Remark: e.target.value })}
                                            maxLength={300}
                                            rows="3">
                                        </textarea>
                                        <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                            <input type="checkbox"
                                                checked={Is_Agree}
                                                onChange={(e) => this.setState({ Is_Agree: e.target.checked, Is_AgreeErr: '' })}
                                            />
                                            <strong>We hereby read & understood the above mentioned clauses & consequences. We accept & agree to abide by the same<span className="required">*</span>.</strong></label>{<span className='text-danger text-start text-left d-block mt-1'>{Is_AgreeErr}</span>}
                                    </form>
                                </div>

                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>
                            <div class='d-flex m-3'>

                                <button
                                    type="button"
                                    className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                    style={{ marginRight: 10 }}
                                    disabled={currentDate > DeadlineDate }
                                    onClick={() => this.handleSubmit()}
                                >
                                    Submit
                                </button>
                                <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { insertUpdateInternetConnection_action, getInternetConnectionDetails_action })(InternetConnection));