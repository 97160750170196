import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../utils/withRouter';
import Loader from '../../utils/Loader'
import { clearSessionStorage } from '../../utils/constants'
import Header from '../../common_components/Header';
import { emailValidator } from '../../utils/Validator'
import Modal from "react-modal";
import Footerwithdr from '../../common_components/Footerwithdr';
import HeaderWithLogo from '../../common_components/header_with_logo';
import AdminDrawer from '../../common_components/admin_module_drawer';
import { fill_action } from '../../actions/fillCombo_action'
import { Link } from "react-router-dom";
import { getAdminDashboardData_action } from "../../actions/admin_module_action/admin_dasboard_action/admin_dashboard_action"
import VendorExhibitionList from './vendor_exhibition_list/vendor_exhibition_list';
import VendorDashboard from './vendor_dashboard/vendor_dashboard';
import VendorDrawer from '../../common_components/vendor_module_drawer';
import ExhibitorDrawer from '../../common_components/exhibitor_module_drawer';


class VendorModule extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            summaryPage: 'vendorExhibitionList',
            // Input Fields
            pendingPaymentCount: "",
            loginAccesslist: []
        }
        this.getDashboardData = this.getDashboardData.bind(this)
        this.isRouteActive = this.isRouteActive.bind(this)
        this.toNavigate = this.toNavigate.bind(this)
        this.getModuleAccess = this.getModuleAccess.bind(this)
    }


    // On Component Load
    componentDidMount() {
        // this.getDashboardData()
    }

    // Get DashBoard Data
    getDashboardData() {
        this.setState({ isLoading: true })
        this.props.getAdminDashboardData_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Admin Dashboard Data ------->", data.data)
                    this.setState({
                        isLoading: false,
                        pendingPaymentCount: data.data[0]['Count_Of_Payment']
                    })
                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    isRouteActive(routePath) {
        console.log(this.props.location)
        return this.props.location.pathname == routePath
    }

    toNavigate() {
        const { summaryPage } = this.state
        if (summaryPage == 'vendorExhibitionList') {
            return <VendorExhibitionList />
        }
        else if (summaryPage == 'vendorDashboard') {
            return <VendorDashboard />
        }
        else {
            return <VendorExhibitionList />
        }
    }

    getModuleAccess(ModuleID) {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == ModuleID);
        if (moduleAccess) {
            return moduleAccess.ReadAccess;
        } else {
            return false
        }
        return false;
    };


    render() {
        const { isLoading, pendingPaymentCount, summaryPage } = this.state
        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                {/* <ExhibitorDrawer /> */}
                <>
                    {/* <AdminDrawer /> */}
                    {/*app-content open*/}
                    <div className="app-content with-dr-container com-height">
                        <div className="navg mt-0">
                            <ul className="nav nav-pills">
                                {/* <li onClick={() => this.setState({ summaryPage: 'vendorDashboard' })} className="nav-item ">
                                    {" "}
                                    <a className={summaryPage == 'vendorDashboard' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                        Dashboard{" "}
                                    </a>{" "}
                                </li> */}
                                <li onClick={() => this.setState({ summaryPage: 'vendorExhibitionList' })} className="nav-item ">
                                    {" "}
                                    <a className={summaryPage == 'vendorExhibitionList' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                        Vendor Exhibitor List{" "}
                                    </a>{" "}
                                </li>

                            </ul>
                        </div>
                        {this.toNavigate()}
                    </div>
                        <VendorDrawer />
                </>

                <Footerwithdr />

            </>
        );
    }


}

export default withRouter(connect(null, { getAdminDashboardData_action })(VendorModule));
