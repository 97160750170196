import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "../../../../../utils/constants";
import { getDefaultDelegateMeeting_action, getDelegateMeetingDetails_action, insertUpdateDelegateMeeting_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/delegate_meeting_action/delegate_meeting_action'
import Modal from "react-modal";
import { data } from "jquery";
import $ from 'jquery'
import { Tab, Nav, ToggleButton, ButtonGroup, Container, Row, Stack } from "react-bootstrap";
import moment from "moment";

// 
class DelegateMeetingAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            exhRegistrationID: '',
            exhibitionID: '',
            currentDate: moment().format('DD-MM-YYYY'),
            DeadlineDate: '',
            delegateMeetingID: '',

            delegateName: '',
            delegateDesignation: '',
            delegateCountry: '',
            conpanyProfile: '',
            industrySegment: '',
            interest: '',
            purposeOfVisit: '',
            meetingDateTimeList: [],
            mainTimeSlotList: [],
            StatusID: ''
        }
        this.handleCancel = this.handleCancel.bind(this)
        this.getDelegateMeetingDetails = this.getDelegateMeetingDetails.bind(this)
        this.onSelect = this.onSelect.bind(this)
        this.insertUpdateDelegateMeeting = this.insertUpdateDelegateMeeting.bind(this)

    }

    componentDidMount() {
        console.log(parseInt(sessionStorage.getItem('ExhRegistrationIDSS')), 'll')
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ? this.props.location.state.Deadline : '',
            delegateMeetingID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.delegateMeetingID != "" ? this.props.location.state.delegateMeetingID : '',
            delegateCountry: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.country != "" ? this.props.location.state.country : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ? this.props.location.state.StatusID : '',
        })
        this.loadscript()
        setTimeout(() => {
            this.getDelegateMeetingDetails()
        }, 200);
    }

    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }

    handleCancel() {
        this.props.navigate('/delegateMeeting')
    }

    getDelegateMeetingDetails() {
        const { delegateMeetingID } = this.state
        this.setState({ isLoading: true })
        this.props
            .getDelegateMeetingDetails_action(delegateMeetingID)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data)
                    var meetingList = data.data.meetingTime.length > 0 ? data.data.meetingTime : []
                    var datelist = []
                    for (var i = 0; i < meetingList.length; i++) {
                        if (!datelist.includes(meetingList[i].MeetingDate)) {
                            datelist.push(meetingList[i].MeetingDate)
                        }
                    }
                    var finalMeetingList = []
                    for (var i = 0; i < datelist.length; i++) {
                        var list = []
                        var obj = {}
                        for (var j = 0; j < meetingList.length; j++) {
                            if (datelist[i] == meetingList[j].MeetingDate) {
                                list.push(meetingList[j])
                            }
                        }
                        obj.meetingArr = list
                        obj.meetingDate = datelist[i]
                        finalMeetingList.push(obj)
                    }
                    console.log(finalMeetingList)
                    this.setState({
                        delegateName: data.data.delegateInformation.PersonInchargeName,
                        delegateDesignation: data.data.delegateInformation.Designation,
                        // delegateCountry: delegateInformation,
                        conpanyProfile: data.data.delegateInformation.CompanyProfile,
                        industrySegment: data.data.delegateInformation.IndustrySegment,
                        interest: data.data.delegateInformation.Interest,
                        purposeOfVisit: data.data.delegateInformation.PurposeOfVisit,
                        meetingDateTimeList: finalMeetingList,
                        mainTimeSlotList: meetingList
                    })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    insertUpdateDelegateMeeting() {
        const { meetingDateTimeList, delegateMeetingID, mainTimeSlotList } = this.state
        var deleghateList = []
        console.log(meetingDateTimeList)
        for (var i = 0; i < meetingDateTimeList.length; i++) {
            for (var j = 0; j < (meetingDateTimeList[i].meetingArr.length); j++) {
                var obj = {}
                if (meetingDateTimeList[i].meetingArr[j].isApplied == 1) {
                    obj.DelegateTimeSlotID = meetingDateTimeList[i].meetingArr[j].DelegateTimeslotID
                    obj.DelegateMeetingID = delegateMeetingID
                    obj.IsBook = 1
                    deleghateList.push(obj)
                }
            }
        }
        if (deleghateList.length != 0) {
            this.setState({ isLoading: true })
            this.props
                .insertUpdateDelegateMeeting_action(deleghateList)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
            this.setState({ isLoading: false })
        } else {
            toastError("Please select atleast 1 timeSlot")
        }
    }

    onSelect(data) {
        console.log(data)
        const { meetingDateTimeList } = this.state
        for (var i = 0; i < meetingDateTimeList.length; i++) {
            if (meetingDateTimeList[i].meetingDate == data.MeetingDate) {
                for (var j = 0; j < (meetingDateTimeList[i].meetingArr.length); j++) {
                    if (meetingDateTimeList[i].meetingArr[j].MeetingFromTime == data.MeetingFromTime && meetingDateTimeList[i].meetingArr[j].MeetingToTime == data.MeetingToTime) {
                        { (meetingDateTimeList[i].meetingArr[j].isApplied == 0) ? meetingDateTimeList[i].meetingArr[j].isApplied = 1 : meetingDateTimeList[i].meetingArr[j].isApplied = 0 }
                    }
                }
            }
        }
        console.log(meetingDateTimeList)
        this.setState({ meetingDateTimeList: meetingDateTimeList })
    }


    render() {
        const { isLoading, DeadlineDate, currentDate, meetingDateTimeList, delegateCountry, delegateDesignation, delegateMeetingID, conpanyProfile, delegateName, industrySegment, interest, purposeOfVisit,StatusID } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content app-content mt-0 with-dr-container">
                    <div className="">
                        <div className="row">
                            <ExhibitorTopMenu />
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Delegate Meeting Application</h3>
                                    </div>
                                    <div className="row" style={{ padding: '12px' }}>
                                        <div className="form-row">
                                            <div className="col-xl-4 mb-1">
                                                <label htmlFor="validationCustom01">
                                                    <strong>Delegate Name</strong>
                                                </label>
                                                {
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        maxLength={100}
                                                        required=""
                                                        value={delegateName}
                                                        disabled={true}
                                                    // onChange={e => this.setState({ companyName: e.target.value })}
                                                    />
                                                }
                                            </div>
                                            <div className="col-xl-4 mb-1">
                                                <label htmlFor="validationCustom01">
                                                    <strong>Delegate Designation</strong>
                                                </label>
                                                {
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        maxLength={100}
                                                        required=""
                                                        disabled={true}
                                                        value={delegateDesignation}
                                                    // onChange={e => this.setState({ Designation: e.target.value })}
                                                    />
                                                }
                                            </div>
                                            <div className="col-xl-4 mb-1">
                                                <label htmlFor="validationCustom01">
                                                    <strong>Delegate Country</strong>
                                                </label>
                                                {
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        maxLength={100}
                                                        required=""
                                                        disabled={true}
                                                        value={delegateCountry}
                                                    // onChange={e => this.setState({ Designation: e.target.value })}
                                                    />
                                                }
                                            </div>

                                            <div className="col-xl-12 mb-1  mt-2">
                                                <label htmlFor="validationCustom01">
                                                    <strong>Company Profile</strong>
                                                </label>
                                                {
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        disabled={true}
                                                        rows={parseInt(conpanyProfile.length / 100) == 0 ? 1 : parseInt(conpanyProfile.length / 100)}
                                                        // maxLength={100}
                                                        required=""
                                                        // style={{ height: '500px' }}
                                                        value={conpanyProfile}
                                                    // onChange={e => this.setState({ Designation: e.target.value })}
                                                    />
                                                }
                                                {console.log(parseInt(interest.length / 100), 'ppppp')}
                                            </div>
                                            <hr></hr>
                                            <div className="col-xl-12 mb-1  mt-2">
                                                <label htmlFor="validationCustom01">
                                                    <strong>Industry Segment</strong>
                                                </label>
                                                {
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        disabled={true}
                                                        // maxLength={100}
                                                        rows={parseInt(industrySegment.length / 100) == 0 ? 1 : parseInt(industrySegment.length / 100)}
                                                        required=""
                                                        value={industrySegment}
                                                    // onChange={e => this.setState({ Designation: e.target.value })}
                                                    />
                                                }
                                            </div>
                                            <div className="col-xl-12 mb-1  mt-2">
                                                <label htmlFor="validationCustom01">
                                                    <strong>Interest</strong>
                                                </label>
                                                {
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        disabled={true}
                                                        // maxLength={100}
                                                        rows={parseInt(interest.length / 100) == 0 ? 1 : parseInt(interest.length / 100)}

                                                        required=""
                                                        value={interest}
                                                    // onChange={e => this.setState({ Designation: e.target.value })}
                                                    />
                                                }
                                            </div>
                                            <div className="col-xl-12 mb-1  mt-2">
                                                <label htmlFor="validationCustom01">
                                                    <strong>Purpose of Visit</strong>
                                                </label>
                                                {
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        disabled={true}
                                                        // maxLength={100}
                                                        rows={parseInt(purposeOfVisit.length / 100) == 0 ? 1 : parseInt(purposeOfVisit.length / 100)}
                                                        required=""
                                                        value={purposeOfVisit}
                                                    // onChange={e => this.setState({ Designation: e.target.value })}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <hr className="mt-3 mb-3"></hr>
                                        {meetingDateTimeList.length > 0 && meetingDateTimeList.map((data) => (
                                            <>
                                                <div className="col-xl-12 mb-2  mt-2">
                                                    <label htmlFor="validationCustom01">
                                                        <h4>Meeting Date ({moment(data.meetingDate).format('Do MMMM YYYY')})</h4>
                                                    </label>
                                                    {/* <ButtonGroup className="mb-2"> */}
                                                    <div className="form-row">
                                                        {data.meetingArr.length > 0 && data.meetingArr.map((item, id) => (
                                                            <div className='col-sm-2 m-2'>
                                                                <ToggleButton
                                                                    className={`p-2 rounded-5 ${item.IsSlotBook && 'text-white'} `}
                                                                    id={item.DelegateTimeslotID}
                                                                    type="checkbox"
                                                                    variant={!item.IsSlotBook ? "outline-primary" : "outline-secondary"}
                                                                    disabled={item.IsSlotBook}
                                                                    checked={item.isApplied}
                                                                    value={item.DelegateTimeslotID}
                                                                    onClick={(e) => this.onSelect(item)}
                                                                >
                                                                    {new Date(item.MeetingFromTime).toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' })} TO  {new Date(item.MeetingToTime).toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' })}
                                                                </ToggleButton>
                                                            </div>
                                                        ))}
                                                        {/* </ButtonGroup> */}
                                                    </div>
                                                </div>
                                            </>
                                        ))}

                                    </div>
                                    <div class="card-body p-1 m-2">
                                        <button type="button"
                                            onClick={(e) => this.insertUpdateDelegateMeeting(e)}
                                            className="btn btn-primary m-2 rounded-0"
                                            // disabled={StatusID == 2}
                                        >
                                            Submit
                                        </button>
                                        <button type="button"
                                            onClick={(e) => this.handleCancel(e)}
                                            className="btn btn-primary m-2 rounded-0"
                                        >
                                            cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>
                            <div class='d-flex m-3'>
                                {/* <button className="btn rounded-0 d-block mt-1 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button> */}

                            </div>

                        </div>
                    </div>
                </div >
            </>
        )
    }
}

export default withRouter(connect(null, { getDefaultDelegateMeeting_action, getDelegateMeetingDetails_action, insertUpdateDelegateMeeting_action })(DelegateMeetingAU))