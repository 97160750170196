import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "../../../../../utils/constants";
import { getHostAndHostessDetails_action, insertUpdateHostAndHostess_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/hostAndHostess_action/hostAndHostess_action'
import Modal from "react-modal";
import $ from 'jquery'
import moment from "moment";
// 
class HostHostessRequirements extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            PowerConnectionList: [],
            Is_Agree: false,
            Remark: '',
            CGST: '',
            SGST: '',
            Is_AgreeErr: '',
            DeadlineDate: "",
            Is_Required: true,
            carPassessId: '',
            currentDate: moment().format('DD-MM-YYYY'),
            AgencyHostAndHostessID: '',
            AgencyDetails: [],
            CompanyName: '',
            ContactDetailsList: '',
            tableData: '',
            StatusID: '',
        }
        // this.getPowerConnectionList = this.getPowerConnectionList.bind(this)

    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ? moment(this.props.location.state.Deadline).format('DD-MM-YYYY') : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ? this.props.location.state.StatusID : '',
        })

        this.getHostAndHostessDetails()
        this.loadscript()
    }
    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }


    handleCancel() {
        this.props.navigate('/manualForms')
    }

    inputValidate() {
        const { Is_Agree } = this.state

        if (Is_Agree == 0 || Is_Agree == false) {
            this.setState({ Is_AgreeErr: 'Kindly agree the above disclaimer for Hoste & Hostess Services Provider' })
        }
    }

    getHostAndHostessDetails() {
        const { Remark, Is_Agree, isQuantity, Is_Required } = this.state
        this.setState({ isLoading: true })
        this.props
            .getHostAndHostessDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    this.setState({
                        AgencyHostAndHostessID: data.noteDetails[0]['AgencyHostAndHostessID'],
                        AgencyDetails: data.noteDetails,
                        CompanyName: data.companyName[0]['CompanyName'],
                        ContactDetailsList: data.contactDetails,

                    })
                    if (data.tableData != '') {
                        const aaray = []
                        data.tableData.map((item) => {
                            data.data.map((obj) => {
                                if (item.HostAndHostessCategoryID == obj.HostAndHostessCategoryID) {
                                    obj.IsCheckBox = 1
                                }
                            })
                        })
                        this.setState({ tableData: data.data })
                    } else {
                        this.setState({ tableData: data.data, })
                    }
                    if (data.getDetails != '') {
                        this.setState({
                            Remark: data.getDetails[0]['Remark'],
                            Is_Agree: data.getDetails[0]['Is_Agree'],
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    addHostAndHostess() {
        const { Remark, Is_Agree, AgencyHostAndHostessID, tableData } = this.state
        const array = []
        tableData.length > 0 && tableData != '' ?
            tableData.map((data) => {
                if (data.IsCheckBox == 1) {
                    array.push({
                        'HostAndHostessCategoryID': data.HostAndHostessCategoryID,
                        'Price': data.Price
                    })
                }
            })
            : <></>
        this.inputValidate()
        this.setState({ isLoading: true })
        if (Is_Agree) {
            this.props
                .insertUpdateHostAndHostess_action(Remark, Is_Agree, array)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            toastError("Plase Enter mandatory feilds!")
            this.inputValidate()
            this.setState({ isLoading: false })
        }
    }

    Is_cheked(e, index) {
        const { tableData } = this.state
        const event = e.target.checked
        tableData.map((data, i) => {
            if (i === index) {
                if (event) {
                    data.IsCheckBox = 1
                } else {
                    data.IsCheckBox = 0
                }
            }
        })

        this.setState({ tableData: tableData })
    }

    render() {
        const { isLoading, Is_Agree, Remark, Is_AgreeErr, currentDate, DeadlineDate, AgencyHostAndHostessID,
            AgencyDetails,
            CompanyName,
            ContactDetailsList,
            tableData, StatusID } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content app-content mt-0 with-dr-container">
                    <div className="">
                        <div className="row">
                            <ExhibitorTopMenu />
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Hoste Hostess Requirement</h3>
                                    </div>
                                    <div class="card-body p-1 m-2">
                                        <ul style={{ lineHeight: '25px', textAlign: 'left', padding: 0 }}>
                                            <li>1) The cost/rates and terms & conditions of utilizing the individual services from the given service provider is solely discretion of exhibitors.
                                            </li>
                                            <li>2) India ITME Society is only information facilitator for the service provider & In no event shall INDIA ITME SOCIETY be liable for any direct, indirect, punitive, incidental, special consequential issues, whatsoever arising out of taking services with the above given service provider.
                                            </li>
                                        </ul>

                                        <div class="table-responsive" style={{ backgroundColor: '#ebebeb', marginBottom: '14px' }}>
                                            <div style={{ margin: '10px 0 10px 10px', lineHeight: '23px' }}>
                                                <strong><p className="m-0">Note:-</p></strong>
                                                <p>
                                                    <ul style={{ listStyleType: 'disc' }}>
                                                        <li>Food Need to be provided by the exhibitor</li>
                                                        <li>Taxes as per actuals.</li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>

                                        <div style={{ margin: '10px 0 10px 10px', lineHeight: '23px' }}>
                                            <p className="m-0">Dear <strong>{CompanyName}</strong>,</p>
                                            <p className="m-0">Kindly find the below details Agency for Hoste & Hostess Services Providers.</p>
                                            {AgencyDetails.map((data) => (
                                                <>
                                                    <p className="m-0">Agency Name: {data.AgencyName}</p>
                                                    <p className="m-0">Email id: {data.AgencyEmail}</p>
                                                </>
                                            ))}
                                        </div>

                                        <div class="table-responsive" style={{ backgroundColor: '#ebebeb', marginBottom: '14px' }}>
                                            <div style={{ margin: '10px 0 10px 10px', lineHeight: '23px' }}>
                                                <strong><p className="m-0">Contact Details:-</p></strong>
                                                {
                                                    ContactDetailsList.length > 0 ?
                                                        ContactDetailsList.map((data) => (
                                                            <p className="m-0">{data.Name}- {data.Mobile}</p>
                                                        ))
                                                        : <></>
                                                }
                                            </div>
                                        </div>

                                        <div class="table-responsive">
                                            <p className="m-0">Charges for Hoste & Hostess Per Person per day as below</p>
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1 w-50">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '5%' }}></th>
                                                        <th style={{ width: '80%' }}>Perticular</th>
                                                        <th >Cost {JSON.parse(sessionStorage.getItem("loginDataSS")).CountryID == 91 ? '(INR)' : '(USD)'}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tableData.length > 0 && tableData != '' ?
                                                            tableData.map((data, i) => (
                                                                <tr>
                                                                    <td>
                                                                        <input type="checkbox" className="m-0"
                                                                            checked={data.IsCheckBox}
                                                                            onChange={(e) => this.Is_cheked(e, i)}
                                                                        />
                                                                    </td>
                                                                    <td>{data.CategoryName}</td>
                                                                    <td className="text-center">{JSON.parse(sessionStorage.getItem("loginDataSS")).CountryID == 91 ? data.Price : data.Price}</td>
                                                                </tr>
                                                            ))
                                                            : <tr><td className="text-center" colSpan={8}>No record found</td></tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        {/* <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-3 w-50">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '5%' }}></th>
                                                        <th style={{ width: '80%' }}>Hoste for Exhibitor</th>
                                                        <th >Cost</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tableData.length > 0 && tableData != '' ?
                                                            tableData.map((data, i) => (
                                                                data.Type == 'Hoste' ?
                                                                    <tr>
                                                                        <td>
                                                                            <input type="checkbox" className="m-0"
                                                                                checked={data.IsCheckBox}
                                                                                onChange={(e) => this.Is_cheked(e, i)}
                                                                            />
                                                                        </td>
                                                                        <td>{data.CategoryName}</td>
                                                                        <td>{data.Price}</td>
                                                                    </tr>
                                                                    : <></>
                                                            ))
                                                            : <tr><td className="text-center" colSpan={8}>No record found</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div> */}


                                        {/* <div class="table-responsive">
                                            <p className="m-0">Charges for Hoste & Hostess Per Person per day as below</p>
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-3 w-50">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '5%' }}>Sr. No.</th>
                                                        <th style={{ width: '80%' }}>Particular</th>
                                                        <th >Cost {JSON.parse(sessionStorage.getItem("loginDataSS")).CountryID == 91 ? '(INR)' : '(USD)'}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr >
                                                        <td className="text-center">1</td>
                                                        <td >Hoste</td>
                                                        <td className="text-center">{JSON.parse(sessionStorage.getItem("loginDataSS")).CountryID == 91 ? '2000' : '30'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">2</td>
                                                        <td >Hostess</td>
                                                        <td className="text-center">{JSON.parse(sessionStorage.getItem("loginDataSS")).CountryID == 91 ? '2500' : '50'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> */}
                                    </div>
                                </div>
                                <div class="row m-1">
                                    <form>
                                        <strong> <label>Remark</label></strong>
                                        <textarea class="form-control"
                                            value={Remark}
                                            onChange={(e) => this.setState({ Remark: e.target.value })}
                                            maxLength={300}
                                            rows="3">
                                        </textarea>
                                        <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                            <input type="checkbox"
                                                checked={Is_Agree}
                                                onChange={(e) => this.setState({ Is_Agree: e.target.checked, Is_AgreeErr: '' })}
                                            />
                                            <strong> I agree the above disclaimer for Hoste & Hostess Services Provider<span className="required">*</span>.</strong></label>{<span className='text-danger text-start text-left d-block mt-1'>{Is_AgreeErr}</span>}
                                    </form>
                                </div>

                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>


                            <div class='d-flex m-3'>

                                <button
                                    type="button"
                                    className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                    style={{ marginRight: 10 }}
                                    disabled={currentDate > DeadlineDate}
                                    onClick={() => this.addHostAndHostess()}
                                >
                                    Submit
                                </button>
                                <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { getHostAndHostessDetails_action, insertUpdateHostAndHostess_action })(HostHostessRequirements));