import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { EXHIBITOR_DETAIL_ACTION_TYPE, ExhibitorDetailsUrl, GENERATE_HALLNO_STALLNO_ACTION_TYPE, GENERATE_PRIORITY_NO_ACTION_TYPE, GET_EXHIBITOR_LIST_ACTION_TYPE, SEND_USER_CRED_ACTION_TYPE, SUBMIT_AUTO_PRIORITY_NO_ACTION_TYPE, SUBMIT_MANUAL_PRIORITY_NO_ACTION_TYPE, GET_EXH_REG_LIST_ACTION_TYPE, GENERATE_OPENSIDE_ACTION_TYPE, SUBMIT_PRIORITY_NO_ACTION_TYPE, generateHallNoStallNoUrl, generatePriorityNoUrl, resendPriorityNoUrl, getExhibitorListUrl, headers, sendUserCredUrl, submitAutoPriorityNoUrl, submitManualPriorityNoUrl, submitPriorityNoUrl, getExhRegListUrl, generateOpenSideUrl, addEdirectoryManualUrl, ADD_EDIRECTORY_MANUAL_ACTION_TYPE, addFasciaManualUrl, ADD_FASCIA_MANUAL_ACTION_TYPE, activateExhibitorsUrl, submitDiscountUrl, SUBMIT_DISCOUNT_ACTION_TYPE, ACTIVATE_EXHIBITORS_ACTION_TYPE, deactivateExhibitorsUrl, DEACTIVATE_EXHIBITORS_ACTION_TYPE, activateDeactivateExhibitorsUrl, submitOpenSidePIUrl, GENERATE_OPEN_SIDE_PI_ACTION_TYPE, ACTIVATE_DEACTIVATE_EXHIBITORS_ACTION_TYPE, SSI_DOCUMENT_APPROVAL, SSIDiscountApproveUrl, generate2ndPIUrl, updateStallTypeAreaUrl, UPDATE_STALL_TYPE_AREA, sendhallStallCredUrl, SEND_USER_HALL_STALL_ACTION_TYPE, ROMOVED_EDIRECTORY_MANUAL_ACTION_TYPE, removedEdirectoryManualUrl } from "../../../utils/constants";




export const exhibitorList_action = (ExhibitionID, exhibitor, PaymentTypeID, statusId, userName, exhibitorTypeId, boothTypeId, HallID,
    agentId, chapter, countryId, exhREgNo, stallNumber, fromArea, toArea, tabName, keyword) => async (dispatch) => {
        try {
            const params = {
                ExhibitionID: ExhibitionID != '' && ExhibitionID != null ? ExhibitionID : sessionStorage.getItem("sessionExhibitionID"),
                ExhibitorName: exhibitor,
                UserName: userName,
                status: statusId,
                ExhibitorType: exhibitorTypeId,
                BoothType: boothTypeId,
                HallID: HallID,
                AccountID: agentId,
                ChapterName: chapter,
                CountryID: countryId,
                ExhRegistrationNo: exhREgNo,
                StallNo: stallNumber,
                FromStallArea: fromArea != "" ? parseFloat(fromArea) : null,
                ToStallArea: toArea != "" ? parseFloat(toArea) : null,
                PaymentTypeID: PaymentTypeID,
                TabName: tabName,
                Keyword: keyword,
                // ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            }
            console.log("exhibitorList_action -Action--------------------------->", params)

            const res = await axiosGet(getExhibitorListUrl, params, headers);
            dispatch({
                type: GET_EXHIBITOR_LIST_ACTION_TYPE,
                payload: res,
            });
            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    }

export const getExhRegList_action = () => async (dispatch) => {
    try {
        const params = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getExhRegList_action -Action--------------------------->", params)

        const res = await axiosGet(getExhRegListUrl, params, headers);
        dispatch({
            type: GET_EXH_REG_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const SSIDocumentApproval_action = (Exhid, status, rejectReason) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: Exhid,
            SSIDiscountStatusID: status,
            SSIDiscountRejectReason: rejectReason,

        }
        console.log("getExhRegList_action -Action--------------------------->", params)
        const res = await axiosGet(SSIDiscountApproveUrl, params, headers);
        dispatch({
            type: SSI_DOCUMENT_APPROVAL,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const getExhiitorDetails_action = (ExhRegID) => async (dispatch) => {
    try {
        const params = {
            ExhRegistrationID: ExhRegID
        }
        console.log("getExhiitorDetails_action -Action--------------------------->", params)
        const res = await axiosGet(ExhibitorDetailsUrl, params, headers);
        dispatch({
            type: EXHIBITOR_DETAIL_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const generatePriorityNo_action = () => async (dispatch) => {
    try {
        const params = {
            // UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            // AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            // Exhibitor: exhibitor,
            // ProformaInvoiceDate: proformaDate != "" ? (moment(proformaDate).format('MM-DD-YYYY')) : null,
            // ProformaInvoiceNo: proformaNo
        }
        console.log("exhibitorList_action -Action--------------------------->", params)

        const res = await axiosGet(generatePriorityNoUrl, params, headers);
        dispatch({
            type: GENERATE_PRIORITY_NO_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const resendPriorityNo_action = (exhregid) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitonID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: exhregid,
        }
        console.log("submitAutoPriorityNo_action -Action--------------------------->", mapData)

        const res = await axiosPost(resendPriorityNoUrl, mapData, headers);
        dispatch({
            type: SUBMIT_AUTO_PRIORITY_NO_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const submitDiscount_action = (ExhibitionID, exhregid, Discountpercent) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            ExhregistrationID: exhregid,
            Discountpercent: Discountpercent
        }
        console.log("submitManualPriorityNo_action -Action--------------------------->", mapData)

        const res = await axiosPost(submitDiscountUrl, mapData, headers);
        dispatch({
            type: SUBMIT_DISCOUNT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const submitOpenSidePI_action = (ExhibitionID, exhregid) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            ExhregistrationID: exhregid,
        }
        console.log("submitManualPriorityNo_action -Action--------------------------->", mapData)

        const res = await axiosPost(submitOpenSidePIUrl, mapData, headers);
        dispatch({
            type: GENERATE_OPEN_SIDE_PI_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const submitManualPriorityNo_action = (id, exhregid) => async (dispatch) => {
    try {
        const mapData = {
            ID: id,
            ExhregistrationID: exhregid,
        }
        console.log("submitManualPriorityNo_action -Action--------------------------->", mapData)

        const res = await axiosPost(submitManualPriorityNoUrl, mapData, headers);
        dispatch({
            type: SUBMIT_MANUAL_PRIORITY_NO_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const submitAutoPriorityNo_action = (exhregid) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitonID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: exhregid,
        }
        console.log("submitAutoPriorityNo_action -Action--------------------------->", mapData)

        const res = await axiosPost(submitAutoPriorityNoUrl, mapData, headers);
        dispatch({
            type: SUBMIT_AUTO_PRIORITY_NO_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const generate2ndPI_action = (exhregid) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: exhregid,
        }
        console.log("generate2ndPI_action -Action--------------------------->", mapData)

        const res = await axiosPost(generate2ndPIUrl, mapData, headers);
        dispatch({
            type: SUBMIT_AUTO_PRIORITY_NO_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const sendUserCred_action = (ExhRegID, flag) => async (dispatch) => {
    try {
        const params = {
            ExhibitionRegID: ExhRegID,
            flag: flag
        }
        console.log("sendUserCred_action -Action--------------------------->", params)
        const res = await axiosGet(sendUserCredUrl, params, headers);
        dispatch({
            type: SEND_USER_CRED_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const sendhallstallUserCred_action = (ExhRegID, hall, stall) => async (dispatch) => {
    try {
        const params = {
            ExhibitionRegID: ExhRegID,
            hall: hall,
            stall: stall,
            // flag: flag
        }
        console.log("sendUserCred_action -Action--------------------------->", params)
        const res = await axiosGet(sendhallStallCredUrl, params, headers);
        dispatch({
            type: SEND_USER_HALL_STALL_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const generateHallNoStallNo_action = (ExhRegID, hallNo, stallNo) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: ExhRegID,
            HallId: hallNo,
            StallId: stallNo
        }
        console.log("generateHallNoStallNo_action -Action--------------------------->", mapData)

        const res = await axiosPost(generateHallNoStallNoUrl, mapData, headers);
        dispatch({
            type: GENERATE_HALLNO_STALLNO_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const generateOpenSide_action = (ExhRegID, openSide) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: ExhRegID,
            OpenSide: openSide
        }
        console.log("generateHallNoStallNo_action -Action--------------------------->", mapData)

        const res = await axiosPost(generateOpenSideUrl, mapData, headers);
        dispatch({
            type: GENERATE_OPENSIDE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const addEdirectoryManual_action = (list) => async (dispatch) => {
    try {
        const mapData = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            exhibitorList: list
        }
        console.log("addEdirectoryManual_action -Action--------------------------->", mapData)

        const res = await axiosPost(addEdirectoryManualUrl, mapData, headers);
        dispatch({
            type: ADD_EDIRECTORY_MANUAL_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const addFasciaManual_action = (list) => async (dispatch) => {
    try {
        const mapData = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            exhibitorList: list
        }
        console.log("addFasciaManual_action -Action--------------------------->", mapData)

        const res = await axiosPost(addFasciaManualUrl, mapData, headers);
        dispatch({
            type: ADD_FASCIA_MANUAL_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const activateDeactivateExhibitors_action = (isActivated, list) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            isactivate: isActivated,
            exhibitorList: list
        }
        console.log("activateDeactivateExhibitors_action -Action--------------------------->", mapData)

        const res = await axiosPost(activateDeactivateExhibitorsUrl, mapData, headers);
        dispatch({
            type: ACTIVATE_DEACTIVATE_EXHIBITORS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const updateStallTypeArea_action = (ExhibitionID, Exhid, ExhibitionStallSizeID, StallArea, StallTypeID, StallDimension) => async (dispatch) => {
    try {
        const mapData = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            ExhregistrationID: Exhid,
            ExhibitionStallSizeID: ExhibitionStallSizeID,
            StallArea: StallArea,
            StallTypeID: StallTypeID,
            StallDimension: StallDimension,
        }

        const res = await axiosPost(updateStallTypeAreaUrl, mapData, headers);
        dispatch({
            type: UPDATE_STALL_TYPE_AREA,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const removedEdirectoryManual_action = (list) => async (dispatch) => {
    try {
        const mapData = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            exhibitorList: list
        }

        const res = await axiosPost(removedEdirectoryManualUrl, mapData, headers);
        dispatch({
            type: ROMOVED_EDIRECTORY_MANUAL_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}
