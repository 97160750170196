import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import {
    GET_WATER_CONNECTION_APPROVAL_DETAILS_ACTION_TYPE, GET_WATER_CONNECTION_APPROVAL_LIST_ACTION_TYPE, GET_WATER_CONNECTION_APPROVAL_EXPORT_DATA_ACTION_TYPE, UPDATE_WATER_CONNECTION_APPROVAL__ACTION_TYPE,
    getWaterConnectionApprovalDetailsUrl, getWaterConnectionApprovalListUrl, getWaterConnectionExportDataUrl, updateWaterConnectionApprovalUrl, headers
} from "../../../../utils/constants";

export const getWaterConnectionApprovalDetails_action = (companyName, status, exhibitorTypeId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyName,
            Status: status,
            ExhibitorTypeID: exhibitorTypeId,
        }

        const res = await axiosGet(getWaterConnectionApprovalDetailsUrl, params, headers)

        dispatch({
            type: GET_WATER_CONNECTION_APPROVAL_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getWaterConnectionApprovalList_action = (companyname) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyname,

        }
        console.log("getVisitorVisaApplication_action Action------->", params)

        const res = await axiosGet(getWaterConnectionApprovalListUrl, params, headers)

        dispatch({
            type: GET_WATER_CONNECTION_APPROVAL_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getWaterConnectionExportData_action = (status) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            Status: status,
        }
        console.log("getVisitorVisaisaApplicationExportData_action Action------->", params)

        const res = await axiosGet(getWaterConnectionExportDataUrl, params, headers)

        dispatch({
            type: GET_WATER_CONNECTION_APPROVAL_EXPORT_DATA_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateWaterConnectionApproval_action = (isApproved, list) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            Is_Approved: isApproved,
            approvalList: list
        }
        console.log("updateVisitorVisaApproval_action Action------->", mapData)

        const res = await axiosPost(updateWaterConnectionApprovalUrl, mapData, headers)

        dispatch({
            type: UPDATE_WATER_CONNECTION_APPROVAL__ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

