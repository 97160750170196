import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { selectExhibition_action } from '../../../actions/admin_module_action/exhibition_action/select_exhibition_action';
import { withRouter } from '../../../utils/withRouter';
import Loader from '../../../utils/Loader'
import { clearSessionStorage, toastError } from '../../../utils/constants'
import Header from '../../../common_components/Header';
import { emailValidator } from '../../../utils/Validator'
import Modal from "react-modal";
import Footerlogin from '../../../common_components/Footerlogin';
import AdminHeader from '../../../common_components/admin_module_header';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fill_action } from '../../../actions/fillCombo_action'
// import { format } from 'date-fns';

/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/


// const mapStateToProps = state => {
//   return {
//     loginData: state,
//   };
// }


class SelectExhibition extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            isLoading: false,
            exhibitionDataList: [],
            isLoginAccess: false,
        }
        this.handleViewExhibition = this.handleViewExhibition.bind(this)
        this.getExhibitionListData = this.getExhibitionListData.bind(this)
        this.formatDateRange = this.formatDateRange.bind(this)


    }


    componentDidMount() {
        clearSessionStorage('')
        this.getExhibitionListData()
        this.setState({ isLoginAccess: JSON.parse(sessionStorage.getItem("loginDataSS")) })
        console.log(JSON.parse(sessionStorage.getItem("loginDataSS")))
    }

    getExhibitionListData() {
        const { isLoading, isOpen, exhibitionDataList } = this.state
        this.setState({ isLoading: true })
        this.props
            .selectExhibition_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("0999", data.data)
                    this.setState({
                        exhibitionDataList: data.data, isLoading: false,
                    })
                    sessionStorage.setItem("sessionExhibitionID", data.data[0]['ExhibitionID'])
                    sessionStorage.setItem("exhibitionCode", data.data[0]['Code'])
                    sessionStorage.setItem("ExhibitionFromDateSS", data.data[0]['FromDate'])
                    sessionStorage.setItem("ExhibitionToDateSS", data.data[0]['ToDate'])
                    sessionStorage.setItem("ExhibitionAddressSS", data.data[0]['Address'])
                    sessionStorage.setItem("ExhibitionLogoSS", data.data[0]['Logo'])
                    sessionStorage.setItem("isDoubleStoreyExhibition", data.data[0]['isDoubleStorey'])
                    sessionStorage.setItem("isSSIcertificateExhibition", data.data[0]['isSSICertificate'])
                    sessionStorage.setItem("DoubleStoreyArea", data.data[0]['DoubleStoreyArea'])
                    sessionStorage.setItem("DoubleStoreyAreaPercentage", data.data[0]['DoubleStoreyAreaPercentage'])
                    sessionStorage.setItem("RulesAndRegulations", data.data[0]['RulesAndRegulations'])
                    sessionStorage.setItem("ExhibitionName", data.data[0]['ExhibitionName'])
                    if (data.data.length == 1) {


                        const roleId = JSON.parse(sessionStorage.getItem("loginDataSS")).RoleID
                        const roleName = JSON.parse(sessionStorage.getItem("loginDataSS")).RoleName
                        console.log('roleid and role name : ',roleId,' ',roleName);
                        if (roleId == 1 && roleName == "Exhibitor") {
                            this.props.navigate('/selectApplication')
                        } else if (roleId == 8 && roleName == "Partner") {
                            this.props.navigate('/selectApplication')
                        } else if (roleId == 3 && roleName == "Visitor") {
                            this.props.navigate('/visitordashboard')
                        } else if ((roleId == 2 && roleName == "Admin") || (roleId == 5 && roleName == "Visitor Admin") ||
                            (roleId == 6 && roleName == "Super Admin")) {
                            this.props.navigate('/dashboard')
                        } else if (roleId == 4 && roleName == "Agent") {
                            this.props.navigate('/agentdashboard')
                        } else if (roleId == 7 && roleName == "Vendor") {
                            this.props.navigate('/VendorModule')
                        }
                    } else {
                        console.log("111111111111111111111111111111")
                    }
                    setTimeout(() => {
                        this.setState({ isLoading: false })
                    }, 500);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    formatDateRange(dateRange) {
        // const [startDateStr, endDateStr] = dateRange.split(' - ');
        // const startDate = new Date(startDateStr);
        // const endDate = new Date(endDateStr);
        // const startFormatted = format(startDate, "do 'of' MMM");
        // const endFormatted = format(endDate, "do 'of' MMM yyyy");
        // console.log('09099',startFormatted ,endFormatted)
        // return (
        //   <strong>
        //     {startFormatted} - {endFormatted}
        //   </strong>DoubleStoreyArea
        // DoubleStoreyAreaPercentage
        // );
    }

    // redirect to dashboard
    handleViewExhibition(data, data2) {
        console.log("exhibition data", data)
        sessionStorage.setItem("sessionExhibitionID", data.ExhibitionID)
        sessionStorage.setItem("exhibitionCode", data.Code)
        sessionStorage.setItem("sessionExhibitionName", data.ExhibitionName)
        sessionStorage.setItem("ExhibitionFromDateSS", data.FromDate)
        sessionStorage.setItem("ExhibitionToDateSS", data.ToDate)
        sessionStorage.setItem("ExhibitionAddressSS", data.Address)
        sessionStorage.setItem("ExhibitionLogoSS", data.Logo)
        sessionStorage.setItem("isDoubleStoreyExhibition", data.isDoubleStorey)
        sessionStorage.setItem("isSSIcertificateExhibition", data.isSSICertificate)
        sessionStorage.setItem("DoubleStoreyArea", data.DoubleStoreyArea)
        sessionStorage.setItem("DoubleStoreyAreaPercentage", data.DoubleStoreyAreaPercentage)
        sessionStorage.setItem("RulesAndRegulations", data.RulesAndRegulations)
        sessionStorage.setItem("ExhibitionName", data.ExhibitionName)
        const loginDataSS = JSON.parse(sessionStorage.getItem("loginDataSS"))
        loginDataSS.RoleID = data2.RoleID
        loginDataSS.RoleName = data2.roleName
        sessionStorage.setItem("loginDataSS", JSON.stringify(loginDataSS))
        sessionStorage.setItem("RoleIDSS", loginDataSS.RoleID)
        const roleId = loginDataSS.RoleID
        const roleName = loginDataSS.RoleName
        if (roleId == 1 && roleName == "Exhibitor") {
            this.props.navigate('/selectApplication')
        } else if (roleId == 8 && roleName == "Partner") {
            this.props.navigate('/selectApplication')
        } else if (roleId == 3 && roleName == "Visitor") {
            this.props.navigate('/visitordashboard')
        } else if ((roleId == 2 && roleName == "Admin") || (roleId == 5 && roleName == "Visitor Admin") ||
            (roleId == 6 && roleName == "Super Admin")) {
            this.props.navigate('/dashboard')
        } else if (roleId == 4 && roleName == "Agent") {
            this.props.navigate('/agentdashboard')
        } else if (roleId == 7 && roleName == "Vendor") {
            this.props.navigate('/VendorModule')
        }
        else {
            this.props.navigate('/dashboard')
        }
    }

    render() {
        const { isLoading, isOpen, exhibitionDataList, isLoginAccess } = this.state
        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <AdminHeader isSteper={true} />

                {/*/APP-SIDEBAR*/}

                <div className="container top-space main-area-start">
                    <div className="row">
                        {exhibitionDataList.length > 0 && exhibitionDataList.map(data => (
                            <>
                                {data.isExhibitionLive ?
                                    <div className="col-md-3" >
                                        {console.log("data-->", data)}
                                        <div className="sel-app p-2">
                                            <div className='align-all-site'>
                                                <img src={data.Logo} />
                                                <button className="btn btn-primary date mt-3" type="submit">
                                                    <strong style={{ fontSize: '13px' }}>
                                                        {`${data.FromDate} - ${data.ToDate}`}
                                                        {/* { this.formatDateRange(`${data.FromDate} - ${data.ToDate}`)} */}
                                                    </strong>
                                                </button>
                                                <h3 className="loc mt-3" >
                                                    {data.Address}
                                                </h3>
                                                {data.isExhibitionClosed == 1 || isLoginAccess == 0 ? (
                                                    <a className='m-1'>
                                                        <button
                                                            type="button"
                                                            disabled={true}
                                                            className="btn btn-outline-dark mb-1 p-2"
                                                            style={{ width: "100%" }}
                                                        //  onClick={e => this.handleViewExhibition(data)}
                                                        >
                                                            View
                                                        </button>
                                                    </a>
                                                ) : (
                                                    <>
                                                        <div className=''>
                                                            {data.accessList.length > 0 && data.accessList.map(data2 => (
                                                                <>
                                                                    {data2.isExhibitionAccess == 0 ? (null) : (
                                                                        <a className='m-1'>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-dark mb-1 p-2"
                                                                                style={{ width: "100%" }}
                                                                                onClick={e => this.handleViewExhibition(data, data2)}
                                                                            >
                                                                                {data2.RoleAccess}
                                                                            </button>
                                                                        </a>

                                                                    )}

                                                                </>
                                                            ))}
                                                        </div>
                                                    </>

                                                )}

                                            </div>
                                        </div>
                                    </div>
                                    :
                                    sessionStorage.getItem('isIndirectAccess') != 'false' ?
                                        <>
                                            <div className="col-md-3">
                                                <div className="sel-app p-2">
                                                    <div className='align-all-site'>
                                                        <img src={data.Logo} />
                                                        <button className="btn btn-primary date mt-3" type="submit">
                                                            <strong style={{ fontSize: '13px' }}>
                                                                {`${data.FromDate} - ${data.ToDate}`}
                                                                {/* { this.formatDateRange(`${data.FromDate} - ${data.ToDate}`)} */}
                                                            </strong>
                                                        </button>
                                                        <h3 className="loc mt-3" >
                                                            {data.Address}
                                                        </h3>
                                                        {data.isExhibitionClosed == 1 || isLoginAccess == 0 ? (
                                                            <a className='m-1'>
                                                                <button
                                                                    type="button"
                                                                    disabled={true}
                                                                    className="btn btn-outline-dark mb-1 p-2"
                                                                    style={{ width: "100%" }}
                                                                //  onClick={e => this.handleViewExhibition(data)}
                                                                >
                                                                    View
                                                                </button>
                                                            </a>
                                                        ) : (
                                                            <>
                                                                <div className=''>
                                                                    {data.accessList.length > 0 && data.accessList.map(data2 => (
                                                                        <>
                                                                            {data2.isExhibitionAccess == 0 ? (null) : (

                                                                                <a className='m-1'>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-outline-dark mb-1 p-2"
                                                                                        style={{ width: "100%" }}
                                                                                        onClick={e => this.handleViewExhibition(data, data2)}
                                                                                    >
                                                                                        {data2.RoleAccess}
                                                                                    </button>
                                                                                </a>

                                                                            )}

                                                                        </>
                                                                    ))}
                                                                </div>
                                                            </>

                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : <></>
                                }
                            </>
                            // <div className="col-md-3">
                            //     <div className="sel-app p-2">
                            //         <div className='align-all-site'>
                            //             <img src={data.Logo} />
                            //             <button className="btn btn-primary date mt-3" type="submit">
                            //                 <strong style={{ fontSize: '13px' }}>
                            //                     {`${data.FromDate} - ${data.ToDate}`}
                            //                     {/* { this.formatDateRange(`${data.FromDate} - ${data.ToDate}`)} */}
                            //                 </strong>
                            //             </button>
                            //             <h3 className="loc mt-3" >
                            //                 {data.Address}
                            //             </h3>
                            //             {data.isExhibitionClosed == 1 || isLoginAccess == 0 ? (
                            //                 <a className='m-1'>
                            //                     <button
                            //                         type="button"
                            //                         disabled={true}
                            //                         className="btn btn-outline-dark mb-1 p-2"
                            //                         style={{ width: "100%" }}
                            //                     //  onClick={e => this.handleViewExhibition(data)}
                            //                     >
                            //                         View
                            //                     </button>
                            //                 </a>
                            //             ) : (
                            //                 <>
                            //                     <div className=''>
                            //                         {data.accessList.length > 0 && data.accessList.map(data2 => (
                            //                             <>
                            //                                 {data2.isExhibitionAccess == 0 ? (null) : (

                            //                                     <a className='m-1'>
                            //                                         <button
                            //                                             type="button"
                            //                                             className="btn btn-outline-dark mb-1 p-2"
                            //                                             style={{ width: "100%" }}
                            //                                             onClick={e => this.handleViewExhibition(data, data2)}
                            //                                         >
                            //                                             {data2.RoleAccess}
                            //                                         </button>
                            //                                     </a>

                            //                                 )}

                            //                             </>
                            //                         ))}
                            //                     </div>
                            //                 </>

                            //             )}

                            //         </div>
                            //     </div>
                            // </div>
                        ))
                        }
                    </div>
                </div>

                <Footerlogin />

            </>
        );
    }


}

export default withRouter(connect(null, { selectExhibition_action })(SelectExhibition));
