import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, BASE_URL } from "../../../../../utils/constants";
import { insertUpdateRentalAudioAndVideo_action, getRentalAudioAndVideoDetails_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/rentalAudioAndVideo_action/rentalAudioAndVideo_action'
import Modal from "react-modal";
import $ from 'jquery'
import moment from "moment";
// 
class RentalAudioAndVedioEquipment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            Is_Agree: false,
            Remark: '',
            CGST: '',
            SGST: '',
            Is_AgreeErr: '',
            DeadlineDate: "",
            Is_Required: true,
            carPassessId: '',
            currentDate: moment().format('DD-MM-YYYY'),
            tableList: [],
            agencyName: '',
            agencyEmail: '',
            exhibitorName: '',
            hallNo: '',
            stallNo: '',
            stallArea: '',
            stallType: '',
            openSides: '',
            Requirement: '',
            AgencyRentalAudioVideoID: '',
            contactDetailsList: [],
            url: '',
            StatusID: '',
        }
        // this.getPowerConnectionList = this.getPowerConnectionList.bind(this)

    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                moment(this.props.location.state.Deadline).format('DD-MM-YYYY') : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ?
                this.props.location.state.StatusID : '',
        })

        const url = BASE_URL.split('/api')
        this.setState({ url: url[0] })

        this.getRentalAudioAndVideoDetails()
        this.loadscript()
    }
    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }


    handleCancel() {
        this.props.navigate('/manualForms')
    }

    inputValidate() {
        const { Is_Agree } = this.state

        if (Is_Agree == 0 || Is_Agree == false) {
            this.setState({ Is_AgreeErr: 'kindly agree to disclaimer for rental audio and video equipments' })
        }
    }

    getRentalAudioAndVideoDetails() {
        const { Remark, Is_Agree, isQuantity, Is_Required } = this.state
        this.setState({ isLoading: true })
        this.props
            .getRentalAudioAndVideoDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    this.setState({
                        contactDetailsList: data.AgencyDetails,
                        exhibitorName: data.ExhibitorDetails[0]['CompanyName'],
                        hallNo: data.ExhibitorDetails[0]['HallName'],
                        stallNo: data.ExhibitorDetails[0]['StallName'],
                        stallArea: data.ExhibitorDetails[0]['StallArea'],
                        stallType: data.ExhibitorDetails[0]['StallTypeName'],
                        openSides: data.ExhibitorDetails[0]['OpenSides'],
                    })
                    if (data.data != '') {
                        this.setState({
                            Remark: data.data[0]['Remark'],
                            Is_Agree: data.data[0]['Is_Agree'],
                            Requirement: data.data[0]['Comment'],
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    addRentalAndVideoEquipment() {
        const { Remark, Is_Agree, AgencyRentalAudioVideoID, Requirement } = this.state
        this.inputValidate()
        this.setState({ isLoading: true })
        if (Is_Agree) {
            this.props
                .insertUpdateRentalAudioAndVideo_action(null, Remark, Is_Agree)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            toastError("Plase Enter mandatory feilds!")
            this.inputValidate()
            this.setState({ isLoading: false })
        }
    }

    openImageInNextTab(path) {
        const { url } = this.state
        window.open(`${url}${path}#toolbar=0&navpanes=0`, '_blank');
    }

    render() {
        const { isLoading, Is_Agree, Remark, Is_AgreeErr, currentDate, DeadlineDate, tableList, Is_Agree2, Is_Agree2Err, agencyName, agencyEmail, exhibitorName, hallNo, stallNo, stallArea, stallType, openSides, Requirement, contactDetailsList, url, StatusID } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content app-content mt-0 with-dr-container">
                    <div className="">
                        <div className="row">
                            <ExhibitorTopMenu />
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Rental Audio And Video Equipment Form</h3>
                                    </div>
                                    <div class="card-body p-1 m-2">
                                        <div style={{ lineHeight: '10px' }}>
                                            <p>1) Please send your remittance directly to official shell contractor. Please do not send remittance to India ITME 2022.
                                            </p>
                                            <p>2) Photos/Picture of the rental equipments wherever given are only for demonstration & not actual.
                                            </p>
                                            <p>3) Exhibitors are requested to contact their respective contractor for technical specifications of the rental audio & Video equipments.
                                            </p>
                                        </div>
                                        <p className="m-0"><strong>Agency Contact Details: :</strong></p>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {contactDetailsList != '' && contactDetailsList.length > 0 ?
                                                contactDetailsList.map((data) => (
                                                    <>
                                                        <div class="table-responsive w-50" style={{ backgroundColor: '#ebebeb', marginBottom: '14px', borderRight: '1px solid lightgray' }}>
                                                            <div className="d-flex" style={{ margin: '10px 0 10px 10px', lineHeight: '23px', justifyContent: 'space-between' }}>
                                                                <div>
                                                                    <p className="m-0"><strong>Name:</strong>  {data.AgencyName} </p>
                                                                    <p className="m-0"><strong>Email:</strong>  {data.AgencyEmail}</p>
                                                                </div>
                                                                {data.AgencyRentalAudioVideoImage != null ?
                                                                    <div className="m-2" >
                                                                        <iframe
                                                                            title="Passport Front Page"
                                                                            src={`${url}${data.AgencyRentalAudioVideoImage}#toolbar=0&navpanes=0`}
                                                                            // src='https://ems_uat.india-itme.com/document/Circular/10/Circular_10.pdf#toolbar=0&navpanes=0'
                                                                            style={{ width: "220px", height: "250px", cursor: 'pointer' }}
                                                                            id="myIframe"
                                                                        />
                                                                        <div
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '124px',
                                                                                width: '195px',
                                                                                height: '227px',
                                                                                cursor: 'pointer',
                                                                                backgroundColor: 'transparent',
                                                                            }}

                                                                            onClick={(e) => this.openImageInNextTab(data.AgencyRentalAudioVideoImage)}
                                                                        />
                                                                    </div>
                                                                    : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                                :
                                                <div class="table-responsive w-50" style={{ backgroundColor: '#ebebeb', marginBottom: '14px', borderRight: '1px solid lightgray' }}>
                                                    <div style={{ margin: '10px 0 10px 10px', lineHeight: '23px' }}>
                                                        <p className="m-0">No record found </p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div style={{ backgroundColor: '#ebebeb', textAlign: 'left' }} className="p-1">
                                            <p className="m-0"><strong>Exhibitor Details: </strong></p>
                                            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                                <li>Exhibitor Name	: {exhibitorName}</li>
                                                <li>Hall No. : {hallNo}</li>
                                                <li>Stall No. : {stallNo}	</li>
                                                <li>Stall Type: {stallType}</li>
                                                <li>Area: {stallArea}</li>
                                                <li>Sides open: {openSides}</li>
                                            </ul>
                                        </div>



                                    </div>
                                    <div class="card-body p-1 m-2" style={{ border: 'none' }}>
                                        <form>
                                            {/* <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                                <strong>Kindly Fill your requirement in below Comment box:</strong></label>
                                            <textarea class="form-control"
                                                value={Requirement}
                                                onChange={(e) => this.setState({ Requirement: e.target.value })}
                                                maxLength={300}
                                                rows="3">
                                            </textarea> */}
                                            <div className="mt-3">
                                                <strong> <label>Remark</label> </strong>
                                                <textarea class="form-control"
                                                    value={Remark}
                                                    onChange={(e) => this.setState({ Remark: e.target.value })}
                                                    maxLength={300}
                                                    rows="3">
                                                </textarea>
                                            </div>
                                            <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                                <input type="checkbox"
                                                    checked={Is_Agree}
                                                    onChange={(e) => this.setState({ Is_Agree: e.target.checked, Is_AgreeErr: '' })}
                                                />
                                                <strong>I agree the above disclaimer for rental audio and video equipments<span className="required">*</span>.</strong></label>{<span className='text-danger text-start text-left d-block mt-1'>{Is_AgreeErr}</span>}
                                        </form>
                                    </div>
                                </div>
                                {/* <div class="row m-1"> */}

                            </div>

                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>
                            <div class='d-flex m-3'>

                                <button
                                    type="button"
                                    className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                    style={{ marginRight: 10 }}
                                    disabled={currentDate > DeadlineDate }
                                    onClick={() => this.addRentalAndVideoEquipment()}
                                >
                                    Submit
                                </button>
                                <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { insertUpdateRentalAudioAndVideo_action, getRentalAudioAndVideoDetails_action })(RentalAudioAndVedioEquipment));