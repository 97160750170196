import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess, BASE_URL } from "../../../../../utils/constants";
import { insertUpdateBrandingOpportunity_action, getBrandingOpportunityDetails_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/brandingOpportunity_action/brandingOpportunity_action'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import $ from 'jquery'
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import moment from "moment";
// 
class BrandingApportunityAndAdvertisement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            Is_Agree: false,
            Remark: '',
            CGST: '',
            SGST: '',
            IGST: '',
            Is_AgreeErr: '',
            DeadlineDate: "",
            Is_Required: true,
            carPassessId: '',
            currentDate: moment().format('DD-MM-YYYY'),
            tableList: [],
            totalAmount: '',
            AccountNumber: '',
            BankName: '',
            BeneficiaryName: '',
            IFSCCodeValue: '',
            exhibitorName: '',
            hallNo: '',
            stallNo: '',
            stallType: '',
            area: '',
            sidesOpen: '',
            imagePath: '',
            isOpen: false,
            imageName: '',
            is_View: false,
            StatusID: ''
        }
        // this.getPowerConnectionList = this.getPowerConnectionList.bind(this)

    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ? moment(this.props.location.state.Deadline).format('DD-MM-YYYY') : '',
            is_View: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.is_View != null ? this.props.location.state.is_View : false,
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != null ? this.props.location.state.StatusID : '',
        })

        const sortUrl = BASE_URL.split('/api')

        this.setState({ downloadUrl: sortUrl[0] })

        this.getBrandingOpportunityDetails()
        this.loadscript()
    }
    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }


    handleCancel() {
        if (this.state.is_View) {
            const companyName = this.props.location.state != null && this.props.location.state.companyName ? this.props.location.state.companyName : ''
            this.props.navigate('/brandingOpportunityApproval', { state: { companyName: companyName } })
        } else {
            this.props.navigate('/manualForms')
        }
    }

    inputValidate() {
        const { Is_Agree } = this.state

        if (Is_Agree == 0 || Is_Agree == false) {
            this.setState({ Is_AgreeErr: 'kindly agree to disclaimer mentioned in this form for Car park passes' })
        }
    }

    getBrandingOpportunityDetails() {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .getBrandingOpportunityDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })

                    if (data.listDataDetails != '') {
                        data.listDataDetails.map((item) => {
                            data.listData.map((data, i) => {
                                if (data.BrandingOppotunityID == item.BrandingOppotunityID) {
                                    data.Amount = item.Amount
                                    data.Quantity = item.Quantity != 0 ? item.Quantity : ''
                                    data.AvailableQty = data.AvailableQty + item.Quantity
                                }
                            })
                        })

                        data.listData.map((item) => {
                            if (item.Amount == undefined) {
                                item.Amount = 0
                            }
                        })

                        console.log("newArray----------", data.listData)
                        const totalAmount = this.sumOfObject(data.listData, 'Amount')
                        this.setState({ tableList: data.listData, totalAmount: totalAmount, })
                    } else {
                        data.listData.map((data, i) => {
                            data.Amount = 0
                            data.Quantity = ''
                        })
                        const totalAmount = this.sumOfObject(data.listData, 'Amount')
                        this.setState({ tableList: data.listData, totalAmount: totalAmount, })
                    }

                    if (data.inputData != '') {
                        this.setState({ Remark: data.inputData[0]['Remark'] })
                    }

                    this.setState({
                        AccountNumber: data.bankDetails[0]['AccountNumber'],
                        BankName: data.bankDetails[0]['BankName'],
                        BeneficiaryName: data.bankDetails[0]['BeneficiaryName'],
                        IFSCCodeValue: data.bankDetails[0]['code'],
                        CGST: data.gst[0]['CGST'] * 100,
                        SGST: data.gst[0]['SGST'] * 100,
                        IGST: data.gst[0]['IGST'] * 100,
                        exhibitorName: data.exhibitorDetails[0]['CompanyName'],
                        hallNo: data.exhibitorDetails[0]['HallName'],
                        stallNo: data.exhibitorDetails[0]['StallName'],
                        stallType: data.exhibitorDetails[0]['StallTypeName'],
                        area: data.exhibitorDetails[0]['StallArea'],
                        sidesOpen: data.exhibitorDetails[0]['OpenSides'],
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    addUpdateBrandingOpportunity(e) {
        e.preventDefault()
        const { Remark, tableList } = this.state
        const newArray = []

        tableList.map(data => {
            if (data.Quantity != 0 && data.Quantity != '' && data.Quantity != null) {
                newArray.push({
                    "BrandingOppotunityID": parseInt(data.BrandingOppotunityID),
                    "Quantity": parseInt(data.Quantity),
                })
            }
        })
        this.setState({ isLoading: true })
        this.props
            .insertUpdateBrandingOpportunity_action(Remark, newArray)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    this.handleCancel()
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    onQuantity(e, index) {
        const { tableList } = this.state
        const event = e.target.value.replace(/\D/, '')
        console.log(event, 'ppp')
        tableList.map((data, i) => {
            if (i === index) {
                if (event != '') {
                    if (event <= data.AvailableQty) {
                        data.Quantity = event
                        data.Amount = event * data.Charges
                    } else {
                        data.Quantity = ''
                        data.Amount = ''
                        toastError("Enter Quantity must be less than or equal to Available Quantity")
                    }
                } else {
                    data.Quantity = ''
                    data.Amount = 0
                }
            }
        })
        const totalAmount = this.sumOfObject(tableList, 'Amount')
        this.setState({ tableList: tableList, totalAmount: totalAmount })
    }

    sumOfObject(arrayOfObjects, propertyName) {
        return arrayOfObjects.reduce((accumulator, currentObject) => {
            return accumulator + currentObject[propertyName];
        }, 0);
    }

    showImage(imagepath, name) {
        this.setState({
            imagePath: imagepath,
            isOpen: true,
            imageName: name
        })
    }

    render() {
        const { isLoading, Is_Agree, Remark, Is_AgreeErr, currentDate, DeadlineDate, tableList, totalAmount, CGST, SGST, IGST, StatusID,
            AccountNumber,
            BankName,
            BeneficiaryName,
            IFSCCodeValue,
            exhibitorName,
            hallNo,
            stallNo,
            stallType,
            area,
            sidesOpen,
            isOpen, imagePath, downloadUrl, is_View
        } = this.state

        return (
            <>
                <ToastContainer />
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : <HeaderWithLogo />
                }
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorDrawer /> : <AdminDrawer />
                }
                <div className="main-content app-content with-dr-container" style={{ marginTop: !is_View ? '0px' : '' }}>
                    <div className="">
                        <div className="row">
                            {
                                JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                    <ExhibitorTopMenu /> : null
                            }
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Branding Opportunity And Advertisement</h3>
                                    </div>
                                    <div class="card-body p-1 m-2">
                                        <div style={{ lineHeight: '10px' }}>
                                            <p>1) Last date for booking display sites / receiving advertising material is Date …………….
                                            </p>
                                            <p>2) India ITME xxxx reserves the right to make slight alterations in advertisement opportunities.
                                            </p>
                                            <p>3) Demand draft should be drawn in favour of “India ITME Society” payable in Mumbai.
                                            </p>
                                            <p>4) GST Tax (as per Government Rule).</p>
                                            <p>5) All display sites/structures are sole property of India ITME xxxx.</p>
                                            <p>6) Display advertising material must be sent in CDR / EPS & TIFF format by email.</p>
                                            <p>7) Branding opportunity and advertisement is first come-first-served basis on submission of 100% payment only.</p>
                                            <p>8) Payment should be reach after 10 days of blocking the branding opportunity after 10 days it will be auto released.</p>
                                        </div>
                                        <p style={{ lineHeight: '23px' }}>
                                            <p className="m-0">Payment Instruction:</p>
                                            {
                                                sessionStorage.getItem('CurrencyID') == 1 ?
                                                    <>
                                                        <div style={{ backgroundColor: '#ebebeb', marginBottom: '10px' }} className="p-1">
                                                            {/* <p className="m-0"><strong>Indian Exhibitor :</strong></p> */}
                                                            <ul style={{ listStyleType: 'none', margin: 0 }}>
                                                                <li>DETAILS FOR REMITTANCE:</li>
                                                                <li>Please remit the funds in favour of "INDIA ITME SOCIETY".</li>
                                                                <li>For RTGS/NEFT please refer details below: For RTGS/NEFT please refer details below: </li>
                                                                <li>Name of the Beneficiary : {BeneficiaryName != null ? BeneficiaryName : '-'}  </li>
                                                                <li>current account no. 	 : {AccountNumber != null ? AccountNumber : '-'} </li>
                                                                <li>bank and branch	 : {BankName != null ? BankName : '-'} </li>
                                                                <li>IFSC Code	 : {IFSCCodeValue != null ? IFSCCodeValue : '-'}</li>
                                                            </ul>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div style={{ backgroundColor: '#ebebeb' }}>
                                                            {/* <p className="m-0"><strong>Foreign Exhibitor: </strong></p> */}
                                                            <ul style={{ listStyleType: 'none', margin: 0 }}>
                                                                <li>Name of the Beneficiary : {BeneficiaryName != null ? BeneficiaryName : '-'}  </li>
                                                                <li>current account no. 	 : {AccountNumber != null ? AccountNumber : '-'} </li>
                                                                <li>bank and branch	 : {BankName != null ? BankName : '-'} </li>
                                                                <li>IFSC Code	 : {IFSCCodeValue != null ? IFSCCodeValue : '-'}</li>
                                                            </ul>
                                                        </div>
                                                    </>
                                            }
                                            <div style={{ backgroundColor: '#ebebeb' }} className="p-1">
                                                <p className="m-0"><strong>Exhibitor Details: </strong></p>
                                                <ul style={{ listStyleType: 'none', margin: 0 }}>
                                                    <li> <strong>Exhibitor Name:</strong> {exhibitorName}</li>
                                                    <li> <strong>Hall Name:</strong> {hallNo}</li>
                                                    <li> <strong>Stall No.:</strong> {stallNo}	</li>
                                                    <li> <strong>Stall Type:</strong> {stallType}</li>
                                                    <li> <strong>Area:</strong> {area}</li>
                                                    <li> <strong>Sides open:</strong> {sidesOpen}</li>
                                                </ul>
                                            </div>
                                        </p>

                                        <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                                <thead>
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Image Location</th>
                                                        <th>Particular and Size</th>
                                                        <th>Charges {sessionStorage.getItem('CurrencyID') == 1 ? '(INR)' : '(USD)'}</th>
                                                        <th>Quantity</th>
                                                        <th>Amount</th>
                                                        <th>Available Quantity</th>
                                                        <th>Total Quantity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tableList != '' && tableList != null && tableList.length > 0 ?
                                                            tableList.map((data, i) => (
                                                                <tr>
                                                                    <td>{i + 1}</td>
                                                                    <td style={{ textAlign: 'center', cursor: 'pointer' }} >
                                                                        {data.ImagePath != null ? <i class="fa fa-image" onClick={() => this.showImage(data.ImagePath, data.ParticularAndSize)}></i> : '-'}

                                                                    </td>
                                                                    <td>{data.ParticularAndSize != null ? data.ParticularAndSize : '-'}</td>
                                                                    <td>{data.Charges != null ? data.Charges : '-'}</td>
                                                                    <td className="p-1">
                                                                        <input type="text" className="form-control"
                                                                            value={data.Quantity}
                                                                            onChange={(e) => this.onQuantity(e, i)}
                                                                            maxLength={5}
                                                                            disabled={data.AvailableQty == 0 || is_View}
                                                                        />
                                                                    </td>
                                                                    <td>{data.Amount != null && data.Amount != '' ? data.Amount : 0}</td>
                                                                    <td>{data.AvailableQty != null ? data.AvailableQty : '-'}</td>
                                                                    <td>{data.TotalQty != null ? data.TotalQty : '-'}</td>
                                                                </tr>
                                                            ))
                                                            : <tr><td className="text-center" colSpan={9}>No record found</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class=" mt-5 p-1" style={{ backgroundColor: '#ebebeb' }}>
                                            <p className="m-0"><strong>Note: </strong></p>
                                            <ul style={{ listStyleType: 'number', textAlign: 'left', margin: 0 }}>
                                                <li>Payment Terms: 100% advance</li>
                                                <li>Taxes extra as applicable </li>
                                            </ul>
                                        </div>
                                        <div class=" mt-2 p-1" style={{ backgroundColor: '#ebebeb' }}>
                                            <p className="m-0"><strong>Payments Details: </strong></p>
                                            <ul style={{ listStyleType: 'number', textAlign: 'left', margin: 0 }}>
                                                <li>Amount : {totalAmount} </li>
                                                <li>CGST {CGST}% : {(CGST / 100) * totalAmount}</li>
                                                <li>SGST {SGST}% : {(SGST / 100) * totalAmount}</li>
                                                <li>Total Amount  : {(CGST / 100) * totalAmount + (SGST / 100) * totalAmount + totalAmount} </li>
                                            </ul>
                                        </div>
                                        <div class=" mt-2">
                                            <strong> <label>Remark</label></strong>
                                            <textarea class="form-control"
                                                value={Remark}
                                                onChange={(e) => this.setState({ Remark: e.target.value })}
                                                maxLength={300}
                                                disabled={is_View}
                                                rows="3">
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="row m-1"> */}
                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>
                            <div class='d-flex m-3'>
                                {!is_View ?
                                    <button
                                        type="button"
                                        className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                        style={{ marginRight: 10 }}
                                        disabled={currentDate > DeadlineDate }
                                        onClick={(e) => this.addUpdateBrandingOpportunity(e)}
                                    >
                                        Submit
                                    </button>
                                    : <></>}
                                <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                            </div>

                        </div>
                    </div>
                </div>

                <Modal show={isOpen} onHide={() => this.setState({ isOpen: false })} >
                    <Modal.Header>
                        <Modal.Title> Images</Modal.Title>
                        <span
                            onClick={() => this.setState({ isOpen: false })}
                            style={{ cursor: "pointer", fontWeight: "800" }}
                        >
                            X
                        </span>
                    </Modal.Header>

                    <Modal.Body style={{ marginBottom: '0px' }}>

                        {console.log("image-----", downloadUrl + imagePath)}
                        <img alt="" title="" src={downloadUrl + imagePath} className="booking_page_img" />

                    </Modal.Body>

                </Modal>
            </>
        )
    }
}

export default withRouter(connect(null, { insertUpdateBrandingOpportunity_action, getBrandingOpportunityDetails_action })(BrandingApportunityAndAdvertisement));