import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "../../../../../utils/constants";
import { getSecurityApplicationDetails_action, insertUpdateSecurityApplication_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/securityApplication_action/securityApplication_action'
import Modal from "react-modal";
import { data } from "jquery";
import $ from 'jquery'
import moment from "moment";
// 
class SecurityApplication extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            PowerConnectionList: [],
            Is_Agree: false,
            Remark: '',
            CGST: 0,
            SGST: 0,
            Is_AgreeErr: '',
            DeadlineDate: "",
            Is_Required: true,
            carPassessId: '',
            currentDate: moment().format('DD-MM-YYYY'),

            AccountNumber: '',
            BankName: '',
            BeneficiaryAddress: '',
            BeneficiaryName: '',
            GSTNumber: '',
            IFSCCodeValue: '',
            PANNumber: '',
            SwiftCode: '',
            tableList: [],
            totalAmount: '',
            IGST: '',
            Address: '',

            contactDetailsList: [],
            StatusID: ''
        }
        // this.getPowerConnectionList = this.getPowerConnectionList.bind(this)

    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                moment(this.props.location.state.Deadline).format('DD-MM-YYYY') : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ?
                this.props.location.state.StatusID : '',
        })

        this.getSecurityApplicationDetails()
        this.loadscript()
    }
    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }


    handleCancel() {
        this.props.navigate('/manualForms')
    }

    inputValidate() {
        const { Is_Agree } = this.state

        if (Is_Agree == 0 || Is_Agree == false) {
            this.setState({ Is_AgreeErr: 'kindly agree the above disclaimer & Vendor Terms and conditions mentioned in Guideline' })
        }
    }

    getSecurityApplicationDetails() {
        const { Remark, Is_Agree, isQuantity, Is_Required } = this.state
        this.setState({ isLoading: true })
        this.props
            .getSecurityApplicationDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        isLoading: false, contactDetailsList: data.contactDetails,
                        IGST: data.gst[0]['IGST'] * 100,
                        CGST: data.gst[0]['CGST'] * 100,
                        SGST: data.gst[0]['SGST'] * 100,
                    })

                    if (data.bankDetails != '') {
                        this.setState({
                            AccountNumber: data.bankDetails[0]['AccountNumber'],
                            BankName: data.bankDetails[0]['BankName'],
                            BeneficiaryAddress: data.bankDetails[0]['BeneficiaryAddress'],
                            BeneficiaryName: data.bankDetails[0]['BeneficiaryName'],
                            GSTNumber: data.bankDetails[0]['GST'],
                            IFSCCodeValue: data.bankDetails[0]['IFSCCode'],
                            PANNumber: data.bankDetails[0]['PAN'],
                            SwiftCode: data.bankDetails[0]['SwiftCode'],
                            Address: data.bankDetails[0]['Address'],
                        })
                    }

                    if (data.getDetails != '') {
                        this.setState({
                            Remark: data.getDetails[0]['Remark'],
                            Is_Agree: data.getDetails[0]['Is_Agree']
                        })
                    }

                    if (data.getTableDetails != '') {
                        data.getTableDetails.map((item, i) => {
                            item.dayTotalAmount = item.DayRate * (item.DayQuantity != 0 ? item.DayQuantity : 1)
                            item.nightTotalAmount = item.NightRate * (item.NightQuantity != 0 ? item.NightQuantity : 1)
                            item.DayQuantity = item.DayQuantity != 0 ? item.DayQuantity : ''
                            item.NightQuantity = item.NightQuantity != 0 ? item.NightQuantity : ''
                            item.SecurityApplicationDescriptionID = data.data[i]['SecurityApplicationDescriptionID']
                        })
                        this.setState({ tableList: data.getTableDetails })
                    } else {
                        data.data.map((item) => {
                            item.Amount = ''
                            item.DayQuantity = ''
                            item.NightQuantity = ''
                            item.dayTotalAmount = item.DayRate
                            item.nightTotalAmount = item.NightRate
                            item.Amount = 0
                        })
                        this.setState({ tableList: data.data })
                    }
                    setTimeout(() => {
                        this.sumOfArrayOfObject(this.state.tableList)
                    }, 100);

                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    sumOfArrayOfObject(arrayOfObjects) {
        const sum = arrayOfObjects.reduce((accumulator, currentObject) => {
            return accumulator + currentObject.Amount;
        }, 0);
        this.setState({ totalAmount: sum })
    }

    addSecurityApplication() {
        const { Remark, Is_Agree, tableList } = this.state
        this.inputValidate()
        const array = []
        tableList.map((data) => {
            array.push(
                {
                    'SecurityApplicationDescriptionID': data.SecurityApplicationDescriptionID,
                    'DayQuantity': data.DayQuantity,
                    'NightQuantity': data.NightQuantity
                }
            )
        })
        this.setState({ isLoading: true })
        if (Is_Agree) {
            this.props
                .insertUpdateSecurityApplication_action(
                    Remark, Is_Agree, array)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            this.inputValidate()
            toastError("Plase Enter mandatory feilds!")
            this.setState({ isLoading: false })
        }
    }

    enterDayQuantity(e, index) {
        const { tableList } = this.state
        const event = e.target.value.replace(/\D/, '')
        tableList.map((data, i) => {
            if (i === index) {
                console.log(event)
                if (event != '' && event != 0) {
                    data.DayQuantity = event
                    data.dayTotalAmount = event * data.DayRate
                    data.Amount = (data.NightQuantity != '' && data.NightQuantity != null) ? (data.dayTotalAmount + data.nightTotalAmount) : data.dayTotalAmount
                } else {
                    if (data.NightQuantity != '' && data.NightQuantity != null) {
                        data.DayQuantity = ''
                        data.dayTotalAmount = 0
                        data.Amount = data.nightTotalAmount
                    }
                    else {
                        data.DayQuantity = ''
                        data.dayTotalAmount = 0
                        data.Amount = 0
                    }
                }
            }
        })
        console.log(tableList)
        this.setState({ tableList: tableList })
        this.sumOfArrayOfObject(tableList)
    }

    enterNightQuantity(e, index) {
        const { tableList } = this.state
        const event = e.target.value.replace(/\D/, '')
        tableList.map((data, i) => {
            if (i === index) {
                if (event != '' && event != 0) {
                    data.NightQuantity = event
                    data.nightTotalAmount = event * data.NightRate
                    data.Amount = (data.NightQuantity != '' && data.NightQuantity != null) ? (data.dayTotalAmount + data.nightTotalAmount) : data.dayTotalAmount
                } else {
                    if (data.DayQuantity != '' && data.DayQuantity != null) {
                        data.NightQuantity = ''
                        data.nightTotalAmount = 0
                        data.Amount = data.dayTotalAmount
                    }
                    else {
                        data.NightQuantity = ''
                        data.nightTotalAmount = 0
                        data.Amount = 0
                    }
                }
            }
        })

        this.setState({ tableList: tableList })
        this.sumOfArrayOfObject(tableList)
    }

    render() {
        const { isLoading, Is_Agree, Remark, Is_AgreeErr, currentDate, DeadlineDate, AccountNumber,
            BankName,
            BeneficiaryAddress,
            BeneficiaryName,
            GSTNumber,
            IFSCCodeValue,
            PANNumber,
            SwiftCode, tableList, totalAmount, IGST, Address,
            contactDetailsList, CGST, SGST, StatusID
        } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content app-content mt-0 with-dr-container">
                    <div className="">
                        <div className="row">
                            <ExhibitorTopMenu />
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Security Application</h3>
                                    </div>
                                    <div class="card-body p-1 m-2">
                                        <ul style={{ lineHeight: '25px', textAlign: 'left', padding: 0 }}>
                                            <li>1) The cost/rates and terms & conditions of utilizing the individual services from the given service provider is solely discretion of exhibitors.
                                            </li>
                                            <li>2) India ITME Society is only information facilitator for the service provider & In no event shall INDIA ITME SOCIETY be liable for any direct, indirect, punitive, incidental, special consequential issues, whatsoever arising out of taking services with the above given service provider.
                                            </li>
                                        </ul>
                                        <strong><p className="m-0">Contact Details:-</p></strong>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {contactDetailsList != '' && contactDetailsList.length > 0 ?
                                                contactDetailsList.map((data) => (
                                                    <>
                                                        <div class="table-responsive w-50" style={{ backgroundColor: '#ebebeb', marginBottom: '14px', borderRight: '1px solid lightgray' }}>
                                                            <div style={{ margin: '10px 0 10px 10px', lineHeight: '23px' }}>
                                                                <p className="m-0"><strong>Name:</strong>  {data.Name} </p>
                                                                <p className="m-0"><strong>Phone:</strong>  {data.Phone}</p>
                                                                <p className="m-0"><strong>Fax:</strong>  {data.Fax}</p>
                                                                <p className="m-0"><strong>Mobile No.</strong>  {data.Mobile}</p>
                                                                <p className="m-0"><strong>Email:</strong>  {data.PrimaryEmail}</p>
                                                                <p className="m-0"><strong>Alternate E-mail:</strong> {data.SecondaryEmail}</p>
                                                                <p className="m-0"><strong>Address:</strong> {data.Address}</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                                :
                                                <div class="table-responsive w-50" style={{ backgroundColor: '#ebebeb', marginBottom: '14px', borderRight: '1px solid lightgray' }}>
                                                    <div style={{ margin: '10px 0 10px 10px', lineHeight: '23px' }}>
                                                        <p className="m-0">No record found </p>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div class="table-responsive" style={{ backgroundColor: '#ebebeb', marginBottom: '14px' }}>
                                            <div style={{ margin: '0px 0 10px 10px', lineHeight: '23px' }}>
                                                <strong><p className="m-0">Terms and Condition:-</p></strong>
                                                <div class="table-responsive" style={{ backgroundColor: '#ebebeb', marginBottom: '14px' }}>
                                                    <p className="m-0">1) Security services herein are confined to guarding individual booth only.
                                                    </p>
                                                    <p className="m-0">2) There are two shifts in one day service:
                                                        <ul style={{ listStyleType: 'circle' }}>
                                                            <li>Day shift: 8.00 am – 8.00 pm.</li>
                                                            <li>Day shift: 8.00 am – 8.00 pm.</li>
                                                        </ul>
                                                    </p>
                                                    <p className="m-0">3) The company reserves the right to refuse any order. Cancellation of orders will only be accepted when made in writing to Customer Service Department no later than 7 business days prior to the commencement of tenancy period. Cancellation of order is subject to a charge at half of standard rate.
                                                    </p>
                                                    <p className="m-0">4) The final request for guards must be received by 31 October 2022 for onward intimation to security service provider.
                                                    </p>
                                                    <strong><p className="m-0">Note:- </p></strong>
                                                    <p>
                                                        <ul style={{ listStyleType: 'disc' }}>
                                                            <li>Rates mentioned above are on hire basis and applicable as per dates available.</li>
                                                            <li>Orders are valid only when accompanied with full payment before the deadline.</li>
                                                            <li>Please make DD or transfer payable to <strong>India Exposition Mart Ltd</strong>.</li>
                                                        </ul>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}>
                                                            <strong>
                                                                Bank Details
                                                            </strong>

                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '50%' }}>
                                                            <strong>
                                                                Bank Name
                                                            </strong>
                                                        </td>
                                                        <td>{BankName != null ? BankName : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                Address
                                                            </strong></td>
                                                        <td>{Address != null ? Address : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> <strong>Beneficiary Name</strong></td>
                                                        <td>{BeneficiaryName != null ? BeneficiaryName : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Beneficiary Address</strong></td>
                                                        <td>{BeneficiaryAddress != null ? BeneficiaryAddress : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Account No</strong></td>
                                                        <td>{AccountNumber != null ? AccountNumber : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Swift Code</strong></td>
                                                        <td>{SwiftCode != null ? SwiftCode : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>IFSC Code</strong></td>
                                                        <td>{IFSCCodeValue != null ? IFSCCodeValue : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>PAN No</strong></td>
                                                        <td>{PANNumber != null ? PANNumber : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>GST No</strong></td>
                                                        <td>{GSTNumber != null ? GSTNumber : '-'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '30%' }}>Description of Service </th>
                                                        <th style={{ width: '30%' }}>Rates Day Shift {sessionStorage.getItem('CurrencyID') == 1 ? '(INR)' : '(USD)'}</th>
                                                        <th style={{ width: '12%' }}>Day Shift No. of Guards</th>
                                                        <th style={{ width: '30%' }}>Rates Night Shift {sessionStorage.getItem('CurrencyID') == 1 ? '(INR)' : '(USD)'}</th>
                                                        <th style={{ width: '12%' }}>Night Shift No. of Guards</th>
                                                        <th style={{ width: '12%' }}>Amount {sessionStorage.getItem('CurrencyID') == 1 ? '(INR)' : '(USD)'}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tableList != '' && tableList.length > 0 ?
                                                            tableList.map((data, i) => (
                                                                <tr>
                                                                    <td>{data.Description != null ? data.Description : '-'}</td>
                                                                    <td>{data.DayRate != null ? data.DayRate : '-'}</td>
                                                                    <td>
                                                                        <input className="form-control" type="text"
                                                                            value={data.DayQuantity}
                                                                            onChange={(e) => this.enterDayQuantity(e, i)}
                                                                            maxLength={5}
                                                                        />
                                                                    </td>
                                                                    <td>{data.NightRate != null ? data.NightRate : '-'}</td>
                                                                    <td>
                                                                        <input className="form-control" type="text"
                                                                            value={data.NightQuantity}
                                                                            onChange={(e) => this.enterNightQuantity(e, i)}
                                                                            maxLength={5}
                                                                        />
                                                                    </td>
                                                                    <td>{data.Amount != '' ? data.Amount : 0}</td>
                                                                </tr>
                                                            ))
                                                            : <tr><td className="text-center" colSpan={8}>No record found</td></tr>
                                                    }

                                                    <tr>
                                                        <td colSpan={4}></td>
                                                        <td><strong>Total</strong></td>
                                                        <td><strong>{totalAmount}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={4}></td>
                                                        <td><strong>CGST @ {CGST}%</strong></td>
                                                        <td><strong>{((CGST / 100) * totalAmount)}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={4}></td>
                                                        <td><strong>SGST @ {SGST}%</strong></td>
                                                        <td><strong>{((SGST / 100) * totalAmount)}</strong></td>
                                                    </tr>
                                                    <tr style={{ backgroundcolor: "#eee" }}>
                                                        <td colSpan={4}></td>
                                                        <td><strong>Grand Total</strong></td>
                                                        <td><strong>{
                                                            (CGST / 100) * totalAmount + (SGST / 100) * totalAmount + totalAmount
                                                        }</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-1">
                                    <form>
                                        <strong> <label>Remark</label></strong>
                                        <textarea class="form-control"
                                            value={Remark}
                                            onChange={(e) => this.setState({ Remark: e.target.value })}
                                            maxLength={300}
                                            rows="3">
                                        </textarea>
                                        <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                            <input type="checkbox"
                                                checked={Is_Agree}
                                                onChange={(e) => this.setState({ Is_Agree: e.target.checked, Is_AgreeErr: '' })}
                                            />
                                            <strong> I agree the above disclaimer & Vendor Terms and conditions mentioned in Guideline<span className="required">*</span>.</strong></label>{<span className='text-danger text-start text-left d-block mt-1'>{Is_AgreeErr}</span>}
                                    </form>
                                </div>

                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>
                            <div class='d-flex m-3'>

                                <button
                                    type="button"
                                    className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                    style={{ marginRight: 10 }}
                                    disabled={currentDate > DeadlineDate }
                                    onClick={() => this.addSecurityApplication()}
                                >
                                    Submit
                                </button>
                                <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { getSecurityApplicationDetails_action, insertUpdateSecurityApplication_action })(SecurityApplication));