import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "../../../../../utils/constants";
import { getLeadRetrievalFormDetails_action, insertUpdateLeadRetrievalForm_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/leadRetrievalForm_action/leadRetrievalForm_action'
import Modal from "react-modal";
import { data } from "jquery";
import $ from 'jquery'
import moment from "moment";
// 
class LeadRetrievalConnectionApplication extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            Is_Agree: false,
            Remark: '',
            CGST: '',
            SGST: '',
            Is_AgreeErr: '',
            DeadlineDate: "",
            currentDate: moment().format('DD-MM-YYYY'),

            Name: '',
            Designation: '',
            MobileNo: '',
            Email: '',
            Quantity: '',
            is_outlet: '',
            is_inlet: '',
            Remark: '',
            Is_Agree: '',
            LeadRetrievalTableList: [],
            Currency: '',
            rates: '',
            IGST: '',
            totalAmount: '',
            contactDetailsList: [],
            StatusID: ''
        }
        this.getLeadRetrievalFormDetails = this.getLeadRetrievalFormDetails.bind(this)

    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                moment(this.props.location.state.Deadline).format('DD-MM-YYYY') : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ?
                this.props.location.state.StatusID : '',
        })
        setTimeout(() => {
            this.getLeadRetrievalFormDetails()
        }, 100);
        this.loadscript()
    }

    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }

    getLeadRetrievalFormDetails() {
        this.setState({ isLoading: true })
        this.props
            .getLeadRetrievalFormDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    if (data.data != '') {
                        this.setState({
                            Currency: data.data['Rates'][0]['CurrencyID'],
                            rates: data.data['Rates'][0]['Rates'],
                            contactDetailsList: data.data['contactDetails']
                        })

                        if (data.data['gst'] != '') {
                            this.setState({
                                IGST: data.data['gst'][0]['IGST'] * 100,
                                CGST: data.data['gst'][0]['CGST'] * 100,
                                SGST: data.data['gst'][0]['SGST'] * 100,
                            })
                        }

                        if (data.data['Data'] != '') {
                            this.setState({
                                MobileNo: data.data['Data'][0]['MobileNo'],
                                Designation: data.data['Data'][0]['Designation'],
                                Email: data.data['Data'][0]['Email'],
                                Is_Agree: data.data['Data'][0]['Is_Agree'],
                                Name: data.data['Data'][0]['Name'],
                                Remark: data.data['Data'][0]['Remark'],
                            })
                        }

                        if (data.data['Qauntity'] != '') {
                            this.setState({
                                Quantity: data.data['Qauntity'][0]['Quantity'],
                                totalAmount: data.data['Qauntity'][0]['Quantity'] * data.data['Rates'][0]['Rates']
                            })
                        } else {
                            this.setState({
                                totalAmount: 0
                            })
                        }

                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }


    handleSubmit() {
        const { Name, MobileNo, Email, Remark, Is_Agree, Designation, Quantity } = this.state

        this.inputValidate()
        this.setState({ isLoading: true })
        if (Is_Agree) {
            this.props
                .insertUpdateLeadRetrievalForm_action(Name, Designation, MobileNo, Email, Quantity, Remark, Is_Agree)
                .then((data) => {
                    if (data.error != 1) {
                        setTimeout(() => {
                            this.props.navigate('/manualForms')
                            this.setState({ isLoading: false })
                        }, 100);
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            this.setState({ isLoading: false })
            toastError("Plase Enter mandatory feilds!")
        }
    }


    handleCancel() {
        this.props.navigate('/manualForms')
    }

    enterQuantity(e, index) {
        const { totalAmount, rates } = this.state
        const event = e.target.value.replace(/\D/, '')
        if (event != '' && event != 0) {
            this.setState({
                Quantity: event,
                totalAmount: rates * event
            })
        } else {
            this.setState({
                Quantity: '',
                totalAmount: 0
            })
        }
    }

    onBlurEnterQuantity(e) {
        if (e.target.value == '') {
            this.setState({
                Quantity: 0,
            })
        }
    }

    inputValidate() {
        const { Is_Agree } = this.state

        if (Is_Agree == 0 || Is_Agree == false) {
            this.setState({ Is_AgreeErr: 'kindly agree to terms and condition' })
        }
    }

    render() {
        const { isLoading, Is_Agree, Remark, SGST, CGST, Is_AgreeErr, currentDate, DeadlineDate, Name,
            Designation,
            MobileNo,
            Email,
            Quantity,
            Currency,
            rates,
            IGST, StatusID,
            totalAmount, contactDetailsList } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content app-content mt-0 with-dr-container">
                    <div className="">
                        <div className="row">
                            <ExhibitorTopMenu />
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Lead Retrieval Connection Application</h3>
                                    </div>
                                    <div class="card-body p-1 m-2">
                                        <ul style={{ lineHeight: '25px', textAlign: 'left', padding: 0 }}>
                                            <li>1) For QR code-based Lead retrieval through a downloadable app, user will be required to download the app using 3G/4G or Wi-Fi network.
                                            </li>
                                            <li>2) For all scanned QR code the service provider shall provide the following information by 25 December 2022: Name, Designation, Company Name, Email and Telephone.
                                            </li>
                                            <li>3) All quoted prices are subject to the prevailing government tax.
                                            </li>
                                            <li>4) Orders will be fulfilled on receipt of full payments.
                                            </li>
                                            <li>5) Late orders received after the form deadline will be subjected to availability.
                                            </li>
                                        </ul>
                                        <strong><p className="m-0">Contact Details:-</p></strong>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {contactDetailsList != '' && contactDetailsList.length > 0 ?
                                                contactDetailsList.map((data) => (
                                                    <>
                                                        <div class="table-responsive w-50" style={{ backgroundColor: '#ebebeb', marginBottom: '14px', borderRight: '1px solid lightgray' }}>
                                                            <div style={{ margin: '10px 0 10px 10px', lineHeight: '23px' }}>
                                                                <p className="m-0"><strong>Name:</strong>  {data.Name} </p>
                                                                <p className="m-0"><strong>Phone:</strong>  {data.Phone}</p>
                                                                <p className="m-0"><strong>Fax:</strong>  {data.Fax}</p>
                                                                <p className="m-0"><strong>Mobile No.</strong>  {data.Mobile}</p>
                                                                <p className="m-0"><strong>Email:</strong>  {data.PrimaryEmail}</p>
                                                                <p className="m-0"><strong>Alternate E-mail:</strong> {data.SecondaryEmail}</p>
                                                                <p className="m-0"><strong>Address:</strong> {data.Address}</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                                :
                                                <div class="table-responsive w-50" style={{ backgroundColor: '#ebebeb', marginBottom: '14px', borderRight: '1px solid lightgray' }}>
                                                    <div style={{ margin: '10px 0 10px 10px', lineHeight: '23px' }}>
                                                        <p className="m-0">No record found </p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Designation</th>
                                                        <th>Mobile</th>
                                                        <th>Email</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr >
                                                        <td>
                                                            <input class="form-control"
                                                                value={Name}
                                                                onChange={(e) => this.setState({ Name: e.target.value })}
                                                                type="text" />
                                                        </td>
                                                        <td>
                                                            <input class="form-control"
                                                                value={Designation}
                                                                onChange={(e) => this.setState({ Designation: e.target.value })}
                                                                type="text" />
                                                        </td>
                                                        <td>
                                                            <input class="form-control"
                                                                value={MobileNo}
                                                                maxLength={Currency === 'INR' ? 10 : 11}
                                                                onChange={(e) => this.setState({ MobileNo: e.target.value })}
                                                                type="text" />
                                                        </td>
                                                        <td>
                                                            <input class="form-control"
                                                                value={Email}
                                                                onChange={(e) => this.setState({ Email: e.target.value })}
                                                                type="text" />
                                                        </td>
                                                    </tr>

                                                    <tr style={{ backgroundColor: '#ebebeb' }}>
                                                        <td colSpan={2} >Description of Service</td>
                                                        <td>Rates {Currency == 1 ? '(INR)' : '(USD)'}</td>
                                                        <td>Quantity</td>
                                                        <td>Amount {Currency == 1 ? '(INR)' : '(USD)'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} >QR code based - Lead retrieval through a downloadable application for your<br /> Android / iOS device. (Minimum Requirement Android 4.4 / iOS 7)</td>
                                                        <td>{rates}</td>
                                                        <td><input
                                                            class="form-control"
                                                            value={Quantity}
                                                            maxLength={10}
                                                            onChange={(e) => this.enterQuantity(e)}
                                                            onBlur={(e) => this.onBlurEnterQuantity(e)}
                                                            type="text" /></td>
                                                        <td>{totalAmount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3}></td>
                                                        <td><strong>CGST @ {CGST}%</strong></td>
                                                        <td><strong>{((CGST / 100) * totalAmount)}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3}></td>
                                                        <td><strong>SGST @ {SGST}%</strong></td>
                                                        <td><strong>{((SGST / 100) * totalAmount)}</strong></td>
                                                    </tr>
                                                    <tr style={{ backgroundcolor: "#eee" }}>
                                                        <td colSpan={3}></td>
                                                        <td><strong>Grand Total</strong></td>
                                                        <td><strong>{
                                                            (CGST / 100) * totalAmount + (SGST / 100) * totalAmount + totalAmount
                                                        }</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-1">
                                    <form>
                                        <label>Remark</label>
                                        <textarea class="form-control"
                                            value={Remark}
                                            onChange={(e) => this.setState({ Remark: e.target.value })}
                                            maxLength={400}
                                            rows="3">
                                        </textarea>
                                        <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                            <input type="checkbox"
                                                checked={Is_Agree}
                                                onChange={(e) => this.setState({ Is_Agree: e.target.checked, Is_AgreeErr: '' })}
                                            />
                                            <strong>We hereby read &amp; understood the above mentioned clauses &amp; consequences. We accept &amp; agree to abide by the same<span className="required">*</span>.</strong></label>{<span className='text-danger text-start text-left d-block mt-1'>{Is_AgreeErr}</span>}
                                    </form>
                                </div>

                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>
                            <div class='d-flex m-3'>

                                <button
                                    type="button"
                                    className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                    style={{ marginRight: 10 }}
                                    disabled={currentDate > DeadlineDate }
                                    onClick={() => this.handleSubmit()}
                                >
                                    Submit
                                </button>
                                <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { getLeadRetrievalFormDetails_action, insertUpdateLeadRetrievalForm_action })(LeadRetrievalConnectionApplication));