import { Component } from "react";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { getStallPossession_action, insertStallPossession_action } from "../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/stall_possession_action/stall_possession_action";
import { emailValidator } from "../../../../../utils/Validator";
import { toastError, toastSuccess } from "../../../../../utils/constants";
import { data } from "jquery";
import moment from "moment";

class StallPossesion extends Component {

    // Initialize Component 
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            exhCompanyName: "",
            hallNo: "",
            stallNo: "",
            personName: "",
            companyName: "",
            stallPossessionId: "",
            countryId: "",
            contactNo: "",
            email: "",
            isAgree: false,
            remark: "",
            DeadlineDate: "",
            currentDate: moment().format('DD-MM-YYYY'),

            // Err Msg
            personNameErr: "",
            companyNameErr: "",
            contactNoErr: "",
            emailErr: "",
            StatusID: "",
        }
        this.getStallPossession = this.getStallPossession.bind(this)
        this.preventMinus = this.preventMinus.bind(this)
        this.handlePaste = this.handlePaste.bind(this)
        this.maxLengthCheck = this.maxLengthCheck.bind(this)
        this.onCancelClick = this.onCancelClick.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    // On Component Load
    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                moment(this.props.location.state.Deadline).format('DD-MM-YYYY') : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ?
                this.props.location.state.StatusID : '',
        })
        this.getStallPossession()
    }

    // Get Stall Possession
    getStallPossession() {
        this.setState({ isLoading: true })
        this.props.getStallPossession_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("getStallPossession Data", data.data)
                    this.setState({
                        exhCompanyName: data.data[0]['Exhibitor Compnay Name'] != null ? data.data[0]['Exhibitor Compnay Name'] : "",
                        hallNo: data.data[0]['HallNo'] != null ? data.data[0]['HallNo'] : "",
                        stallNo: data.data[0]['StallNo'] != null ? data.data[0]['StallNo'] : "",
                        stallPossessionId: data.data[0]['StallPossessionID'] != null ? data.data[0]['StallPossessionID'] : "",
                        countryId: data.data[0]['CountryID'] != null ? data.data[0]['CountryID'] : "",
                        personName: data.data[0]['PersonName'] != null ? data.data[0]['PersonName'] : "",
                        companyName: data.data[0]['CompanyName'] != null ? data.data[0]['CompanyName'] : "",
                        contactNo: data.data[0]['ContactNo'] != null ? data.data[0]['ContactNo'] : "",
                        email: data.data[0]['Email'] != null ? data.data[0]['Email'] : "",
                        isAgree: data.data[0]['IS_Agree'] != null ? data.data[0]['IS_Agree'] : false,
                        remark: data.data[0]['Remark'] != null ? data.data[0]['Remark'] : "",
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    preventMinus = (e) => {
        (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    }

    handlePaste = (event) => {
        const pastedValue = event.clipboardData.getData('text/plain');
        const sanitizedValue = pastedValue.replace(/[-.eE]/g, ''); // Remove '-', '.', 'e', 'E' characters

        // Replace the input value with the sanitized value
        document.execCommand('insertText', false, sanitizedValue);

        event.preventDefault(); // Prevent the default paste behavior
    }

    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    // On Cancel Click
    onCancelClick() {
        this.props.navigate('/manualForms')
    }

    // Input Validate 
    inputValidate() {
        const { personName, companyName, contactNo, email } = this.state
        if (personName == "") {
            this.setState({ personNameErr: 'Please Enter Person Name' })
        }
        if (companyName == "") {
            this.setState({ companyNameErr: 'Please Enter Company Name' })
        }
        if (contactNo == "") {
            this.setState({ contactNoErr: 'Please Enter Contact No.' })
        }
        if (email == "") {
            this.setState({ emailErr: "Please enter Email" });
        }
        if (email != "") {
            var emailError = emailValidator(email)
            if (emailError != '') {
                this.setState({ emailErr: emailError })
                return
            }
        }
    }

    // On Submit 
    onSubmit() {
        this.inputValidate()
        const { personName, companyName, contactNo, email, personNameErr, companyNameErr, contactNoErr, emailErr, isAgree, remark } = this.state
        if (personName != "" && companyName != "" && contactNo != "" && email != "") {
            if (personNameErr == "" && companyNameErr == "" && contactNoErr == "" && emailErr == "") {
                if (isAgree) {
                    this.setState({ isLoading: true })
                    this.props.insertStallPossession_action(personName, companyName, contactNo, email, isAgree, remark)
                        .then((data) => {
                            if (data.error != 1) {
                                this.setState({ isLoading: false })
                                toastSuccess('Stall Possession Submitted Successfully')
                                setTimeout(() => {
                                    this.props.navigate('/manualForms')
                                }, 1000);
                            } else {
                                this.setState({ isLoading: false })
                                toastError(data.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } else {
                    toastError('Please Agree T & C')
                }
            } else {
                toastError('Please Enter Mandatory Fields')
            }
        } else {
            toastError('Please Enter Mandatory Fields')
        }

    }

    render() {
        const { isLoading, exhCompanyName, hallNo, stallNo, companyName, personName, contactNo, email, isAgree, remark, personNameErr, companyNameErr,
            countryId, contactNoErr, emailErr, currentDate, DeadlineDate, StatusID } = this.state
        return (

            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content with-dr-container">
                    <ExhibitorTopMenu />
                    <div className="">
                        <div className="row">
                            <div className="card-header p-3">
                                <h3 className="card-title">Stall Possession</h3>
                            </div>
                        </div>
                        <div>
                            <div className="row mt-4 mb-4 p-2">
                                <div className="col-md-4">
                                    <label htmlFor="validationCustom03">Exhibitor Company Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled
                                        value={exhCompanyName}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="validationCustom03">
                                        Hall Name <span className="text-red">*</span>
                                    </label>
                                    <input type="text" className="form-control"
                                        disabled
                                        value={hallNo}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="validationCustom03">
                                        Stall No <span className="text-red">*</span>
                                    </label>
                                    <input type="text" className="form-control"
                                        disabled
                                        value={stallNo}
                                    />
                                </div>
                                <div className="col-md-12 mt-4">
                                    <h5 className="text-primary">
                                        Please hand over possession of space allotted to us, to our
                                        representative mentioned below:
                                    </h5>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="validationCustom03">
                                        Person Name <span className="text-red">*</span>
                                    </label>
                                    <input type="text" className="form-control"
                                        value={personName}
                                        onChange={(e) => this.setState({ personName: e.target.value, personNameErr: "" })}
                                    />
                                    {<span className='text-danger text-start text-left d-block mt-1'>{personNameErr}</span>}
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="validationCustom03">
                                        Company Name <span className="text-red">*</span>
                                    </label>
                                    <input type="text" className="form-control"
                                        value={companyName}
                                        onChange={(e) => this.setState({ companyName: e.target.value, companyNameErr: "" })}
                                    />
                                    {<span className='text-danger text-start text-left d-block mt-1'>{companyNameErr}</span>}
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="validationCustom03">
                                        Contact No <span className="text-red">*</span>
                                    </label>
                                    <input type="number" className="form-control"
                                        value={contactNo}
                                        // maxLength={countryId == 91 ? 10 : 20}
                                        maxLength={20}
                                        // onInput={(e) => this.maxLengthCheck(e, countryId == 91 ? 10 : 20)}
                                        onInput={(e) => this.maxLengthCheck(e, 20)}
                                        onKeyDown={(e) => this.preventMinus(e)}
                                        onWheel={(e) => e.target.blur()}
                                        onPaste={(e) => this.handlePaste(e)}
                                        onChange={(e) => e.target.value >= 0 &&
                                            this.setState({ contactNo: e.target.value, contactNoErr: "" })}
                                    />
                                    {<span className='text-danger text-start text-left d-block mt-1'>{contactNoErr}</span>}
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label htmlFor="validationCustom03">
                                        Email ID <span className="text-red">*</span>
                                    </label>
                                    <input type="text" className="form-control"
                                        value={email}
                                        onChange={(e) => this.setState({ email: e.target.value, emailErr: "" })}
                                    />
                                    {<span className='text-danger text-start text-left d-block mt-1'>{emailErr}</span>}
                                </div>
                                <label className="mt-3 mb-3">
                                    {" "}
                                    <input type="checkbox"
                                        value={isAgree}
                                        checked={isAgree}
                                        onChange={(e) => this.setState({ isAgree: !isAgree })}
                                    /> We agree that we have paid all the dues as
                                    per the payment schedule and other charges for utilities given in the
                                    online manual.
                                </label>
                                <div className="col-md-12">
                                    <textarea
                                        className="form-control"
                                        rows={3}
                                        value={remark}
                                        onChange={(e) => this.setState({ remark: e.target.value })}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <fieldset className="d-flex mt-3 mb-4">
                                        <button
                                            className="btn rounded-0 btn-primary"
                                            style={{ padding: "3px 7px", marginRight: 10 }}
                                            disabled={currentDate > DeadlineDate }
                                            onClick={(e) => this.onSubmit()}
                                        >
                                            Submit
                                        </button>
                                        <button
                                            className="btn rounded-0 btn-dark"
                                            style={{ padding: "3px 7px" }}
                                            onClick={(e) => this.onCancelClick(e)}
                                        >
                                            Cancel
                                        </button>
                                    </fieldset>
                                </div>
                                <div className="row">
                                    {currentDate > DeadlineDate &&
                                        <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}
export default withRouter(connect(null, { getStallPossession_action, insertStallPossession_action })(StallPossesion))