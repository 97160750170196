
import { Component } from "react";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import Loader from "../../../../../utils/Loader";
import { ToastContainer, toast } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { getStallDesignDetails_action, insertUpdateStallDesign_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/stallDesign_action/stallDesign_action'
import $ from 'jquery'
import { toastError, toastSuccess } from "../../../../../utils/constants";
import Resizer from "react-image-file-resizer";
import { BASE_URL } from "../../../../../utils/constants";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

class StallDesign extends Component {

    //Initialize constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            ExhibitorName: '',
            StallName: '',
            HallName: '',
            OpenSides: '',
            StallArea: '',
            StallTypeName: '',
            filePath_1: '',
            filePath_2: '',
            filePath_3: '',
            filePath_4: '',
            filePath_5: '',
            filePath_6: '',

            fileName_1: '',
            fileName_2: '',
            fileName_3: '',
            fileName_4: '',
            fileName_5: '',
            fileName_6: '',

            fileType_1: '',
            fileType_2: '',
            fileType_3: '',
            fileType_4: '',
            fileType_5: '',
            fileType_6: '',

            Remark: '',
            DeadlineDate: "",
            currentDate: moment().format('DD-MM-YYYY'),

            StallDesignFormID: null,
            FloorPlanDocument: null,
            ThreeDViewDocument: null,
            FrontViewDocument: null,
            SupportingDocument: null,
            DimentionalView: null,
            FireSafteyCertificate: null,

            displayfile_1: '',
            displayfile_2: '',
            displayfile_3: '',
            displayfile_4: '',
            displayfile_5: '',
            displayfile_6: '',
            isOpen: false,

            modalImagePath: '',
            modalImageName: '',

            is_View: false,
            StatusID: '',
            isOpen2: false,
            isCollapse: false,
        }
    }

    componentDidMount() {

        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                moment(this.props.location.state.Deadline).format('DD-MM-YYYY') : '',
            is_View: this.props.location.state != null && this.props.location.state.is_View ?
                this.props.location.state.is_View : '',
            StatusID: this.props.location.state != null && this.props.location.state.StatusID ?
                this.props.location.state.StatusID : '',
        })

        this.loadscript()
        this.getStallDesignetails()

    }

    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }

    getStallDesignetails() {
        const { Remark, Is_Agree, isQuantity, Is_Required } = this.state
        this.setState({ isLoading: true })
        this.props
            .getStallDesignDetails_action()
            .then((data) => {
                if (data.error != 1) {

                    this.setState({
                        isLoading: false,
                        ExhibitorName: data.data[0]['ExhibitorName'] != null ? data.data[0]['ExhibitorName'] : '-',
                        StallName: data.data[0]['StallName'] != null ? data.data[0]['StallName'] : '-',
                        HallName: data.data[0]['HallName'] != null ? data.data[0]['HallName'] : '-',
                        OpenSides: data.data[0]['OpenSides'] != null ? data.data[0]['OpenSides'] : '-',
                        StallArea: data.data[0]['StallArea'] != null ? data.data[0]['StallArea'] : '-',
                        StallTypeName: data.data[0]['StallTypeName'] != null ? data.data[0]['StallTypeName'] : '-',
                        FloorPlanDocument: data.data[0]['FloorPlanDocument'],
                        ThreeDViewDocument: data.data[0]['ThreeDViewDocument'],
                        FrontViewDocument: data.data[0]['FrontViewDocument'],
                        SupportingDocument: data.data[0]['SupportingDocument'],
                        DimentionalView: data.data[0]['DimentionalView'],
                        FireSafteyCertificate: data.data[0]['FireSafteyCertificate'],
                        StallDesignFormID: data.data[0]['StallDesignFormID'],
                        Remark: data.data[0]['Remark']
                    })

                    const url = BASE_URL.split('/api')

                    if (data.data[0]['FloorPlanDocument'] != null) {
                        const file = data.data[0]['FloorPlanDocument'].split(`${data.data[0]['ExhRegistrationID']}/`)
                        this.setState({ fileName_1: file[1], displayfile_1: url[0] + data.data[0]['FloorPlanDocument'] })
                    }
                    if (data.data[0]['ThreeDViewDocument'] != null) {
                        const file = data.data[0]['ThreeDViewDocument'].split(`${data.data[0]['ExhRegistrationID']}/`)
                        this.setState({ fileName_2: file[1], displayfile_2: url[0] + data.data[0]['ThreeDViewDocument'] })
                    }
                    if (data.data[0]['FrontViewDocument'] != null) {
                        const file = data.data[0]['FrontViewDocument'].split(`${data.data[0]['ExhRegistrationID']}/`)
                        this.setState({ fileName_3: file[1], displayfile_3: url[0] + data.data[0]['FrontViewDocument'] })
                    }
                    if (data.data[0]['SupportingDocument'] != null) {
                        const file = data.data[0]['SupportingDocument'].split(`${data.data[0]['ExhRegistrationID']}/`)
                        this.setState({ fileName_4: file[1], displayfile_4: url[0] + data.data[0]['SupportingDocument'] })
                    }
                    if (data.data[0]['DimentionalView'] != null) {
                        const file = data.data[0]['DimentionalView'].split(`${data.data[0]['ExhRegistrationID']}/`)
                        this.setState({ fileName_5: file[1], displayfile_5: url[0] + data.data[0]['DimentionalView'] })
                    }
                    if (data.data[0]['FireSafteyCertificate'] != null) {
                        const file = data.data[0]['FireSafteyCertificate'].split(`${data.data[0]['ExhRegistrationID']}/`)
                        this.setState({ fileName_6: file[1], displayfile_6: url[0] + data.data[0]['FireSafteyCertificate'] })
                    }

                    setTimeout(() => {
                        console.log("displayfile_1-------", this.state.displayfile_1)
                    }, 500);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    handleSubmit() {
        const { Remark,
            filePath_1,
            filePath_2,
            filePath_3,
            filePath_4,
            filePath_5,
            filePath_6,

            fileName_1,
            fileName_2,
            fileName_3,
            fileName_4,
            fileName_5,
            fileName_6,

            fileType_1,
            fileType_2,
            fileType_3,
            fileType_4,
            fileType_5,
            fileType_6,

            StallDesignFormID,
            FloorPlanDocument,
            ThreeDViewDocument,
            FrontViewDocument,
            SupportingDocument,
            DimentionalView,
            FireSafteyCertificate,
        } = this.state
        this.setState({ isLoading: true })
        if (fileName_1 != '' && fileName_2 != '' && fileName_3 != '' && fileName_4 != '') {
            this.props
                .insertUpdateStallDesign_action(
                    StallDesignFormID,
                    FloorPlanDocument,
                    ThreeDViewDocument,
                    FrontViewDocument,
                    SupportingDocument,
                    DimentionalView,
                    FireSafteyCertificate,
                    Remark,
                    filePath_1,
                    filePath_2,
                    filePath_3,
                    filePath_4,
                    filePath_5,
                    filePath_6,
                    fileName_1,
                    fileName_2,
                    fileName_3,
                    fileName_4,
                    fileName_5,
                    fileName_6,
                    fileType_1,
                    fileType_2,
                    fileType_3,
                    fileType_4,
                    fileType_5,
                    fileType_6,
                )
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({
                            isLoading: false,
                        })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            toastError("Please Choose Mandatory File's")
            this.setState({ isLoading: false })
        }
    }

    onFilePick = async (e, file, typeName) => {
        e.preventDefault()
        const { formFile, trcFile, declarationFile, formFileType, declarationFileType, trcFileType } = this.state
        var image = '';
        if (file[0]['size'] > 3145728) {
            toast.error('File size more than 3MB are not allow.', {
                position: "top-center",
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        } else {
            if (file[0]['name'].toString().toLowerCase().includes('.pdf')
                ||
                file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                file[0]['name'].toString().toLowerCase().includes('.png') ||
                file[0]['name'].toString().toLowerCase().includes('.jpg')
            ) {
                if (file[0]['name'].includes('pdf')) {
                    image = await this.onPdfSelect(file[0]);
                }
                else {
                    image = await this.resizeFile(file[0]);
                }
                console.log("image---", image)

                if (typeName == 'file_1') {
                    this.setState({ filePath_1: image, fileName_1: file[0]['name'], displayfile_1: image })
                } else if (typeName == 'file_2') {
                    this.setState({ filePath_2: image, fileName_2: file[0]['name'], displayfile_2: image })
                } else if (typeName == 'file_3') {
                    this.setState({ filePath_3: image, fileName_3: file[0]['name'], displayfile_3: image })
                } else if (typeName == 'file_4') {
                    this.setState({ filePath_4: image, fileName_4: file[0]['name'], displayfile_4: image })
                } else if (typeName == 'file_5') {
                    this.setState({ filePath_5: image, fileName_5: file[0]['name'], displayfile_5: image })
                } else {
                    this.setState({ filePath_6: image, fileName_6: file[0]['name'], displayfile_6: image })
                }

                const fileType = file[0]['type']
                var fileTypeList;
                if (fileType.includes("/")) {
                    fileTypeList = fileType.split("/")
                }
                const finalFileTypeName = fileTypeList.length > 0 ? fileTypeList[1] : ''

                if (typeName == 'file_1') {
                    this.setState({ fileType_1: finalFileTypeName })
                } else if (typeName == 'file_2') {
                    this.setState({ fileType_2: finalFileTypeName })
                } else if (typeName == 'file_3') {
                    this.setState({ fileType_3: finalFileTypeName })
                } else if (typeName == 'file_4') {
                    this.setState({ fileType_4: finalFileTypeName })
                } else if (typeName == 'file_5') {
                    this.setState({ fileType_5: finalFileTypeName })
                } else {
                    this.setState({ fileType_6: finalFileTypeName })
                }

            } else {
                // Only .pdf , .jpeg, .png, .jpg files are allowed
                toast.error('Only .pdf, .jpeg, .png, files are allowed', {
                    position: "top-center",
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
            }
        }
    }

    onPdfSelect = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
        });

    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });

    removeFile(e, fileType) {
        e.preventDefault()
        if (fileType == 'file_1') {
            this.setState({ fileName_1: '', filePath_1: '', fileType_1: '', displayfile_1: '' })
        } else if (fileType == 'file_2') {
            this.setState({ fileName_2: '', filePath_2: '', fileType_2: '', displayfile_2: '' })
        } else if (fileType == 'file_3') {
            this.setState({ fileName_3: '', filePath_3: '', fileType_3: '', displayfile_3: '' })
        } else if (fileType == 'file_4') {
            this.setState({ fileName_4: '', filePath_4: '', fileType_4: '', displayfile_4: '' })
        } else if (fileType == 'file_5') {
            this.setState({ fileName_5: '', filePath_5: '', fileType_5: '', displayfile_5: '' })
        } else {
            this.setState({ fileName_6: '', filePath_6: '', fileType_6: '', displayfile_6: '' })
        }
    }

    handleCancel() {
        const { is_View } = this.state
        if (is_View) {
            // this.props.navigate('/stallDesign_approval', { state: { is_View: true, CompanyName: this.props.location.state.CompanyName } })
            const from = this.props.location.state != null && this.props.location.state.from != null ? this.props.location.state.from : ''
            if (from == 'vendor') {
                this.props.navigate('/VendorModule', { state: { companyName: '' } })
            } else {
                this.props.navigate('/stallDesign_approval', { state: { companyName: '' } })
            }
        } else {
            this.props.navigate('/manualForms')
        }
    }

    showImage(typeName, name) {
        const { displayfile_1,
            displayfile_2,
            displayfile_3,
            displayfile_4,
            displayfile_5,
            displayfile_6, } = this.state
        this.setState({
            isOpen: true,
        })
        if (typeName == 'file_1') {
            this.setState({ modalImagePath: displayfile_1, modalImageName: name })
        } else if (typeName == 'file_2') {
            this.setState({ modalImagePath: displayfile_2, modalImageName: name })
        } else if (typeName == 'file_3') {
            this.setState({ modalImagePath: displayfile_3, modalImageName: name })
        } else if (typeName == 'file_4') {
            this.setState({ modalImagePath: displayfile_4, modalImageName: name })
        } else if (typeName == 'file_5') {
            this.setState({ modalImagePath: displayfile_5, modalImageName: name })
        } else {
            this.setState({ modalImagePath: displayfile_6, modalImageName: name })
        }
    }

    render() {
        const { isLoading, ExhibitorName, StallName, HallName, OpenSides, StallArea, StallTypeName, Remark, currentDate, DeadlineDate,
            fileName_1,
            fileName_2,
            fileName_3,
            fileName_4,
            fileName_5,
            fileName_6,

            displayfile_1,
            displayfile_2,
            displayfile_3,
            displayfile_4,
            displayfile_5,
            displayfile_6,
            isOpen,
            modalImagePath,
            modalImageName,
            is_View,
            StatusID,
            isOpen2,
            isCollapse
        } = this.state
        return (

            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content with-dr-container">
                    <div className="">
                        <div className="row">
                            <ExhibitorTopMenu />
                        </div>
                        <div className="row">
                            <div className="col-xl-12 p-1">
                                <div className="card rounded-0 pt-1 p-2 ">
                                    <div className="card-header p-3">
                                        <h3 className="card-title" style={{textAlign:'left'}}> Stall Design Approval By Architect <br />
                                            Note: <strong>(Refer floor plan ceiling Height-3.80m (under blue line))</strong>
                                        </h3>
                                    </div>
                                    <div className="card-body p-3 text-left">
                                        <div className="row ">
                                            <h6 className="mt-2">
                                                1) The cost/rates and terms &amp; conditions of utilizing the
                                                individual services from the given service provider is solely
                                                discretion of exhibitors.
                                            </h6>
                                            <h6 className="mt-2">
                                                2) India ITME Society is only information facilitator for the
                                                service provider &amp; in no event shall India ITME Society be
                                                liable for any direct, indirect, punitive, incidental, special
                                                consequential issues, whatsoever arising out of taking services
                                                with the above given service provider.
                                            </h6>
                                        </div>
                                        <ol type="1)" style={{ textAlign: 'left' }}>
                                            <li>The exhibitor is responsible for safety at the stand and for ensuring that industrial safety and accident prevention regulations are complied with. The exhibitor is liable for any damage that results from the construction or running of his stand or the exhibits displayed in it. Conditions inside and outside the halls as well as on the open-air site are similar to those on a building site throughout the construction and dismantling periods. The personnel working in this environment must be made aware of the dangers that exist.</li>
                                            <li>To achieve uniformity in display and appearance of the entire exhibition and to ensure proper presentation of all exhibits, Exhibitors are advised to strictly follow the guidelines for stand construction, decoration and display specified by the Organisers.</li>
                                            <li>There would be general lighting within the hall. Temporary electrical connection would be provided to carry on the construction work only from 0800 hrs to 1900 hrs, which will be disconnected at 1900 hrs till 0800 hrs next morning</li>
                                            <li>Aisles in the halls must be kept clear at all times, even during the construction and dismantling period since they are required as rescue and escape routes. Construction material, empty packaging and exhibits are not allowed to be stored in the aisles.</li>
                                            <li>Digging or grouting or cutting of floor is not permitted in any Hall.</li>
                                            <li>Organisers will allot only bare space with proper marking of the borders on the floor for the area booked by the exhibitors. Construction of partition walls is the responsibility of the bare space stall holder. For example, a single side open bare space stall holder will have the responsibility of constructing partition walls on 3 sides. While finishing is to be carried out only on one side, facing your stand, if you can co-ordinate with the adjoining stand owner and finish from both sides, thereby reducing the manpower and cost for both the exhibitors.</li>
                                            <li>Exhibitors are required to submit stall layout plan, elevation and 3D artist’s impression, online for it’s approval positively before 00.00.000.</li>
                                            <li>An exhibitor whose design has been approved by the IITME architect, can commence fabrication after taking official possession of the stall area. No exhibitor shall be allowed to start work on their stand without prior approval of the design. The decision of the ITME architect as regards to the approval of the design shall be final and binding upon exhibitors and the work must be carried out as per the approved design only.</li>
                                            <li>Exhibitors are advised not to use any vacant space around and behind their stands for storage or otherwise. This could be hazardous and could restrict the movement of service personnel. Organisers reserve the right to remove such material at the risk & cost of the Exhibitor.</li>
                                            <li>Workers having night badges issued by the organisers will be allowed to stay inside the halls at night and that too only within their stall area from 2100 hrs to 0800 hrs next day. Any worker, Contractor, Exhibitors’ representative, material supplier, wanting to enter the exhibition halls must have an official badge issued by the Organisers.</li>
                                            <li>Double Storey maybe permitted to be constructed inside the stands having an area of above 54 Sqm. subject to payment of additional space rent and on the following conditions :-</li>
                                            <ol type="a">
                                                <li>Area of Double Storey shall not exceed 25% of the stand area subject to approval of the show architect.</li>
                                                <li>Maximum clear height below the Double Storey  shall not exceed 2.2 mtrs. Overall height of the stand including double storey shall not exceed 5.0 Mts</li>
                                                <li>The railing on the sides of the Double Storey  shall be of transparent material in the portion extending beyond 2.5 mtrs. Height from the ground level.</li>
                                                <li>Any stall which is not along the natural wall; Double Storey  covering through the solid wall is not allowed and only open Double Storey  with railing of maximum 1.5 Mtr. height is permissible.</li>
                                                <li>A strip of at least 0.10 Mtr. must be mounted along the edge of the Double Storey beneath the railing to prevent falling of objects.</li>
                                                <li>Walls adjoining neighbouring stands on the Double Storey must be sufficiently stable so as to prevent accidental falling of persons or objects.</li>
                                                <li>Exhibitor to get a structural engineer to duly certify the stability of the structure of the Double Storey  and the Organisers shall not be held responsible for any mishap due to the sub-standard design / workmanship / material used while the construction of the Double Storey . Any sub-standard construction leading to collapse of Double Storey  / accidents will be penalised and blacklisted for future editions</li>
                                                <li>The construction of Double Storey  shall be permitted only if, in the opinion of the Fair Architect, it is not obstructing the display / visibility of any adjoining stand(s).</li>
                                                <li>No branding is allowed on and above the Double Storey s or 5.0 Mts whichever is higher.</li>
                                                <li>Only railing of maximum height of 1.5 Mtr without any branding is allowed in the front and open sides of the stand.</li>
                                                <li>Meeting rooms on Double Storey , if required, should be constructed in the center leaving 1.0 Mtr from the periphery in case the Double Storey  is to be placed along a partition that is common with another stand.</li>
                                                <li>Walls of meeting rooms on Double Storey  should be of transparent material above 1.2 Mts height.</li>
                                                <li>The approval and use of Double Storey  area shall be subject to payment @ 50% of the total stall charges payable for the area used for Double Storey .</li>
                                                <li>The Double Storey s can be used only as a visitor hospitality lounge and meeting areas. Exhibits / graphic display/ Video walls will not be permitted on the Double Storey .</li>
                                                <li>Approval of Mezzanine shall be granted only if the design is accompanied with the Structural Stability certificate from a certified Structural engineer</li>
                                            </ol>
                                            <h6>11.2 Double Storey Design Guideline</h6>
                                            <ol type="a">
                                                <li>Offices / cabins with closed walls & ceilings are strictly prohibited on the Double Storey . The Double Storey   must be an open area only with safety railings on open sides.</li>
                                            </ol>
                                            <h6>11.3 Escape routes</h6>
                                            <ol type="a">
                                                <li>Nearest escape route / exit should have been provided no further than 10 mtrs from any location within the stand. Exhibitors are to ensure that such routes / exits are not blocked or obstructed in any way.</li>
                                                <li>Double Storey s with areas{'>'}50 sq.mtrs. must have two stairways – one for entry and one for exit. Such staircases must be placed separately and not next to each other for better escape route</li>
                                            </ol>
                                            <h6>11.4 Stairways</h6>
                                            <ol type="a">
                                                <li>Staircase risers in all steps must be completely closed.</li>
                                                <li>The riser of each step should not exceed 0.20 mtrs and the tread depth must be at least 0.25 mtrs in the middle of the steps.</li>
                                                <li>Handrails are required on both the sides of the stairway. They must provide a safe grip and must be free of gaps even on landings.</li>
                                                <li>Handrail clearance to an adjacent structure must be at least 0.10 mtrs.</li>
                                                <li>The width of stairway should be at least 1.25 mtrs and should not exceed 1.5 mtrs.</li>
                                            </ol>
                                            <h6>11.5 Stairways</h6>
                                            <ol type="a">
                                                <li>Safety railings must be at least 1.5 mtrs high and consist of a top, middle and bottom rail.</li>
                                            </ol>
                                            <h6>11.6 Fire prevention & Stall height</h6>
                                            <ol type="1">
                                                <li>Any load bearing elements of the Double Storey must be flame retardant.</li>
                                                <li>The Double Storey should be equipped with at least one fire extinguishers per 20 Sq. Mts of Double Storey .</li>
                                                <li>Double Storey Structural Stability Certificate with technical specifications approved by Structural Engineer has to be submitted to the IITME 2022 Secretariat for approval at the time of submission of design.</li>
                                                <li>The completion certificate from the structural engineer has to be provided before the use of such a mezzanine structure.</li>
                                                <li>Exhibitors should leave minimum 30% of the stall area free for movement of visitors.</li>
                                                <li>The panels adjacent to other stand areas and the ceilings of the stand areas that are visible from above must be properly finished in neutral colour without any branding towards the adjacent stands.</li>
                                                <li>All stands with an accessible platform higher than 50mm must have an access ramp and the slope of such ramp(s) must be uniform and not more than 8%. This ramp/ slope must fall within the stand area and must not protrude into the aisle.</li>
                                                <li>Organisers suggest co-operation between neighboring exhibitors for construction and finishing of common partitions.</li>
                                                <li>Sides, which are open to the aisles, should not be blocked by panels for more than 30% of the length of such side. Any structure, publicity material, photographs, charts, should not have height exceeding 4.0 meters. However, products higher than 4.0 Mts on display and placed directly on the floor without artificially raising the height can be more than 4.0 meters as per availability of height within the premises. Height of panels and common panels between stalls should not be more than 4.0 meters and should have smooth surface on both sides. However, the back wall of the stall against natural wall of the hall can go up to a height of 5.0 meters, wherever available. <strong>(Refer floor plan ceiling Height-3.80m (under blue line)</strong>.</li>
                                                <li>Pillars in the stalls may be covered with paneling up to height of 5.0 meters without grouting on the wall, floor or causing damage of any nature to the structure of the exhibition hall. A clear access with proper signage should be provided on such paneling, in case the pillar has electrical main supply point/ distribution boards for access to electric supply/ Fire extinguishers/ Fire Control Panels.</li>
                                                <li>No suspensions are to be made from the trusses of the Exhibition Hall. Exhibitors will not be permitted to fasten machines or any part of display may be attached, nailed, screwed or drilled on to the floor, columns, wall or any other part of the hall. If this instruction is ignored, the Exhibitor/Contractor concerned will be penalized for the damage thus caused.</li>
                                                <li>A back wall must be provided, except in the case of an island stall. The Organiser reserves the right to change, modify, lower or shorten any back wall or sidewall, if such a wall, in the opinion of the Organiser/ Fair Architect, may obstruct the reasonable view or exposure of other Exhibitors’ stalls at the cost and risk of the exhibitor.</li>
                                                <li>Exhibitors applying for shell scheme construction would be provided partitions on all covered sides and a fascia indicating company name and stand number.</li>
                                                <li>Use of bright and/or coloured decorative lights, neon signs of any size, shape or colour (including those depicting exhibitor’s name or logo) or any other similar electrical decorative materials is strictly forbidden.</li>
                                                <li>Use of any type of loudspeakers or musical instrument in the stalls is forbidden. Use of video tapes for display and demonstration will be permitted.</li>
                                                <li>Use of all types of banners, hanging decorations made by paper, cloth or any other inflammable material etc. is strictly forbidden. Use of flowers in vases and potted plants is welcome.</li>
                                                <li>Carpeting/ Floor covering of the stands is compulsory for all Exhibitors. Use of suitable commercial material for floor covering inside the stands is permitted. Gluing/ pasting of carpet on the floor may be done only by using easily removable bi-adhesive tape that leaves no marks on the floor.</li>
                                                <li>Enclosures for office, conference or pantry can be set up in the stalls provided the height and area covered by such enclosures is not more than 4.0 meters and 30% of the stall area respectively. Such enclosures should be located along the building wall of the exhibition hall, if any, or at the rear of the stall. Enclosures should be at least 2 meters away from the aisles/ open sides. Enclosures have to conform to the open side regulation as per point number 16. In case length of such Enclosures is more than the prescribed length, it shall be at least 2 meters away from aisles.</li>
                                                <li>For stands with 3/4 open sides, there shall be no partitions for more than 30% of the length of any open side and the inside partitions should also be such that they do not obstruct the view or aesthetics of the adjoining stand/s. In case they are found to be obstructive, such partitions shall have to be provided with clear glass / acrylic above 1.2 meters height.</li>
                                                <li>No part of any structure or exhibit, including overhangs/ cantilevers, would be allowed to extend beyond the boundaries of the stall allocated. This includes, symbols, logos, lighting, floral decorations, furnishings etc.</li>
                                                <li>Logos and single branding signages can be displayed by exhibitors up to a height of 4.0 meters for stands up to 54 Sq. Mts, up to a height of 5.0 meters for stands up to 108 Sq. Mts provided they are away from adjacent stalls by a minimum distance of 1.5 meters and up to a height of 6.0 meters for stands above 108 Sq. Mts provided they are away from adjacent stalls by a minimum distance of 2 meters and subject to height available in the Hall.</li>
                                                <li>All the halls are air-conditioned. However, the participants who desire to install air-conditioning facility in their cabins would need to raise a request for the same. Permission for installation of Air conditioners is subject to approval received from venue owners as they normally don't allow such installations.</li>
                                                <li>Exhibitors shall keep and maintain the interiors including all fittings, fixtures etc. belonging to BCEC in good order and condition and will leave the same in as good a condition as they were on the date of possession of the stall.</li>
                                                <li>Kindly note that all Exhibitors, Media Partners, Associates and Agents are allowed to do promotion, distribution or marketing within their respective booth. No temporary or unauthorized staff will be allowed to distribute pamphlets or do any marketing outside their booth or use public areas to do so. All staff allowed inside exhibition premises will have to get permission from organizers to be inside venue and should carry identity badges</li>
                                                <li>We request your co-operation in this regard and help us to conduct the event in an organized and planned way, and safe manner making it convenient for both the exhibitors/visitors and the organizers.</li>
                                                <li>Use of all types of publicity materials such as posters, banners, hanging decorations made of any material is not allowed outside the stall area of the exhibitors. Suspending of display items from the Hall ceiling or any other structure falling within the stands is also not permitted.</li>
                                                <li>Posters, banners, hangings and advertisement boards will be put up by the Organisers and will be available as sponsorship opportunities for the exhibitors.</li>
                                                <li>Use of potted plants is allowed only inside the stall area of the exhibitor.</li>
                                                <li>Exhibitors are requested to note that it is the responsibility of their appointed stall-fitting contractors to clean the stalls upon completion of construction, before handing over to the Exhibitor. Thereafter, the official cleaning contractor will clean the aisles. Kindly also ensure that they carry out the prevalent Sanitization Protocol as governed by the venue and Organiser guidelines.</li>
                                                <li>The guidelines for bare space stalls (as stated above) are also applicable to the contractors/ exhibitors carrying out interior decoration for the stalls under shell scheme.</li>
                                                <li>Exhibitors are advised to take insurance policy covering their machinery/exhibit, transit and loading and unloading of machines, their exhibition personnel, vendors and third parties (visitors).</li>
                                            </ol>
                                        </ol>
                                    
                                        {/* Refer Rules and Regulations <span style={{ color: '#0d6efd', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.setState({ isOpen2: true })}> click here to view</span> */}
                                    </div>
                                    <div class="card-header p-3" style={{ marginBottom: '15px' }}>
                                        <h3 class="card-title" style={{ fontsize: "15px", marginRight: '15px' }}>Exhibitors can use electrical guidelines for their stall
                                        </h3>
                                        <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
                                            onClick={() => this.setState({ isCollapse: !isCollapse })}>click here to {isCollapse ? 'hide' : 'read'} Guidelines</span>
                                    </div>
                                    <div class="card-body p-1 m-2">
                                        {isCollapse && <div>
                                            <ol type="1)" style={{ textAlign: 'left' }}>
                                                <li>Exhibitors under Bare Space category are required to apply for power supply and pay electricity connection/consumption charges on the basis of required connected load for machinery, including lighting and air-conditioning. Exhibitors’ should inform their power requirements along with the payment towards power charges before deadline.
                                                </li>
                                                <li>Exhibitors should note that 1kw supply/outlet is compulsory in every single stall as a facility for lighting. Every exhibitor must submit their layout plan with indication of point of power supply.
                                                </li>
                                                <li>The stall contractors undertaking lighting work shall possess valid electrical wireman license..
                                                </li>
                                                <li>The load ≤ 3 kW shall be fed through single phase electrical supply at 230V AC ± 6 % and load {'>'} 3 kW shall be fed through 3 Phase electrical Supply at 415V AC ± 6 % The supply Frequency will ne 50Hz ± 3%. d.</li>
                                                <li>The exhibitor requiring supply at voltage level other than above shall make necessary arrangement for the level matching equipment such as transformer, voltage stabilizer, inverter etc. to match the supply voltage level to their load..</li>
                                                <li>The frame of stationary motor and the metallic parts, not intended as conductors, including transformers and any other apparatus used for regulating or controlling electricity of voltage exceeding 250V but not exceeding 650V shall be earthed firmly by the conductor size as mention. </li>
                                                <li>Exhibitor are advised to make arrangement for power conditioning equipment such as UPS, CVT etc. for their critical & supply sensitive load/ equipment operating under very narrow band of tolerance</li>
                                                <li>The contractors shall use BIS marked PVC insulated 650/1100 V copper conductor wire for supplying electrical power to lighting & motive load to be installed in stall</li>
                                                <li>All switch boards shall conform to BIS & to Indian Electricity Act (amended).</li>
                                                <li>The insulation tape used shall carry IS marking</li>
                                                <li>For lighting circuit, the minimum wire size shall be 1.5/2.5 sq.mm PVC insulated 650V grade FRLS copper wire. For 16A power point, the minimum wire size shall be 4sq.mm PVC insulated 650V grade FRLS copper wire. The IR value of cable used shall not be less than 1MΩ when measured with 500V DC megger</li>
                                                <li>Exhibitor can bring international adopter for their internal electrical connection</li>
                                                <li>The contractor shall use following size of protective conductors for equipment at their stall from point of supply (MCB Box).</li>
                                                <div class="table-responsive">
                                                    <table class="table border text-nowrap text-md-nowrap table-bordered mb-2 mt-2 w-50">
                                                        <thead>
                                                            <tr>
                                                                <th>Size of phase conductor</th>
                                                                <th>Size of protective conductor of the same material as phase conductor</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Up to 4mm²</td>
                                                                <td>Same size as that of phase conductor</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Above 4mm² & up to 16mm²</td>
                                                                <td>Same size as that of phase conductor</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Above 16mm² & up to 35mm²</td>
                                                                <td>16mm²</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Above 35mm²</td>
                                                                <td>Half size of phase conductor</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <li>The earth wires shall be connected to the main earth firmly. After switching the entire load at stall, the voltage between neutral & earth shall not exceed 10V.</li>
                                                <li>Exhibitor shall ensure that, the contractor shall carry out all electrical works in the stall area as per Indian Electricity Rules 2005/ CEA Regulation 2010/ General Specifications for Electrical Works, Part I & II by CPWD.</li>
                                                <li>The Contractor shall carry out wiring at their installation as per IS-732:2010 with all safety precautions.</li>
                                                <li>The load connected to power point shall be suitable for the socket and under no circumstances exceed the rated current capacity of the socket.</li>
                                                <li>Exhibitors must install separate and independent MCBs/MCCBs/RCCBs of appropriated rating for their individual machines so as to automatically disconnect the supply in case of a) Overload b) Earth leakage or earth fault c) short circuit.</li>
                                                <li>It is mandatory to use only reputed make wires like Polycab, KEI, Anchor etc and MCB/RCCB like Legrand, Siemens, ABB, Schneider for the electrical installation.</li>
                                                <li>Wherever 3 ph connection is requested, the load shall be evenly distributed on all three phases. The current through neutral conductor or in other words, the current unbalance shall not exceed 5%.</li>
                                                <li>The contractor shall also ensure that conductor size of the cable/ wire is suitable to cater connected load without overloading/overheating in normal operation and during abnormal conditions. This may be checked and verified by electrical consultant.</li>
                                                <li>In case any material used by the contractor is found to be of inferior or sub-standard quality, the contractor shall be responsible for replacing the same without any loss of time as per the quality mutually agreed and mentioned above.</li>
                                                <li>It is recommended to make use of low loss/ low heating choke manufactured as per IS and of reputed make namely Philips, Crompton, Bajaj, Wipro or equivalent.</li>
                                                <li>The contractor shall control maximum 10 points or 800 watts whichever less, from single point.</li>
                                                <li>Joint to the wires in any place is unacceptable. However, good quality connector of adequate rating properly covered cab be used.</li>
                                                <li>Use 3-pin top / industrial to shall be made to take supply from sockets. The third pin shall be connected to earth through protective (loop earthing) conductor. It is strictly prohibited to insert wires directly into socket for drawing supply.</li>
                                                <li>Adequate access to main power supply (relieving point) at stall shall be provided for cleaning & maintenance purpose. A clear space of not less than 1 meter shall be provided in front of the switchboard. Materials particularly combustible shall not be dumped at this location.</li>
                                                <li>All main electrical supply points must be kept easily accessible for operation and repairs and for isolation of power in the event of emergency. These should not be concealed or covered under any circumstances.</li>
                                                <li>Exhibitors requesting for 3-phase supply shall draw single phase power for lighting purpose from 3-phase supply outlet using double pole MCBs.  The double pole MCB/RCCB shall be arranged by the exhibitor.</li>
                                                <li>The electrical supply will be released only after completion of wiring work conforming to the Indian standards & codes of wiring and to the satisfaction of Electrical consultant.</li>
                                                <li>UPS/Stabiliser with rating greater than 3kVA should have 3 Phase Input.</li>
                                                <li>1 point of power supply will be provided to below 15 kw.</li>
                                                <li>Exhibitors may contact the Electrical Consultant at venue office for any technical queries.</li>
                                                <li>Exhibitors under Shell Scheme package would be provided with a plug point socket and a switch for a 230 V, 6 Amp A.C. supply. Consumption of power through this plug point for the connected load upto 1 kw is included in the cost of shell scheme package. However, additional power would be made available to them on extra payment.</li>
                                                <li>Exhibitors shall bring their own electrical cables, wires, sockets, international electrical adaptor to connect their electrical installation.</li>
                                                <li>The Exhibition authority arrange for supply of power will be entrusted with the authority for regulating and controlling the power supply to the exhibition halls and individual stalls.</li>
                                                <li>ELECTRICAL INSTALLATION REGULATIONS</li>
                                                <ol>
                                                    <li>Electrical wiring and installation must be done by Exhibitors through registered professional technicians using proper wiring, switches etc. so as to ensure safety. The Organisers shall not be responsible or liable in case of any mishap due to use of sub-standard material or unskilled labour.</li>
                                                    <li>Lamps and appliances with high temperature surfaces should be guarded and used well away from combustible materials.</li>
                                                    <li>No light fitting or other appliance may be suspended from the roof of the exhibition hall.</li>
                                                    <li>Exhibitors are requested to review their internal wiring and connections thoroughly before going live. Also, Members should estimate the power requirement in advance so that, appropriate load management can be done. Switching from temporary to permanent power supply should be done in advance clear two days, so that testing and balancing can be done properly.</li>
                                                    <li>Exhibitor shall submit Electrical Work Completion Report to Hall Directors for permanent power connection as per the format. Power will be released on receipt of the completion report.</li>
                                                    <li>The Organisers reserve the right to disconnect any installation, which in their opinion is dangerous or likely to cause annoyance to visitors or other Exhibitors</li>
                                                    <li>In order to take care of voltage fluctuations, the Exhibitors are advised to fix up constant voltage transformers or insulation Transformer or stabilizers for CNC and other sensitive machines. Exhibitors are encouraged to use MCCB wherever necessary. The main supply points and electrical installations in the stand should be kept open for easy access and should not be concealed.</li>
                                                    <li>Electrical power should be drawn from designated points allocated to the exhibitor only. Any exhibitor or its designated contractor found drawing power from unauthorized sources will be liable to forfeiter of security deposit </li>
                                                </ol>

                                            </ol>
                                            <div style={{ textAlign: 'left' }}>
                                                <strong>Note :</strong> In case of any dispute arising in case of “Safety” and “consumption of power drawn”. The judgment by consultant will be final & binding on the contractor/Exhibitor.
                                            </div>
                                        </div>}
                                    </div>
                                    <p style={{ margin: '0 0 0 10px' }}><strong>Exhibitor Details:</strong></p>
                                    <div className="row mb-2 m-0">
                                        <div className="col-md-4">
                                            <label>Exhibitor Name</label>
                                            <input type="text" className="form-control"
                                                value={ExhibitorName}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-md-4 ">
                                            <label htmlFor="validationCustom03">Stall No</label>
                                            <input type="text" className="form-control"
                                                value={StallName}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-md-4 ">
                                            <label htmlFor="validationCustom03">Hall Name</label>
                                            <input type="text" className="form-control"
                                                value={HallName}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-4 m-0">
                                        <div className="col-md-4">
                                            <label>Open Sides</label>
                                            <input type="text" className="form-control"
                                                value={OpenSides}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-md-4 ">
                                            <label htmlFor="validationCustom03">Area</label>
                                            <input type="text" className="form-control"
                                                value={StallArea}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-md-4 ">
                                            <label htmlFor="validationCustom03">Stall Type</label>
                                            <input type="text" className="form-control"
                                                value={StallTypeName}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="row mb-4 m-0">
                                        <div class="step-name">
                                            <p>Upload Stall Design</p>
                                        </div>
                                    </div> */}
                                    <div class="row m-3">
                                        <div className="col-md-3 p-0" style={{ border: '1px solid #c9c4c4', borderRadius: '5px' }}>
                                            <p style={{
                                                padding: '15px',
                                                background: '#e3e2e2',
                                                textAlign: 'center'
                                            }}> <strong>Floor Plan<span className="required">*</span></strong> </p>
                                            <iframe
                                                title=""
                                                // src={`${url}${data.CateringImage}#toolbar=0&navpanes=0`}
                                                src={`${displayfile_1}#toolbar=0&navpanes=0`}
                                                // src='https://ems_uat.india-itme.com/document/Circular/10/Circular_10.pdf#toolbar=0&navpanes=0'
                                                style={{ width: "220px", height: "270px", cursor: 'pointer' }}
                                                id="myIframe"
                                            />
                                            <div
                                                className="viewImageIframe"
                                                onClick={(e) => this.showImage('file_1', 'Floor Plan')}
                                            />
                                            <div className="mb-2">
                                                {fileName_1 != '' ?
                                                    <>
                                                        <div className="d-flex" style={{ justifyContent: 'center' }}>
                                                            <div className="chooseStallFile" >
                                                                <p className="overflow-membership m-0">{fileName_1}
                                                                </p>
                                                                <span
                                                                    className='membership_file_cross text-danger'
                                                                    onClick={(e) => !is_View ? !is_View ? this.removeFile(e, 'file_1') : '' : ''}
                                                                >X</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="d-flex" style={{ justifyContent: 'center' }}>
                                                        <input type="file" className="form-control mt-2 p-1 mb-2 custom-file-input" style={{ width: '80%' }}
                                                            onChange={(e) => this.onFilePick(e, e.target.files, 'file_1')}
                                                        />
                                                    </div>
                                                }
                                                <p style={{ margin: '6px', textAlign: 'center' }}>
                                                    (All image formats are allowed with maximum size of 3MB)
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 p-0" style={{ border: '1px solid #c9c4c4', borderRadius: '5px' }}>
                                            <p style={{
                                                padding: '15px',
                                                background: '#e3e2e2',
                                                textAlign: 'center'
                                            }}>  <strong>3D View<span className="required">*</span></strong></p>
                                            <iframe
                                                title=""
                                                src={`${displayfile_2}#toolbar=0&navpanes=0`}
                                                style={{ width: "220px", height: "270px", cursor: 'pointer' }}
                                                id="myIframe"
                                            />
                                            <div
                                                className="viewImageIframe"
                                                onClick={(e) => this.showImage('file_2', '3D View')}
                                            />
                                            <div className="mb-2">
                                                {fileName_2 != '' ?
                                                    <>
                                                        <div className="d-flex" style={{ justifyContent: 'center' }}>
                                                            <div className="chooseStallFile" >
                                                                <p className="overflow-membership m-0">{fileName_2}
                                                                </p>
                                                                <span
                                                                    className='membership_file_cross text-danger'
                                                                    onClick={(e) => !is_View ? this.removeFile(e, 'file_2') : ''}
                                                                >X</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="d-flex" style={{ justifyContent: 'center' }}>
                                                        <input type="file" className="form-control mt-2 p-1 mb-2" style={{ width: '80%', }}
                                                            onChange={(e) => this.onFilePick(e, e.target.files, 'file_2')}
                                                        />
                                                    </div>
                                                }
                                                <p style={{ margin: '6px', textAlign: 'center' }}>
                                                    (All image formats are allowed with maximum size of 3MB)
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 p-0" style={{ border: '1px solid #c9c4c4', borderRadius: '5px' }}>
                                            <p style={{
                                                padding: '15px',
                                                background: '#e3e2e2',
                                                textAlign: 'center'
                                            }}>  <strong>Front View<span className="required">*</span></strong></p>
                                            <iframe
                                                title=""
                                                src={`${displayfile_3}#toolbar=0&navpanes=0`}
                                                style={{ width: "220px", height: "270px", cursor: 'pointer' }}
                                                id="myIframe"
                                            />
                                            <div
                                                className="viewImageIframe"
                                                onClick={(e) => this.showImage('file_3', 'Front View')}
                                            />
                                            <div className="mb-2">
                                                {fileName_3 != '' ?
                                                    <>
                                                        <div className="d-flex" style={{ justifyContent: 'center' }}>
                                                            <div className="chooseStallFile" >
                                                                <p className="overflow-membership m-0">{fileName_3}
                                                                </p>
                                                                <span
                                                                    className='membership_file_cross text-danger'
                                                                    onClick={(e) => !is_View ? this.removeFile(e, 'file_3') : ''}
                                                                >X</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="d-flex" style={{ justifyContent: 'center' }}>
                                                        <input type="file" className="form-control mt-2 p-1 mb-2" style={{ width: '80%', }}
                                                            onChange={(e) => this.onFilePick(e, e.target.files, 'file_3')}
                                                        />
                                                    </div>
                                                }
                                                <p style={{ margin: '6px', textAlign: 'center' }}>
                                                    (All image formats are allowed with maximum size of 3MB)
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 p-0" style={{ border: '1px solid #c9c4c4', borderRadius: '5px' }}>
                                            <p style={{
                                                padding: '15px',
                                                background: '#e3e2e2',
                                                textAlign: 'center'
                                            }}> <strong>Supporting Document<span className="required">*</span></strong></p>
                                            <iframe
                                                title=""
                                                src={`${displayfile_4}#toolbar=0&navpanes=0`}
                                                style={{ width: "220px", height: "270px", cursor: 'pointer' }}
                                                id="myIframe"
                                            />
                                            <div
                                                className="viewImageIframe"
                                                onClick={(e) => this.showImage('file_4', 'Supporting Document')}
                                            />
                                            <div className="mb-2">
                                                {fileName_4 != '' ?
                                                    <>
                                                        <div className="d-flex" style={{ justifyContent: 'center' }}>
                                                            <div className="chooseStallFile" >
                                                                <p className="overflow-membership m-0">{fileName_4}
                                                                </p>
                                                                <span
                                                                    className='membership_file_cross text-danger'
                                                                    onClick={(e) => !is_View ? this.removeFile(e, 'file_4') : ''}
                                                                >X</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="d-flex" style={{ justifyContent: 'center' }}>
                                                        <input type="file" className="form-control mt-2 p-1 mb-2" style={{ width: '80%', }}
                                                            onChange={(e) => this.onFilePick(e, e.target.files, 'file_4')}
                                                        />
                                                    </div>
                                                }
                                                <p style={{ margin: '6px', textAlign: 'center' }}>
                                                    (All image formats are allowed with maximum size of 3MB)
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row m-3">
                                        <div className="col-md-3 p-0" style={{ border: '1px solid #c9c4c4', borderRadius: '5px' }}>
                                            <p style={{
                                                padding: '15px',
                                                background: '#e3e2e2',
                                                textAlign: 'center'
                                            }}> <strong>Structural certificates from certified Engineer </strong></p>
                                            <iframe
                                                title=""
                                                src={`${displayfile_5}#toolbar=0&navpanes=0`}
                                                style={{ width: "220px", height: "270px", cursor: 'pointer' }}
                                                id="myIframe"
                                            />
                                            <div
                                                className="viewImageIframe"
                                                onClick={(e) => this.showImage('file_5', 'Structural certificates from certified Engineer')}
                                            />
                                            <div className="mb-2">
                                                {fileName_5 != '' ?
                                                    <>
                                                        <div className="d-flex" style={{ justifyContent: 'center' }}>
                                                            <div className="chooseStallFile" >
                                                                <p className="overflow-membership m-0">{fileName_5}
                                                                </p>
                                                                <span
                                                                    className='membership_file_cross text-danger'
                                                                    onClick={(e) => !is_View ? this.removeFile(e, 'file_5') : ''}
                                                                >X</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="d-flex" style={{ justifyContent: 'center' }}>
                                                        <input type="file" className="form-control mt-2 p-1 mb-2" style={{ width: '80%' }}
                                                            onChange={(e) => this.onFilePick(e, e.target.files, 'file_5')}
                                                            disabled={is_View}
                                                        />
                                                    </div>
                                                }
                                                <p style={{ margin: '6px', textAlign: 'center' }}>
                                                    (All image formats are allowed with maximum size of 3MB)
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 p-0" style={{ border: '1px solid #c9c4c4', borderRadius: '5px' }}>
                                            <p style={{
                                                padding: '15px',
                                                background: '#e3e2e2',
                                                textAlign: 'center'
                                            }}><strong> Fire safety certificates from certified Engineer </strong></p>
                                            <iframe
                                                title=""
                                                src={`${displayfile_6}#toolbar=0&navpanes=0`}
                                                style={{ width: "220px", height: "270px", cursor: 'pointer' }}
                                                id="myIframe"
                                            />
                                            <div
                                                className="viewImageIframe"
                                                onClick={(e) => this.showImage('file_6', 'Fire safety certificates from certified Engineer')}
                                            />
                                            <div className="mb-2">
                                                {fileName_6 != '' ?
                                                    <>
                                                        <div className="d-flex" style={{ justifyContent: 'center' }}>
                                                            <div className="chooseStallFile" >
                                                                <p className="overflow-membership m-0">{fileName_6}
                                                                </p>
                                                                <span
                                                                    className='membership_file_cross text-danger'
                                                                    onClick={(e) => !is_View ? this.removeFile(e, 'file_6') : ''}
                                                                >X</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="d-flex" style={{ justifyContent: 'center' }}>
                                                        <input type="file" className="form-control mt-2 p-1 mb-2" style={{ width: '80%' }}
                                                            disabled={is_View}
                                                            onChange={(e) => this.onFilePick(e, e.target.files, 'file_6')}
                                                        />
                                                    </div>
                                                }
                                                <p style={{ margin: '6px', textAlign: 'center' }}>
                                                    (All image formats are allowed with maximum size of 3MB)
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row m-1">
                                        <form>
                                            <strong> <label>Remark</label></strong>
                                            <textarea class="form-control"
                                                value={Remark}
                                                onChange={(e) => this.setState({ Remark: e.target.value })}
                                                maxLength={300}
                                                disabled={is_View}
                                                rows="3">
                                            </textarea>
                                        </form>
                                    </div>

                                    <div class='d-flex m-3'>
                                        {!is_View ?
                                            <button
                                                type="button"
                                                className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                                style={{ marginRight: 10 }}
                                                disabled={currentDate > DeadlineDate}
                                                onClick={() => this.handleSubmit()}
                                            >
                                                Submit
                                            </button> : <></>
                                        }
                                        <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={isOpen} onHide={() => this.setState({ isOpen: false })} >
                    <Modal.Header>
                        <Modal.Title> {modalImageName} </Modal.Title>
                        <span
                            onClick={() => this.setState({ isOpen: false })}
                            style={{ cursor: "pointer", fontWeight: "800" }}
                        >
                            X
                        </span>
                    </Modal.Header>

                    <Modal.Body style={{ marginBottom: '0px' }}>

                        <iframe
                            title=""
                            // src={`${url}${data.CateringImage}#toolbar=0&navpanes=0`}
                            src={`${modalImagePath}#toolbar=0&navpanes=0`}
                            // src='https://ems_uat.india-itme.com/document/Circular/10/Circular_10.pdf#toolbar=0&navpanes=0'
                            style={{ width: "905px", height: "550px" }}
                            id="myIframe"
                        />

                    </Modal.Body>

                </Modal>

                <Modal show={isOpen2} onHide={() => this.setState({ isOpen2: false })} >
                    <Modal.Header>
                        <Modal.Title> {modalImageName} </Modal.Title>
                        <span
                            onClick={() => this.setState({ isOpen2: false })}
                            style={{ cursor: "pointer", fontWeight: "800" }}
                        >
                            X
                        </span>
                    </Modal.Header>

                    <Modal.Body style={{ marginBottom: '0px' }}>
                        <iframe
                            id="myIframe"
                            // src={`/${sessionStorage.getItem("sessionExhibitionID")}/Rules-and-Regulations.pdf#toolbar=0&navpanes=0`}
                            src={`${sessionStorage.getItem('RulesAndRegulations')}#toolbar=0&navpanes=0`}
                            width="100%"
                            height="435px"
                            title="Bing"
                        >
                            &lt;/div&gt; &lt;div style="height: 100px;"&gt;&lt;/div&gt;
                            &lt;/div&gt; &lt;/div&gt; &lt;/div&gt;
                        </iframe>

                    </Modal.Body>

                </Modal>
            </>
        )
    }
}
export default withRouter(connect(null, { getStallDesignDetails_action, insertUpdateStallDesign_action })(StallDesign))