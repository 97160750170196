import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess, cityActionId, primaryChapterListActionId } from "../../../../../utils/constants";
import { insertUpdateCarPassessApplication_action, getCarPassessApplicationDetails_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/carPassessApplication_action/carPassessApplication_action'
import { getVisitorMeeting_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/visitorMeeting_action/visitorMeeting_action'
import Modal from "react-modal";
import { data } from "jquery";
import $ from 'jquery'
import { fill_action } from "../../../../../actions/fillCombo_action"
import moment from "moment";

// import {, } from "../../../utils/constants";

// 
class VisitorMeetingApplication extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            exhRegistrationID: '',
            exhibitionID: '',
            currentDate: moment().format('DD-MM-YYYY'),
            DeadlineDate: '',
            cityList: [],
            tempCityList: [],
            chapterList: [],
            cityID: '',
            city: '',
            chapterID: '',
            companyName: '',
            Designation: '',
            isDisplayCityList: false,
            visitorMeetingList: [],
            bookedVisitorList: [],
            StatusID: ''
        }
        this.getFillList = this.getFillList.bind(this)
        this.getVisitorMeetingList = this.getVisitorMeetingList.bind(this)
        this.onCitySearch = this.onCitySearch.bind(this)
        this.renderCityList = this.renderCityList.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.handleBookMeeting = this.handleBookMeeting.bind(this)

    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                moment(this.props.location.state.Deadline).format('DD-MM-YYYY') : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ?
                this.props.location.state.StatusID : '',
        })
        this.getFillList(primaryChapterListActionId, '', '', '', '', '')
        this.getFillList(cityActionId, '', '', '', '', '')
        this.getVisitorMeetingList(0)
        // this.getCarPassessDetails()
        this.loadscript()
    }

    // Get Fill Lists
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // city list
                    if (actionID === cityActionId) {
                        console.log("city data", data.data)
                        this.setState({
                            cityList: data.data,
                            tempCityList: data.data,
                        })
                    }
                    if (actionID === primaryChapterListActionId) {
                        console.log("primaryChapterListActionId 121212", data.data['primaryChapter'])
                        this.setState({
                            chapterList: data.data['primaryChapter'].length > 0 ? data.data['primaryChapter'] : [],
                        })
                    };
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }

    handleCancel() {
        this.props.navigate('/manualForms')
    }

    handleBookMeeting(CompanyName, Designation, City, RegDays, id, MeetingDate, MeetingTime, Remark) {
        const { DeadlineDate, StatusID } = this.state
        this.props.navigate('/visitorMeetingAU', {
            state: {
                Deadline: DeadlineDate,
                CompanyName: CompanyName,
                Designation: Designation,
                City: City,
                RegDays: RegDays,
                VisitorMeetingBookingDataID: id,
                MeetingDate: MeetingDate,
                MeetingTime: MeetingTime,
                Remark: Remark,
                StatusID: StatusID,
            }
        })

    }
    getVisitorMeetingList(id) {
        const { companyName, Designation, city, chapterList, chapterID } = this.state
        this.setState({ isLoading: true })
        var chapter = ''
        if (id == 1) {
            for (var i = 0; i < chapterList.length; i++) {
                if (chapterList[i].ChapterID == chapterID) {
                    chapter = chapterList[i].ChapterName
                }
            }
        }
        this.props
            .getVisitorMeeting_action(companyName, Designation, city, chapter)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ isLoading: false })
                    var bookedVisitorMeetingList = data.data.bookedVisitorMeetingList.length > 0 ? data.data.bookedVisitorMeetingList : []
                    for (var i = 0; i < data.data.visitorMeetingList.length; i++) {
                        for (var j = 0; j < bookedVisitorMeetingList.length; j++) {
                            if (data.data.visitorMeetingList[i].VisitorMeetingBookingDataID == bookedVisitorMeetingList[j].VisitorMeetingBookingDataID) {
                                data.data.visitorMeetingList[i].isBooked = 1
                                data.data.visitorMeetingList[i].MeetingDate = bookedVisitorMeetingList[j].MeetingDate
                                data.data.visitorMeetingList[i].MeetingTime = bookedVisitorMeetingList[j].MeetingTime
                                data.data.visitorMeetingList[i].Remark = bookedVisitorMeetingList[j].Remark
                                break
                            }
                            else {
                                data.data.visitorMeetingList[i].isBooked = 0
                                data.data.visitorMeetingList[i].MeetingDate = null
                                data.data.visitorMeetingList[i].MeetingTime = null
                                data.data.visitorMeetingList[i].Remark = null
                            }
                        }
                    }
                    console.log(data.data.visitorMeetingList, data.data.bookedVisitorMeetingList)
                    this.setState({
                        visitorMeetingList: data.data.visitorMeetingList,
                        bookedVisitorList: data.data.bookedVisitorMeetingList.length > 0 ? data.data.bookedVisitorMeetingList : []
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    clearValue() {
        const { chapterID, city, companyName, Designation } = this.state
        this.setState({
            chapterID: '', city: '', companyName: '', Designation: ''
        })
        setTimeout(() => {
            this.getVisitorMeetingList(0)
        }, 200);
    }

    // addCarPassessApplication() {
    //     const { Remark, Is_Agree, isQuantity, Is_Required, carPassessId } = this.state
    //     this.inputValidate()
    //     this.setState({ isLoading: true })
    //     if (Is_Agree) {
    //         this.props
    //             .insertUpdateCarPassessApplication_action(isQuantity,
    //                 Is_Required == 1 ? true : false,
    //                 Remark, Is_Agree, carPassessId)
    //             .then((data) => {
    //                 if (data.error != 1) {
    //                     this.setState({ isLoading: false })
    //                     this.handleCancel()
    //                 }
    //                 else {
    //                     this.setState({ isLoading: false })
    //                     toastError(data.msg)
    //                 }
    //             })
    //             .catch((e) => {
    //                 console.log(e);
    //             });
    //     } else {
    //         this.inputValidate()
    //         this.setState({ isLoading: false })
    //     }
    // }

    // search city
    onCitySearch(e) {
        const { cityList, tempCityList } = this.state
        if (e.target.value != '') {
            var searchCityList = tempCityList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                city: e.target.value,
                tempCityList: searchCityList,
                isDisplayCityList: true
            })
        } else {
            this.setState({
                city: e.target.value,
                tempCityList: cityList,
                isDisplayCityList: false
            })
        }
    }

    // render city
    renderCityList() {
        const { tempCityList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempCityList.length > 0 && tempCityList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ city: data.Description, isDisplayCityList: false })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    render() {
        const { isLoading, city, cityID, cityList, isDisplayCityList, chapterID, visitorMeetingList, DeadlineDate, companyName, Designation, currentDate, chapterList } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content app-content mt-0 with-dr-container">
                    <div className="">
                        <div className="row">
                            <ExhibitorTopMenu />
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Visitor Meeting</h3>
                                    </div>
                                    <div className="row" style={{ padding: '12px' }}>
                                        <div className="form-row">
                                            <div className="col-xl-3 mb-1">
                                                <label htmlFor="validationCustom01">
                                                    Company Name
                                                </label>
                                                {
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        maxLength={100}
                                                        required=""
                                                        value={companyName}
                                                        onChange={e => this.setState({ companyName: e.target.value })}
                                                    />
                                                }
                                            </div>
                                            <div className="col-xl-3 mb-1">
                                                <label htmlFor="validationCustom01">
                                                    Designation
                                                </label>
                                                {
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        maxLength={100}
                                                        required=""
                                                        value={Designation}
                                                        onChange={e => this.setState({ Designation: e.target.value })}
                                                    />
                                                }
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    City
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    value={city}
                                                    onChange={(e) => this.onCitySearch(e)}
                                                />
                                                {isDisplayCityList && this.renderCityList()}
                                            </div>
                                            <div className="col-xl-3 mb-1">
                                                <label> Chapter Name</label>
                                                <select
                                                    className="form-control"
                                                    value={chapterID}
                                                    onChange={e => this.setState({ chapterID: e.target.value, })}
                                                >
                                                    <option value={''}>Select Chapter</option>
                                                    {
                                                        chapterList.length > 0 && chapterList.map((data) => (
                                                            <option key={data.ChapterID} value={data.ChapterID} >{data.ChapterName}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-12">
                                                <div
                                                    className="btn-group mb-1"
                                                    role="group"
                                                    aria-label="Basic example"
                                                    style={{ float: "left" }}
                                                >
                                                    <button type="button" className="btn ad-fil-btn"
                                                        onClick={e => this.getVisitorMeetingList(1)}
                                                    >
                                                        Search
                                                    </button>
                                                    <button type="button" className="btn ad-fil-btn"
                                                        onClick={e => this.clearValue()}
                                                    >
                                                        Clear
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body p-1 m-2">
                                        <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center"> Company Name</th>
                                                        <th class="text-center"> Designation</th>
                                                        <th class="text-center"> City</th>
                                                        <th class="text-center"> Chapter Name</th>
                                                        <th class="text-center"> Reg. Date</th>
                                                        <th class="text-center"> Book Meeting</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {visitorMeetingList.length > 0 && visitorMeetingList.map((data) => (
                                                        <tr key={data.VisitorMeetingBookingDataID}>
                                                            <td class="text-center">{data.CompanyName} </td>
                                                            <td class="text-center">{data.Designation}  </td>
                                                            <td class="text-center">{data.City}  </td>
                                                            <td class="text-center">{data.ChapterName}  </td>
                                                            <td class="text-center">{data.RegDays} Day </td>
                                                            <td class="text-center">

                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-primary btn-sm"
                                                                    onClick={(e) => this.handleBookMeeting(data.CompanyName, data.Designation, data.City, data.RegDays, data.VisitorMeetingBookingDataID, data.MeetingDate, data.MeetingTime, data.Remark)}
                                                                >
                                                                    {data.isBooked == 1 ? 'Edit' : '  Book Meeting'}
                                                                </button>

                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <button type="button"
                                            onClick={(e) => this.handleCancel(e)}
                                            className="btn btn-primary m-2 rounded-0"
                                        >
                                            cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>
                            <div class='d-flex m-3'>
                                {/* <button className="btn rounded-0 d-block mt-1 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button> */}

                            </div>

                        </div>
                    </div>
                </div >
            </>
        )
    }
}

export default withRouter(connect(null, { insertUpdateCarPassessApplication_action, fill_action, getVisitorMeeting_action, getCarPassessApplicationDetails_action })(VisitorMeetingApplication));