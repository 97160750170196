import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "../../../../../utils/constants";
import { insertUpdateCarPassessApplication_action, getCarPassessApplicationDetails_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/carPassessApplication_action/carPassessApplication_action'
import Modal from "react-modal";
import moment from "moment";
import $ from 'jquery'
// 
class CarPassesApplication extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            PowerConnectionList: [],
            Is_Agree: false,
            Remark: '',
            CGST: '',
            SGST: '',
            Is_AgreeErr: '',
            DeadlineDate: "",
            Is_Required: true,
            carPassessId: '',
            currentDate: moment().format('DD-MM-YYYY'),
            StatusID: ''
        }
        // this.getPowerConnectionList = this.getPowerConnectionList.bind(this)

    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                moment(this.props.location.state.Deadline).format('DD-MM-YYYY') : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ?
                this.props.location.state.StatusID : '',
        })
        this.loadscript()
        this.getCarPassessDetails()
    }

    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }

    handleCancel() {
        this.props.navigate('/manualForms')
    }

    inputValidate() {
        const { Is_Agree } = this.state

        if (Is_Agree == 0 || Is_Agree == false) {
            this.setState({ Is_AgreeErr: 'kindly agree to disclaimer mentioned in this form for Car park passes' })
        }
    }

    getCarPassessDetails() {
        const { Remark, Is_Agree, isQuantity, Is_Required } = this.state
        this.setState({ isLoading: true })
        this.props
            .getCarPassessApplicationDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    this.setState({
                        carPassessId: data.data[0]['CarPassesID'],
                        isQuantity: data.data[0]['CarPassesQuantity'],
                        Is_Required: data.data[0]['IsRequiredCarPasses'],
                        Remark: data.data[0]['Remark'],
                        Is_Agree: data.data[0]['IsAgree'],
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    addCarPassessApplication() {
        const { Remark, Is_Agree, isQuantity, Is_Required, carPassessId } = this.state
        this.inputValidate()
        this.setState({ isLoading: true })
        if (Is_Agree) {
            this.props
                .insertUpdateCarPassessApplication_action(isQuantity,
                    Is_Required == 1 ? true : false,
                    Remark, Is_Agree, carPassessId)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            toastError("Plase Enter mandatory feilds!")
            this.inputValidate()
            this.setState({ isLoading: false })
        }
    }

    render() {
        const { isLoading, Is_Agree, Remark, Is_AgreeErr, currentDate, DeadlineDate, Is_Required, StatusID } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content app-content mt-0 with-dr-container">
                    <div className="">
                        <div className="row">
                            <ExhibitorTopMenu />
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Car Passes Application</h3>
                                    </div>
                                    <div class="card-body p-1 m-2">
                                        <ul style={{ lineHeight: '25px', textAlign: 'left', padding: 0 }}>
                                            <li>1) Car Pass is valid only for show days XX TO XX.XX.XXX.
                                            </li>
                                            <li>2) For setup days parking will be available at Nasa Ground and not inside the venue.
                                            </li>
                                            <li>3) Kindly note the car passes are allotted as per booth space & limited parking available inside the venue.
                                            </li>
                                            <li>4) Parking is on first come first serve basis.</li>
                                            <li>5) Exhibitor to park their car in Nasa Parking in case parking in Venue is Fullon.</li>
                                            <li>6) India ITME Society or venue should not be responsible for any damage to vehicle or belongings in parked car at Exhibition Venue or Nasa ground Parking.</li>
                                        </ul>
                                        <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1 w-50">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}>
                                                            <strong>
                                                                <p>Dear {JSON.parse(sessionStorage.getItem('loginDataSS'))['CompanyName']}</p>

                                                                <p>As per your booth space 2 car Pass/passes are allotted to you.</p>

                                                                <p> Kindly confirm the requirement of your allotted car pass by selecting Required / Not Required option from below.</p>
                                                            </strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <label className='d-flex'><input type="radio"
                                                                name="Billing"
                                                                checked={Is_Required == 1}
                                                                onChange={(e) => this.setState({ Is_Required: 1 })}
                                                            /><strong>Required</strong> </label>
                                                        </td>
                                                        <td><label className='d-flex'><input type="radio"
                                                            checked={Is_Required == 0}
                                                            onChange={(e) => this.setState({ Is_Required: 0 })}
                                                            name="Billing" /><strong>Not Required</strong> </label></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-1">
                                    <form>
                                        <strong> <label>Remark</label></strong>
                                        <textarea class="form-control"
                                            value={Remark}
                                            onChange={(e) => this.setState({ Remark: e.target.value })}
                                            maxLength={300}
                                            rows="3">
                                        </textarea>
                                        <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                            <input type="checkbox"
                                                checked={Is_Agree}
                                                onChange={(e) => this.setState({ Is_Agree: e.target.checked, Is_AgreeErr: '' })}
                                            />
                                            <strong>I agree to disclaimer mentioned in this form for Car park passes<span className="required">*</span>.</strong></label>{<span className='text-danger text-start text-left d-block mt-1'>{Is_AgreeErr}</span>}
                                    </form>
                                </div>

                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>
                            <div class='d-flex m-3'>

                                <button
                                    type="button"
                                    className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                    style={{ marginRight: 10 }}
                                    disabled={currentDate > DeadlineDate }
                                    onClick={() => this.addCarPassessApplication()}
                                >
                                    Submit
                                </button>
                                <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { insertUpdateCarPassessApplication_action, getCarPassessApplicationDetails_action })(CarPassesApplication));