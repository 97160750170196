import { Component } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../../utils/Loader";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";
import { getOldPaymentList_action } from "../../../actions/exhibitor_module_actions/accounts_action/update_payment_action";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "../../../utils/constants"
import moment from "moment";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import AdminDrawer from '../../../common_components/admin_module_drawer';
import { getPaymentList_action } from '../../../actions/admin_module_action/admin_account_action/admin_account_action'
import { data } from "jquery";



class PaymentList extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        //States
        this.state = {
            isLoading: false,
            loginAccesslist : [],

            // Lists
            paymentListData: []

        }
        this.getPaymentList = this.getPaymentList.bind(this)
        this.onProceedClick = this.onProceedClick.bind(this)
        this.getWriteAccess = this.getWriteAccess.bind(this)
    }

    componentDidMount() {
        this.getPaymentList()
        this.setState({loginAccesslist : JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist']})
    }

    getWriteAccess(){
        const {loginAccesslist} = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '18');
        if (moduleAccess) {
        return moduleAccess.WriteAccess;
      }else{
        return false
      }
      return false;
    }

    // Get Payment List
    getPaymentList() {
        this.setState({ isLoading: true })
        this.props.getPaymentList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Payment Listing Data------->", data.data)
                    this.setState({
                        isLoading: false,
                        paymentListData: data.data
                    })

                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On Proceed Click
    onProceedClick(e, id) {
        e.preventDefault()
        this.props.navigate('/receiptentry', { state: { is_add: true, From: "PaymentList", AccountPaymentID: id } })
    }


    render() {
        const { isLoading, paymentListData } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <AdminDrawer />
                {/*app-content open*/}
                <div className="page">
                    <div className="page-main">
                        <div className="main-content  with-dr-container com-height">
                            <div className="row">
                                {/* <div className="step-name">
                                <p>Exhibitor Dashboard</p>
                            </div> */}
                                {/* <ExhibitorTopMenu /> */}
                            </div>
                            {/* <div className="card-header mt-0 p-3 mb-2" >
                                <h3 className="card-title" style={{ fontSize: 15 }}>
                                    View / Update Old Payments
                                </h3>
                            </div> */}
                            <div className="row">
                                <div className="step-name-space" style={{ zIndex: "3" }}>
                                    <p>Payment Listing</p>
                                </div>
                            </div>
                            <div className="container-fluid">

                                <div className="row">
                                    <div className="col-xl-12 pt-5">
                                        <div className="card rounded-0 pt-1">

                                            <div className="card-body p-0">
                                                {/* <button
                                                    className="btn btn-dark p-1 mb-2"
                                                    type="btn"
                                                    style={{ float: "right" }}
                                                >
                                                    New payment
                                                </button> */}
                                                <div className="table-responsive ">
                                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Payment Date</th>
                                                                <th>Company Name</th>
                                                                <th>Application No.</th>
                                                                <th>Gross Amount</th>
                                                                <th>TDS Amount </th>
                                                                <th>Net Amount</th>
                                                                <th style={{ width: 90 }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                paymentListData.length > 0 && paymentListData.map((data) => (
                                                                    <tr key={data.AccountPaymentID}>
                                                                        <td>{moment(data.PaymentDate).utc().format('DD-MM-YYYY')}</td>
                                                                        <td>{data.CompanyName}</td>
                                                                        <td>{data.ExhRegistrationNo}</td>
                                                                        <td>{data.GrossAmount}</td>
                                                                        <td>{data.TDSAmount}</td>
                                                                        <td>{data.NetAmount}</td>
                                                                        <td className="p-1 text-center">
                                                                            <button type="button" className="btn btn-primary p-1"
                                                                            disabled={ !this.getWriteAccess()}
                                                                                onClick={(e) => this.onProceedClick(e, data.AccountPaymentID)}
                                                                            >
                                                                                Proceed
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>

        )
    }
}

export default withRouter(connect(null, { getPaymentList_action })(PaymentList))
