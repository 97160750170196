import { Component } from "react";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { fill_action } from "../../../../../actions/fillCombo_action";
import { cityActionId, countryActionId, pincodeActionId, primaryChapterListActionId, stateActionId, toastError, toastSuccess } from "../../../../../utils/constants";
import { data } from "jquery";
import { emailValidator } from "../../../../../utils/Validator";
import { addEDirectory_action, getEDirectoryDetails_action, updateEDirectory_action } from "../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/e-directory_form_action/e-directory_form_action";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import moment from "moment";


class eDirectoryAdd extends Component {
    // Initialize Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isDisplayPincodeList: false,
            isDisplayCityList: false,
            isDisplayStateList: false,
            isDisplayCountryList: false,
            isAdd: false,
            isUpdate: false,
            isView: true,
            EDirectoryType: "",
            EDirectoryId: "",
            directoryStatus: "",

            // Fields
            companyName: "",
            add: "",
            altAdd: "",
            pin: "",
            city: "",
            regState: "",
            countryId: "",
            country: "",
            personInchName: "",
            Designation: "",
            preMobNo: "",
            mobNo: "",
            preTelNo: "",
            telNo: "",
            fax: "",
            primaryEmail: "",
            secondaryEmail: "",
            web: "",

            // List
            countryList: [],
            tempCountryList: [],
            stateList: [],
            tempStateList: [],
            cityList: [],
            tempCityList: [],
            pincodeList: [],
            tempPincodeList: [],
            level2: [],
            level3: [],
            level4: [],
            subChapters: [],



            // Err Messages
            companyNameErr: "",
            addErr: "",
            pinErr: "",
            countryErr: "",
            cityErr: "",
            regStateErr: "",
            personInchNameErr: "",
            DesignationErr: "",
            mobNoErr: "",
            primaryEmailErr: "",

            DeadlineDate: ''
        }
        this.onCancelClick = this.onCancelClick.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.onPincodeSearch = this.onPincodeSearch.bind(this)
        this.renderPincodeList = this.renderPincodeList.bind(this)
        this.onCitySearch = this.onCitySearch.bind(this)
        this.renderCityList = this.renderCityList.bind(this)
        this.onStateSearch = this.onStateSearch.bind(this)
        this.renderStateList = this.renderStateList.bind(this)
        this.callStateList = this.callStateList.bind(this)
        this.onCountrySearch = this.onCountrySearch.bind(this)
        this.renderCountryList = this.renderCountryList.bind(this)
        this.maxLengthCheck = this.maxLengthCheck.bind(this)
        this.preventMinus = this.preventMinus.bind(this)
        this.handlePaste = this.handlePaste.bind(this)
        this.onCheckLevel2 = this.onCheckLevel2.bind(this)
        this.record = this.record.bind(this)
        this.onCheckLevel3 = this.onCheckLevel3.bind(this)
        this.recordFour = this.recordFour.bind(this)
        this.onSelectLevel4 = this.onSelectLevel4.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        this.getEDirectoryDetails = this.getEDirectoryDetails.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onUpdate = this.onUpdate.bind(this)
    }

    // On Component Load
    componentDidMount() {
        // Fill List Calling ForDropDown
        this.getFillList(countryActionId, '', '', '', '', '');
        this.getFillList(stateActionId, '', '', '', '', '');
        this.getFillList(cityActionId, '', '', '', '', '');
        this.getFillList(pincodeActionId, '', '', '', '', '');
        this.getFillList(primaryChapterListActionId, '', '', '', '', '');


        this.setState({
            isAdd: this.props.location.state != null && this.props.location.state.is_add ? this.props.location.state.is_add : false,
            isUpdate: this.props.location.state != null && this.props.location.state.is_Update ? this.props.location.state.is_Update : false,
            isView: this.props.location.state != null && this.props.location.state.is_View ? this.props.location.state.is_View : false,
            EDirectoryId: this.props.location.state != null && this.props.location.state.eDirectoryID ? this.props.location.state.eDirectoryID : "",
            EDirectoryType: this.props.location.state != null && this.props.location.state.addType ? this.props.location.state.addType : "",
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                moment(this.props.location.state.Deadline).format('DD-MM-YYYY') : '',
        })
        // Calling Get E-Directory Details on Update & View
        this.props.location.state != null && (this.props.location.state.is_Update || this.props.location.state.is_View) && this.getEDirectoryDetails()

    }

    // On Cancel Click
    onCancelClick() {
        // this.props.navigate('/edirectoryList')
        this.props.navigate(-1)
    }

    // Get Fill Lists
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { isAdd } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === countryActionId) {
                        console.log("country data", data.data)
                        this.setState({
                            countryList: data.data,
                            tempCountryList: data.data,
                            isLoading: false
                        })

                    }
                    // state list
                    if (actionID === stateActionId) {
                        console.log("stateList data", data.data)
                        this.setState({
                            stateList: data.data,
                            tempStateList: data.data,
                            isLoading: false
                        })

                    }
                    // city list
                    if (actionID === cityActionId) {
                        console.log("city data", data.data)
                        this.setState({
                            cityList: data.data,
                            tempCityList: data.data,
                            isLoading: false
                        })

                    }
                    // pincode
                    if (actionID === pincodeActionId) {
                        console.log("pincode data", data.data)
                        this.setState({
                            pincodeList: data.data,
                            tempPincodeList: data.data,
                            isLoading: false
                        })

                    }
                    if (actionID === primaryChapterListActionId) {
                        async function seqnoFormat(arr) {
                            for (var i = 0; i < arr.length; i++) {
                                if (arr[i].seqno != '' && arr[i].seqno != null) {
                                    arr[i].ChapterName.trim()
                                    // console.log(arr[i].ChapterName)
                                    if (/^\d$/.test(arr[i].ChapterName[0])) {
                                        for (var j = 0; j < arr[i].ChapterName.length; j++) {
                                            if (arr[i].ChapterName[j] == ' ') {
                                                var str = arr[i].ChapterName.slice(j)
                                                var finalName = arr[i].seqno + str
                                                arr[i].ChapterName = finalName
                                                // console.log("primaryChapterListActionId 121212", i, finalName)
                                                break
                                            }
                                        }
                                    } else {
                                        var str = arr[i].ChapterName
                                        var finalName = arr[i].seqno + ' ' + str
                                        arr[i].ChapterName = finalName
                                    }
                                }
                            }
                            return
                        }
                        for (var i = 0; i < data.data['primaryChapter'].length; i++) {
                            for (var j = 0; j < data.data['primaryChapter'].length - 1 - i; j++) {
                                var seq1 = data.data['primaryChapter'][j].seqno
                                var seq2 = data.data['primaryChapter'][j + 1].seqno
                                if ((parseFloat(seq1) > parseFloat(seq2))) {
                                    var temp = data.data['primaryChapter'][j]
                                    data.data['primaryChapter'][j] = data.data['primaryChapter'][j + 1]
                                    data.data['primaryChapter'][j + 1] = temp;
                                    // console.log(i, j, arr[j])
                                }
                            }
                            if (data.data['primaryChapter'][i].seqno == null || data.data['primaryChapter'][i].seqno == undefined) {
                                console.log(data.data['primaryChapter'][i].seqno, i)
                            }
                            // seqnoFormat(data.data['primaryChapter'])
                        }
                        for (var i = 0; i < data.data['level2'].length; i++) {
                            for (var j = 0; j < data.data['level2'].length - 1 - i; j++) {
                                var seq1 = data.data['level2'][j].seqno
                                var seq2 = data.data['level2'][j + 1].seqno
                                if ((parseFloat(seq1) > parseFloat(seq2))) {
                                    var temp = data.data['level2'][j]
                                    data.data['level2'][j] = data.data['level2'][j + 1]
                                    data.data['level2'][j + 1] = temp;
                                    // console.log(i, j, arr[j])
                                }
                            }
                            if (data.data['level2'][i].seqno == null || data.data['level2'][i].seqno == undefined) {
                                console.log(data.data['level2'][i].seqno, i)
                            }
                            seqnoFormat(data.data['level2'])
                        }
                        console.log("prr", data.data['level2'])
                        for (var i = 0; i < data.data['level3'].length; i++) {
                            for (var j = 0; j < data.data['level3'].length - 1 - i; j++) {

                                var seq1 = data.data['level3'][j].seqno
                                seq1 = seq1 != null && seq1.replaceAll('.', '')
                                var seq2 = data.data['level3'][j + 1].seqno
                                seq2 = seq2 != null && seq2.replaceAll('.', '')

                                if ((parseFloat(seq1) > parseFloat(seq2))) {
                                    var temp = data.data['level3'][j]
                                    data.data['level3'][j] = data.data['level3'][j + 1]
                                    data.data['level3'][j + 1] = temp;
                                }
                            }
                            if (data.data['level3'][i].seqno == null || data.data['level3'][i].seqno == undefined) {
                                console.log(data.data['level3'][i].seqno, i)
                            }
                            seqnoFormat(data.data['level3'])
                        }
                        for (var i = 0; i < data.data['level4'].length; i++) {
                            for (var j = 0; j < data.data['level4'].length - 1 - i; j++) {
                                var seq1 = data.data['level4'][j].seqno
                                seq1 = seq1 != null && seq1.replaceAll('.', '')
                                var seq2 = data.data['level4'][j + 1].seqno
                                seq2 = seq2 != null && seq2.replaceAll('.', '')
                                if ((parseFloat(seq1) > parseFloat(seq2))) {
                                    var temp = data.data['level4'][j]
                                    data.data['level4'][j] = data.data['level4'][j + 1]
                                    data.data['level4'][j + 1] = temp;
                                    // console.log(i, j, arr[j])
                                }
                            }
                            if (data.data['level4'][i].seqno == null || data.data['level4'][i].seqno == undefined) {
                                console.log(data.data['level4'][i].seqno, i)
                            }
                            seqnoFormat(data.data['level4'])
                        }

                        for (var i = 0; i < data.data['level2'].length; i++) {
                            data.data['level2'][i].isHighlight = false;
                        }
                        this.setState({
                            primaryChapterListData: data.data['primaryChapter'],
                            level0: data.data['level0'],
                            level2: data.data['level2'],
                            level3: data.data['level3'],
                            level4: data.data['level4'],
                            isLoading: false,
                        })
                        setTimeout(() => {
                            this.filterLevelData()
                        }, 300);
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    filterLevelData() {
        const { level2, level3, level4 } = this.state
        var newLevel = [...level2]
        for (var i = 0; i < level3.length; i++) {
            var tempLevel4 = [];
            for (var j = 0; j < level4.length; j++) {
                if (level3[i]['ChapterID'] == level4[j]['ParentChapterID']) {
                    tempLevel4.push(level4[j]);
                }
            }
            level3[i]['level_four_List'] = tempLevel4;
            // console.log(" level3[i]['level_four_List']", tempLevel4);
        }

        for (var i = 0; i < newLevel.length; i++) {
            var tempLevel3 = [];
            for (var j = 0; j < level3.length; j++) {
                if (newLevel[i]['ChapterID'] == level3[j]['ParentChapterID']) {
                    tempLevel3.push(level3[j]);
                }
            }
            newLevel[i]['level_third_List'] = tempLevel3;
            // console.log("level2[i]['level_third_List']", tempLevel3);

        }

        ///////////============
        console.log("level2", newLevel)
        this.setState({ level2: newLevel })
    }


    //Input Field Validations
    inputValidate() {
        const { companyName, add, pin, city, regState, country, personInchName, Designation, mobNo, primaryEmail, countryId } = this.state

        if (companyName == "") {
            this.setState({ companyNameErr: "Please Enter Company Name" });
        }
        if (add == "") {
            this.setState({ addErr: "Please Enter Address" });
        }
        if (pin == "") {
            this.setState({ pinErr: "Please Enter Pincode" });
        }
        if (city == "") {
            this.setState({ cityErr: "Please Enter City" });
        }
        if (regState == "" && country.toLowerCase().trim() == "india") {
            this.setState({ regStateErr: "Please Enter State" });
        }
        if (countryId == "") {
            this.setState({ countryErr: "Please Enter Country" });
        }
        if (personInchName == "") {
            this.setState({ personInchNameErr: "Please Enter Person Incharge" });
        }
        if (Designation == "") {
            this.setState({ DesignationErr: "Please Enter Designation" });
        }
        if (mobNo == "") {
            this.setState({ mobNoErr: "Please Enter Mobile No" });
        }
        if (primaryEmail == "") {
            this.setState({ emailErr: "Please enter Email" });
        }
        if (primaryEmail != "") {
            var emailError = emailValidator(primaryEmail)
            if (emailError != '') {
                this.setState({ primaryEmailErr: emailError })
                return
            }
        }
    }

    // Get E Directory Details
    getEDirectoryDetails() {
        this.setState({ isLoading: true })
        var id = this.props.location.state.eDirectoryID
        this.props.getEDirectoryDetails_action(id)
            .then((data) => {
                if (data.error != 1) {
                    console.log("getEDirectoryDetails Data", data.data)
                    // Split Phone Numbers and country Code 
                    // Registration Mobile No
                    var mob1 = data.data['registrationDetails'][0]['MobileNo']
                    const parts = mob1.split("-");
                    const regMob1Code = parts[0]
                    const regMob1No = parts[1]
                    // Registration TelePhone No
                    var tel = data.data['registrationDetails'][0]['Telephone']
                    const parts2 = tel.split("-")
                    const regtelCode = parts2[0]
                    const regtelNo = parts2[1]

                    setTimeout(() => {
                        // Setting chapters
                        const { level2, level3, level4 } = this.state
                        console.log("111->>>>>>>>>>>>>>>>>>>>>", level2)
                        console.log("222->>>>>>>>>>>>>>>>>>>>>", level3)
                        console.log("333->>>>>>>>>>>>>>>>>>>>>", level4)
                        var selectedChap = data.data.chapterList != null ? data.data.chapterList : []
                        level2.length > 0 && level2.map((data) => {
                            selectedChap.length > 0 && selectedChap.map((item) => {
                                if (data.ChapterID == item.ChapterID) {
                                    data.IsSelect = true
                                }
                            })
                        })

                        level3.length > 0 && level3.map((data) => {
                            selectedChap.length > 0 && selectedChap.map((item) => {
                                if (data.ChapterID == item.ChapterID) {
                                    data.IsSelect = true
                                }
                            })
                        })

                        level4.length > 0 && level4.map((data) => {
                            selectedChap.length > 0 && selectedChap.map((item) => {
                                if (data.ChapterID == item.ChapterID) {
                                    data.IsSelect = true
                                }
                            })
                        })

                        // Setting SubChapters
                        var mergeArray = [...level2, ...level3, ...level4]
                        console.log("mergeArray------------>", mergeArray)
                        console.log("selectedChap------------>", selectedChap)

                        let simillarData = []
                        simillarData = mergeArray.filter(item =>
                            selectedChap.some(innerItem => innerItem.ChapterID === item.ChapterID)
                        );
                        console.log("similar id", simillarData);
                        this.setState({
                            companyName: data.data.registrationDetails[0]['CompanyName'] != null ? data.data.registrationDetails[0]['CompanyName'] : '',
                            add: data.data.registrationDetails[0]['Address'] != null ? data.data.registrationDetails[0]['Address'] : '',
                            altAdd: data.data.registrationDetails[0]['Address1'] != null ? data.data.registrationDetails[0]['Address1'] : '',
                            pin: data.data.registrationDetails[0]['Pincode'] != null ? data.data.registrationDetails[0]['Pincode'] : '',
                            city: data.data.registrationDetails[0]['City'] != null ? data.data.registrationDetails[0]['City'] : '',
                            regState: data.data.registrationDetails[0]['State'] != null ? data.data.registrationDetails[0]['State'] : '',
                            country: data.data.registrationDetails[0]['Country'] != null ? data.data.registrationDetails[0]['Country'] : '',
                            countryId: data.data.registrationDetails[0]['CountryID'] != null ? data.data.registrationDetails[0]['CountryID'] : '',
                            personInchName: data.data.registrationDetails[0]['PersonIncharge'] != null ? data.data.registrationDetails[0]['PersonIncharge'] : '',
                            Designation: data.data.registrationDetails[0]['Designation'] != null ? data.data.registrationDetails[0]['Designation'] : '',
                            preMobNo: regMob1Code,
                            mobNo: regMob1No,
                            preTelNo: regtelCode,
                            telNo: regtelNo,
                            fax: data.data.registrationDetails[0]['Fax'] != null ? data.data.registrationDetails[0]['Fax'] : '',
                            primaryEmail: data.data.registrationDetails[0]['PrimaryEmail'] != null ? data.data.registrationDetails[0]['PrimaryEmail'] : '',
                            secondaryEmail: data.data.registrationDetails[0]['SecondaryEmail'] != null ? data.data.registrationDetails[0]['SecondaryEmail'] : '',
                            web: data.data.registrationDetails[0]['Website'] != null ? data.data.registrationDetails[0]['Website'] : '',
                            subChapters: simillarData,
                            EDirectoryId: data.data.registrationDetails[0]['DirectoryListingID'] != null ? data.data.registrationDetails[0]['DirectoryListingID'] : '',
                            directoryStatus: data.data.registrationDetails[0]['Status'] != null ? data.data.registrationDetails[0]['Status'] : '',
                            isLoading: false
                        })

                    }, 1500);
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On Submit
    onSubmit() {
        const { companyName, add, pin, city, country, personInchName, Designation, mobNo, primaryEmail, companyNameErr, addErr, pinErr,
            cityErr, regStateErr, countryErr, personInchNameErr, DesignationErr, mobNoErr, primaryEmailErr, subChapters, EDirectoryId,
            altAdd, regState, countryId, preMobNo, preTelNo, telNo, fax, secondaryEmail, web, directoryStatus, DeadlineDate
        } = this.state
        this.inputValidate()

        if (companyName != "" && add != "" && pin != "" && city != "" && country != "" && personInchName != "" && Designation != "" &&
            mobNo != "" && primaryEmail != ""
        ) {
            if (companyNameErr == "" && addErr == "" && pinErr == "" && cityErr == "" && regStateErr == "" && countryErr == "" &&
                personInchNameErr == "" && DesignationErr == "" && mobNoErr == "" && primaryEmailErr == ""
            ) {
                if (subChapters.length > 0) {
                    // checked chapters List data Preparing to store
                    var list = []
                    subChapters.map(data => {
                        var mapData = {
                            'ChapterID': parseInt(data.ChapterID)
                        }
                        list.push(mapData)
                    });

                    /// Setting data in session storage For Insert Next
                    sessionStorage.setItem("sessionregisterTelephoneNoPre", preTelNo)
                    sessionStorage.setItem("sessionregisterMobNoPre", preMobNo)
                    this.setState({ isLoading: true })
                    this.props.addEDirectory_action(EDirectoryId, companyName, add, altAdd, pin, city, regState,
                        countryId, personInchName, Designation, mobNo, telNo, fax, primaryEmail, secondaryEmail,
                        web, directoryStatus, list)
                        .then((data) => {
                            if (data.error != 1) {
                                this.setState({ isLoading: false })
                                toastSuccess("E-Directory Added Succefully")
                                setTimeout(() => {
                                    this.setState({ isLoading: false })
                                    this.props.navigate('/edirectoryList', { state: { DeadlineDate: DeadlineDate } })
                                }, 1000);
                            } else {
                                toastError(data.msg)
                                this.setState({ isLoading: false })
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } else {
                    toastError("Please Select Atleast One Chapter")
                }
            } else {
                toastError("Please Enter Mandatory Fields")
            }
        } else {
            toastError("Please Enter Mandatory Fields")
        }
    }

    // On Update 
    onUpdate() {
        const { companyName, add, pin, city, country, personInchName, Designation, mobNo, primaryEmail, companyNameErr, addErr, pinErr,
            cityErr, regStateErr, countryErr, personInchNameErr, DesignationErr, mobNoErr, primaryEmailErr, subChapters, DeadlineDate
        } = this.state
        this.inputValidate()

        if (companyName != "" && add != "" && pin != "" && city != "" && country != "" && personInchName != "" && Designation != "" &&
            mobNo != "" && primaryEmail != ""
        ) {
            if (companyNameErr == "" && addErr == "" && pinErr == "" && cityErr == "" && regStateErr == "" && countryErr == "" &&
                personInchNameErr == "" && DesignationErr == "" && mobNoErr == "" && primaryEmailErr == ""
            ) {
                if (subChapters.length > 0) {
                    this.setState({ isLoading: true })
                    this.props.updateEDirectory_action()
                        .then((data) => {
                            if (data.error != 1) {
                                this.setState({ isLoading: false })
                                toastSuccess("E-Directory updated Succefully")
                                setTimeout(() => {
                                    this.setState({ isLoading: false })
                                    this.props.navigate('/edirectoryList', { state: { DeadlineDate: DeadlineDate } })
                                }, 1000);
                            } else {
                                toastError(data.msg)
                                this.setState({ isLoading: false })
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } else {
                    toastError("Please Select Atleast One Chapter")
                }
            } else {
                toastError("Please Enter Mandatory Fields")
            }
        } else {
            toastError("Please Enter Mandatory Fields")
        }
    }

    //Pincode Search
    onPincodeSearch(e) {
        const { pincodeList, tempPincodeList } = this.state
        // console.log("onPincodeSearch 123", tempPincodeList)
        if (e.target.value != '') {
            var searchPincodeList = tempPincodeList.filter(data => {
                return (
                    data
                        .Pincode
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                pin: e.target.value,
                pinErr: '',
                tempPincodeList: searchPincodeList,
                isDisplayPincodeList: true
            })
        } else {
            this.setState({
                pin: e.target.value,
                tempPincodeList: pincodeList,
                isDisplayPincodeList: false
            })
        }
    }

    // render pincode
    renderPincodeList() {
        const { tempPincodeList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempPincodeList.length > 0 && tempPincodeList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                pin: data.Pincode,
                                isDisplayPincodeList: false
                            })}
                        >{data.Pincode}</li>
                    ))
                }
            </ul>
        )
    }

    // search city
    onCitySearch(e) {
        const { cityList, tempCityList } = this.state
        if (e.target.value != '') {
            var searchCityList = tempCityList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                city: e.target.value,
                cityErr: "",
                tempCityList: searchCityList,
                isDisplayCityList: true
            })
        } else {
            this.setState({
                city: e.target.value,
                tempCityList: cityList,
                isDisplayCityList: false
            })
        }
    }

    // render city
    renderCityList() {
        const { tempCityList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempCityList.length > 0 && tempCityList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ city: data.Description, isDisplayCityList: false })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // search state
    onStateSearch(e) {
        const { tempStateList, stateList } = this.state
        if (e.target.value != '') {
            var searchStateList = tempStateList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                regState: e.target.value,
                regStateErr: '',
                tempStateList: searchStateList,
                isDisplayStateList: true
            })
        } else {
            this.setState({
                regState: e.target.value,
                tempStateList: stateList,
                isDisplayStateList: false
            })
        }
    }

    // render state
    renderStateList() {
        const { tempStateList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempStateList.length > 0 && tempStateList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.callStateList(data.Description)}
                        >{data.Description}</li>

                    ))
                }
            </ul>
        )
    }

    // state list
    callStateList(value) {
        const { regState } = this.state
        this.setState({ regState: value, isDisplayStateList: false })
        this.getFillList(stateActionId, '', '', '', regState, '', '')
    }

    // Search Country
    onCountrySearch(e) {
        const { countryList, tempCountryList } = this.state
        this.setState({ regCountry: '' })
        if (e.target.value != '') {
            var searchCoutryList = tempCountryList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                country: e.target.value,
                countryErr: '',
                tempCountryList: searchCoutryList,
                isDisplayCountryList: true
            })
        }
        else {

            this.setState({
                country: e.target.value,
                tempCountryList: countryList,
                isDisplayCountryList: false
            })
        }
    }

    // render Country
    renderCountryList() {
        const { tempCountryList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempCountryList.length > 0 && tempCountryList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                country: data.Description, countryId: data.Code,
                                preMobNo: data.CountryCode, preTelNo: data.CountryCode, isDisplayCountryList: false
                            })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    preventMinus = (e) => {
        (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    }

    handlePaste = (event) => {
        const pastedValue = event.clipboardData.getData('text/plain');
        const sanitizedValue = pastedValue.replace(/[-.eE]/g, ''); // Remove '-', '.', 'e', 'E' characters

        // Replace the input value with the sanitized value
        document.execCommand('insertText', false, sanitizedValue);

        event.preventDefault(); // Prevent the default paste behavior
    }

    // on check level 2
    onCheckLevel2(e, id) {
        const { level2, subChapters } = this.state
        var tempExhibitDetailsList = []
        for (let i = 0; i < level2.length; i++) {
            if (level2[i]['ChapterID'] == id) {

                if (e.target.checked) {
                    if (!subChapters.includes(level2[i])) {
                        level2[i].isLengthValidate = false;
                        subChapters.push(level2[i])
                        this.setState({ subChapters: subChapters })
                    }
                }
                else {
                    this.setState({ subChapters: subChapters.filter(item => item.ChapterID != level2[i]['ChapterID']) })
                }

                level2[i]['IsSelect'] = !level2[i]['IsSelect']
            }
        }
        setTimeout(() => {
            this.setState({ level2: level2 })
        }, 200);
    }

    // on check level 3
    onCheckLevel3(e, id) {
        const { level3, subChapters } = this.state
        var tempExhibitDetailsList = []
        for (let i = 0; i < level3.length; i++) {
            if (level3[i]['ChapterID'] == id) {

                if (e.target.checked) {
                    if (!subChapters.includes(level3[i])) {
                        level3[i].isLengthValidate = false;
                        subChapters.push(level3[i])
                        this.setState({ subChapters: subChapters })
                    }
                }
                else {
                    this.setState({ subChapters: subChapters.filter(item => item.ChapterID != level3[i]['ChapterID']) })
                }

                level3[i]['IsSelect'] = !level3[i]['IsSelect']
            }
        }
        setTimeout(() => {
            this.setState({ level3: level3 })
        }, 200);
    }

    // on check level 4
    onSelectLevel4(e, id) {
        const { level4, subChapters } = this.state
        var tempExhibitDetailsList = []
        for (let i = 0; i < level4.length; i++) {
            if (level4[i]['ChapterID'] == id) {

                if (e.target.checked) {
                    if (!subChapters.includes(level4[i])) {
                        level4[i].isLengthValidate = false;
                        subChapters.push(level4[i])
                        this.setState({ subChapters: subChapters })
                    }
                }
                else {
                    this.setState({ subChapters: subChapters.filter(item => item.ChapterID != level4[i]['ChapterID']) })
                }

                level4[i]['IsSelect'] = !level4[i]['IsSelect']
            }
        }
        setTimeout(() => {
            console.log("xxxxxxxxxxxxxxxxxxxxxxxxx", subChapters)
            this.setState({ level4: level4 })
        }, 200);

    }

    // to call level third list 
    record(level2, level3, level4) {
        // var newdata = JSON.stringify(data);
        var tempLevel3 = [];
        for (var j = 0; j < level3.length; j++) {
            if (level2.ChapterID == level3[j]['ParentChapterID']) {
                tempLevel3.push(level3[j]);
            }
        }
        return (
            <>
                <div id={'collapse_l1_' + level2.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l1_' + level2.ChapterID} data-bs-parent="#accordionExample">
                    {
                        tempLevel3 && tempLevel3.map(level3data => {
                            return (
                                <div class="accordion-body">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id={'heading_l2_' + level3data.ChapterID}>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#collapse_l2_' + level3data.ChapterID} aria-expanded="false" aria-controls={'collapse_l2_' + level3data.ChapterID}>
                                                {/* // checkbox for level3 */}
                                                {
                                                    level3data.IsCheckbox && <input type="checkbox" className="from-left-sp"
                                                        name="" key={level3data.ChapterID} checked={level3data.IsSelect}
                                                        onClick={e => this.onCheckLevel3(e, level3data.ChapterID)} />
                                                }

                                                {level3data.ChapterName} </button>
                                        </h2>
                                        {this.recordFour(level3data, level4)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </>
        );
    }

    // to call level four list
    recordFour(level3data, level4) {
        var tempLevel4 = [];
        for (var j = 0; j < level4.length; j++) {
            if (level3data.ChapterID == level4[j]['ParentChapterID']) {
                tempLevel4.push(level4[j]);
            }
        }
        return (<>
            <div id={'collapse_l2_' + level3data.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l2_' + level3data.ChapterID}>
                {
                    tempLevel4 && tempLevel4.map(level4data => {
                        return (
                            <div class="accordion-body third-step">
                                {/* level4data.IsCheckbox && */}
                                <input type="checkbox" name="" key={level4data.ChapterID} checked={level4data.IsSelect}
                                    onClick={(e) => this.onSelectLevel4(e, level4data.ChapterID)} />
                                {level4data.ChapterName}
                            </div>
                        )
                    })
                }
            </div>
        </>);
    }

    render() {
        const { isLoading, companyName, companyNameErr, add, altAdd, pin, city, regState, country, personInchName, Designation, preMobNo,
            mobNo, preTelNo, telNo, fax, primaryEmail, secondaryEmail, web, addErr, pinErr, regStateErr, personInchNameErr, DesignationErr,
            mobNoErr, primaryEmailErr, isDisplayPincodeList, countryErr, cityErr, isDisplayCityList, isDisplayStateList, isDisplayCountryList,
            level2, level3, level4, subChapters, isAdd, isView, isUpdate } = this.state

        const toInputUppercase = e => {
            e.target.value = ("" + e.target.value).toUpperCase();
        };

        return (
            <>
                <>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        null
                    )}
                    <ToastContainer />
                    {
                        JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                            <ExhibitorHeader /> : <HeaderWithLogo />
                    }
                    {
                        JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                            <ExhibitorDrawer /> : <AdminDrawer />
                    }
                    <div className="with-dr-container">
                        <div className="main-content  mt-0">
                            <div className="">
                                <div className=" width-dr-nav">
                                    <div className="row">
                                        {
                                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                                <ExhibitorTopMenu /> : null
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12 p-0">
                                        <div className=" pt-1">
                                            <div className="card-header p-4 pt-3 pb-3">
                                                <h3 className="card-title" style={{ fontSize: 15 }}>
                                                    E-Directory Form
                                                </h3>
                                            </div>
                                            <div className="card-body p-4">
                                                <div className="row mt-1">
                                                    <div className="col-md-12">
                                                        <h5 style={{ fontWeight: 500 }}>
                                                            Step 1: Correspondence details
                                                        </h5>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Name of the Company <span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            disabled={isView}
                                                            value={companyName}
                                                            onChange={(e) => this.setState({ companyName: e.target.value, companyNameErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{companyNameErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Address<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            disabled={isView}
                                                            value={add}
                                                            onChange={(e) => this.setState({ add: e.target.value, addErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{companyNameErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">Alternate Address</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            disabled={isView}
                                                            value={altAdd}
                                                            onChange={(e) => this.setState({ altAdd: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            {country.toLowerCase().trim() == "india" ? 'Pincode' : 'Zipcode'}
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        {
                                                            country.toLowerCase().trim() == "india" &&
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                disabled={isView}
                                                                value={pin}
                                                                maxLength={6}
                                                                onChange={(e) => this.onPincodeSearch(e)}
                                                            />
                                                        }
                                                        {
                                                            country.toLowerCase().trim() != "india" &&
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                value={pin}
                                                                disabled={isView}
                                                                maxLength={10}
                                                                onChange={(e) => this.onPincodeSearch(e)}
                                                                onInput={toInputUppercase}
                                                            />
                                                        }
                                                        {isDisplayPincodeList && this.renderPincodeList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{pinErr}</span>}
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            City<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            disabled={isView}
                                                            value={city}
                                                            onChange={(e) => this.onCitySearch(e)}
                                                        />
                                                        {isDisplayCityList && this.renderCityList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{cityErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            {country.toLowerCase().trim() == "india" ? 'state' : 'State/Province'}
                                                            {country.toLowerCase().trim() == "india" ? <span className="text-red">*</span> : ''}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            disabled={isView}
                                                            value={regState}
                                                            onChange={(e) => this.onStateSearch(e)}
                                                        />
                                                        {isDisplayStateList && this.renderStateList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{regStateErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Country<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            disabled={isView}
                                                            value={country}
                                                            onChange={(e) => this.onCountrySearch(e)}
                                                        />
                                                        {isDisplayCountryList && this.renderCountryList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{countryErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Person Incharge Name
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={personInchName}
                                                            disabled={isView}
                                                            maxLength={'100'}
                                                            onChange={(e) => this.setState({ personInchName: e.target.value, personInchNameErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{personInchNameErr}</span>}
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Designation<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            disabled={isView}
                                                            value={Designation}
                                                            onChange={(e) => this.setState({ Designation: e.target.value, DesignationErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{DesignationErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Mobile<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            disabled={isView}
                                                            style={{ paddingLeft: 50 }}
                                                            value={mobNo}
                                                            maxLength={country.toLowerCase().trim() == "China" ? 11 : 10}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            onPaste={(e) => this.handlePaste(e)}
                                                            onChange={(e) => e.target.value >= 0 &&
                                                                this.setState({ mobNo: e.target.value, mobNoErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{mobNoErr}</span>}
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            placeholder={+91}
                                                            value={preMobNo}
                                                            disabled={isView}
                                                            onChange={(e) => this.setState({ preMobNo: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 21,
                                                                padding: "6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 35
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">Telephone No</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            defaultValue=""
                                                            style={{ paddingLeft: 50 }}
                                                            disabled={isView}
                                                            value={telNo} maxLength={'10'}
                                                            onPaste={(e) => this.handlePaste(e)}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)} size={'10'}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            onChange={(e) => e.target.value >= 0 && this.setState({ telNo: e.target.value })}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            value={preTelNo}
                                                            disabled={isView}
                                                            onChange={(e) => this.setState({ preTelNo: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 21,
                                                                padding: "6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 35
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">Fax</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            style={{ paddingLeft: 50 }}
                                                            maxLength={50}
                                                            disabled={isView}
                                                            value={fax}
                                                            onChange={e => this.setState({ fax: e.target.value })}
                                                        />
                                                        {/* <input
                                                            type="text"
                                                            className="form-control-1"
                                                            defaultValue=""
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 21,
                                                                padding: "6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 35
                                                            }}
                                                        /> */}
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Primary Email<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            disabled={isView}
                                                            value={primaryEmail}
                                                            onChange={(e) => this.setState({ primaryEmail: e.target.value, primaryEmailErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{primaryEmailErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">Secondary Email</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            disabled={isView}
                                                            maxLength={'100'}
                                                            value={secondaryEmail}
                                                            onChange={(e) => this.setState({ secondaryEmail: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">Website</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            disabled={isView}
                                                            id="validationCustom03"
                                                            maxLength={100}
                                                            value={web}
                                                            onChange={(e) => this.setState({ web: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </div>
                        <div className="main-content app-content mt-0">
                            <div className="p-1 title-bg m-1 mb-0 mr-0">
                                <p className="mb-0" style={{ fontSize: 15 }}>
                                    Select Product given below (for Catalog Purpose)
                                </p>
                            </div>
                            <div className="one-section">
                                <div className=" mb-2">
                                    <div className="row">
                                        <div className="asc">
                                            <div className="" style={{ paddingLeft: 10 }}>
                                                <form className="needs-validation pb-2" noValidate="">
                                                    <div className="form-row">
                                                        <div class="accordion" id="accordionExample">
                                                            {
                                                                level2.length > 0 && level2.map(level2Data => (
                                                                    <>
                                                                        {
                                                                            <div class="accordion-item">
                                                                                <h2 class="accordion-header" id={'heading_l1_' + level2Data.ChapterID}>
                                                                                    <button class={"accordion-button collapsed"}
                                                                                        type="button" data-bs-toggle="collapse" disabled={isView}
                                                                                        data-bs-target={'#collapse_l1_' + level2Data.ChapterID} aria-expanded="false"
                                                                                        aria-controls={'collapse_l1_' + level2Data.ChapterID}>
                                                                                        {
                                                                                            level2Data.IsCheckbox &&
                                                                                            <input type="checkbox" className="from-left-sp" name="" key={level2Data.ChapterID}
                                                                                                checked={level2Data.IsSelect} disabled={isView}
                                                                                                onClick={e => this.onCheckLevel2(e, level2Data.ChapterID)}
                                                                                            />
                                                                                        }
                                                                                        {level2Data.ChapterName} </button>
                                                                                </h2>
                                                                                {this.record(level2Data, level3, level4)}
                                                                            </div>
                                                                        }

                                                                    </>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-1 title-bg m-1 mb-0 mr-0">
                                <p className="mb-0" style={{ fontSize: 15 }}>
                                    Selected Product
                                </p>
                            </div>
                            <div style={{ padding: "0px 0px 0px 10px" }}>
                                {subChapters.length > 0 && subChapters.map((data) => (
                                    <p>
                                        {data.ChapterName}
                                    </p>
                                ))
                                }
                            </div>
                            <div className="main-container">
                                <div className="btn-group w-auto mt-1 mb-4">
                                    <a
                                        className="btn btn-previous"
                                        style={{ marginRight: "10px" }}
                                        type="submit"
                                        onClick={(e) => this.onCancelClick()}
                                    >
                                        Back
                                    </a>
                                    {isAdd &&
                                        <a
                                            className="btn btn-success rounded-0"
                                            style={{ padding: "4px 25px" }}
                                            onClick={(e) => this.onSubmit(e)}
                                        >
                                            Submit
                                        </a>
                                    }
                                    {isUpdate &&
                                        <a
                                            className="btn btn-success rounded-0"
                                            style={{ padding: "4px 25px" }}
                                            onClick={(e) => this.onSubmit(e)}
                                        >
                                            Update
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>




            </>
        )
    }
}
export default withRouter(connect(null, { fill_action, addEDirectory_action, getEDirectoryDetails_action, updateEDirectory_action })(eDirectoryAdd))