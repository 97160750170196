import { Component, forwardRef } from "react";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import AdminDrawer from "../../../common_components/admin_module_drawer";
import Pagination from "../../../utils/pagination";
import {
    exhibitorList_action, getExhiitorDetails_action, generatePriorityNo_action,
    submitManualPriorityNo_action, sendUserCred_action, submitAutoPriorityNo_action,
    generateHallNoStallNo_action, generateOpenSide_action, getExhRegList_action, removedEdirectoryManual_action,
    addEdirectoryManual_action, addFasciaManual_action, activateDeactivateExhibitors_action, SSIDocumentApproval_action, updateStallTypeArea_action, submitOpenSidePI_action, sendhallstallUserCred_action, submitDiscount_action
} from "../../../actions/admin_module_action/exhibitor_list_action/exhibitor_list_action";
import { agentListActionId, boothTypeActionId, countryActionId, downloadReport, exhibitorTypeActionId, fetchAuthReport, hallListActionId, hallNoActionId, hallNoList, loginUrl, manualFormDoc, paymentTypeActionId, primaryChapterActionId, priorityNoActionId, stallNoListActionId, statusTypeActionId, toastError, toastSuccess, segmentActionId, exhibitionActionId, hallMasterListActionId, stallSizeActionId, stallTypeActionId } from "../../../utils/constants";
import Modal from "react-modal";
import { data } from "jquery";
import { descriptionPreview_action } from "../../../actions/description_action"
import { fill_action, fill_master_action } from "../../../actions/fillCombo_action"
import Workbook from 'react-excel-workbook'
import { axiosPost } from "../../../react_axios/Axios";
import moment from "moment";


const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="form-control"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));

class ExhibitorList extends Component {
    // intialize constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            checkedAll: false,
            showForRegistration: false,
            currentPage: 1,
            startIndex: 0,
            isOpen: false,
            isDetailOpen: false,
            isFromDashboard: false,
            exhibitor: "",
            exhibition: "",
            exhibitorList: [],
            estimateDetails: [],
            generatePriorityNoType: 1,
            priorityNoList: [],
            paymentTypeList: [],
            priorityNoId: "",
            paymentTypeId: "",
            priorityNoErr: "",
            exhregId: "",
            exhibitorOutstanding: "",
            exhRegList: [],
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false,
            isOpen6: false,
            isOpen7: false,
            hallNo: "",
            stallNo: "",
            openSide: '',
            statusCount: [],
            userName: "",
            statusID: "",
            statusTypeList: [],
            exhibitorTypeId: '',
            exhibitorTypeList: [],
            boothTypeList: [],
            hallNoList: [],
            boothTypeId: "",
            hallNumber: "",
            agentList: [],
            agentId: "",
            chapterList: [],
            chapter: "",
            countryList: [],
            loginAccesslist: [],
            hallList: [],
            stallList: [],
            countryId: "",
            exhREgNo: "",
            stallNumber: "",
            fromArea: "",
            toArea: "",
            tabName: "",
            keyword: "",
            exhibitorArea: "",
            hallSegmentList: [],
            hallMasterList: [],
            // Err Messages
            hallNoErr: "",
            stallNoErr: "",
            openSideErr: '',

            exhibitionList: [],
            exhibitionID: '',
            SSIStatus: '',
            RejectReason: '',
            SSIexhID: '',
            discountPercent: '',
            discountPercentErr: '',
            discountExhibitonID: '',
            openSidePIExhibitionID: '',

            schemeTypeList: [],
            schemeType: '',
            stallAreaList: [],
            stallAreaName: '',
            stallAreaId: '',
            stallArea: '',
            areaCountryID: '',
            areaExhibitionID: '',
            modalOpenSide: '',
            modalArea: '',
            Flag: false,
            modalData: ''
        }
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.getExhibitorList = this.getExhibitorList.bind(this)
        this.getExhibitorDetails = this.getExhibitorDetails.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.toggleDetailModal = this.toggleDetailModal.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.updateItem = this.updateItem.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.onDetailsClick = this.onDetailsClick.bind(this)
        this.resendUserCred = this.resendUserCred.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.onGenerateClick = this.onGenerateClick.bind(this)
        this.onFinalGeneratePriorityNo = this.onFinalGeneratePriorityNo.bind(this)
        this.generateManualPriorityNo = this.generateManualPriorityNo.bind(this)
        this.generateAutoPriorityNo = this.generateAutoPriorityNo.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.onLogin = this.onLogin.bind(this)
        this.getExhRegList = this.getExhRegList.bind(this)
        this.onCreateExcel = this.onCreateExcel.bind(this)
        this.onGenerateHallNoStallNO = this.onGenerateHallNoStallNO.bind(this)
        this.toggleModal2 = this.toggleModal2.bind(this)
        this.toggleModal3 = this.toggleModal3.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        this.genrateHallStall = this.genrateHallStall.bind(this)
        this.ChangeOpenSide = this.ChangeOpenSide.bind(this)
        this.onHallStallClick = this.onHallStallClick.bind(this)
        this.selectAllExhibitors = this.selectAllExhibitors.bind(this)
        this.handleCheckedExhibitors = this.handleCheckedExhibitors.bind(this)
        this.onAddEdirectory = this.onAddEdirectory.bind(this)
        this.onAddFascia = this.onAddFascia.bind(this)
        this.getSpecialAccess = this.getSpecialAccess.bind(this)
        this.getDownloadAccess = this.getDownloadAccess.bind(this)
        this.getWirteAccess = this.getWirteAccess.bind(this)
        this.getUpdateAccess = this.getUpdateAccess.bind(this)
        this.activateDeactivateUsers = this.activateDeactivateUsers.bind(this)
        this.onHallChange = this.onHallChange.bind(this)
        this.onAreaClick = this.onAreaClick.bind(this)
        this.toggleModal4 = this.toggleModal4.bind(this)
        this.getMasterFillList = this.getMasterFillList.bind(this)
        this.onDiscountClick = this.onDiscountClick.bind(this)
        this.onDiscountSubmit = this.onDiscountSubmit.bind(this)
        this.onDiscountChange = this.onDiscountChange.bind(this)
        this.onGenerateOpenSidePI = this.onGenerateOpenSidePI.bind(this)
        this.onPISubmit = this.onPISubmit.bind(this)
        this.onSchemeTypeChange = this.onSchemeTypeChange.bind(this)
        this.onLengthTypeChange = this.onLengthTypeChange.bind(this)
        this.onUpdateStallTypeArea = this.onUpdateStallTypeArea.bind(this)
    }

    componentDidMount() {
        this.setState({
            tabName: this.props.location.state != null && this.props.location.state.tab ? this.props.location.state.tab : '',
            Flag: this.props.location.state != null && this.props.location.state.Flag ? this.props.location.state.Flag : false,
            isFromDashboard: this.props.location.state != null && this.props.location.state.fromDashBoard ? this.props.location.state.fromDashBoard : false,
            keyword: this.props.location.state != null && this.props.location.state.keyword ? this.props.location.state.keyword : '',
            paymentTypeId: this.props.location.state != null && this.props.location.state.paymentType ? this.props.location.state.paymentType : '',
            exhibitorTypeId: this.props.location.state != null && this.props.location.state.exhibitorType ? this.props.location.state.exhibitorType : '',
            loginAccesslist: JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist']
        }, () => {
            this.getExhibitorList(null)
        }
        )

        this.getExhRegList()
        sessionStorage.removeItem('ExhibitorAccessData')
        this.getFillList(paymentTypeActionId, '', '', '', '', '');
        this.getFillList(statusTypeActionId, '', '', '', '', '');
        this.getFillList(exhibitorTypeActionId, '', '', '', '', '');
        this.getFillList(boothTypeActionId, '', '', '', '', '');
        // this.getFillList(hallNoActionId, '', '', '', '', '');
        this.getFillList(agentListActionId, '', '', '', '', '');
        this.getFillList(primaryChapterActionId, '', '', '', '', '');
        this.getFillList(countryActionId, '', '', '', '', '');
        this.getFillList(hallMasterListActionId, '', '', '', '', '');
        this.getMasterFillList(hallMasterListActionId, '', '', '', '', '');
        this.getFillList(exhibitionActionId, '', '', '', '', '');
        this.getFillList(stallTypeActionId, sessionStorage.getItem("sessionExhibitionID"), '', '', '', '')
    }

    getMasterFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        // if (actionID == countryActionId) {
        this.props.fill_master_action(actionID, null, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // Country List
                    if (actionID === hallMasterListActionId) {
                        this.setState({
                            hallMasterList: data.data,
                            // TempCountryList: data.data,
                            isLoading: false,
                            // allocateCountryList: alloCountryList
                        })
                        // console.log("country data", alloCountryList)
                    }
                }
            })
        // }
    }


    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID) {
        const { stallAreaId } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID)
            .then((data) => {
                if (data.error != 1) {
                    // voucher list
                    if (actionID === priorityNoActionId) {
                        console.log("Priority No data", data.data)
                        this.setState({
                            priorityNoList: data.data,
                            // isLoading: false,
                        })
                    }
                    if (actionID === stallTypeActionId) {
                        // console.log("country data", data.data)
                        this.setState({
                            schemeTypeList: data.data,
                        })
                        if (data.data.length == 1) {
                            this.onSchemeTypeChange(null, data.data[0].Code)
                        }
                    }
                    if (actionID === stallSizeActionId) {
                        console.log("country data", data.data, stallAreaId)
                        this.setState({
                            stallAreaList: data.data,
                        })
                        if (stallAreaId != '') {
                            for (var i = 0; i < data.data.length; i++) {
                                if (data.data[i].Code == stallAreaId) {
                                    console.log('Description : ', data.data[i].Description);
                                    this.setState({ stallAreaName: data.data[i].Description })
                                }
                            }
                        }
                    }
                    // Payment Type list
                    if (actionID === paymentTypeActionId) {
                        console.log("Payment Type list data", data.data)
                        var list = data.data.filter(element =>
                            element.ID != 4
                        )
                        this.setState({
                            paymentTypeList: list.length > 0 ? list : [],
                            // isLoading: false
                        })
                    }
                    // Status Type list
                    if (actionID === statusTypeActionId) {
                        console.log("Status Type list data", data.data)
                        this.setState({
                            statusTypeList: data.data,
                            // isLoading: false
                        })
                    }
                    // Exhibitor Type list
                    if (actionID === exhibitorTypeActionId) {
                        console.log("Exhibitor Type list data", data.data)
                        this.setState({
                            exhibitorTypeList: data.data,
                            // isLoading: false
                        })
                    }
                    // Booth Type list
                    if (actionID === boothTypeActionId) {
                        console.log("Booth Type list data", data.data)
                        this.setState({
                            boothTypeList: data.data,
                            // isLoading: false
                        })
                    }
                    // hall List
                    if (actionID === hallNoActionId) {
                        console.log("hallNoList list data", data.data)
                        this.setState({
                            hallNoList: data.data,
                            // isLoading: false
                        })
                    }
                    // Agent List
                    if (actionID === agentListActionId) {
                        console.log("Agent list data", data.data)
                        this.setState({
                            agentList: data.data,
                            // isLoading: false
                        })
                    }
                    // Primary Chapter List
                    if (actionID === primaryChapterActionId) {
                        console.log("Primary Chapter list data", data.data)
                        this.setState({
                            chapterList: data.data,
                            // isLoading: false
                        })
                    }
                    // country List
                    if (actionID === countryActionId) {
                        console.log("country List list data", data.data)
                        this.setState({
                            countryList: data.data,
                            // isLoading: false
                        })
                    }
                    //exhibition
                    if (actionID === exhibitionActionId) {
                        console.log("Stall Posession Status list data", data.data)
                        this.setState({
                            exhibitionList: data.data.length > 0 ? data.data : [],
                            isLoading: false
                        })
                    }
                    // Hall List
                    if (actionID === hallMasterListActionId) {
                        console.log("Hall List list data", data.data)
                        this.setState({
                            hallList: data.data,
                            // isLoading: false
                        })
                    }
                    // Stall List
                    if (actionID === stallNoListActionId) {
                        console.log("stall List list data", data.data)
                        this.setState({
                            stallList: data.data,
                            isLoading: false
                        })
                    }
                    // segment
                    if (actionID === segmentActionId) {
                        console.log("segment List list data", data.data)
                        this.setState({
                            hallSegmentList: data.data,
                            isLoading: false
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    onSchemeTypeChange(e, id) {
        console.log('id :', id);
        if (e) {
            e.preventDefault();
        }
        const { areaCountryID } = this.state
        this.setState({ schemeType: id, stallAreaId: '', stallAreaName: '', stallArea: '' })
        this.getFillList(stallSizeActionId, sessionStorage.getItem("sessionExhibitionID"), '', id, areaCountryID, '')
        this.setState({ isLoading: false })
    }

    // For Exhibitor List
    getExhibitorList(Status) {
        const { exhibitor, exhibition, paymentTypeId, userName, exhibitorTypeId, boothTypeId, hallNumber, agentId, chapter, countryId, exhREgNo,
            stallNumber, fromArea, toArea, Flag, tabName, exhibitionID, modalData, keyword, isOpen2 } = this.state
        this.setState({ isLoading: true })
        console.log(exhibitionID)

        this.props.exhibitorList_action(exhibitionID, exhibitor, paymentTypeId, Status, userName, exhibitorTypeId, boothTypeId, hallNumber,
            agentId, chapter, countryId, exhREgNo, stallNumber, fromArea, toArea, tabName, keyword)
            .then((data) => {
                if (data.error != 1) {
                    var exhList = data.data != null && data.data['tableData']
                    exhList.length > 0 && exhList.map((item) => {
                        item.isSelect = false
                    })
                    console.log("xxxxxxxxxxxxxx", exhList)
                    if (Flag) {
                        exhList = exhList.filter((item) =>
                            item.isGeneratePIopenSide == true
                        )
                    }
                    this.setState({
                        exhibitorList: exhList != null && exhList,
                        statusCount: data.data != null && data.data['statusCount'],
                        isLoading: false
                    })

                    if (isOpen2) {
                        exhList.length > 0 && exhList.map((item) => {
                            item.ExhRegistrationID == modalData.ExhRegistrationID && this.setState({ modalArea: item.Area, modalOpenSide: item.OpenSides })
                        })
                    }
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // For ExhRegistration List
    getExhRegList() {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props.getExhRegList_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        exhRegList: data.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    OnSSIDocumentApprove() {
        const { SSIStatus, SSIexhID, RejectReason } = this.state
        this.setState({ isLoading: true })
        this.props.SSIDocumentApproval_action(SSIexhID, SSIStatus, (RejectReason != '' && RejectReason != null) ? RejectReason : null)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        SSIexhID: '',
                        SSIStatus: '',
                        RejectReason: '',
                        isLoading: false,
                        isOpen5: false
                    })
                    this.getExhibitorList(null)
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // to create excel file
    onCreateExcel() {
        const { exhRegList } = this.state

        var list = [...exhRegList]
        var columnArray = [],
            rowData = [];
        // for column data 
        list.map((data, index) => {
            if (index == 0) {
                Object.keys(data).map((key, index) => {
                    columnArray.push(key)
                })
            }
        })


        // for row data
        list.map((data, index) => {
            rowData.push(data)
        })

        // console.log("rowData", rowData)
        // this.setState({
        //     columnArrayData: columnArray,
        //     rowData: rowData
        // })

        // { this.renderList() }

        return (
            <div className="row text-center">
                {exhRegList.length > 0 ? <Workbook filename="ExhibitorList.xlsx" style={{}} element={
                    <button type="button" className="btn ad-fil-btn"
                        disabled={!this.getDownloadAccess()}
                    >
                        Export All
                    </button>
                }>
                    <Workbook.Sheet data={rowData} name="Sheet A">
                        {columnArray.map((value, index) =>
                            <Workbook.Column label={value} value={value} />
                        )
                        }
                    </Workbook.Sheet>
                </Workbook> : <button type="button" className="btn ad-fil-btn" disabled={true}>
                    Export All
                </button>}
            </div>
        );
    }

    // Exhibtor Details
    getExhibitorDetails() {
        this.setState({ isLoading: true })
        this.props.getExhiitorDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    handleKeyDown(e) {
        const { statusID } = this.state
        if (e.code == 'Enter') {
            this.getExhibitorList(statusID)
        }
    }

    // Update Current Page
    updateItem(item) {
        const { exhibitorList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = exhibitorList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(exhibitorList.length / recordsPerPage)
        this.setState({ startIndex: recordsPerPage * (item - 1) });
    }

    // On Search Click
    onSearchClick() {
        const { exhibitor, exhibitionID, paymentTypeId, userName, statusID, exhibitorTypeId, boothTypeId, hallNumber, agentId, chapter,
            countryId, exhREgNo, stallNumber, fromArea, toArea } = this.state
        if (exhibitor != "" || exhibitionID != "" || paymentTypeId != "" || userName != "" || statusID != "" || exhibitorTypeId != "" ||
            boothTypeId != "" || hallNumber != "" || agentId != "" || chapter != "" || countryId != "" || exhREgNo != "" || stallNumber != "" ||
            fromArea != "" || toArea != "") {
            console.log(exhibitionID)
            this.getExhibitorList(statusID)
            if (exhibitionID != '' && exhibitionID != null) {
                this.getFillList(hallMasterListActionId, exhibitionID, '', '', '', '');
            }
        } else {
            toastError("Atleast Fill One field to Search")
        }
    }

    // Modal Toggle
    toggleModal() {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
    }

    // Modal Toggle 2
    toggleModal2() {
        const { isOpen2 } = this.state
        this.setState({ isOpen2: !isOpen2 })
    }

    // Modal Toggle 3
    toggleModal3() {
        const { isOpen3 } = this.state
        this.setState({ isOpen3: !isOpen3 })
    }

    // Modal Toggle 4
    toggleModal4() {
        const { isOpen4 } = this.state
        this.setState({ isOpen4: !isOpen4 })
    }
    toggleModal5(exhId, Status) {
        const { isOpen5 } = this.state
        if (isOpen5 == true) {
            this.setState({ isOpen5: false, RejectReason: '', SSIStatus: '', SSIexhID: '' })
        }
        else {
            this.setState({ isOpen5: true, SSIStatus: Status, SSIexhID: exhId })
        }
    }

    // Exhibitors Details Modal toggle
    toggleDetailModal() {
        const { isDetailOpen } = this.state
        this.setState({ isDetailOpen: !isDetailOpen })
    }

    clearValue() {
        this.setState({
            exhibitor: "",
            exhibition: "",
            paymentTypeId: "",
            userName: "",
            statusID: "",
            exhibitorTypeId: "",
            boothTypeId: "",
            hallNumber: "",
            agentId: "",
            chapter: "",
            countryId: "",
            exhREgNo: "",
            stallNumber: "",
            fromArea: "",
            toArea: "",
            exhibitionID: ''
        })
        setTimeout(() => {
            this.getExhibitorList(null)
        }, 500);
    }

    // On Details Click
    onDetailsClick(e, exhregid) {
        this.setState({ isLoading: true })
        this.props.descriptionPreview_action(exhregid)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    console.log("ExhRegistration  Pdf Download", data)
                    var url = `${downloadReport}?SessionIdentifier=${data}&Export=true`;
                    const win = window.location.replace(url, '_blank');
                    if (win != null) {
                        win.focus();
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // onSSIClick(e, exhregid) {
    //     this.setState({ isLoading: true })
    //     this.props.descriptionPreview_action(exhregid)
    //         .then((data) => {
    //             if (data.error != 1) {
    //                 this.setState({ isLoading: false })
    //                 console.log("ExhRegistration  Pdf Download", data)
    //                 var url = `${downloadReport}?SessionIdentifier=${data}&Export=true`;
    //                 const win = window.location.replace(url, '_blank');
    //                 if (win != null) {
    //                     win.focus();
    //                 }
    //             }
    //             else {
    //                 this.setState({ isLoading: false })
    //                 toastError(data.msg)
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    // On Generate Click 
    onGenerateClick(id, outstanding) {
        this.setState({ exhregId: id, exhibitorOutstanding: outstanding })
        this.getFillList(priorityNoActionId, sessionStorage.getItem('sessionExhibitionID'), '', '', '', '');
        this.toggleModal()
    }

    // On Generate Hall No Stall No 
    onGenerateHallNoStallNO(data) {
        this.setState({ exhregId: data.ExhRegistrationID, modalArea: data.Area, modalOpenSide: data.OpenSides, modalData: data })
        this.toggleModal2()
    }

    // On Generate Priority No
    onFinalGeneratePriorityNo() {
        const { generatePriorityNoType } = this.state
        if (generatePriorityNoType == 1) {
            this.generateAutoPriorityNo()
        } else {
            this.generateManualPriorityNo()
        }
    }

    // generate Manual priority No
    generateManualPriorityNo() {
        const { priorityNoId } = this.state
        if (priorityNoId == "" || priorityNoId == "Select Priority Number") {
            this.setState({ priorityNoErr: 'Please Select Priority No' })
        }
        setTimeout(() => {
            const { priorityNoErr, exhregId, isOpen, priorityNoId } = this.state

            if (priorityNoId != "") {
                if (priorityNoErr == "") {
                    this.setState({ isLoading: true })
                    this.props.submitManualPriorityNo_action(priorityNoId, exhregId)
                        .then((data) => {
                            if (data.error != 1) {
                                console.log("Manual Priority No ", data.data[0]['PriorityNo'])
                                this.setState({
                                    isLoading: false,
                                    exhregId: "",
                                    exhibitorOutstanding: "",
                                    isOpen: !isOpen,
                                })
                                this.getExhibitorList(null)
                                toastSuccess(`Generated Priority No:- ${data.data[0]['PriorityNo']}`)
                            } else {
                                this.setState({ isLoading: false })
                                toastError(data.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
            }
        }, 500);
    }

    // On Auto Generate Priority No
    generateAutoPriorityNo() {
        const { exhregId, isOpen } = this.state
        this.setState({ isLoading: true })
        this.props.submitAutoPriorityNo_action(exhregId)
            .then((data) => {
                if (data.error != 1) {
                    console.log("Auto Priority No ", data.data)
                    this.setState({
                        exhregId: "",
                        exhibitorOutstanding: "",
                        isOpen: !isOpen,
                        isLoading: false
                    })
                    toastSuccess(`Generated Priority No:- ${data.data[0]['PriorityNo']}`)
                    this.getExhibitorList(null)
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    // On Resend User ID & Password to exhibitors
    resendUserCred(e, exhregId) {
        this.setState({ isLoading: true })
        this.props.sendUserCred_action(exhregId, 0)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    toastSuccess("UserID & Password Sent")
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On Resend User ID & Password to exhibitors
    sendhallstallUserCred(e, exhregId, hallStall) {
        hallStall = hallStall.split('/')
        this.props.sendhallstallUserCred_action(exhregId, hallStall[0], hallStall[1])
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    toastSuccess("UserID & Password Sent")
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On Edit Click
    handleUpdate(data) {
        sessionStorage.setItem('ExhibitorAccessData', JSON.stringify(data))
        this.props.navigate('/exhibitorspacebooking')
    }

    // On Login Click
    onLogin(data) {
        var exhId = data.ExhibitionID
        var id = data.UserName
        // var pass = data.Password
        // var password = pass.replace('/', '§');
        // console.log("passContent", password)
        var loginContent = `${exhId}--${id}`
        // --${password}`
        var url = `${loginUrl}${loginContent}`
        console.log("url---------->", url)
        window.open(url, "_blank");
    }

    // Input Validation
    inputValidate() {
        const { hallNo, stallNo } = this.state
        if (hallNo == "") {
            this.setState({ hallNoErr: "Please Enter Hall No" })
        }
        if (stallNo == "") {
            this.setState({ stallNoErr: "Please Enter Stall No" })
        }
    }

    // Input Validation
    inputValidate2() {
        const { openSide } = this.state
        if (openSide == "") {
            this.setState({ openSideErr: "Please Enter Open Side." })
        }
    }

    // On Generate 
    genrateHallStall() {
        this.inputValidate()
        const { isOpen2, hallNo, stallNo, hallNoErr, stallNoErr, exhregId } = this.state
        if (hallNo != "" && stallNo != "") {
            if (hallNoErr == "" && stallNoErr == "") {
                this.setState({ isLoading: true })
                this.props.generateHallNoStallNo_action(exhregId, hallNo, stallNo)
                    .then((data) => {
                        console.log("genrateHallStall Data", data.data)
                        if (data.error != 1) {
                            this.setState({
                                isLoading: false,
                                exhregId: "",
                                isOpen2: !isOpen2,
                            })
                            toastSuccess(`Hall and Stall added successfully.`)
                            this.getExhibitorList(null)

                        } else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })

            } else {
                toastError("Please Enter Mandatory Fields")
            }
        } else {
            toastError("Please Enter Mandatory Fields")
        }
    }

    // On Hall No Stall No 
    onHallStallClick(data, id,) {
        this.getFillList(stallNoListActionId, '', data.HallId, '', '', '');
        this.setState({ hallNo: data.HallId, stallNo: data.StallId, exhregId: id, modalArea: data.Area, modalOpenSide: data.OpenSides, modalData: data })
        console.log(data.Area, data.OpenSides)
        this.toggleModal2()
    }

    // On open side click....... 
    onOpenSideClick(number, id) {
        this.setState({ openSide: number, exhregId: id })
        this.toggleModal3()
    }

    // On Area Click
    onAreaClick(ExhibitionStallSizeID, StallDimension, StallTypeID, area, id, CountryID, areaExhibitionID) {
        this.setState({ stallAreaId: ExhibitionStallSizeID, exhregId: id, areaExhibitionID: areaExhibitionID, areaCountryID: CountryID, stallAreaName: StallDimension, schemeType: StallTypeID, stallArea: area })
        this.getFillList(stallSizeActionId, sessionStorage.getItem("sessionExhibitionID"), '', StallTypeID, CountryID, '')
        this.setState({ isLoading: false })
        this.toggleModal4()
    }

    onLengthTypeChange(e) {
        this.setState({ stallAreaId: e.target.value, })
        console.log(e.target.value)
        const { stallAreaList, stallAreaName } = this.state
        for (var i = 0; i < stallAreaList.length; i++) {
            if (stallAreaList[i].Code == e.target.value) {

                console.log('Description : ', stallAreaList[i].Description);
                this.setState({ stallAreaName: stallAreaList[i].Description, stallArea: stallAreaList[i].StallArea })

            }
        }
    }

    // On Generate 
    onUpdateStallTypeArea() {
        const { isOpen3, isOpen4, openSide, areaExhibitionID, openSideErr, exhregId, stallArea, stallAreaId, stallAreaName, schemeType } = this.state
        if (exhregId != '' && stallAreaId != '' && stallArea != '' && schemeType != '' && stallAreaName != '' && areaExhibitionID != '') {
            this.setState({ isLoading: true })
            this.props.updateStallTypeArea_action(areaExhibitionID, exhregId, stallAreaId, stallArea, schemeType, stallAreaName)
                .then((data) => {
                    console.log("genrate open side Data", data.data)
                    if (data.error != 1) {
                        this.setState({
                            isLoading: false,
                            exhregId: "", stallAreaId: '', stallArea: '', schemeType: '', stallAreaName: '',
                            isOpen4: !isOpen4,
                        })
                        toastSuccess(`Area Updated successfully.`)
                        this.getExhibitorList(null)

                    } else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })

        } else {
            toastError("Please Enter Mandatory Fields")
        }
    }

    // On Generate 
    ChangeOpenSide() {
        this.inputValidate2()
        const { isOpen3, openSide, openSideErr, exhregId } = this.state
        if (openSide != "") {
            if (openSideErr == "") {
                this.setState({ isLoading: true })
                this.props.generateOpenSide_action(exhregId, openSide)
                    .then((data) => {
                        console.log("genrate open side Data", data.data)
                        if (data.error != 1) {
                            this.setState({
                                isLoading: false,
                                exhregId: "",
                                isOpen3: !isOpen3,
                            })
                            toastSuccess(`Open Side Updated successfully.`)
                            this.getExhibitorList(null)

                        } else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })

            } else {
                toastError("Please Enter Mandatory Fields")
            }
        } else {
            toastError("Please Enter Mandatory Fields")
        }
    }

    //on Select All Click
    selectAllExhibitors(e) {
        const { checkedAll, exhibitorList } = this.state
        this.setState({ checkedAll: !checkedAll })
        const checked = e.target.checked
        exhibitorList.length > 0 && exhibitorList.map((data) => {
            if (checked) {
                if (data.StatusID != '1' && data.StatusID != '2') {
                    data.isSelect = true
                }
            } else {
                data.isSelect = false
            }
        })
        console.log("yyyyyyyyyyyyy", exhibitorList)
    }

    // On Checked exhibitors
    handleCheckedExhibitors(e, id) {
        const { exhibitorList } = this.state
        const checked = e.target.checked

        exhibitorList.length > 0 && exhibitorList.map((data) => {
            if (data.ExhRegistrationID == id) {
                if (checked) {
                    data.isSelect = true
                } else {
                    data.isSelect = false
                    this.setState({ checkedAll: false })
                }
            }
        })
        console.log("aaaaaaaaaaa", exhibitorList)
        this.setState({})
    }

    // On Download Manual Form Report
    onDownloadManualFormReport = async () => {
        const { exhibitorList } = this.state
        try {
            var selectedExhibitors = exhibitorList.filter((item) => item.isSelect)
            if (selectedExhibitors.length > 0) {
                this.setState({ isLoading: true });
                var exhIdList = []

                selectedExhibitors.length > 0 && selectedExhibitors.map((data, index) => {
                    exhIdList.push(data.ExhRegistrationID)
                })
                var list = [];
                const mapdata1 = {
                    "ParameterName": "@UserID",
                    "ParameterValue": JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
                    "ParameterDataType": "int"
                };
                list.push(mapdata1);
                const mapdata2 = {
                    "ParameterName": "@ExhibitionID",
                    "ParameterValue": parseInt(sessionStorage.getItem('sessionExhibitionID')),
                    "ParameterDataType": "int"
                };
                list.push(mapdata2);
                const mapdata3 = {
                    "ParameterName": "@ExhRegistration",
                    "ParameterValue": exhIdList.toString(),
                    "ParameterDataType": "varchar"
                };
                list.push(mapdata3);
                const mapData = {
                    "report_name": manualFormDoc,
                    "parameter_details": list
                };
                console.log("xxxxxxxxxxxxxxxxxxxxx", mapData)

                const res = await axiosPost(fetchAuthReport, mapData);
                this.setState({ isLoading: false });

                if (res != null) {
                    var url = `${downloadReport}?SessionIdentifier=${res}&Export=Yes`;
                    const win = window.location.replace(url, '_blank');
                    if (win != null) {
                        win.focus();
                    }
                }
            } else {
                toastError("Please select Atleast One Exhibitor")
            }
        } catch (err) {
            console.error(err);
        }
    }

    // On Add Edirectory
    onAddEdirectory() {
        const { exhibitorList } = this.state
        var selectedExhibitors = exhibitorList.length > 0 && exhibitorList.filter((item) => item.isSelect)
        if (selectedExhibitors.length > 0) {
            var list = []
            selectedExhibitors.length > 0 && selectedExhibitors.map((element) => {
                var mapdata = {
                    "ExhregistrationID": element.ExhRegistrationID
                }
                list.push(mapdata)
            })
            this.setState({ isLoading: true })
            this.props.addEdirectoryManual_action(list)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        toastSuccess("E-Directory Added Successfully")
                        this.getExhibitorList(null)
                    } else {
                        this.setState({ isLoading: false })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError("Please Select Atleast one exhibitor")
        }
    }

    // On Add Edirectory
    removedEdirectoryManual() {
        const { exhibitorList } = this.state
        var selectedExhibitors = exhibitorList.length > 0 && exhibitorList.filter((item) => item.isSelect)
        if (selectedExhibitors.length > 0) {
            var list = []
            selectedExhibitors.length > 0 && selectedExhibitors.map((element) => {
                var mapdata = {
                    "ExhregistrationID": element.ExhRegistrationID
                }
                list.push(mapdata)
            })
            this.setState({ isLoading: true })
            this.props.removedEdirectoryManual_action(list)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        toastSuccess("E-Directory Added Successfully")
                        this.getExhibitorList(null)
                    } else {
                        this.setState({ isLoading: false })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError("Please Select Atleast one exhibitor")
        }
    }

    // On Add Fascia
    onAddFascia() {
        const { exhibitorList } = this.state
        var selectedExhibitors = exhibitorList.length > 0 && exhibitorList.filter((item) => item.isSelect)
        if (selectedExhibitors.length > 0) {
            var list = []
            selectedExhibitors.length > 0 && selectedExhibitors.map((element) => {
                var mapdata = {
                    "ExhregistrationID": element.ExhRegistrationID
                }
                list.push(mapdata)
            })
            this.setState({ isLoading: true })
            this.props.addFasciaManual_action(list)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        toastSuccess("Fascia Added Successfully")
                        this.getExhibitorList()
                    } else {
                        this.setState({ isLoading: false })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError("Please Select Atleast one exhibitor")
        }
    }

    getWirteAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '9');
        if (moduleAccess) {
            return moduleAccess.WriteAccess;
        } else {
            return false
        }
        return false;
    }

    getUpdateAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '9');
        if (moduleAccess) {
            return moduleAccess.UpdateAccess;
        } else {
            return false
        }
        return false;
    }

    getDownloadAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '9');
        if (moduleAccess) {
            return moduleAccess.DownloadAccess;
        } else {
            return false
        }
        return false;
    }

    getSpecialAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '9');
        if (moduleAccess) {
            return moduleAccess.Is_SpecialAccess;
        } else {
            return false
        }
        return false;
    }

    activateDeactivateUsers(isActivated) {
        const { exhibitorList } = this.state

        var selectedExhibitors = exhibitorList.length > 0 && exhibitorList.filter((item) => item.isSelect)
        if (selectedExhibitors.length > 0) {
            var list = [];
            selectedExhibitors.length > 0 && selectedExhibitors.map((element) => {
                var mapdata = {
                    "ExhregistrationID": element.ExhRegistrationID,
                    "StatusID": element.StatusID
                }
                list.push(mapdata)
            })
            this.setState({ isLoading: true })
            this.props.activateDeactivateExhibitors_action(isActivated, list)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        toastSuccess("Exhibitor Record Updated")
                        this.getExhibitorList()
                    } else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError("Please Select Atleast one exhibitor")
        }
    }

    onHallChange(e, flag) {
        var value = e.target.value
        this.setState({ hallNo: value, hallNoErr: "" })
        this.getFillList(stallNoListActionId, '', value, '', '', '');
        if (flag == 1) {
            this.getFillList(segmentActionId, '', value, '', '', '');
        }
    }

    onDiscountClick(data, exhId) {
        const { isOpen6, discountExhibitonID } = this.state
        if (isOpen6) {
            this.setState({ discountPercent: '', discountExhibitonID: '', discountPercentErr: '', exhregId: '', isOpen6: false })
        } else if (!isOpen6) {
            if (data.discount != null && data.discount != '') {
                this.setState({ discountPercent: data.discount, })
            }
            this.setState({ isOpen6: true, exhregId: exhId, discountPercentErr: '', discountExhibitonID: data.ExhibitionID })
        }

    }
    onGenerateOpenSidePI(data, exhId) {
        const { isOpen7, openSidePIExhibitionID } = this.state
        if (isOpen7) {
            this.setState({ openSidePIExhibitionID: '', exhregId: '', isOpen7: false })
        } else if (!isOpen7) {
            this.setState({ isOpen7: true, exhregId: exhId, openSidePIExhibitionID: data.ExhibitionID })
        }
    }

    onDiscountSubmit() {
        const { discountPercent, exhregId, discountExhibitonID, discountPercentErr } = this.state
        if (discountPercent == '' || discountPercent == null) {
            toastError('Please enter Discount Value')
        } else {
            if (discountPercentErr == null || discountPercentErr == '') {
                console.log(discountExhibitonID, exhregId, discountPercent)
                this.setState({ isLoading: true })
                this.props.submitDiscount_action(discountExhibitonID, exhregId, parseInt(discountPercent))
                    .then((data) => {
                        if (data.error != 1) {
                            this.setState({ isLoading: false })
                            toastSuccess("Discount given Successfully")
                            this.setState({ discountPercent: '', discountExhibitonID: '', discountPercentErr: '', exhregId: '', isOpen6: false })
                            this.getExhibitorList()
                        } else {
                            this.setState({ isLoading: false })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                toastError('Please enter Proper Discount Value')
            }
        }
    }
    onDiscountChange(e) {
        const { discountPercent } = this.state
        // console.log(e.target)
        this.setState({ discountPercent: (e.target.value) })
        var err = false
        for (var i = 0; i < e.target.value.length; i++) {
            if (e.target.value[i] == 'e' || e.target.value[i] == '.') {
                err = 'discount percent needs to be an integer'
            }
            if (e.target.value > 100) {
                err = 'discount percent should be less than 100'
            }
        }
        if (err != false) {
            this.setState({ discountPercentErr: err })
        } else {
            this.setState({ discountPercentErr: '' })

        }
    }
    onPISubmit() {
        const { exhregId, openSidePIExhibitionID } = this.state
        this.setState({ isLoading: true })
        this.props.submitOpenSidePI_action(openSidePIExhibitionID, exhregId)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    toastSuccess("Generated Successfully")
                    this.setState({ openSidePIExhibitionID: '', exhregId: '', isOpen7: false })
                    this.getExhibitorList()
                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    render() {
        const { isLoading, proformaInv, exhibitor, proformaDate, proformaNo, currentPage, isOpen, isDetailOpen, exhibition, exhibitorList,
            estimateDetails, generatePriorityNoType, priorityNoList, priorityNoId, priorityNoErr, exhibitorOutstanding, isOpen2, isOpen3, hallNo, openSide,
            stallNo, hallNoErr, stallNoErr, openSideErr, checkedAll, paymentTypeList, paymentTypeId, statusCount, userName, statusID,
            statusTypeList, exhibitorTypeId, exhibitorTypeList, boothTypeId, boothTypeList, hallNoList, hallNumber, agentList, agentId,
            chapterList, chapter, countryId, countryList, exhREgNo, schemeTypeList, schemeType, stallNumber, fromArea, toArea, isFromDashboard, hallList, stallList,
            isOpen4, showForRegistration, startIndex, stallAreaId, stallAreaList, stallAreaName, modalData } = this.state
        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = exhibitorList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(exhibitorList.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                    <div className="main-container">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Exhibitor List</p>
                            </div>
                        </div>
                    </div>
                    {
                        !isFromDashboard &&
                        <div className="row m-1 mt-1 mb-4 text-center">
                            {
                                statusCount.length > 0 && statusCount.map((data) => (
                                    <div className="col top-box" style={{ cursor: "pointer" }}
                                        onClick={(e) => this.getExhibitorList(data.Name == 'received' ? 1 : data.Name == 'incomplete' ? 2 :
                                            data.Name == 'complete' ? 3 : data.Name == 'activated' ? 4 : data.Name == 'duplicate' ? 5 : data.Name == 'cancelled' ? 6 : null)}
                                    >
                                        <p className="text-center mb-0 pb-2">{data.Name.toUpperCase()}</p>
                                        <h4 className="mb-0 pb-1">
                                            {data.Count}
                                        </h4>
                                    </div>
                                ))
                            }
                        </div>
                    }

                    <div className="main-container p-2 mt-1">
                        {
                            !isFromDashboard &&
                            <div className="row ">
                                <div className="col-xl-3 mb-3">
                                    <label>Exhibitor</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        defaultValue=""
                                        required=""
                                        value={exhibitor}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(e) => this.setState({ exhibitor: e.target.value })}
                                    />
                                </div>
                                <div className="col-xl-3 mb-3">
                                    <label htmlFor="validationCustom03">Exhibition</label>
                                    <select className="form-control"
                                        value={this.state.exhibitionID}
                                        onChange={(e) => this.setState({ exhibitionID: e.target.value })}
                                    >
                                        <option value={''}>Select Exhibition</option>
                                        {
                                            this.state.exhibitionList.length > 0 && this.state.exhibitionList.map((data) => (
                                                <option key={data.ExhibitionID} value={data.ExhibitionID}>{data.ExhibitionName}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-xl-3 mb-3">
                                    <label htmlFor="validationCustom03">Payment Type</label>
                                    <select className="form-control"
                                        value={paymentTypeId}
                                        onChange={(e) => this.setState({ paymentTypeId: e.target.value })}
                                    >
                                        <option value={''}>Select Payment Type</option>
                                        {
                                            paymentTypeList.length > 0 && paymentTypeList.map((data) => (
                                                <option key={data.ID} value={data.ID}>{data.PaymentType}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-xl-3 mb-3">
                                    <label>User Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        defaultValue=""
                                        required=""
                                        value={userName}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(e) => this.setState({ userName: e.target.value })}
                                    />
                                </div>
                            </div>
                        }
                        {
                            !isFromDashboard &&
                            <div className="row">
                                <div className="col-xl-3 mb-3">
                                    <label htmlFor="validationCustom03">Status</label>
                                    <select className="form-control"
                                        value={statusID}
                                        onChange={(e) => this.setState({ statusID: e.target.value })}
                                    >
                                        <option value={''}>Select Status</option>
                                        {
                                            statusTypeList.length > 0 && statusTypeList.map((data) => (
                                                <option key={data.ID} value={data.ID}>{data.Name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-xl-3 mb-3">
                                    <label htmlFor="validationCustom03">Exhibitor Type</label>
                                    <select className="form-control"
                                        value={exhibitorTypeId}
                                        onChange={(e) => this.setState({ exhibitorTypeId: e.target.value })}
                                    >
                                        <option value={''}>Select Exhibitor Type</option>
                                        {
                                            exhibitorTypeList.length > 0 && exhibitorTypeList.map((data) => (
                                                <option key={data.ID} value={data.ID}>{data.ExhibitorType}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-xl-3 mb-3">
                                    <label htmlFor="validationCustom03">Booth Type</label>
                                    <select className="form-control"
                                        value={boothTypeId}
                                        onChange={(e) => this.setState({ boothTypeId: e.target.value })}
                                    >
                                        <option value={''}>Select Booth Type</option>
                                        {
                                            boothTypeList.length > 0 && boothTypeList.map((data) => (
                                                <option key={data.Code} value={data.Code}>{data.Description}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                {/* <div className="col-xl-3 mb-3">
                            <label htmlFor="validationCustom03">Booking Status</label>
                            <select className="form-control"
                            value={exhibitorTypeId}
                            onChange={(e) => this.setState({ exhibitorTypeId: e.target.value })}
                            >
                                <option>Select Booking Status</option>
                                {
                                    exhibitorTypeList.length > 0 && exhibitorTypeList.map((data) => (
                                        <option key={data.ID} value={data.ID}>{data.ExhibitorType}</option>
                                    ))
                                }
                            </select>
                        </div> */}
                                <div className="col-xl-3 mb-3">
                                    <label htmlFor="validationCustom03">Hall NO</label>
                                    <select className="form-control"
                                        value={hallNumber}
                                        onChange={(e) => this.setState({ hallNumber: e.target.value })}
                                    >
                                        <option value={''}>Select Hall NO</option>
                                        {
                                            this.state.hallMasterList.length > 0 && this.state.hallMasterList.map((data) => (
                                                <option key={data.HallId} value={data.HallId}>{data.HallName}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        }
                        {
                            !isFromDashboard &&
                            <div className="row">
                                <div className="col-xl-3 mb-3">
                                    <label htmlFor="validationCustom03">Agent List</label>
                                    <select className="form-control"
                                        value={agentId}
                                        onChange={(e) => this.setState({ agentId: e.target.value })}
                                    >
                                        <option value={''}>Select Agent</option>
                                        {
                                            agentList.length > 0 && agentList.map((data) => (
                                                <option key={data.AccountID} value={data.AccountID}>{data.CompanyName}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-xl-3 mb-3">
                                    <label htmlFor="validationCustom03">Primary Chapter</label>
                                    <select className="form-control"
                                        value={chapter}
                                        onChange={(e) => this.setState({ chapter: e.target.value })}
                                    >
                                        <option value={''}>Select Primary Chapter</option>
                                        {
                                            chapterList.length > 0 && chapterList.map((data) => (
                                                <option key={data.PrimaryChapter} value={data.PrimaryChapter}>{data.PrimaryChapter}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-xl-3 mb-3">
                                    <label htmlFor="validationCustom03">Country</label>
                                    <select className="form-control"
                                        value={countryId}
                                        onChange={(e) => this.setState({ countryId: e.target.value })}
                                    >
                                        <option value={''}>Select Country</option>
                                        {
                                            countryList.length > 0 && countryList.map((data) => (
                                                <option key={data.Code} value={data.Code}>{data.Description}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-xl-3 mb-3">
                                    <label>Application No</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        defaultValue=""
                                        required=""
                                        value={exhREgNo}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(e) => this.setState({ exhREgNo: e.target.value })}
                                    />
                                </div>
                            </div>
                        }
                        {
                            !isFromDashboard &&
                            <div className="row">
                                <div className="col-xl-3 mb-3">
                                    <label>Stall No</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        defaultValue=""
                                        required=""
                                        value={stallNumber}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(e) => this.setState({ stallNumber: e.target.value })}
                                    />
                                </div>
                                <div className="col-xl-3 mb-3">
                                    <label>From Area</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        defaultValue=""
                                        required=""
                                        value={fromArea}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(e) => this.setState({ fromArea: e.target.value })}
                                    />
                                </div>
                                <div className="col-xl-3 mb-3">
                                    <label>To Area</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        defaultValue=""
                                        required=""
                                        value={toArea}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(e) => this.setState({ toArea: e.target.value })}
                                    />
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-md-12">
                                {
                                    !isFromDashboard &&
                                    <div
                                        className="btn-group mb-1"
                                        role="group"
                                        aria-label="Basic example"
                                        style={{ float: "right" }}
                                    >
                                        <button type="button" className="btn ad-fil-btn"
                                            onClick={e => this.onSearchClick()}
                                        >
                                            Search
                                        </button>
                                        <button type="button" className="btn ad-fil-btn"
                                            onClick={e => this.clearValue()}
                                        >
                                            Clear
                                        </button>
                                        <button type="button" className="btn ad-fil-btn"
                                            disabled={!this.getDownloadAccess()}
                                            onClick={e => this.onDownloadManualFormReport()}
                                        >
                                            Manual Form Report
                                        </button>
                                        <button type="button" className="btn ad-fil-btn"
                                            disabled={!this.getWirteAccess()}
                                            onClick={e => this.onAddEdirectory()}
                                        >
                                            Add E-Directory
                                        </button>
                                        <button type="button" className="btn ad-fil-btn"
                                            disabled={!this.getWirteAccess()}
                                            onClick={e => this.removedEdirectoryManual()}
                                        >
                                            Removed E-Directory
                                        </button>
                                        <button type="button" className="btn ad-fil-btn"
                                            disabled={!this.getWirteAccess()}
                                            onClick={e => this.onAddFascia()}
                                        >
                                            Add Fascia
                                        </button>
                                        <button type="button" className="btn ad-fil-btn"
                                            disabled={!this.getSpecialAccess()}
                                            onClick={e => this.activateDeactivateUsers(1)}
                                        >
                                            Activate
                                        </button>
                                        <button type="button" className="btn ad-fil-btn"
                                            disabled={!this.getSpecialAccess()}
                                            onClick={e => this.activateDeactivateUsers(0)}
                                        >
                                            Deactivate
                                        </button>
                                        <div >
                                            {this.onCreateExcel()}
                                        </div>
                                    </div>
                                }


                                <div className="table-responsive">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                        <thead>
                                            <tr>
                                                <th className="text-center" style={{ width: 60 }}>
                                                    <input type="checkbox"
                                                        checked={checkedAll}
                                                        onChange={(e) => this.selectAllExhibitors(e)}
                                                    />
                                                </th>
                                                <th className="text-center" style={{ width: 50 }}>
                                                    Sr.No
                                                </th>
                                                <th>Exhibition</th>
                                                <th>Exhibition Date</th>
                                                <th>Application No.</th>
                                                <th>Exhibitor</th>
                                                <th>Chapters</th>
                                                <th className="text-center" style={{ width: 100 }}>
                                                    Area (sqm)
                                                </th>
                                                <th className="text-center" style={{ width: 100 }}>
                                                    Open Side
                                                </th>
                                                <th className="text-center" style={{ width: 100 }}>
                                                    open side PI
                                                </th>
                                                <th>Scheme</th>
                                                <th className="text-center" style={{ width: 100 }}>
                                                    Discount
                                                </th>
                                                <th className="text-center" style={{ width: 100 }}>
                                                    Info
                                                </th>
                                                <th className="text-center" style={{ width: 100 }}>
                                                    SSI/TMMA-ITAMMA certificate
                                                </th>
                                                {this.getWirteAccess() &&
                                                    <th className="text-center" style={{ width: 100 }}>
                                                        HallNo/StallNo
                                                    </th>
                                                }
                                                {/* <th className="text-center" style={{ width: 100 }}>
                                                    Priority No
                                                </th> */}
                                                {this.getUpdateAccess() &&
                                                    <th>Edit Application</th>
                                                }
                                                <th></th>
                                                {this.getSpecialAccess() &&
                                                    <th></th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentRecords.length > 0 ? currentRecords.map((data, index) => (
                                                <tr key={Math.random()}>
                                                    <td className="text-center">
                                                        <input type="checkbox"
                                                            // disabled={data.StatusID == '1' || data.StatusID == '2'}
                                                            value={data.isSelect}
                                                            checked={data.isSelect}
                                                            onChange={(e) => this.handleCheckedExhibitors(e, data.ExhRegistrationID)}
                                                        />
                                                    </td>
                                                    <td className="text-center">{this.state.startIndex + index + 1}</td>
                                                    <td>{data.ExhibitionName}</td>
                                                    <td>{data.ExhRegistrationDate != null ? moment(data.ExhRegistrationDate).format('DD/MM/YYYY') : null}</td>
                                                    <td>{data.ApplicationNo}</td>
                                                    <td>{data.ExhibitorName}</td>
                                                    <td>
                                                        {data.ChapterName}
                                                    </td>
                                                    <td className="text-center text-primary" style={{ cursor: 'pointer' }} onClick={(e) => this.getWirteAccess() &&
                                                        this.onAreaClick(data.ExhibitionStallSizeID, data.StallDimension, data.StallTypeID, data.Area, data.ExhRegistrationID, data.CountryID, data.ExhibitionID)} >{data.Area}</td>
                                                    <td className="text-center" style={{ cursor: 'pointer' }} onClick={(e) => this.getWirteAccess() &&
                                                        this.onOpenSideClick(data.OpenSides, data.ExhRegistrationID)}><span className="text-primary">{data.OpenSides}</span></td>
                                                    <td className="text-center" style={{ cursor: 'pointer' }}>{
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            disabled={data.IsOpenSidePerforma != null && data.IsOpenSidePerforma != false}
                                                            style={{ padding: "1px 10px" }}
                                                            onClick={(e) => this.onGenerateOpenSidePI(data, data.ExhRegistrationID)}
                                                        >
                                                            {/* {console.log('data.IsOpenSidePerforma', data.IsOpenSidePerforma)} */}
                                                            {data.IsOpenSidePerforma != null && data.IsOpenSidePerforma != false ? 'Generated' : 'Generate  open side PI'}
                                                        </button>
                                                    }</td>
                                                    <td>{data.Scheme}</td>
                                                    <td className="text-center" style={{ cursor: 'pointer' }}>
                                                        {
                                                            data.discount != null && data.discount != '' ?
                                                                <span className="text-primary text-center" onClick={(e) => this.onDiscountClick(data, data.ExhRegistrationID)}>{data.discount}%</span>
                                                                :
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    style={{ padding: "1px 10px" }}
                                                                    onClick={(e) => this.onDiscountClick(data, data.ExhRegistrationID)}
                                                                >
                                                                    Discount
                                                                </button>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            // data-bs-toggle="modal"
                                                            // data-bs-target="#detail"
                                                            style={{ padding: "1px 10px" }}
                                                            // onClick={(e) => this.toggleDetailModal()}
                                                            onClick={(e) => this.onDetailsClick(e, data.ExhRegistrationID)}
                                                        >
                                                            <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                    <td className="text-center">
                                                        {data.isSSICertified ?
                                                            <><button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                // data-bs-toggle="modal"
                                                                // data-bs-target="#detail"
                                                                style={{ padding: "0" }}

                                                            // onClick={(e) => this.toggleDetailModal()}
                                                            // onClick={(e) => this.onDetailsClick(e, data.ExhRegistrationID)}
                                                            ><a style={{ padding: "1px 10px" }} className="text-white" href={data.SSIDocumentPath}
                                                                target="_blank"
                                                                rel="noopener noreferrer">SSI Document</a>
                                                            </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary ms-2"
                                                                    // data-bs-toggle="modal"
                                                                    // data-bs-target="#detail"
                                                                    style={{ padding: "0" }}
                                                                // onClick={(e) => this.toggleDetailModal()}
                                                                // onClick={(e) => this.onDetailsClick(e, data.ExhRegistrationID)}
                                                                >
                                                                    <a style={{ padding: "1px 10px" }} className="text-white" href={data.TMMA_ITAMMA_DocumentPath}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer">  TMMA-ITAMMA Document</a>
                                                                </button></> : null}
                                                    </td>
                                                    {
                                                        this.getWirteAccess() &&
                                                        <td>
                                                            {
                                                                data['HallNo/StallNo'] != null && data['HallNo/StallNo'] != '' && data['HallNo/StallNo'] != '/' ?
                                                                    <span className="text-primary" onClick={(e) => this.onHallStallClick(data, data.ExhRegistrationID)}>{data['HallNo/StallNo']}</span>
                                                                    :
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary"
                                                                        style={{ padding: "1px 10px" }}
                                                                        onClick={(e) => this.onGenerateHallNoStallNO(data)}
                                                                    >
                                                                        Generate
                                                                    </button>
                                                            }
                                                        </td>
                                                    }
                                                    {/* <td>
                                                        {
                                                            data.PriorityNo != null ?
                                                                <span className="text-primary">{data.PriorityNo}</span> :
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    style={{ padding: "1px 10px" }}
                                                                    onClick={(e) => this.onGenerateClick(data.ExhRegistrationID, data.Outstanding)}
                                                                >
                                                                    Generate
                                                                </button>
                                                        }

                                                    </td> */}

                                                    {
                                                        this.getUpdateAccess() &&
                                                        <td className="text-center">
                                                            <i className="fa fa-pencil" aria-hidden="true"
                                                                onClick={e => this.handleUpdate(data)}
                                                            />
                                                        </td>
                                                    }
                                                    < td className="text-center" >
                                                        {
                                                            data.isSSICertified ? data.SSIDiscountStatusID == 1 ?
                                                                <><button
                                                                    type="button"
                                                                    className="btn btn-success"
                                                                    // data-bs-toggle="modal"
                                                                    // data-bs-target="#detail"
                                                                    style={{ padding: "1px 10px" }}
                                                                    // onClick={(e) => this.toggleDetailModal()}
                                                                    disabled={data.SSIDiscountStatusID == 1}
                                                                // onClick={(e) => this.toggleModal5(data.ExhRegistrationID, 1)}
                                                                >  <i class="fa fa-check" aria-hidden="true"></i> Approved SSI Discount
                                                                </button></> : data.SSIDiscountStatusID == 2 ?
                                                                    <><button
                                                                        type="button"
                                                                        className="btn btn-success"
                                                                        // data-bs-toggle="modal"
                                                                        // data-bs-target="#detail"
                                                                        style={{ padding: "1px 10px" }}
                                                                        // onClick={(e) => this.toggleDetailModal()}
                                                                        // disabled={data.SSIDiscountStatusID == 1}
                                                                        onClick={(e) => this.toggleModal5(data.ExhRegistrationID, 1)}
                                                                    >  Approve SSI Discount
                                                                    </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger ms-2"
                                                                            // data-bs-toggle="modal"
                                                                            // data-bs-target="#detail"
                                                                            style={{ padding: "1px 10px" }}
                                                                            // onClick={(e) => this.toggleDetailModal()}
                                                                            disabled={data.SSIDiscountStatusID == 1 || data.SSIDiscount}
                                                                            onClick={(e) => this.toggleModal5(data.ExhRegistrationID, 2)}
                                                                        >
                                                                            SSI Document Rejected
                                                                        </button></> :
                                                                    <><button
                                                                        type="button"
                                                                        className="btn btn-success"
                                                                        // data-bs-toggle="modal"
                                                                        // data-bs-target="#detail"
                                                                        style={{ padding: "1px 10px" }}
                                                                        // onClick={(e) => this.toggleDetailModal()}
                                                                        disabled={data.SSIDiscountStatusID == 1}
                                                                        onClick={(e) => this.toggleModal5(data.ExhRegistrationID, 1)}
                                                                    >  Approve SSI Discount
                                                                    </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger ms-2"
                                                                            // data-bs-toggle="modal"
                                                                            // data-bs-target="#detail"
                                                                            style={{ padding: "1px 10px" }}
                                                                            // onClick={(e) => this.toggleDetailModal()}
                                                                            disabled={data.SSIDiscountStatusID == 1}
                                                                            onClick={(e) => this.toggleModal5(data.ExhRegistrationID, 2)}
                                                                        >
                                                                            Reject SSI Discount
                                                                        </button></> : null
                                                        }
                                                    </td>
                                                    {this.getSpecialAccess() &&
                                                        <td className="text-center">
                                                            {data['HallNo/StallNo'] != null && data['HallNo/StallNo'] != '' && data['HallNo/StallNo'] != '/' && <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                style={{ padding: "1px 10px", marginRight: "10px" }}
                                                                onClick={(e) => this.sendhallstallUserCred(e, data.ExhRegistrationID, data['HallNo/StallNo'])}
                                                            >
                                                                send Hall/Stall No
                                                            </button>}
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                style={{ padding: "1px 10px", marginRight: "10px" }}
                                                                onClick={(e) => this.resendUserCred(e, data.ExhRegistrationID)}
                                                            >
                                                                Resend UserID & Password
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                style={{ padding: "1px 10px" }}
                                                                onClick={(e) => this.onLogin(data)}
                                                            >
                                                                Login
                                                            </button>

                                                        </td>
                                                    }
                                                </tr>
                                            )) : <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>

                                            }
                                        </tbody>
                                    </table>

                                </div>
                                <div className="mt-2 float-left">
                                    {
                                        exhibitorList.length > 0 ?
                                            <Pagination
                                                nPages={nPages}
                                                currentPage={currentPage}
                                                setCurrentPage={this.updateItem}
                                            /> : <></>
                                    }
                                    <div className="tab-rec " style={{ float: "right" }}>
                                        <p>{`${currentPage} of ${nPages} pages`}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
                <Modal isOpen={isOpen2} onRequestClose={(e) => this.toggleModal2(e)}>
                    <div
                        className="modal-dialog modal-dialog-centered bd-example-modal-lg"
                        id="detail"
                    // tabIndex={-1}
                    // aria-labelledby="detailLabel"
                    // aria-hidden="true"
                    >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content estimate-details">
                                <div className="modal-header pb-1">
                                    <h5 className="modal-title" id="detailLabel">
                                        <strong>Generate Hall No / Stall No</strong>
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close pt-6"
                                        data-bs-dismiss="modal"
                                        style={{ fontSize: 12 }}
                                        aria-label="Close"
                                        onClick={(e) => this.toggleModal2(e)}
                                    />
                                </div>
                                <div className="modal-body p-2">
                                    <div className="col-xl-6 ms-2">
                                        <p>Area: <strong>{this.state.modalArea}sqm</strong>  <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                            onClick={(e) => this.onAreaClick(modalData.ExhibitionStallSizeID, modalData.StallDimension, modalData.StallTypeID, modalData.Area, modalData.ExhRegistrationID, modalData.CountryID, modalData.ExhibitionID)}
                                        >
                                            Change
                                        </button></p>
                                        <p>Open Side: <strong>{this.state.modalOpenSide}</strong>  <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                            onClick={(e) => this.onOpenSideClick(modalData.OpenSides, modalData.ExhRegistrationID)}
                                        >
                                            Change
                                        </button></p>

                                    </div>
                                    <hr></hr>
                                    <div className="d-flex">
                                        <div className="col-xl-6">
                                            <label className="text-left">Hall No <span style={{ color: 'red' }}>*</span></label>
                                            {/* <input
                                                type="text"
                                                className="form-control"
                                                value={hallNo}
                                                onChange={(e) => this.setState({ hallNo: e.target.value, hallNoErr: "" })}
                                            /> */}
                                            <select className="form-control"
                                                value={hallNo}
                                                // onChange={(e) => this.setState({ hallNo: e.target.value, hallNoErr: "" })}
                                                onChange={(e) => this.onHallChange(e, 1)}
                                            >
                                                <option value={''}>Select Hall</option>
                                                {
                                                    hallList.length > 0 && hallList.map((data) => (
                                                        <option key={data.HallId} value={data.HallId}>{data.HallName}</option>
                                                    ))
                                                }
                                            </select>
                                            {<span className='text-danger text-start text-left d-block mt-1'>{hallNoErr}</span>}
                                        </div>
                                        <div className="col-xl-6">
                                            <label className="text-left">Stall No <span style={{ color: 'red' }}>*</span></label>
                                            {/* <input
                                                type="text"
                                                className="form-control"
                                                value={stallNo}
                                                onChange={(e) => this.setState({ stallNo: e.target.value, stallNoErr: "" })}
                                            /> */}
                                            <select className="form-control"
                                                value={stallNo} disabled={hallNo == "" || hallNo == "Select Hall"}
                                                onChange={(e) => this.setState({ stallNo: e.target.value, stallNoErr: "" })}
                                            >
                                                <option value={''}>Select Stall</option>
                                                {
                                                    stallList.length > 0 && stallList.map((data) => (
                                                        <option key={data.StallId} value={data.StallId}>{data.StallName}</option>
                                                    ))
                                                }
                                            </select>
                                            {<span className='text-danger text-start text-left d-block mt-1'>{stallNoErr}</span>}
                                        </div>
                                    </div>
                                    <div className="m-3">
                                        {this.state.hallSegmentList.length > 0 &&
                                            <table className="table border text-nowrap text-md-nowrap table-bordered ">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" style={{ width: '60px' }}>Sr no.</th>
                                                        <th className="text-center">Segment</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.hallSegmentList.map((data, i) => (
                                                        <tr>
                                                            <td className="text-center" style={{ width: '60px' }}>{i + 1}</td>
                                                            <td className="text-center">{data.ChapterName}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                </div>
                                <div className="modal-footer p-1">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.genrateHallStall(e)}
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.toggleModal2(e)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal isOpen={isOpen} onRequestClose={(e) => this.toggleModal(e)}>
                    <div
                        className="modal-dialog modal-dialog-centered bd-example-modal-lg"
                        id="detail"
                    // tabIndex={-1}
                    // aria-labelledby="detailLabel"
                    // aria-hidden="true"
                    >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content Gen-btnpop">
                                <div className="modal-header pb-1">
                                    <h5 className="modal-title" id="detailLabel">
                                        <strong>Generate Priority No</strong>
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close pt-6"
                                        data-bs-dismiss="modal"
                                        style={{ fontSize: 12 }}
                                        aria-label="Close"
                                        onClick={(e) => this.toggleModal(e)}
                                    />
                                </div>
                                <div className="modal-body ">
                                    {
                                        exhibitorOutstanding != 0 ?
                                            <p className="text-danger"> The exhibitor has not yet made a 100% payment for the Space Booking.</p>
                                            : ""
                                    }
                                    {/* <p className=""> The exhibitor has made a 100% payment for the exhibition.</p> */}
                                    <hr />

                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" for="inlineRadio1" style={{ lineHeight: '15px' }}>
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                                value={generatePriorityNoType}
                                                checked={generatePriorityNoType == 1}
                                                onClick={(e) => this.setState({ generatePriorityNoType: 1 })}
                                            />Auto</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" for="inlineRadio2" style={{ lineHeight: '15px' }}>
                                            <input class="form-check-input" type="radio" id="inlineRadio2"
                                                value={generatePriorityNoType}
                                                checked={generatePriorityNoType == 0}
                                                onClick={(e) => this.setState({ generatePriorityNoType: 0 })}
                                            />Manual</label>
                                    </div>

                                    {
                                        generatePriorityNoType == 0 &&
                                        <form>
                                            <select class="form-control mt-2" aria-label="Default select example"
                                                value={priorityNoId}
                                                onChange={(e) => this.setState({ priorityNoId: e.target.value, priorityNoErr: "" })}
                                            >
                                                <option selected>Select Priority Number</option>
                                                {
                                                    priorityNoList.length > 0 && priorityNoList.map((data) => (
                                                        <option key={data.ID} value={data.ID}>{data.PriorityNo}</option>
                                                    ))
                                                }

                                            </select>
                                            {<span className='text-danger text-start text-left d-block mt-1'>{priorityNoErr}</span>}
                                        </form>
                                    }

                                </div>
                                <div className="modal-footer p-1">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.onFinalGeneratePriorityNo(e)}
                                    >
                                        Generate
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal isOpen={isOpen3} onRequestClose={(e) => this.toggleModal3(e)}>
                    <div
                        className="modal-dialog modal-dialog-centered bd-example-modal-lg"
                        id="detail"
                    // tabIndex={-1}
                    // aria-labelledby="detailLabel"
                    // aria-hidden="true"
                    >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content estimate-details">
                                <div className="modal-header pb-1">
                                    <h5 className="modal-title" id="detailLabel">
                                        <strong>Change Open Side</strong>
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close pt-6"
                                        data-bs-dismiss="modal"
                                        style={{ fontSize: 12 }}
                                        aria-label="Close"
                                        onClick={(e) => this.toggleModal3(e)}
                                    />
                                </div>
                                <div className="modal-body p-2">
                                    <div className="d-flex">
                                        <div className="col-xl-6">
                                            <label className="text-left">Open Side <span style={{ color: 'red' }}>*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={openSide}
                                                onChange={(e) => this.setState({ openSide: e.target.value, openSideErr: "" })}
                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{openSideErr}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer p-1">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.ChangeOpenSide(e)}
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.toggleModal3(e)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal isOpen={isOpen4} onRequestClose={(e) => this.toggleModal4(e)}>
                    <div
                        className="modal-dialog modal-dialog-centered bd-example-modal-lg"
                        id="detail"
                    // tabIndex={-1}
                    // aria-labelledby="detailLabel"
                    // aria-hidden="true"
                    >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content estimate-details">
                                <div className="modal-header pb-1">
                                    <h5 className="modal-title" id="detailLabel">
                                        <strong>Change Area</strong>
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close pt-6"
                                        data-bs-dismiss="modal"
                                        style={{ fontSize: 12 }}
                                        aria-label="Close"
                                        onClick={(e) => this.toggleModal4(e)}
                                    />
                                </div>
                                <div className="modal-body p-2">
                                    <div className="form-row mt-1 p-2">

                                        {
                                            schemeTypeList.length > 0 && schemeTypeList.map((schemeTypeListData) => (
                                                <div className="col-xl-4">
                                                    <label>
                                                        <input type="radio"
                                                            id={'schemeType'}
                                                            name={'schemeType'}
                                                            radioGroup={schemeType == schemeTypeListData.Code && true}
                                                            select={schemeType == schemeTypeListData.Code && true}
                                                            checked={schemeType == schemeTypeListData.Code && true}
                                                            value={schemeType}
                                                            onChange={e => this.onSchemeTypeChange(e, schemeTypeListData.Code)}
                                                        />
                                                        {schemeTypeListData.Description}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="form-row mt-2 p-2">
                                        <div className="col-xl-10">
                                            <label>Length * width in Meters</label>
                                            <select
                                                className="form-control select2 select-hidden-accessible pl-0 mt-1"
                                                id="roledb"
                                                value={stallAreaId}
                                                onChange={e => this.onLengthTypeChange(e)}
                                            >
                                                <option value={''}>Select</option>
                                                {
                                                    stallAreaList.length > 0 && stallAreaList.map(stallAreaData => (
                                                        <option value={stallAreaData.Code} key={stallAreaData.Code}>{stallAreaData.Description}</option>
                                                    ))
                                                }


                                            </select>


                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer p-1">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        disabled={this.state.exhregId == '' || stallAreaId == '' || this.state.stallArea == '' || schemeType == '' || stallAreaName == ''}
                                        onClick={(e) => this.onUpdateStallTypeArea(e)}
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.toggleModal4(e)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal isOpen={this.state.isOpen5} onRequestClose={(e) => this.toggleModal5(e)}>
                    <div
                        className="modal-dialog modal-dialog-centered bd-example-modal-lg"
                        id="detail"
                    // tabIndex={-1}
                    // aria-labelledby="detailLabel"
                    // aria-hidden="true"
                    >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content estimate-details">
                                <div className="modal-header pb-1">
                                    <h5 className="modal-title" id="detailLabel">
                                        <strong>SSI Document {this.state.SSIStatus == 1 ? 'Approve' : 'Reject'}?</strong>
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close pt-6"
                                        data-bs-dismiss="modal"
                                        style={{ fontSize: 12 }}
                                        aria-label="Close"
                                        onClick={(e) => this.toggleModal5(e)}
                                    />
                                </div>
                                {
                                    this.state.SSIStatus != 1 &&
                                    <div className="modal-body p-2">
                                        <div className="d-flex mt-3">
                                            <div className="col-xl-12">
                                                <label className="text-right"><strong>Reject Reason</strong></label>
                                                <textarea type="text"
                                                    className="form-control"
                                                    value={this.state.RejectReason}
                                                    onChange={(e) => this.setState({ RejectReason: e.target.value })}>
                                                </textarea >
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="modal-footer p-1">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.OnSSIDocumentApprove(e)}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.toggleModal5(e)}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal isOpen={this.state.isOpen6} onRequestClose={(e) => this.onDiscountClick(e)}>
                    <div
                        className="modal-dialog modal-dialog-centered bd-example-modal-lg"
                        id="detail"
                    // tabIndex={-1}
                    // aria-labelledby="detailLabel"
                    // aria-hidden="true"
                    >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content estimate-details">
                                <div className="modal-header pb-1">
                                    <h5 className="modal-title" id="detailLabel">
                                        <strong>Discount</strong>
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close pt-6"
                                        data-bs-dismiss="modal"
                                        style={{ fontSize: 12 }}
                                        aria-label="Close"
                                        onClick={(e) => this.onDiscountClick(e)}
                                    />
                                </div>
                                <div className="modal-body p-2">
                                    <div className="d-flex">
                                        <div className="col-xl-12">
                                            <label className="text-left">Discount Percent<span style={{ color: 'red' }}>*</span></label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={this.state.discountPercent}
                                                onChange={(e) => this.onDiscountChange(e)}
                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{this.state.discountPercentErr}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer p-1">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.onDiscountSubmit(e)}
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.onDiscountClick(e)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal isOpen={this.state.isOpen7} onRequestClose={(e) => this.onGenerateOpenSidePI(e)}>
                    <div
                        className="modal-dialog modal-dialog-centered bd-example-modal-lg"
                        id="detail"
                    // tabIndex={-1}
                    // aria-labelledby="detailLabel"
                    // aria-hidden="true"
                    >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content estimate-details">
                                <div className="modal-header pb-1">
                                    <h5 className="modal-title" id="detailLabel">
                                        <strong>Generate OpenSide PI</strong>
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close pt-6"
                                        data-bs-dismiss="modal"
                                        style={{ fontSize: 12 }}
                                        aria-label="Close"
                                        onClick={(e) => this.onGenerateOpenSidePI(e)}
                                    />
                                </div>
                                <div className="modal-footer p-1">
                                    <button
                                        type="button"
                                        className="btn btn-success"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.onPISubmit(e)}
                                    >
                                        Generate
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.onGenerateOpenSidePI(e)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* <Footerwithdr /> */}
            </>
        )
    }
}
export default withRouter(connect(null, {
    exhibitorList_action, getExhiitorDetails_action, descriptionPreview_action, SSIDocumentApproval_action, updateStallTypeArea_action,
    sendUserCred_action, fill_action, submitManualPriorityNo_action, submitAutoPriorityNo_action, getExhRegList_action,
    generateHallNoStallNo_action, generateOpenSide_action, addEdirectoryManual_action, addFasciaManual_action, sendhallstallUserCred_action,
    activateDeactivateExhibitors_action, fill_master_action, submitDiscount_action, submitOpenSidePI_action, removedEdirectoryManual_action
})(ExhibitorList))