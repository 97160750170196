import {
    headers, ADD_REFUND, GET_REFUND_TYPE_AMOUNT, shareRefundUrl, SHARE_REFUND, getRefundTypeAmountUrl, addRefundUrl, generateRefundVoucherUrl, GENERATE_REFUND_VOUCHER, updateRefundUrl, UPDATE_REFUND, deleteRefundUrl,fetchAuthReport, DELETE_REFUND, fetchRefundListUrl, FETCH_RECEIPT_LIST, getRefundNoUrl, GET_REFUND_NO, getRefundDetailsUrl, GET_REFUND_DETAILS,GET_REFUND_PDF_ACTION_TYPE
} from "../../../utils/constants"
import { axiosPost, axiosGet } from "../../../react_axios/Axios"
import moment from 'moment';

export const get_receipt_list_action = (exhibitor, RefundFromDate, RefundToDate, RefundNo) => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            // AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            token: 'token',
            Exhibitor: exhibitor,
            RefundFromDate: RefundFromDate != "" ? (moment(RefundFromDate).format('MM-DD-YYYY')) : null,
            RefundToDate: RefundToDate != "" ? (moment(RefundToDate).format('MM-DD-YYYY')) : null,
            RefundNo: RefundNo
        }
        console.log("ReceiptListSearch -Action--------------------------->", params)

        const res = await axiosGet(fetchRefundListUrl, params, headers);
        dispatch({
            type: FETCH_RECEIPT_LIST,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const refundNo_action = (AccountID) => async (dispatch) => {

    try {
        const params = {
            AccountID: AccountID,
            exhibitionid: sessionStorage.getItem('sessionExhibitionID')
        }

        const res = await axiosGet(getRefundNoUrl, params, headers);
        dispatch({
            type: GET_REFUND_NO,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const add_refund_action = (voucherTypeId, bankAccountId, currencyId, conRate, AvailableRefundAmount, RefundAmount, totalAmount, RefundNo, RefundDate, pymtModeId, PaymentDoneDate, InstrumentNo, instruDate, bankName, branchName, narration, refundTypeAmountList, OtherChargesListData, accPaymentId, exhregistrationID) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: parseInt(JSON.parse(sessionStorage.getItem('loginDataSS')).UserID),
            Token: 'token',
            LoggedInAccountID: parseInt(JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID),
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: exhregistrationID,
            RefundID: null,
            VoucherTypeID: parseInt(voucherTypeId),
            // ExhAccountID: parseInt(exhibitorId),
            BankID: parseInt(bankAccountId),
            CurrencyID: parseInt(currencyId),
            ConversionRate: parseFloat(conRate).toFixed(2),
            AvailableRefundAmount: AvailableRefundAmount != "" && AvailableRefundAmount != null ? parseFloat(AvailableRefundAmount).toFixed(2) : parseFloat(0).toFixed(2),
            // TDSAmount: tdsAmount != "" && tdsAmount != null ? parseFloat(tdsAmount).toFixed(2) : parseFloat(0).toFixed(2),
            // OnAccAmount: parseFloat(onAccAmt).toFixed(2),
            RefundAmount: parseFloat(RefundAmount).toFixed(2),
            TotalAmount: parseFloat(totalAmount).toFixed(2),
            RefundNo: RefundNo,
            RefundDate: RefundDate,
            PaymentModeID: parseInt(pymtModeId),
            PaymentDoneDate: PaymentDoneDate,
            InstrumentNo: InstrumentNo != "" ? InstrumentNo : 0,
            InstrumentDate: instruDate,
            BankName: bankName,
            BranchName: branchName,
            Narration: narration,
            refundTypeAmountList: refundTypeAmountList,
            otherChargesList: OtherChargesListData,
            AccountPaymentID: accPaymentId != "" ? accPaymentId : null
        }
        console.log("Receiptentry-Action--------------------------->", mapdata)
        const res = await axiosPost(addRefundUrl, mapdata, headers);

        dispatch({
            type: ADD_REFUND,
            payload: res,
        })
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const get_refund_type_amount_action = (exhID) => async (dispatch) => {

    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhRegistrationID: exhID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("ReceiptListSearch -Action--------------------------->", params)

        const res = await axiosGet(getRefundTypeAmountUrl, params, headers);
        dispatch({
            type: GET_REFUND_TYPE_AMOUNT,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const generate_refund_voucher_action = (bankAccountId, RefundAmount,
    refundTypeAmountList, otherChargesList, RefundID) => async (dispatch) => {
        try {
            const mapData = {
                UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
                // AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
                // token: sessionStorage.getItem('tokenSS'),
                // voucherTypeId: voucherTypeId,
                // ExhibitorId: exhibitorId,
                BankAccountId: bankAccountId,
                // currency: currency,
                // conRate: conRate,
                // BankAmount: bankAmount,
                RefundAmount: RefundAmount,
                // ExchDiff: exchdiif,
                // onAccAmt: onAccAmt,
                // CrAmount: crAmount,
                // TotalAmount: totalAmount,
                refundTypeAmountList: refundTypeAmountList,
                otherChargesList: otherChargesList,
                RefundID: RefundID != '' ? RefundID : 0,
                ExhibitionID: sessionStorage.getItem('sessionExhibitionID')
            }
            console.log("Generate Voucher Action --------->", mapData)
            const res = await axiosPost(generateRefundVoucherUrl, mapData, headers);
            dispatch({
                type: GENERATE_REFUND_VOUCHER,
                payload: res,
            })
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

export const refund_update_action = (RefundID, voucherTypeId, accPaymentId, bankAccountId, currencyId, conRate, AvailableRefundAmount, RefundAmount,
    totalAmount, RefundNo, RefundDate, PaymentDoneDate, pymtModeId, instrNo, instruDate, bankName, branchName,
    narration, refundTypeAmountList, OtherChargesListData, ExhregistrationID) => async (dispatch) => {
        try {
            // console.log('BankAmount : ', bankAmount);
            const mapData = {
                UserID: parseInt(JSON.parse(sessionStorage.getItem('loginDataSS')).UserID),
                Token: 'token',
                ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
                ExhregistrationID: ExhregistrationID,
                LogedinAccountID: parseInt(JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID),
                RefundID: parseInt(RefundID),
                VoucherTypeID: parseInt(voucherTypeId),
                AccountPaymentID: parseInt(accPaymentId),
                BankID: parseInt(bankAccountId),
                CurrencyID: parseInt(currencyId),
                ConversionRate: parseFloat(conRate).toFixed(2),
                AvailableRefundAmount: AvailableRefundAmount != "" && AvailableRefundAmount != null ? parseFloat(AvailableRefundAmount).toFixed(2) : parseFloat(0).toFixed(2),
                RefundAmount: RefundAmount != "" && RefundAmount != null ? parseFloat(RefundAmount).toFixed(2) : parseFloat(0).toFixed(2),
                // ExchangeDifference: exchdiif != "" && exchdiif != null ? parseFloat(exchdiif).toFixed(2) : parseFloat(0).toFixed(2),
                // OnAccAmount: parseFloat(onAccAmt).toFixed(2),
                // CreditAmount: parseFloat(crAmount).toFixed(2),
                TotalAmount: parseFloat(totalAmount).toFixed(2),
                RefundNo: RefundNo,
                RefundDate: RefundDate,
                PaymentDoneDate: PaymentDoneDate,
                PaymentModeID: parseInt(pymtModeId),
                InstrumentNo: instrNo != "" ? instrNo : 0,
                InstrumentDate: instruDate,
                BankName: bankName,
                BranchName: branchName,
                Narration: narration,
                refundTypeAmountList: refundTypeAmountList,
                otherChargesList: OtherChargesListData
            }
            console.log("ReceiptList-Action Update--------------------------->", mapData);
            const res = await axiosPost(updateRefundUrl, mapData, headers);
            dispatch({
                type: UPDATE_REFUND,
                payload: res
            })
            return Promise.resolve(res)
        } catch (error) {
            return Promise.resolve(error)
        }
    }

export const refundShare_action = (refundList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            refundList: refundList,
        }
        console.log("pendingReceiptDetails_action-Action--------------------------->", params)

        const res = await axiosGet(shareRefundUrl, params, headers);
        dispatch({
            type: SHARE_REFUND,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const delete_refund_action = (RefundID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            RefundID: RefundID,
            // token: 'token'
        }
        console.log("Delete Receipt Entry Action---------------->", params)
        const res = await axiosGet(deleteRefundUrl, params, headers);
        dispatch({
            type: DELETE_REFUND,
            payload: res
        })
        return Promise.resolve(res)

    } catch (error) {
        return Promise.reject(error)
    }
}

export const refund_details_action = (RefundID) => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            // ExhRegistrationID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            RefundID: RefundID,
            token: sessionStorage.getItem('tokenSS')
        }
        console.log("ReceiptList-Action--------------------------->", params)

        const res = await axiosGet(getRefundDetailsUrl, params, headers);
        dispatch({
            type: GET_REFUND_DETAILS,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const getRefunedPdf_action = (RefundID) => async (dispatch) => {
    try {
        var list = [];
        const mapdata1 = {
            "ParameterName": "@RefundID",
            "ParameterValue": RefundID,
            "ParameterDataType": "int"
        }
        list.push(mapdata1);
        const mapData = {
            "report_name":  'Refundcopy.rpt',
            "parameter_details": list
        }
        const res = await axiosPost(fetchAuthReport, mapData);
        dispatch({
            type: GET_REFUND_PDF_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};