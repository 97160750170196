import { Component } from "react";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import {
    receipt_entry_save_action, receiptDetails_action, proformaInvoice_action, generateVoucher_action,
    receiptNo_action, deleteReceipt_action, receiptUpdate_action, pendingReceiptDetails_action
} from "../../../actions/admin_module_action/registration_receipt_actions/receipt_action";
import {
    get_refund_type_amount_action, generate_refund_voucher_action, refund_update_action, add_refund_action, delete_refund_action, refundNo_action, refund_details_action
} from "../../../actions/admin_module_action/refund_action/refund_action.js";
import {
    toastError, voucherTypeActionId, exhibitorActionId, exhibitorActionId2, bankAccountActionId, refundExhibitorDropdown,
    paymentModeActionId, otherChargesAccountTypeActionId
} from "../../../utils/constants";
import { fill_action } from '../../../actions/fillCombo_action';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import AdminDrawer from "../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import { toastSuccess } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import { data } from "jquery";




class RefundEntry extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            isDelOpen: false,
            isView: false,
            isReadOnly: false,
            isGenVoucherDisabled: false,
            // isRightToDelete: false,
            isAdd: false,
            isDelete: false,
            isUpdate: false,
            isTotalsMatched: false,
            accPaymentId: "",
            voucherTypeId: "",
            exhibitorId: "",
            exhibitorAccountID: "",
            bankAccountId: "",
            currency: "",
            currencyId: "",
            conRate: 1,
            availableRefundAmount: '',
            RefundAmount: "",
            onAccAmt: "",
            totalAmount: "",
            receivedAmt: "",
            ledDebAmt: "",
            ledCrAmt: "",
            subLedDebAmt: "",
            subLedCrAmt: "",
            narration: "",
            RefundNo: "",
            RefundID: "",
            RefundDate: new Date(),
            PaymentDoneDate: new Date(),
            pymtModeId: "",
            instrNo: "",
            instruDate: "",
            bankName: "",
            branchName: "",
            exhibitionID: "",
            exhRegistrationID: "",
            voucherTypeList: [],
            exhibitorList: [],
            bankAccountList: [],
            otherChargesList: [{
                account: "",
                amount: ""
            }],
            otherChargesBlankItems: 0,
            paymentModeList: [],
            SelectedInv: [],
            checkedRefundList: [],
            checkedAll: false,
            tableRows: [],
            otherCharges: [],
            otherChargesAccountList: [],
            refundTypeList: [],
            ledgerList: [],
            subLedgerList: [],
            isContinue: false,
            otherChargesAccountTypeListId: '',
            otherChargesAccountTypeList: [],
            loginAccesslist: [],
            isTotalAmountAvailable: false,
            // refundTypeList: [],
            // Input Errors
            voucherTypeErr: "",
            exhibitorErr: "",
            bankAccountErr: "",
            availableRefundAmountErr: "",
            RefundAmountErr: "",
            pymtModeErr: "",
            otherChargesListErr: "",
            totalsErr: "",
            totalAmountErr: '',
            from: ''
        }
        this.saveRefundEntry = this.saveRefundEntry.bind(this)
        this.onCancleRefundEntry = this.onCancleRefundEntry.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.handleCheckRefund = this.handleCheckRefund.bind(this)
        this.handleOtherCharges = this.handleOtherCharges.bind(this)
        this.deleteTableRows = this.deleteTableRows.bind(this)
        this.addTableRows = this.addTableRows.bind(this)
        this.otherInputValidate = this.otherInputValidate.bind(this)
        this.selectAllInv = this.selectAllInv.bind(this)
        // this.calculateTotals = this.calculateTotals.bind(this)
        this.handleReceivedAmount = this.handleReceivedAmount.bind(this)
        this.totalAmtsVal = this.totalAmtsVal.bind(this)
        this.generateVoucher = this.generateVoucher.bind(this)
        this.handleContinue = this.handleContinue.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.getRefundDetails = this.getRefundDetails.bind(this)
        this.clearVoucher = this.clearVoucher.bind(this)
        this.preventNegative = this.preventNegative.bind(this)
        this.getRefundList = this.getRefundList.bind(this)
        this.selectExhibitor = this.selectExhibitor.bind(this)
        this.autoFillRefundAmount = this.autoFillRefundAmount.bind(this)
        this.handleavailableRefundAmount = this.handleavailableRefundAmount.bind(this)
        this.handleRefundAmount = this.handleRefundAmount.bind(this)
        // this.handleTotalAmt = this.handleTotalAmt.bind(this)
        this.toggleButtons = this.toggleButtons.bind(this)
        this.handleOnAccAmt = this.handleOnAccAmt.bind(this)
        this.createLedgerTable = this.createLedgerTable.bind(this)
        this.createSubLedgerTable = this.createSubLedgerTable.bind(this)
        this.generateRefundNo = this.generateRefundNo.bind(this)
        this.toggleDelModal = this.toggleDelModal.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.updateRefundEntry = this.updateRefundEntry.bind(this)
        this.onPaymentDoneDate = this.onPaymentDoneDate.bind(this)
        this.getPendingReceiptDetails = this.getPendingReceiptDetails.bind(this)
        this.getUpdateAccess = this.getUpdateAccess.bind(this)
        this.getWriteAccess = this.getWriteAccess.bind(this)
        this.getDeleteAccess = this.getDeleteAccess.bind(this)
    }


    // On Component Load
    componentDidMount() {
        const { exhibitionID, exhRegistrationID } = this.state
        // Fetching DropDown Fill List On Component Load
        // if (this.props.location.state != null && (this.props.location.state.is_view || this.props.location.state.is_update)) {
        //     this.getFillList(exhibitorActionId2, exhibitionID, '', '', '', exhRegistrationID);
        // } else {

        //     this.getFillList(exhibitorActionId, exhibitionID, '', '', '', exhRegistrationID);
        // }

        setTimeout(() => {
            this.getFillList(refundExhibitorDropdown, exhibitionID, '', '', '', exhRegistrationID);
            this.getFillList(voucherTypeActionId, exhibitionID, '', '', '', exhRegistrationID);
            this.getFillList(bankAccountActionId, exhibitionID, '', '', '', exhRegistrationID);
            this.getFillList(paymentModeActionId, exhibitionID, '', '', '', exhRegistrationID);
            this.getFillList(otherChargesAccountTypeActionId, exhibitionID, '', '', '', exhRegistrationID);
        }, 0);
        console.log("IsView=================", this.props.location.state != null && this.props.location.state.is_view)
        console.log("IsAdd=================", this.props.location.state != null && this.props.location.state.is_add)
        console.log("IsUpdate=================", this.props.location.state != null && this.props.location.state.is_update)
        console.log("RefundID====================", this.props.location.state != null && this.props.location.state.RefundID ? this.props.location.state.RefundID : '')

        this.setState({
            isView: this.props.location.state != null && this.props.location.state.is_view ? this.props.location.state.is_view : false,
            isAdd: this.props.location.state != null && this.props.location.state.is_add ? this.props.location.state.is_add : false,
            isUpdate: this.props.location.state != null && this.props.location.state.is_update ? this.props.location.state.is_update : false,
            RefundID: this.props.location.state != null && this.props.location.state.RefundID ? this.props.location.state.RefundID : '',
            // from: this.props.location.state != null && this.props.location.state.from ? this.props.location.state.from : '',
            loginAccesslist: JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist']
            // isRightToDelete: JSON.parse(sessionStorage.getItem('DummyLoginData')) != null &&
            //     JSON.parse(sessionStorage.getItem('DummyLoginData'))['accessRights'] != null &&
            //     JSON.parse(sessionStorage.getItem('DummyLoginData'))['accessRights'].find(obj => obj.ModuleId == 1 && obj.ModuleName == "Receipt").isDelete,
        })
        // setTimeout(() => {
console.log('ppppp',this.props.location.state.RefundID)
        this.props.location.state != null && (this.props.location.state.is_view || this.props.location.state.is_update) && this.getRefundDetails()
        //     this.props.location.state != null && (this.props.location.state.From == "PaymentList" && this.props.location.state.is_add) && this.getPendingReceiptDetails()
        // }, 300);

    }

    // auto fill received amount
    autoFillRefundAmount() {
        const { totalAmount, checkedRefundList } = this.state
        console.log(" autoFillRefundAmount", checkedRefundList)
        var balAmount = 0
        for (let i = 0; i < checkedRefundList.length; i++) {
            if (checkedRefundList.length > 1) {
                if (i == 0) {
                    if (totalAmount >= checkedRefundList[i].OutstandingAmount) {
                        checkedRefundList[i].refundAmt = checkedRefundList[i].OutstandingAmount
                        balAmount = totalAmount - checkedRefundList[i].OutstandingAmount
                    }
                } else {
                    if (balAmount >= checkedRefundList[i].OutstandingAmount) {
                        balAmount = balAmount - checkedRefundList[i].OutstandingAmount
                        checkedRefundList[i].refundAmt = checkedRefundList[i].OutstandingAmount
                    } else {
                        checkedRefundList[i].refundAmt = balAmount
                    }
                }
            }
            else {
                if (checkedRefundList.length == 1) {
                    if (checkedRefundList[i].OutstandingAmount >= totalAmount) {
                        checkedRefundList[i].refundAmt = parseInt(totalAmount)
                    } else {
                        checkedRefundList[i].refundAmt = parseInt(checkedRefundList[i].OutstandingAmount)
                    }
                }
            }
        }
    }

    // handle Proforma invoice
    getRefundList(exhID, refundListFormDetails) {
        const { exhibitorId, isUpdate, isView, checkedRefundList } = this.state
        // console.log("proInvoices", exhibitorId)
        this.setState({ isLoading: true, refundTypeList: [] })
        this.props.get_refund_type_amount_action(exhID)
            .then((data) => {
                if (data.error != 1) {
                    var list = []
                    // console.log("proInvoices1111111111", data.data)
                    var receiptIDs = []
                    // if (data.data.length > 0) {
                    //     receiptIDs = data.data[0].ReceiptIDs.split(',')
                    // }

                    // }, 500);
                    console.log(list, 'ooo', refundListFormDetails)
                    var refundList = []
                    if (isView || isUpdate) {
                        refundListFormDetails.map(data => {
                            var mapData = {
                                // 'id': data.ReceiptIDs,
                                // "service": data.ServiceName,
                                // "taxType": data.Tax_Type,
                                "cgst": data.CGST ?? 0,
                                "sgst": data.SGST ?? 0,
                                "igst": data.IGST ?? 0,
                                // "totalDue": data.TotalAmount,
                                // "currPend": data.Pending,
                                // "no": data.refundTypeListoice_No,
                                // "Date": data.refundTypeListoice_Date,
                                'RefundTypeID': data.RefundTypeID,
                                'RefundTypeName': data.RefundTypeName ?? '',
                                "Amount": data.Totalamount,
                                "isSelected": 1,
                                "refundAmt": data.ReceivedAmount,
                                'OutstandingAmount': data.Totalamount,
                                'receiptID': data.ReceiptID
                            }
                            list.push(mapData)
                        })

                        this.setState({ isLoading: false, checkedRefundList: list },()=>{
                            this.handleOnAccAmt()
                        })
                    } else {
                        data.data.map((data, i) => {
                            var mapData = {
                                // 'id': data.ReceiptIDs,
                                // "service": data.ServiceName,
                                // "taxType": data.Tax_Type,
                                "cgst": data.CGST ?? 0,
                                "sgst": data.SGST ?? 0,
                                "igst": data.IGST ?? 0,
                                // "totalDue": data.TotalAmount,
                                // "currPend": data.Pending,
                                // "no": data.refundTypeListoice_No,
                                // "Date": data.refundTypeListoice_Date,
                                'RefundTypeID': data.RefundTypeID,
                                'RefundTypeName': data.RefundTypeName,
                                "Amount": data.TotalAmount,
                                "isSelected": 0,
                                "refundAmt": "",
                                'OutstandingAmount': data.TotalAmount,
                                'receiptID': data.ReceiptIDs
                            }
                            list.push(mapData)
                        })
                        // this.setState({ refundTypeList: refundTypeList })
                        // setTimeout(() => {
                        this.setState({ isLoading: false, refundTypeList: list }, () => { })
                    }


                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            }).catch((e) => {
                console.log(e)
            })
    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID)
            .then((data) => {
                if (data.error != 1) {
                    // voucher list
                    if (actionID === voucherTypeActionId) {
                        console.log("voucher type data", data.data)
                        this.setState({
                            voucherTypeList: data.data, isLoading: false,
                        })
                    }
                    // exhibitor list
                    // if (actionID === exhibitorActionId) {
                    //     console.log("exhibitor data", data.data)
                    //     this.setState({
                    //         exhibitorList: data.data, isLoading: false,
                    //     })
                    // }
                    // // exhibitor list for update and view
                    // if (actionID === exhibitorActionId2) {
                    //     console.log("exhibitor data", data.data)
                    //     this.setState({
                    //         exhibitorList: data.data, isLoading: false,
                    //     })
                    // }
                    // bankAccount list
                    if (actionID === bankAccountActionId) {
                        console.log("bankAccount data", data.data)
                        this.setState({
                            bankAccountList: data.data, isLoading: false,
                        })
                    }

                    // OtherCharges list
                    if (actionID === paymentModeActionId) {
                        console.log("Payment Mode data", data.data)
                        this.setState({
                            paymentModeList: data.data, isLoading: false,
                        })
                    }
                    if (actionID === refundExhibitorDropdown) {
                        console.log("Payment Mode data", data.data)
                        this.setState({
                            exhibitorList: data.data, isLoading: false,
                        })
                    }
                    // otherChargesAccountType list
                    if (actionID === otherChargesAccountTypeActionId) {
                        console.log("otherChargesAccountTypeActionId data ===========", data.data)
                        this.setState({
                            otherChargesAccountTypeList: data.data, isLoading: false,
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // get Receipt details
    getRefundDetails() {
        const { otherChargesList, checkedRefundList, refundTypeList, exhibitorList } = this.state
        this.setState({ isLoading: true, otherChargesList: [] })
        var RefundID = this.props.location.state.RefundID
        console.log("receiptId=========/////////////////////", RefundID)
        this.props
            .refund_details_action(RefundID)
            .then((data) => {
                if (data.error != 1) {
                    console.log("Receipt Details =================", data.data)
                    var otherchargeslist = []
                    if (data.data.otherCharges.length != 0) {
                        otherChargesList.shift();
                    }
                    data.data.otherCharges != null && data.data.otherCharges.map(data => {
                        otherchargeslist = {
                            'account': data.AccountTypeID,
                            'amount': data.Amount
                        }
                        otherChargesList.push(otherchargeslist)
                    })

                    this.selectExhibitor('', data.data.refundTypeList, data.data.description[0]['ExhRegistrationID'])

                    this.setState({
                        voucherTypeId: data.data.description[0]['VoucherTypeID'] != null ? data.data.description[0]['VoucherTypeID'] : '',
                        // exhibitorId: data.data.description[0]['ExhRegistrationID'] != null ? data.data.description[0]['ExhRegistrationID'] : '',
                        bankAccountId: data.data.description[0]['BankID'] != null ? data.data.description[0]['BankID'] : '',
                        currencyId: data.data.description[0]['CurrencyID'] != null ? data.data.description[0]['CurrencyID'] : '',
                        currency: data.data.description[0]['Currency'] != null ? data.data.description[0]['Currency'] : '',
                        conRate: data.data.description[0]['ConversionRate'] != null ? data.data.description[0]['ConversionRate'] : '',
                        availableRefundAmount: data.data.description[0]['AvailableRefundAmount'] != null ? data.data.description[0]['AvailableRefundAmount'] : '',
                        RefundAmount: data.data.description[0]['RefundAmount'] != null ? data.data.description[0]['RefundAmount'] : '',
                        onAccAmt: data.data.description[0]['OnAccAmount'] != null ? data.data.description[0]['OnAccAmount'] : '',
                        totalAmount: data.data.description[0]['TotalAmount'] != null ? data.data.description[0]['TotalAmount'] : '',
                        narration: data.data.description[0]['Narration'] != null ? data.data.description[0]['Narration'] : '',
                        RefundNo: data.data.description[0]['RefundNo'] != null ? data.data.description[0]['RefundNo'] : '',
                        RefundDate: data.data.description[0]['RefundDate'] != null ? new Date(data.data.description[0]['RefundDate']) : '',
                        pymtModeId: data.data.description[0]['PaymentModeID'] != null ? data.data.description[0]['PaymentModeID'] : '',
                        PaymentDoneDate: data.data.description[0]['PaymentDoneDate'] != null ? new Date(data.data.description[0]['PaymentDoneDate']) : '',
                        instrNo: data.data.description[0]['InstrumentNo'] != null ? data.data.description[0]['InstrumentNo'] : '',
                        instruDate: data.data.description[0]['InstrumentDate'] != null ? new Date(data.data.description[0]['InstrumentDate']) : '',
                        bankName: data.data.description[0]['BankName'] != null ? data.data.description[0]['BankName'] : '',
                        branchName: data.data.description[0]['BranchName'] != null ? data.data.description[0]['BranchName'] : '',
                        // checkedRefundList: data.data.proformaList != null ? data.data.proformaList : '',
                        otherChargesList: otherChargesList,
                        isLoading: false
                    })

                    setTimeout(() => {
                        // this.calculateTotals()
                        // this.totalAmtsVal()
                    }, 500);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // Get Pending Receipt Details
    getPendingReceiptDetails() {
        this.setState({ isLoading: true })
        var AccpaymentId = this.props.location.state.AccountPaymentID
        console.log("AccountPaymentID=========/////////////////////", AccpaymentId)
        this.props
            .pendingReceiptDetails_action(AccpaymentId)
            .then((data) => {
                if (data.error != 1) {
                    console.log("Receipt Details =================", data.data)




                    this.selectExhibitor('', data.data.proformaList, data.data.description[0]['exhibitorAccountID'])

                    setTimeout(() => {
                        const { refundTypeList } = this.state
                        var PIList = refundTypeList
                        console.log("Proforma Inv List------------>", refundTypeList)
                        console.log("Proforma Inv List00000000------------>", PIList)
                        PIList.length > 0 && PIList.map((item) => {
                            data.data.proformaList.length > 0 && data.data.proformaList.map((data) => {
                                if (item.id == data.refundTypeListoiceID) {
                                    item.isSelected = 1
                                }
                            })
                        })
                        var checkinv = PIList.filter(obj => obj.isSelected = 1)
                        this.setState({ checkedRefundList: checkinv })
                        console.log("Proforma Inv List111111------------>", PIList)
                    }, 300);



                    this.setState({
                        currencyId: data.data.description[0]['CurrencyID'] != null ? data.data.description[0]['CurrencyID'] : '',
                        currency: data.data.description[0]['Currency'] != null ? data.data.description[0]['Currency'] : '',
                        availableRefundAmount: data.data.description[0]['GrossAmount'] != null ? parseFloat(data.data.description[0]['GrossAmount']).toFixed(2) : '',
                        RefundAmount: data.data.description[0]['RefundAmount'] != null ? parseFloat(data.data.description[0]['RefundAmount']).toFixed(2) : '',
                        totalAmount: data.data.description[0]['NetAmount'] != null ? data.data.description[0]['NetAmount'] : '',
                        pymtModeId: data.data.description[0]['InstrumentTypeID'] != null ? data.data.description[0]['InstrumentTypeID'] : '',
                        instrNo: data.data.description[0]['InstrumentNo'] != null ? data.data.description[0]['InstrumentNo'] : '',
                        instruDate: data.data.description[0]['InstrumentDate'] != null ? new Date(data.data.description[0]['InstrumentDate']) : '',
                        bankName: data.data.description[0]['Bank'] != null ? data.data.description[0]['Bank'] : '',
                        branchName: data.data.description[0]['Branch'] != null ? data.data.description[0]['Branch'] : '',
                        accPaymentId: data.data.description[0]['AccountPaymentID'] != null ? data.data.description[0]['AccountPaymentID'] : '',
                        // exhibitorId: data.data.description[0]['exhibitorAccountID'] != null ? data.data.description[0]['exhibitorAccountID'] : '',

                        isLoading: false

                    })


                    setTimeout(() => {
                        // this.calculateTotals()
                        // this.handleTotalAmt()
                        this.handleOnAccAmt()
                    }, 500);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // Update Receipt Entry
    saveRefundEntry(e) {
        e.preventDefault()
        const { voucherTypeId, exhibitorId, exhibitorAccountID, bankAccountId, currency, conRate, availableRefundAmount, RefundAmount, onAccAmt,
            totalAmount, narration, RefundNo, RefundDate, pymtModeId, instrNo, instruDate, branchName, bankName, voucherTypeErr,
            exhibitorErr, bankAccountErr, refundTypeList, availableRefundAmountErr, RefundAmountErr, pymtModeErr, checkedRefundList, otherChargesList,
            currencyId, isGenVoucherDisabled, isTotalsMatched, PaymentDoneDate, accPaymentId } = this.state
        //Validate Inpute Feilds on save Refund
        this.inputValidate()
        this.otherInputValidate()
        var totalsMatched = true
        if (parseFloat(RefundAmount).toFixed(2) > parseFloat(this.state.availableRefundAmount)) {
            this.setState({ RefundAmountErr: "Refund Amount should be less than total available refund amount", totalAmount: "", onAccAmt: '' })
            totalsMatched = false
        }

        var isSameAsOutstanding = true
        for (var i = 0; i < checkedRefundList.length; i++) {
            if (checkedRefundList[i].refundAmt != checkedRefundList[i].OutstandingAmount) {
                isSameAsOutstanding = false
                break
            }
        }
        // this.setState({ totalsErr: "Total Amounts Are Not matched", isTotalsMatched: false })
        if (!isSameAsOutstanding) {
            var totalRefunding = 0
            for (var i = 0; i < checkedRefundList.length; i++) {
                totalRefunding = parseFloat(totalRefunding) + parseFloat(checkedRefundList[i].refundAmt)
            }
            if (parseFloat(totalRefunding).toFixed(2) != totalAmount) {
                this.setState({ totalAmountErr: "Total Amounts Are Not matched", isTotalsMatched: false })
                totalsMatched = false
            } else {
                this.setState({ totalAmountErr: "", isTotalsMatched: true })
            }
            console.log(parseFloat(totalRefunding).toFixed(2), totalAmount)
        }
        if (voucherTypeId !== "" && exhibitorId !== "" && bankAccountId !== "" && RefundAmount.toString() != "") {
            if (voucherTypeErr == "" && exhibitorErr == "" && bankAccountErr == "" && RefundAmountErr == "") {
                if (checkedRefundList.length > 0) {
                    if (totalsMatched) {
                        // console.log("xyxyxyxyxyxyxyyxyxyxyxyxy")
                        if (isGenVoucherDisabled) {
                            if (pymtModeId != "" && pymtModeId != "Select" && pymtModeErr == "") {
                                // CheckInv List data Preparing to store
                                var refundTypeAmountList = []
                                refundTypeList.map((data) => {
                                    if (data.isSelected) {
                                        refundTypeAmountList.push(data)
                                    }
                                })
                                // otherCharges List data Preparing
                                var OtherChargesListData = []
                                otherChargesList.map((data) => {
                                    var mapdata = {
                                        'Account_type_ID': parseInt(data.account),
                                        'Amount': !isNaN(data.amount) && data.amount != "" &&
                                            data.amount != null ? parseFloat(data.amount).toFixed(2) : 0
                                    }
                                    OtherChargesListData.push(mapdata)
                                })
                                console.log("Othercharges LIst ---------> ", otherChargesList)
                                this.setState({ isLoading: true })
                                this.props.add_refund_action(voucherTypeId, bankAccountId, currencyId, conRate, availableRefundAmount, RefundAmount,
                                    totalAmount, RefundNo, RefundDate, pymtModeId, PaymentDoneDate, instrNo, instruDate, bankName,
                                    branchName, narration, refundTypeAmountList, OtherChargesListData, accPaymentId, exhibitorId)
                                    .then((data) => {
                                        if (data.error != 1) {
                                            this.setState({ isLoading: false })
                                            toastSuccess("Refund Save Succesfully")
                                            setTimeout(() => {
                                                this.props.navigate('/refundList')
                                            }, 2000);

                                        } else {
                                            this.setState({ isLoading: false })
                                            toastError(data.msg)
                                        }
                                    }).catch((e) => {
                                        console.log(e)
                                    })
                            }
                        } else {
                            toastError("Please Generate Voucher")
                        }
                    } else {
                        toastError("Total Amounts Not Matched")
                    }
                } else {
                    toastError("Please Select Refund type")
                }
            } else {
                toastError("Please Fill Mandatory Fields")
            }
        }
    }

    // Update RefundID Entry
    updateRefundEntry() {
        const { voucherTypeId, exhibitorId, exhibitorAccountID, bankAccountId, availableRefundAmount, RefundAmount, pymtModeId, voucherTypeErr, exhibitorErr,
            availableRefundAmountErr, RefundAmountErr, pymtModeErr, bankAccountErr, currency, conRate, onAccAmt,
            totalAmount, refundTypeList, otherChargesList, ledgerList, subLedgerList, narration, RefundNo, RefundDate, instrNo,
            instruDate, bankName, branchName, checkedRefundList, currencyId, RefundID, accPaymentId, isGenVoucherDisabled, isTotalsMatched, PaymentDoneDate } = this.state
        // const { totalReceivedAmt } = this.calculateTotals()
        //Validate Inpute Feilds on Update RefundID RefundID
        this.inputValidate()
        this.otherInputValidate()
        var totalsMatched = true
        if (parseFloat(RefundAmount).toFixed(2) > parseFloat(this.state.availableRefundAmount)) {
            this.setState({ RefundAmountErr: "Refund Amount should be less than total available refund amount", totalAmount: "", onAccAmt: '' })
            totalsMatched = false
        }

        var isSameAsOutstanding = true
        for (var i = 0; i < checkedRefundList.length; i++) {
            if (checkedRefundList[i].refundAmt != checkedRefundList[i].OutstandingAmount) {
                isSameAsOutstanding = false
                break
            }
        }
        // this.setState({ totalsErr: "Total Amounts Are Not matched", isTotalsMatched: false })
        if (!isSameAsOutstanding) {
            var totalRefunding = 0
            for (var i = 0; i < checkedRefundList.length; i++) {
                totalRefunding = parseFloat(totalRefunding) + parseFloat(checkedRefundList[i].refundAmt)
            }
            if (parseFloat(totalRefunding).toFixed(2) != totalAmount) {
                this.setState({ totalAmountErr: "Total Amounts Are Not matched", isTotalsMatched: false })
                totalsMatched = false
            } else {
                this.setState({ totalAmountErr: "", isTotalsMatched: true })
            }
            console.log(parseFloat(totalRefunding).toFixed(2), totalAmount)
        }

        if (voucherTypeId !== "" && exhibitorId !== "" && bankAccountId !== "" && RefundAmount.toString() != "") {
            if (voucherTypeErr == "" && exhibitorErr == "" && bankAccountErr == "" && RefundAmountErr == "") {
                if (checkedRefundList.length > 0) {
                    if (totalsMatched) {
                        // console.log("xyxyxyxyxyxyxyyxyxyxyxyxy")
                        if (isGenVoucherDisabled) {
                            if (pymtModeId != "" && pymtModeId != "Select" && pymtModeErr == "") {
                                // CheckInv List data Preparing to store
                                var refundTypeAmountList = []
                                refundTypeList.map((data) => {
                                    if (data.isSelected) {
                                        refundTypeAmountList.push(data)
                                    }
                                })
                                // otherCharges List data Preparing
                                var OtherChargesListData = []
                                otherChargesList.map((data) => {
                                    var mapdata = {
                                        'Account_type_ID': parseInt(data.account),
                                        'Amount': !isNaN(data.amount) && data.amount != "" &&
                                            data.amount != null ? parseFloat(data.amount).toFixed(2) : 0
                                    }
                                    OtherChargesListData.push(mapdata)
                                })
                                this.setState({ isLoading: true })
                                this.props.refund_update_action(RefundID, voucherTypeId, accPaymentId, bankAccountId, currencyId, conRate, availableRefundAmount, RefundAmount, totalAmount, RefundNo, RefundDate, PaymentDoneDate, pymtModeId, instrNo, instruDate, bankName, branchName, narration, refundTypeAmountList, OtherChargesListData, exhibitorId)
                                    .then((data) => {
                                        if (data.error != 1) {
                                            this.setState({ isLoading: false })
                                            toastSuccess("Receipt Updated Succesfully")
                                            setTimeout(() => {
                                                this.props.navigate('/receiptlist')
                                            }, 2000);
                                        } else {
                                            this.setState({ isLoading: false })
                                        }
                                    }).catch((error) => {
                                        console.log(error)
                                    })
                            }
                        } else {
                            toastError("Please Generate Voucher")
                        }
                    } else {
                        toastError("Total Amounts Not Matched")
                    }
                } else {
                    toastError("Please Select Refund Type")
                }
            } else {
                toastError("Please Fill Mandatory Fields")
            }
        }
    }

    // On Cancel RefundID Entry Navigating To Back Page
    onCancleRefundEntry(e) {
        e.preventDefault()
        this.props.navigate(-1)
    }

    // handle checked and Unchecked proforma Refund Type
    handleCheckRefund(e, id) {
        e.preventDefault()
        const { refundTypeList, checkedRefundList } = this.state
        const checked = e.target.checked
        console.log("0909090 checked////", e.target.type)
        console.log("0909090 refundTypeList////", refundTypeList)

        refundTypeList.map(data => {
            if (data.receiptID === id) {
                console.log('handleCheckRefundc id', id)
                if (checked) {
                    data.isSelected = 1
                }
                else if (e.target.type == undefined) {
                    data.isSelected = !data.isSelected
                }
                else {
                    data.isSelected = 0
                    this.setState({ checkedAll: false })
                }
            }
        })
        // this.setState({})
        console.log("777777777", checkedRefundList)
        // Select All Proforma Refund Type
        const AllSelected = refundTypeList.every(item => item.isSelected == 1)
        if (AllSelected) {
            this.setState({ checkedAll: true })
        }
        this.setState({ refundTypeList: refundTypeList })

        // this.calculateTotals()
    }

    // Handle continue on proforma invoice selected
    handleContinue() {
        const { isOpen, checkedRefundList, refundTypeList } = this.state
        var list = [...checkedRefundList];
        console.log('test....', list);
        for (var i = 0; i < refundTypeList.length; i++) {
            if (refundTypeList[i].isSelected == 1) {
                console.log("condition1")
                if (list.length == 0) {
                    list.push(refundTypeList[i])
                } else {
                    var isAvailable = false;
                    for (var j = 0; j < list.length; j++) {
                        if (list[j].receiptID.toString() == refundTypeList[i].receiptID.toString()) {
                            isAvailable = true;
                        }
                    }
                    if (!isAvailable) {
                        list.push(refundTypeList[i])
                    }
                }
            } else {
                for (var k = 0; k < list.length; k++) {
                    if (list[k].receiptID == refundTypeList[i].receiptID) {
                        list.splice(k, 1)
                    }
                }
            }
        }

        this.setState({ isOpen: !isOpen, checkedRefundList: list, totalAmountErr: '' }, () => {
            // console.log("222=======@@@@@@", checkedRefundList)
            this.autoFillRefundAmount()
        })
        setTimeout(() => {
            // this.calculateTotals()
        }, 300);

        setTimeout(() => {
            // const { totalCurrPend } = this.calculateTotals()
            // if (totalCurrPend != 0) {
            this.handleOnAccAmt()
            // } else {
            // this.setState({ onAccAmt: 0.00 })
            // }
        }, 500);
    }

    // handle total amounts validation 
    totalAmtsVal() {
        const { checkedRefundList, totalAmount, onAccAmt, isTotalsMatched } = this.state
        // error show on last index change 
        // const { totalReceivedAmt } = this.calculateTotals();
        // var OnAccTotal = parseFloat(totalReceivedAmt) + parseFloat(onAccAmt)
        // if (totalAmount != OnAccTotal) {
        toastError("Total Amount Not Matched")
        this.setState({ isTotalsMatched: false })
        // } else {
        // this.setState({ totalsErr: "", isTotalsMatched: true })
        // }
    }

    // handle Received Amount 
    handleReceivedAmount(e, id) {
        const { checkedRefundList, totalAmount, receivedAmt, onAccAmt } = this.state
        const value = e.target.value
        // const id = e.target.id
        for (var i = 0; i < checkedRefundList.length; i++) {
            if (checkedRefundList[i].receiptID == id) {
                checkedRefundList[i].refundAmt = value
            }
        }
        var isSameAsOutstanding = true
        for (var i = 0; i < checkedRefundList.length; i++) {
            if (checkedRefundList[i].refundAmt != checkedRefundList[i].OutstandingAmount) {
                isSameAsOutstanding = false
                break
            }
        }
        // this.setState({ totalsErr: "Total Amounts Are Not matched", isTotalsMatched: false })
        if (!isSameAsOutstanding) {
            var totalRefunding = 0
            for (var i = 0; i < checkedRefundList.length; i++) {
                if (checkedRefundList[i].receiptID == id) {
                    totalRefunding = parseFloat(totalRefunding) + parseFloat(value)
                } else {
                    totalRefunding = parseFloat(totalRefunding) + parseFloat(checkedRefundList[i].refundAmt)
                }
            }
            if (parseFloat(totalRefunding).toFixed(2) != totalAmount) {
                this.setState({ totalAmountErr: "Total Amounts Are Not matched", isTotalsMatched: false })
            } else {
                this.setState({ totalAmountErr: "", isTotalsMatched: true })
            }
            console.log(parseFloat(totalRefunding).toFixed(2), totalAmount)
        }
        // const lastIndex = checkedRefundList.length - 1
        // if (checkedRefundList[lastIndex].receiptID == id) {
        //     // const { totalReceivedAmt } = this.calculateTotals()
        //     // var OnAccountTotal = parseFloat(totalReceivedAmt) + parseFloat(onAccAmt)
        //     console.log("AmountOnAcc Total------------->", totalAmount)
        //     if (totalAmount != parseFloat(checkedRefundList[lastIndex].refundAmt).toFixed(2)) {
        //         // checkedRefundList[lastIndex].refundAmt = ''
        //         this.setState({ totalsErr: "Total Amounts Are Not matched", isTotalsMatched: false })
        //         if (checkedRefundList[lastIndex].OutstandingAmount < checkedRefundList[lastIndex].refundAmt) {
        //             console.log("===========///------------->",
        //                 checkedRefundList[lastIndex].OutstandingAmount != checkedRefundList[lastIndex].refundAmt)
        //             checkedRefundList[lastIndex].refundAmtErr = 'Received amount must be less than or equal to pending amount'
        //             this.setState({ totalsErr: "Pending Amounts Are Not matched" })
        //         }
        //     }
        //     else {
        //         this.setState({ totalsErr: "", isTotalsMatched: true })
        //     }
        // }
        this.setState({ checkedRefundList: checkedRefundList }, () => { })
    }

    // Input Feilds Validation
    inputValidate() {
        const { voucherTypeId, exhibitorId, bankAccountId, availableRefundAmount, RefundAmount, pymtModeId, isGenVoucherDisabled } = this.state

        if (voucherTypeId == "" || voucherTypeId == "Select") {
            this.setState({ voucherTypeErr: 'Please Select Voucher Type' })
        }
        if (exhibitorId == "" || exhibitorId == "Select") {
            this.setState({ exhibitorErr: 'Please Select Exhibitor' })
        }
        if (bankAccountId == "" || bankAccountId == "Select") {
            this.setState({ bankAccountErr: 'Please Select Bank Account' })
        }
        // if (availableRefundAmount == "") {
        //     this.setState({ availableRefundAmountErr: 'Please Enter Bank Amount' })
        // }
        if (RefundAmount != null && RefundAmount.toString() == "") {
            this.setState({ RefundAmountErr: 'Please Enter Refund amount' })
        }
        if (isGenVoucherDisabled) {
            if (pymtModeId == "" || pymtModeId == "Select") {
                this.setState({ pymtModeErr: 'Please Select Payment Mode' })
            }
        }
    }

    //on Select All Click
    selectAllInv(e) {
        const { checkedAll, refundTypeList } = this.state
        this.setState({ checkedAll: !checkedAll })
        const checked = e.target.checked
        refundTypeList.map((data) => {
            if (checked) {
                data.isSelected = 1
            } else {
                data.isSelected = 0
            }
        })
        this.setState({ SelectedInv: refundTypeList })
    }

    // other Charges Input Validation
    otherInputValidate() {
        const { otherChargesList, otherChargesBlankItems } = this.state
        var isNotAmountContain = false
        var isOtherChargesAccountContain = false
        otherChargesList.map((data) => {
            if (data.account !== "" && data.account !== "Select") {
                isOtherChargesAccountContain = true
                if (isOtherChargesAccountContain) {
                    if (data.amount == "") {
                        isNotAmountContain = true
                    }
                }
            }
        })
        var blankItems = false
        for (let i = 0; i < otherChargesList.length; i++) {
            if (i !== 0) {
                if (otherChargesList[i].account.length === 0 || otherChargesList[i].account == 'Select') {
                    blankItems = true
                    if (otherChargesList[i].amount == '') {
                        blankItems = true
                        console.log("blankkk--->", blankItems)
                    } else {
                        blankItems = false
                    }
                }
                else {
                    blankItems = false
                    if (otherChargesList[i].amount.length === 0) {
                        blankItems = true
                        console.log("blankkk--->", blankItems)
                    } else {
                        blankItems = false
                    }
                }
            }
        }
        console.log("otherInputVAl===================", otherChargesBlankItems)
        setTimeout(() => {
            this.setState({ otherChargesBlankItems: blankItems })
            if (blankItems) {

                this.setState({ otherChargesListErr: "Please Enter Other charges" })
            } else {
                this.setState({ otherChargesListErr: "" })
            }
        }, 400);
    }

    // On change OtherCharges Input Validation
    handleOtherCharges(index, event) {
        const { otherChargesList } = this.state
        const { name, value } = event.target
        const rowsInput = [...otherChargesList]
        rowsInput[index][name] = value;
        this.setState({ otherChargesList: rowsInput, otherChargesListErr: '' })
        this.otherInputValidate()
        console.log("HandleOtherCharges-------------------->", otherChargesList)
    }

    // OtherCharges Table Row Add
    addTableRows() {
        const { otherChargesList, otherChargesListErr, otherChargesBlankItems } = this.state
        var isBlank = 0
        for (let i = 0; i < otherChargesList.length; i++) {
            if (otherChargesList[i].account == "" || otherChargesList[i].amount == "" || otherChargesList[i].amount == 0) {
                otherChargesList.splice(i, 1)
            }
        }
        if (isBlank == 0) {
            if (otherChargesList[otherChargesList.length - 1]?.amount != 0) {
                if (otherChargesListErr == "" && otherChargesBlankItems == 0) {
                    const rowsInput = {
                        account: "",
                        amount: ""
                    }
                    this.setState({ otherChargesList: [...otherChargesList, rowsInput] })
                }
            }
        }
        this.otherInputValidate()
    }

    //Delete Table Rows from Other charges
    deleteTableRows(index) {
        console.log("DeleteIndex00000000-------->", index)
        const { otherChargesList } = this.state
        // this.otherInputValidate()
        const rows = [...otherChargesList]
        var filterList = []
        if (index != 0) {
            console.log("DeleteIndex-------->", index)
            // rows.splice(index, 1)
            filterList = rows.filter((data, id) => index != id)
            console.log("filterList-------->", rows)
            setTimeout(() => {
                this.setState({ otherChargesList: filterList }, () => {
                    console.log("deleteRow data ----------->", otherChargesList)
                })
            }, 300);
        }
        else {
            this.setState({ otherChargesBlankItems: true })
        }
    }

    //For Calculation All total Amounts in table 
    // calculateTotals() {
    //     try {
    //         const { checkedRefundList } = this.state
    //         let totalAmt = 0;
    //         let totalDueAmt = 0;
    //         let totalIgst = 0;
    //         let totalCurrPend = 0;
    //         let totalReceivedAmt = 0;

    //         checkedRefundList.forEach((data) => {
    //             if (data.isSelected) {
    //                 totalAmt += Number(data.totalDue);
    //                 totalDueAmt += Number(data.Amount);
    //                 totalIgst += Number(data.igst);
    //                 totalCurrPend += Number(data.currPend);
    //                 totalReceivedAmt += Number(data.refundAmt)
    //             }
    //         });
    //         return {
    //             totalAmt,
    //             totalDueAmt,
    //             totalIgst,
    //             totalCurrPend,
    //             totalReceivedAmt
    //         };

    //     } catch (e) {
    //         console.log('exception : ', e);
    //     }

    // }

    // Generate Voucher 
    generateVoucher() {
        const { voucherTypeId, exhibitorId, bankAccountId, currencyId, conRate, availableRefundAmount, RefundAmount, onAccAmt,
            totalAmount, voucherTypeErr, exhibitorErr, bankAccountErr, availableRefundAmountErr, RefundAmountErr,
            otherChargesListErr, otherChargesList, checkedRefundList, refundTypeList, isTotalsMatched, RefundID, exhibitorAccountID } = this.state

        //Validate Inpute Feilds on save RefundID
        this.inputValidate()
        this.otherInputValidate()
        var isNotBlank = false
        var Err = false
        //Checking Total Amount matched 
        var isMatched = false
        // const { totalReceivedAmt } = this.calculateTotals();
        // var OnAccTotal = parseFloat(totalReceivedAmt) + parseFloat(onAccAmt)
        // if (totalAmount != OnAccTotal) {
        // toastError("Total Amount Not Matched")
        this.setState({ isTotalsMatched: false })
        isMatched = false

        if (parseFloat(RefundAmount).toFixed(2) > parseFloat(this.state.availableRefundAmount)) {
            this.setState({ RefundAmountErr: "Refund Amount should be less than total available refund amount", totalAmount: "", onAccAmt: '' })
            Err = true
        }

        var isSameAsOutstanding = true
        for (var i = 0; i < checkedRefundList.length; i++) {
            if (checkedRefundList[i].refundAmt != checkedRefundList[i].OutstandingAmount) {
                isSameAsOutstanding = false
                break
            }
        }
        // this.setState({ totalsErr: "Total Amounts Are Not matched", isTotalsMatched: false })
        if (!isSameAsOutstanding) {
            var totalRefunding = 0
            for (var i = 0; i < checkedRefundList.length; i++) {
                totalRefunding = parseFloat(totalRefunding) + parseFloat(checkedRefundList[i].refundAmt)
            }
            if (parseFloat(totalRefunding).toFixed(2) != totalAmount) {
                this.setState({ totalAmountErr: "Total Amounts Are Not matched", isTotalsMatched: false })
                Err = true
            } else {
                this.setState({ totalAmountErr: "", isTotalsMatched: true })
            }
            console.log(parseFloat(totalRefunding).toFixed(2), totalAmount)
        }

        for (let i = 0; i < otherChargesList.length; i++) {
            if (i !== 0) {
                if (otherChargesList[i].account == "" || otherChargesList[i].amount == "" || otherChargesList[i].amount == 0) {
                    otherChargesList.splice(i, 1)
                }
            }
        }
        if (Err) {
            toastError("Please enter proper data")
        } else {
            if (voucherTypeId !== "" && exhibitorId !== "" && bankAccountId !== ""
                && RefundAmount != null && RefundAmount.toString() != "") {

                if (voucherTypeErr == "" && exhibitorErr == "" && bankAccountErr == "" && RefundAmountErr == "" && otherChargesListErr == "") {
                    if (checkedRefundList.length > 0) {
                        // if (isMatched) {
                        var refundTypeAmountList = []
                        refundTypeList.map((data) => {
                            if (data.isSelected) {
                                refundTypeAmountList.push(data)
                            }
                        })
                        this.setState({ isLoading: true, isReadOnly: true })
                        this.props.generate_refund_voucher_action(bankAccountId, RefundAmount
                            , refundTypeAmountList, otherChargesList, RefundID)
                            .then((data) => {
                                if (data.error != 1) {
                                    //adding some of ledger........................
                                    var ledgerList = data.data.ledger
                                    var ledgerDebitSum = 0
                                    var ledgerCreditSum = 0
                                    var mapLedger;
                                    for (var i = 0; i < ledgerList.length; i++) {
                                        ledgerDebitSum = ledgerDebitSum + ledgerList[i].Debit
                                        ledgerCreditSum = ledgerCreditSum + ledgerList[i].Credit
                                        // if (i == (ledgerList.length - 1)) {
                                        //     mapLedger = {
                                        //         Account: "Total",
                                        //         Credit: ledgerDebitSum,
                                        //         Debit: ledgerCreditSum
                                        //     }
                                        // }
                                    }
                                    if (mapLedger != null) {
                                        ledgerList.push(mapLedger)
                                    }
                                    //adding some of sub-ledger........................
                                    var subledgerList = data.data.subledger
                                    var subledgerDebitSum = 0
                                    var subledgerCreditSum = 0
                                    var mapSubLedger;
                                    for (var i = 0; i < subledgerList.length; i++) {
                                        subledgerDebitSum = subledgerDebitSum + subledgerList[i].Debit
                                        subledgerCreditSum = subledgerCreditSum + subledgerList[i].Credit
                                        if (i == (subledgerList.length - 1)) {
                                            mapSubLedger = {
                                                Account: "Total",
                                                Credit: subledgerDebitSum,
                                                Debit: subledgerCreditSum
                                            }
                                        }
                                    }
                                    if (mapSubLedger != null) {
                                        subledgerList.push(mapSubLedger)
                                    }

                                    this.setState({
                                        isLoading: false,
                                        ledgerList: ledgerList,
                                        subLedgerList: subledgerList
                                    })
                                    this.toggleButtons()
                                } else {
                                    this.setState({ isLoading: false })
                                    toastError(data.msg)
                                }
                            }).catch((e) => {
                                console.log(e)
                            })
                        // } else {
                        //     if (checkedRefundList[checkedRefundList.length - 1]['refundAmt'] == '') {
                        //         toastError("Please Enter Received Amount")
                        //     } else {
                        //         toastError("Total Amounts Not Matched")
                        //     }
                        // }
                    } else {
                        toastError("Please Select Refund Type")
                    }
                }
                else {
                    toastError("Please Fill Mandatory Fields")
                }
            }
        }
    }

    // on clear Voucher 
    clearVoucher() {
        this.setState({ isReadOnly: false, ledgerList: [], subLedgerList: [] })
        this.toggleButtons()
    }

    // Select Poforma Refund Type Modal toggle
    toggleModal() {
        const { isOpen, exhibitorId } = this.state
        if (exhibitorId != "" && exhibitorId != "Select") {
            this.setState({ isOpen: !isOpen })
        } else {
            toastError("Please Select Exhibitor")
        }
    }

    // Delete RefundID Modal Toggle
    toggleDelModal() {
        const { isDelOpen } = this.state
        this.setState({ isDelOpen: !isDelOpen })
    }

    //prevent Negative values in number inputs
    preventNegative(e) {
        (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
        if (e.code === 'Minus') {
            e.preventDefault();
        }
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            const currentVal = parseFloat(e.target.value);
            const increment = e.key === "ArrowUp" ? 1 : -1;
            const newVal = currentVal + increment;
            if (newVal < 0) {
                e.preventDefault()
            }
        }
    }

    handlePaste = (event) => {
        const pastedValue = event.clipboardData.getData('text/plain');
        const sanitizedValue = pastedValue.replace(/[-.eE]/g, ''); // Remove '-', '.', 'e', 'E' characters

        // Replace the input value with the sanitized value
        document.execCommand('insertText', false, sanitizedValue);

        event.preventDefault(); // Prevent the default paste behavior
    }

    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    //handle exhibitor
    selectExhibitor(e, refundTypeListfromDetails, value) {
        // console.log('selectExhibitor entry : ', refundListFormDetails, ' ', value);
        const { isAdd, exhibitorList, refundTypeList } = this.state
        var code = ''
        var exhID = ''
        if (e) {
            e.preventDefault()
        }
        console.log("selectExhibitor+++++++")
        const { currency } = this.state
        this.setState({ checkedRefundList: [], refundTypeList: [], checkedAll: false })
        if (isAdd) {
            exhID = e.target.value
            this.setState({ exhibitorId: e.target.value, exhibitorErr: "" })
            exhibitorList.length > 0 && exhibitorList.map((data) => {
                if (data.ExhRegistrationID == exhID) {
                    code = data.AccountID
                    this.setState({
                        currency: data.Currency, currencyId: data.CurrencyID,
                        availableRefundAmount: parseFloat(data.TotalPayable).toFixed(2)
                    })
                }
            })
            // this.setState({ exhibitorAccountID: code })
            // this.refundTypeListoice(code, refundListFormDetails, exhID)
            //
            this.getRefundList(exhID, refundTypeListfromDetails)
            this.generateRefundNo(code)
        } else {
            exhibitorList.length > 0 && exhibitorList.map((data) => {
                if (data.ExhRegistrationID == value) {
                    // exhID = data.ExhRegistrationID
                    code = data.AccountID
                    this.setState({
                        currency: data.Currency, currencyId: data.CurrencyID,
                        availableRefundAmount: parseFloat(data.TotalPayable).toFixed(2)
                    })

                }
            })
            this.setState({
                exhibitorId: value,
                exhibitorErr: "",
            })
            console.log('exhhhhhh', code, value)
            this.getRefundList(value, refundTypeListfromDetails)
            // this.generateReceiptNo(value)
        }
    }

    //handle Bank Amount
    handleavailableRefundAmount(e) {
        this.setState({ availableRefundAmount: parseFloat(e.target.value).toFixed(2) })
        // this.handleTotalAmt()
        // const { totalCurrPend } = this.calculateTotals()
        setTimeout(() => {
            // if (totalCurrPend != 0) {
            this.handleOnAccAmt()
            // }
        }, 500);
    }

    // handle Refund Amount
    handleRefundAmount(e) {
        if (parseFloat(e.target.value).toFixed(2) <= parseFloat(this.state.availableRefundAmount)) {
            this.setState({ RefundAmount: parseFloat(e.target.value).toFixed(2), RefundAmountErr: "", totalAmount: parseFloat(e.target.value).toFixed(2) })
            // this.handleTotalAmt()
            // const { totalCurrPend } = this.calculateTotals()
            setTimeout(() => {
                // if (totalCurrPend != 0) {

                this.handleOnAccAmt()
                // }
            }, 500);
        } else {
            this.setState({ RefundAmountErr: "Refund Amount should be less than or equal to Available Refund amount", totalAmount: '', onAccAmt: '' })
            toastError("Refund Amount should be less than or equal to Available Refund amount")
        }
    }

    // handle total amount availableRefundAmount + RefundAmount
    // handleTotalAmt() {
    //     const { availableRefundAmount, RefundAmount } = this.state
    //     console.log("0202020202", availableRefundAmount, RefundAmt)
    //     var bankAmt = availableRefundAmount != "" ? availableRefundAmount : "0"
    //     var RefundAmt = RefundAmount != "" ? RefundAmount : "0"
    //     console.log("010101010", bankAmt, RefundAmt)
    //     if (bankAmt != "" && RefundAmt != "") {

    //         const totalCal = parseFloat(bankAmt) + parseFloat(RefundAmt)
    //         this.setState({ totalAmount: totalCal.toFixed(2), isTotalAmountAvailable: totalCal > 0 ? true : false }, () => {
    //             console.log("totalCal ", totalCal.toFixed(2))
    //         })
    //     } else {
    //         this.setState({ totalAmount: "", isTotalAmountAvailable: false })

    //     }
    // }

    //toggle generate voucher and clear Voucher buttons
    toggleButtons() {
        const { isGenVoucherDisabled } = this.state
        this.setState({ isGenVoucherDisabled: !isGenVoucherDisabled })
    }

    // handle On Acc Amt 
    handleOnAccAmt() {
        const { availableRefundAmount, RefundAmount, onAccAmt } = this.state
        // const { totalCurrPend } = this.calculateTotals()
        console.log("OnAccAmt", availableRefundAmount, RefundAmount)
        const CalOnAccAmt = parseFloat(availableRefundAmount) - parseFloat(RefundAmount)
        if (CalOnAccAmt >= 0) {
            this.setState({ onAccAmt: CalOnAccAmt.toFixed(2) }, () => {
            })
        } else {
            this.setState({ onAccAmt: 0.00 })
        }
    }

    //For Ledger Table Create 
    createLedgerTable() {
        const { ledgerList } = this.state
        var list = [...ledgerList]
        var ledgerColumnArray = []
        var ledgerRowData = []
        // saving all keys of ledger data
        list.map((data, index) => {
            if (index == 0) {
                Object.keys(data).map((key, i) => {
                    ledgerColumnArray.push(key)
                })
            }
        })
        // saving all row data 
        list.map((data) => {
            ledgerRowData.push(data)
        })
        return (
            <div className="col-md-6">
                <p className="mb-0">
                    <strong>Ledger</strong>
                </p>
                <div className="table-responsive mb-1">
                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                        <thead>
                            <tr key={Math.random()} >
                                {
                                    ledgerColumnArray.length > 0 && ledgerColumnArray.map((data, index) => (
                                        <th>{data}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {console.log('oooo', ledgerRowData)}
                            {
                                ledgerRowData.length > 0 && ledgerRowData.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.Account}</td>
                                        <td>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={389.0}
                                                value={data.Debit}
                                                disabled={true}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={0.0}
                                                value={data.Credit}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    //create sub Ledger Table
    createSubLedgerTable() {
        const { subLedgerList } = this.state
        var list = [...subLedgerList]
        var subLedgerColumnArray = []
        var subLedgerRowData = []
        // saving all keys of ledger data
        list.map((data, index) => {
            if (index == 0) {
                Object.keys(data).map((key, i) => {
                    subLedgerColumnArray.push(key)
                })
            }
        })
        // saving All row data
        list.map((data) => {
            subLedgerRowData.push(data)
        })
        return (
            <div className="col-md-6">
                <p className="mb-0">
                    {" "}
                    <strong>Sub Ledger</strong>
                </p>
                <div className="table-responsive mb-3">
                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                        <thead>
                            <tr key={Math.random()}>
                                {
                                    subLedgerColumnArray.length > 0 && subLedgerColumnArray.map((data) => (
                                        <th>{data}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                subLedgerRowData.length > 0 && subLedgerRowData.map((data, index) => (
                                    <tr>
                                        <td>{data.Account}</td>
                                        <td>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={0.0}
                                                value={data.Debit}
                                                disabled={true}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={330.0}
                                                value={data.Credit}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        )

    }

    // generate Receipt No 
    generateRefundNo(id) {
        const { exhibitorId } = this.state
        this.setState({ isLoading: true })
        this.props.refundNo_action(id)
            .then((data) => {
                console.log("Receipt No--------------> ", data)
                if (data.error != 1) {
                    console.log("Receipt No--------------> ", data.data['RefundNo'])
                    this.setState({ RefundNo: data.data['RefundNo'], isLoading: false })
                } else {
                    toastError(data.msg)
                    this.setState({ isLoading: false })
                }
            })
    }

    // handle RefundID Delete"
    handleDelete(e) {
        e.preventDefault()
        const { isDelOpen, RefundID } = this.state
        var Id = this.props.location.state.RefundID
        console.log("idddddddddddd 2222222222", Id)

        this.setState({ isLoading: true })
        this.props.delete_refund_action(Id)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Refund Deleted SuccessFully")
                    this.setState({ isLoading: false, isDelOpen: !isDelOpen })
                    setTimeout(() => {
                        this.props.navigate('/refundList')
                    }, 2000);
                } else {
                    toastError(data.msg)
                    this.setState({ isLoading: false })
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    // On Change Payment Received Date
    onPaymentDoneDate(datetimeZone, date) {
        const adjustedDate = new Date(date - (datetimeZone * 60 * 1000))
        this.setState({ PaymentDoneDate: adjustedDate })
    }

    getUpdateAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '2');
        if (moduleAccess) {
            return moduleAccess.UpdateAccess;
        } else {
            return false
        }
        return false;
    }

    getWriteAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '2');
        if (moduleAccess) {
            return moduleAccess.WriteAccess;
        } else {
            return false
        }
        return false;
    }

    getDeleteAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '2');
        if (moduleAccess) {
            return moduleAccess.DeleteAccess;
        } else {
            return false
        }
        return false;
    }


    render() {
        const { isLoading, refundTypeList, exhibitorId, currency, conRate, availableRefundAmount, RefundAmount, onAccAmt, totalAmount,
            RefundNo, RefundDate, instrNo, instruDate, branchName, bankName, voucherTypeErr, exhibitorErr, bankAccountErr,
            availableRefundAmountErr, RefundAmountErr, pymtModeErr, voucherTypeList, exhibitorList, bankAccountList, otherChargesList,
            paymentModeList, otherChargesAccountList, otherChargesListErr, isOpen, currentDate, checkedRefundList, checkedAll,
            totalsErr, isView, isReadOnly, isGenVoucherDisabled, isAdd, isUpdate, isDelete, isDelOpen,
            otherChargesAccountTypeList, voucherTypeId, bankAccountId, narration, pymtModeId, isTotalAmountAvailable, PaymentDoneDate, from } = this.state
        const { totalAmt, totalDueAmt, totalIgst, totalCurrPend, totalReceivedAmt } = this.state
        var totalAmountCal = 0
        var totalOutstandCal = 0
        var totalReceivedAmtCal = 0
        if (checkedRefundList.length > 0) {
            checkedRefundList.forEach(item => {
                totalAmountCal = parseFloat(totalAmountCal) + parseFloat(item.Amount)
                totalOutstandCal = parseFloat(totalOutstandCal) + parseFloat(item.OutstandingAmount)
                totalReceivedAmtCal = parseFloat(totalReceivedAmtCal) + parseFloat(item.refundAmt)
            })
        }
        // console.log(checkedRefundList)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {from != '' && from == 'exhibitor' ? (
                    <ExhibitorHeader />
                ) : (
                    <HeaderWithLogo />
                )}
                {from != '' && from == 'exhibitor' ? (
                    <ExhibitorDrawer />
                ) : (
                    <AdminDrawer />
                )}

                <div className="main-content with-dr-container com-height">
                    <div className="main-container">
                        <div className="row">
                            <div className="step-name">
                                <p>Refund Entry</p>
                            </div>
                        </div>
                    </div>
                    <div className="main-container p-2 mt-1">
                        <div className="row ">
                            <div className="col">
                                <label>Voucher Type <span style={{ color: 'red' }}>*</span></label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    style={{ textAlign: "left" }}
                                    value={voucherTypeId}
                                    disabled={isView || isReadOnly || isUpdate}
                                    onChange={(e) => this.setState({ voucherTypeId: e.target.value, voucherTypeErr: "" })}
                                >
                                    <option>Select</option>
                                    {voucherTypeList.length > 0 && voucherTypeList.map(data => (
                                        <option key={data.VoucherTypeID} value={data.VoucherTypeID}>{data.VoucherTypeName}</option>
                                    ))}
                                </select>
                                {<span className='text-danger text-start text-left d-block mt-1'>{voucherTypeErr}</span>}
                            </div>
                            <div className="col">
                                <label>Exhibitor <span style={{ color: 'red' }}>*</span></label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    style={{ textAlign: "left" }}
                                    value={exhibitorId}
                                    disabled={isView || isReadOnly || isUpdate}
                                    onChange={(e) => this.selectExhibitor(e, [], '')}
                                >
                                    <option>Select</option>
                                    {exhibitorList.length > 0 && exhibitorList.map((data) => (
                                        <option key={data.ExhRegistrationID} value={data.ExhRegistrationID}
                                        >{data.Description}</option>
                                    ))}
                                </select>{<span className='text-danger text-start text-left d-block mt-1'>{exhibitorErr}</span>}
                            </div>
                            <div className="col">
                                <label>Bank Account <span style={{ color: 'red' }}>*</span></label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    style={{ textAlign: "left" }}
                                    value={bankAccountId}
                                    disabled={isView || isReadOnly}
                                    onChange={(e) => this.setState({ bankAccountId: e.target.value, bankAccountErr: "" })}
                                >
                                    <option>Select</option>
                                    {bankAccountList.length > 0 && bankAccountList.map((data) => (
                                        <option key={data.BankID} value={data.BankID}>{data.Name}</option>
                                    ))}
                                </select>{<span className='text-danger text-start text-left d-block mt-1'>{bankAccountErr}</span>}
                            </div>
                            <div className="col">
                                <label>Currency</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom03"
                                    disabled={true}
                                    value={currency}
                                />
                            </div>
                            <div className="col">
                                <label>Con.Rate</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom03"
                                    disabled={true}
                                    value={conRate}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-xl-2">
                                <label>Available Refund Amount <span style={{ color: 'red' }}>*</span> </label>
                                <input type="number" className="form-control" name="availableRefundAmount" value={availableRefundAmount}
                                    disabled
                                    maxLength={'16'}

                                    onChange={(e) =>
                                        e.target.value >= 0 && this.setState({ availableRefundAmount: e.target.value, availableRefundAmountErr: "" })}
                                    onPaste={(e) => this.handlePaste(e)}
                                    onInput={(e) => this.maxLengthCheck(e, 16)}
                                    // onKeyDown={(e) => this.preventNegative(e)}
                                    onBlur={(e) => this.handleavailableRefundAmount(e)} />
                                {<span className='text-danger text-start text-left d-block mt-1'>{availableRefundAmountErr}</span>}
                            </div>
                            <div className="col-xl-2">
                                <label>Refund Amount <span style={{ color: 'red' }}>*</span></label>
                                <input type="number" className="form-control" name="" value={RefundAmount}
                                    maxLength={'16'}
                                    disabled={isView || isReadOnly}
                                    onChange={(e) => e.target.value >= 0 && this.setState({ RefundAmount: e.target.value, RefundAmountErr: "" })}
                                    // onKeyDown={(e) => this.preventNegative(e)}
                                    onInput={(e) => this.maxLengthCheck(e, 16)}
                                    onPaste={(e) => this.handlePaste(e)}
                                    onBlur={(e) => this.handleRefundAmount(e)}
                                />
                                {<span className='text-danger text-start text-left d-block mt-1'>{RefundAmountErr}</span>}
                            </div>
                            <div className="col-xl-2">
                                <label>on Acc Amt</label>
                                <input type="number" className="form-control" name="" value={onAccAmt}
                                    maxLength={'16'}
                                    disabled={true}
                                    onInput={(e) => this.maxLengthCheck(e, 16)}
                                    onKeyDown={(e) => this.preventNegative(e)}
                                    onPaste={(e) => this.handlePaste(e)}
                                />
                            </div>
                            <div className="col-xl-2">
                                <label>Total Amount</label>
                                <input type="number" className="form-control" name="" value={totalAmount}
                                    maxLength={'16'}
                                    disabled={true}
                                    onInput={(e) => this.maxLengthCheck(e, 16)}
                                    onKeyDown={(e) => this.preventNegative(e)}
                                    onPaste={(e) => this.handlePaste(e)}
                                />
                                {<span className='text-danger text-start text-left d-block mt-1'>{this.state.totalAmountErr}</span>}
                            </div>
                        </div>
                        {<span className='text-danger text-start text-left d-block mt-1'>{totalsErr}</span>}
                        <div className="row mt-1">
                            <div className="table-responsive mb-3">
                                <div
                                    className="btn-group mb-1"
                                    role="group"
                                    aria-label="Basic example"
                                    style={{ float: "right" }}
                                >
                                    <button
                                        type="button"
                                        // className="btn btn-dark p-1 btn-small m-0"
                                        className="btn btn-dark sel-per p-1 btn-small m-0"
                                        disabled={isView || isReadOnly || !(totalAmount != '' && totalAmount != null) || isUpdate}
                                        onClick={e => this.toggleModal()}
                                    >
                                        Select Refund Type(s)
                                    </button>
                                </div>
                                <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                    <thead>
                                        <tr>
                                            <th>Refund Type</th>
                                            {/* <th>Proforma Date</th> */}
                                            {/* <th> Taxable Amount</th> */}
                                            {/* <th>Tax Type</th> */}
                                            <th>CGST </th>
                                            <th>SGST </th>
                                            <th>IGST </th>
                                            <th>Total Refundable</th>
                                            <th>Outstanding</th>
                                            <th>Refund Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            checkedRefundList.length > 0 && checkedRefundList.map((data) => (
                                                data.isSelected == 1 &&
                                                <tr key={data.receiptID}>
                                                    <td>{data.RefundTypeName}</td>
                                                    {/* <td>{new Date(data.Date).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}</td> */}
                                                    {/* <td>{data.service}</td> */}
                                                    {/* <td>{data.totalDue}</td> */}
                                                    {/* <td>{data.taxType}</td> */}
                                                    <td>{data.cgst}</td>
                                                    <td>{data.sgst}</td>
                                                    <td>{data.igst}</td>
                                                    <td>{data.Amount}</td>
                                                    <td>{data.OutstandingAmount}</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            // id={data.id}
                                                            className="form-control"
                                                            value={data.refundAmt}
                                                            disabled={isView || isReadOnly || data.isReadOnly}
                                                            maxLength={'16'}
                                                            onChange={(e) => this.handleReceivedAmount(e, data.receiptID)}
                                                            // onKeyDown={(e) => this.preventNegative(e)}
                                                            onInput={(e) => this.maxLengthCheck(e, 16)}
                                                            onPaste={(e) => this.handlePaste(e)}
                                                        // onBlur={(e) => this.totalAmtsVal(e, data.id)}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{data.refundAmtErr}</span>}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        <tr key={Math.random()}>
                                            <td>Total</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            {/* <td>{totalAmt}</td>
                                            <td></td>
                                            <td></td> */}
                                            {/* <td>{totalIgst}</td> */}
                                            <td>{totalAmountCal == 0 ? '' : totalAmountCal}</td>
                                            <td>{totalOutstandCal == 0 ? '' : totalOutstandCal}</td>
                                            <td className="text-right">{totalReceivedAmtCal == 0 ? '' : totalReceivedAmtCal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <p className="mb-1 mt-2">
                                <strong>Other Charges</strong>
                            </p>
                            <div className="table-responsive mb-1">
                                <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th />
                                            <th>Account</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {otherChargesList.length > 0 && otherChargesList.map((item, index) => {
                                            const { account, amount } = item
                                            return (
                                                <tr key={index}>
                                                    <td
                                                        className="text-center"
                                                        style={{ padding: "5px!important", width: 30 }}
                                                    >
                                                        <div className="table__button-group mt-2">
                                                            {
                                                                index === otherChargesList.length - 1 &&
                                                                <a >
                                                                    <i className="fa fa-plus " onClick={!isView && !isReadOnly ? this.addTableRows : undefined} style={{ color: "#8b8f93" }} />{" "}
                                                                </a>
                                                            }
                                                            <a >
                                                                <span className="text-danger">
                                                                    <i className="fa fa-trash-o" onClick={!isView && !isReadOnly ? () => this.deleteTableRows(index) : undefined} />
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <select
                                                            className="form-control"
                                                            style={{ textAlign: "left" }}
                                                            name="account"
                                                            value={item.account}
                                                            disabled={isView || isReadOnly}
                                                            onChange={(e) => this.handleOtherCharges(index, e)}
                                                        >
                                                            <option>Select</option>
                                                            {otherChargesAccountTypeList.length > 0 && otherChargesAccountTypeList.map((data) => (
                                                                <option key={data.ReceiptAccountTypeID} value={data.ReceiptAccountTypeID}>{data.AccountType}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="number" className="form-control" value={item.amount} name="amount"
                                                            disabled={isView || isReadOnly}
                                                            onKeyDown={(e) => this.preventNegative(e)}
                                                            onChange={(e) => e.target.value >= 0 && this.handleOtherCharges(index, e)}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                </table>
                                {<span className='text-danger text-start text-left d-block mt-1'>{otherChargesListErr}</span>}

                            </div>
                        </div>
                        <div className="row">
                            <div
                                className=" mb-1"
                                role="group"
                                aria-label="Basic example"
                                style={{ float: "right" }}
                            >
                                {!isGenVoucherDisabled && (
                                    <button
                                        type="button"
                                        className="btn btn-dark p-1 sel-per btn-small m-0"
                                        style={{ float: "right" }}
                                        disabled={isView}
                                        onClick={(e) => this.generateVoucher(e)}
                                    >
                                        Generate Voucher
                                    </button>
                                )}
                                {isGenVoucherDisabled && (
                                    <button
                                        type="button"
                                        className="btn btn-dark p-1 btn-small m-0"
                                        style={{ float: "right" }}
                                        onClick={this.clearVoucher}
                                    >
                                        Clear Voucher
                                    </button>
                                )}
                            </div>
                            {
                                isGenVoucherDisabled && this.createLedgerTable()
                            }
                            {
                                isGenVoucherDisabled && this.createSubLedgerTable()
                            }
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Narration</label>
                                <textarea
                                    className="form-control"
                                    // defaultValue={"\n                                        "}
                                    value={narration}
                                    disabled={isView}
                                    maxLength={'500'}
                                    onChange={(e) => this.setState({ narration: e.target.value, })}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-3">
                                <label>Refund no </label>
                                <input type="text" className="form-control" name="" value={RefundNo} disabled={true}
                                />
                            </div>
                            <div className="col-md-3">
                                <label>Refund Date</label>
                                <DatePicker selected={RefundDate}
                                    dateFormat={"dd/MM/yyyy"} className="form-control" disabled={true}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="
                                    Refund Date"dayClassName={() => "example-datepicker-day-class"}
                                    popperClassName="example-datepicker-class" />
                            </div>
                            <div className="col-md-3">
                                <label>Payment Received Date</label>
                                <DatePicker selected={PaymentDoneDate}
                                    dateFormat={"dd/MM/yyyy"} className="form-control"
                                    onChange={(date) => this.onPaymentDoneDate(date.getTimezoneOffset(), date.getTime())}
                                    peekNextMonth
                                    disabled={isView}
                                    maxDate={new Date()}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="
                                    Payment Refund Date"dayClassName={() => "example-datepicker-day-class"}
                                    popperClassName="example-datepicker-class" />
                            </div>
                            <div className="col-md-3">
                                <label>Payment Mode <span style={{ color: 'red' }}>*</span></label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    style={{ textAlign: "left" }}
                                    value={pymtModeId}
                                    disabled={isView}
                                    onChange={(e) => this.setState({ pymtModeId: e.target.value, pymtModeErr: "" })}
                                >
                                    <option>Select</option>
                                    {paymentModeList.length > 0 && paymentModeList.map((data) => (
                                        <option key={data.PaymentModeID} value={data.PaymentModeID}>{data.Name}</option>
                                    ))}
                                </select>{<span className='text-danger text-start text-left d-block mt-1'>{pymtModeErr}</span>}
                            </div>

                            <div className="col-md-3">
                                <label>Instrument no</label>
                                <input type="text" className="form-control" name="" value={instrNo} disabled={isView}
                                    maxLength={'50'}
                                    onChange={(e) => this.setState({ instrNo: e.target.value })} />
                            </div>
                            <div className="col-md-3">
                                <label>Instrument Date</label>
                                <DatePicker selected={instruDate} onChange={(date) => this.setState({ instruDate: date })}
                                    dateFormat={"dd/MM/yyyy"} className="form-control" disabled={isView}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Refund Date"
                                    // minDate={RefundDate}
                                    dayClassName={() => "example-datepicker-day-class"}
                                    popperClassName="example-datepicker-class" />
                            </div>
                            <div className="col-md-3">
                                <label>Bank Name</label>
                                <input type="text" className="form-control" name="" value={bankName} disabled={isView}
                                    maxLength={'500'}
                                    onChange={(e) => this.setState({ bankName: e.target.value })} />
                            </div>
                            <div className="col-md-3">
                                <label>Branch Name</label>
                                <input type="text" className="form-control" name="" value={branchName} disabled={isView}
                                    maxLength={'250'}
                                    onChange={(e) => this.setState({ branchName: e.target.value })} />
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className=" mb-1 mt-2"
                                role="group"
                                aria-label="Basic example"
                                style={{ float: "right", textAlign: "right" }}
                            >
                                {
                                    isAdd &&
                                    <button type="button" className="btn ad-fil-btn mr-2"
                                        disabled={!this.getWriteAccess()}
                                        onClick={this.saveRefundEntry}>
                                        Save
                                    </button>
                                }
                                {
                                    isUpdate &&
                                    <button type="button" className="btn ad-fil-btn mr-2"
                                        disabled={!this.getUpdateAccess()}
                                        onClick={this.updateRefundEntry}>
                                        Update
                                    </button>
                                }
                                {
                                    (isUpdate || isView) &&
                                    <button type="button" className="btn ad-fil-btn m-2"
                                        disabled={!this.getDeleteAccess()}
                                        onClick={this.toggleDelModal}
                                    // disabled={!isRightToDelete}
                                    >
                                        Delete
                                    </button>
                                }
                                <button type="button" className="btn ad-fil-btn m-0" onClick={this
                                    .onCancleRefundEntry}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={isDelOpen} onRequestClose={this.toggleDelModal}>
                    <div className="modal-dialog">
                        <div className="modal-content confirm-logout">
                            <div class="modal-header p-2">
                                <h5 class="modal-title modal_title_text text-danger">Confirm Delete</h5>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleDelModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p class="pop_label">Do you really want to delete this record?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop" onClick={(e) => this.handleDelete(e)}>Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleDelModal}>No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog">
                        <div className="modal-content proforma-invoice">
                            <div className="modal-header p-3">
                                <h5 className="modal-title" id="staticBackdropLabel">
                                    Refund Type
                                </h5>
                                <button type="button" className="btn-close" style={{ fontSize: '12px' }}
                                    // data-bs-dismiss="modal"
                                    // aria-label="Close"
                                    onClick={e => this.setState({ isOpen: !isOpen })}
                                />
                            </div>
                            <div className="modal-body p-3">
                                <div className="table-responsive ">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th className="text-center">
                                                    <input type="checkbox" checked={checkedAll} onChange={(e) => this.selectAllInv(e)} />
                                                </th>
                                                <th className="text-center">Refund Type</th>
                                                {/* <th>Proforma Invoice Date</th> */}
                                                {/* <th>Service</th> */}
                                                <th>Total Payable</th>
                                                <th>Outstanding</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {refundTypeList.length > 0 && refundTypeList.map((data, index) => (
                                                <tr key={Math.random()} onClick={(e) => this.handleCheckRefund(e, data.receiptID)} >
                                                    <td className="text-center">
                                                        <input type="checkbox" value={data.isSelected} checked={data.isSelected} onChange={(e) => this.handleCheckRefund(e, data.receiptID)} />
                                                    </td>
                                                    <td className="text-center">{data.RefundTypeName}</td>
                                                    {/* <td>{new Date(data.Date).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}</td> */}
                                                    {/* <td>{data.service}</td> */}
                                                    <td>{data.Amount}</td>
                                                    <td>{data.OutstandingAmount}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer p-1">
                                <button
                                    type="button"
                                    className="btn ad-fil-btn"
                                    data-bs-dismiss="modal"
                                    style={{ background: "#ddd", color: "#000", padding: "4px 16px" }}
                                    onClick={this.handleContinue}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}
export default withRouter(connect(null, {
    receipt_entry_save_action, receiptDetails_action, fill_action, proformaInvoice_action, generateVoucher_action, refundNo_action, generate_refund_voucher_action, refund_update_action, refund_details_action,
    receiptNo_action, deleteReceipt_action, receiptUpdate_action, pendingReceiptDetails_action, get_refund_type_amount_action, add_refund_action, delete_refund_action
})(RefundEntry));
