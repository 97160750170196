import { Component } from "react";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import AdminDrawer from "../../../common_components/admin_module_drawer";
import { getVisaInvitation_action, getVisaApplicationExportData_action } from "../../../actions/admin_module_action/visa_approval_action/visa_approval_action";
import { exhibitorActionId, exhibitorTypeActionId, toastError } from "../../../utils/constants";
import { data } from "jquery";
import Pagination from "../../../utils/pagination";
import { fill_action } from "../../../actions/fillCombo_action";
import Workbook from 'react-excel-workbook'

class VisaInvitation extends Component {
    // Initialize Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            companyName: "",
            invitationList: [],
            statusList: [],
            currentPage: 1,
            exhibitorTypeList: [],
            exhibitorTypeId: "",
            exportData: [],
            loginAccesslist: []
        }
        this.onNameClick = this.onNameClick.bind(this)
        this.getVisaInvitations = this.getVisaInvitations.bind(this)
        this.onSearch = this.onSearch.bind(this)
        this.updateItem = this.updateItem.bind(this)
        this.onApprovedClick = this.onApprovedClick.bind(this)
        this.onAppliedClick = this.onAppliedClick.bind(this)
        this.onDeclinedClick = this.onDeclinedClick.bind(this)
        this.onPendingClick = this.onPendingClick.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.onCreateExcel = this.onCreateExcel.bind(this)
        this.onCreateExcel2 = this.onCreateExcel2.bind(this)
        this.onExportData = this.onExportData.bind(this)
        this.getWirteAccess = this.getWirteAccess.bind(this)
        this.getDownloadAccess = this.getDownloadAccess.bind(this)
    }

    // On Component Load
    componentDidMount() {
        this.setState({ loginAccesslist: JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist'] })
        this.getFillList(exhibitorTypeActionId, '', '', '', '', '');
        this.getVisaInvitations(null)
        this.onExportData()
    }

    // On Name Click 
    onNameClick(e, name, exhRegId) {
        sessionStorage.setItem("ExhRegistrationIDSS", exhRegId)
        this.props.navigate('/visaapproval', { state: { companyName: name } })
    }

    // get Visa Invitations
    getVisaInvitations(key) {
        const { companyName, exhibitorTypeId } = this.state
        this.setState({ isLoading: true })
        this.props.getVisaInvitation_action(companyName, key != undefined && key != null ? key : null,
            exhibitorTypeId != "" && exhibitorTypeId != "Select Exhibitor Type" ? exhibitorTypeId : "")
            .then((data) => {
                if (data.error != 1) {
                    console.log("getVisaInvitations Data", data.data)
                    this.setState({
                        statusList: data.data['applicationStatusList'],
                        invitationList: data.data['invitationsList'],
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On Search Click
    onSearch() {
        this.getVisaInvitations(null)
    }

    // curent page update Item
    updateItem(item) {
        const { invitationList } = this.state
        this.setState({ currentPage: item });
        console.log("xyxyxyxyyxyxyyyxyx", item)
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = invitationList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(invitationList.length / recordsPerPage)
    }

    // On Applied Click
    onAppliedClick(key) {
        this.getVisaInvitations(key)
        this.onExportData(key)
    }

    // On Approved Click
    onApprovedClick(key) {
        this.getVisaInvitations(key)
        this.onExportData(key)
    }

    // On Declined Click
    onDeclinedClick(key) {
        this.getVisaInvitations(key)
        this.onExportData(key)
    }

    // On Pending Click
    onPendingClick(key) {
        this.getVisaInvitations(key)
        this.onExportData(key)
    }

    // Get Fill Lists
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // Exhibitor Type list
                    if (actionID === exhibitorTypeActionId) {
                        console.log("Exhibitor Type list data", data.data)
                        this.setState({
                            exhibitorTypeList: data.data.length > 0 ? data.data : [],
                            isLoading: false
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // to create excel file
    onCreateExcel() {
        const { invitationList } = this.state
        var listData = []
        var mapData
        invitationList.length > 0 && invitationList.map((item, index) => {
            mapData = {
                "Sr No": index + 1,
                "Company Name": item.CompanyName,
                "Type": item.Type,
                "Used": item.Used,
                "Pending": item.Pending,
                "Approved": item.Approved,
                "Declined": item.Declined
            }
            listData.push(mapData)
        })

        var list = [...listData]
        var columnArray = [],
            rowData = [];
        // for column data 
        list.map((data, index) => {
            if (index == 0) {
                Object.keys(data).map((key, index) => {
                    columnArray.push(key)
                })
            }
        })


        // for row data
        list.map((data, index) => {
            rowData.push(data)
        })

        // console.log("rowData", rowData)
        // this.setState({
        //     columnArrayData: columnArray,
        //     rowData: rowData
        // })

        // { this.renderList() }

        return (
            <div className="row text-center">
                {invitationList.length > 0 ? <Workbook filename="ExhibitorList.xlsx" style={{}} element={
                    <button type="button" className="btn btn-outline-primary m-0"
                        disabled={!this.getDownloadAccess()}
                    >
                        Export to Excel
                    </button>
                }>
                    <Workbook.Sheet data={rowData} name="Sheet A">
                        {columnArray.map((value, index) =>
                            <Workbook.Column label={value} value={value} />
                        )
                        }
                    </Workbook.Sheet>
                </Workbook> : <button type="button" className="btn btn btn-outline-primary m-0" disabled={true}>
                    Export to Excel
                </button>}
            </div>
        );
    }

    // On Export Data
    onExportData(key) {
        this.setState({ isLoading: true })
        this.props.getVisaApplicationExportData_action(key != undefined && key != null ? key : null)
            .then((data) => {
                if (data.error != 1) {
                    console.log("onExportData Data", data.data)
                    this.setState({
                        exportData: data.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // to create excel file
    onCreateExcel2() {
        const { exportData } = this.state
        var listData = []
        var list = [...exportData]
        var columnArray = [],
            rowData = [];
        // for column data 
        list.map((data, index) => {
            if (index == 0) {
                Object.keys(data).map((key, index) => {
                    columnArray.push(key)
                })
            }
        })

        // for row data
        list.map((data, index) => {
            rowData.push(data)
        })

        return (
            <div className="row text-center">
                {<Workbook filename="Visa-Application_Data.xlsx" style={{}} element={
                    <button type="button" className="btn btn-outline-primary m-0"
                        disabled={!this.getDownloadAccess()}
                    >
                        Export Data
                    </button>
                }>
                    <Workbook.Sheet data={rowData} name="Sheet A">
                        {columnArray.map((value, index) =>
                            <Workbook.Column label={value} value={value} />
                        )
                        }
                    </Workbook.Sheet>
                </Workbook>}
            </div>
        );
    }

    getDownloadAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '12');
        if (moduleAccess) {
            return moduleAccess.DownloadAccess;
        } else {
            return false
        }
        return false;
    }

    getWirteAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '12');
        if (moduleAccess) {
            return moduleAccess.WriteAccess;
        } else {
            return false
        }
        return false;
    }

    render() {
        const { isLoading, companyName, statusList, invitationList, currentPage, exhibitorTypeList, exhibitorTypeId } = this.state
        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = invitationList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(invitationList.length / recordsPerPage)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <AdminDrawer />
                <div className="main-content app-content with-dr-container">
                    <div className="container-fluid p-0">
                        <div className="">


                        </div>
                        <div className="">
                            <div className="col-xl-12 p-0">
                                <div className="card rounded-0 pt-1">
                                    <div
                                        className="card-header p-3"
                                        style={{ paddingLeft: "7px !important" }}
                                    >
                                        <h3 className="card-title" style={{ fontSize: 15 }}>
                                            Invitation Letter for Visa Application-Exhibitor
                                        </h3>
                                    </div>
                                    <div className="card-body p-1">
                                        <div className="row m-0 mt-1 mb-4 text-center">
                                            <div className="col top-box" style={{ cursor: "pointer" }}
                                                onClick={(e) => this.getVisaInvitations(null)}>
                                                <p className="mb-0 pb-2">Application Received</p>
                                                <h4 className="mb-0 pb-1">{statusList.length > 0 ? statusList[0]['ApplicationReceived'] : 0}</h4>
                                            </div>
                                            <div className="col top-box" style={{ cursor: "pointer" }}
                                                onClick={(e) => this.onAppliedClick(1)}>
                                                <p className="mb-0 pb-2">Applied</p>
                                                <h4 className="mb-0 pb-1">{statusList.length > 0 ? statusList[0]['Applied'] : 0}</h4>
                                            </div>
                                            <div className="col top-box" style={{ cursor: "pointer" }}
                                                onClick={(e) => this.onApprovedClick(2)}>
                                                <p className="mb-0 pb-2">Approved</p>
                                                <h4 className="mb-0 pb-1">{statusList.length > 0 ? statusList[0]['Approved'] : 0}</h4>
                                            </div>
                                            <div className="col top-box" style={{ cursor: "pointer" }}
                                                onClick={(e) => this.onDeclinedClick(3)}>
                                                <p className="mb-0 pb-2">Declined</p>
                                                <h4 className="mb-0 pb-1">{statusList.length > 0 ? statusList[0]['Declined'] : 0}</h4>
                                            </div>
                                            <div className="col top-box" style={{ cursor: "pointer" }}
                                                onClick={(e) => this.onPendingClick(4)}>
                                                <p className="mb-0 pb-2">Pending</p>
                                                <h4 className="mb-0 pb-1">{statusList.length > 0 ? statusList[0]['Pending'] : 0}</h4>
                                            </div>
                                        </div>
                                        <div className="row m-0" style={{ marginTop: "20px !important" }}>
                                            {/* <div className="col-md-4 p-0">
                                                <form>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Login Name..."
                                                        name=""
                                                    />
                                                </form>
                                            </div> */}
                                            <div className="col-md-4 ">
                                                <form>
                                                    <label htmlFor="validationCustom03">Company Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Company Name..."
                                                        value={companyName}
                                                        onChange={(e) => this.setState({ companyName: e.target.value })}
                                                    />
                                                </form>
                                            </div>
                                            <div className="col-md-4 ">
                                                <label htmlFor="validationCustom03">Exhibitor Type</label>
                                                <select className="form-control"
                                                    value={exhibitorTypeId}
                                                    onChange={(e) => this.setState({ exhibitorTypeId: e.target.value })}
                                                >
                                                    <option>Select Exhibitor Type</option>
                                                    {
                                                        exhibitorTypeList.length > 0 && exhibitorTypeList.map((data) => (
                                                            <option key={data.ID} value={data.ID}>{data.ExhibitorType}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-md-2 p-0 d-flex">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary mt-4"
                                                    style={{ padding: "4px 10px" }}
                                                    onClick={(e) => this.onSearch(e)}
                                                >
                                                    Search
                                                </button>
                                                <div className="mt-4 ml-2">
                                                    {this.onCreateExcel()}
                                                </div>
                                                <div className="mt-4 ml-2">
                                                    {this.onCreateExcel2()}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="table-responsive">
                                            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-2">
                                                <thead>
                                                    <tr>
                                                        <th>SR NO</th>
                                                        {/* <th className="text-center">Login Name</th> */}
                                                        <th>Company</th>
                                                        <th>Type</th>
                                                        <th className="text-center">Used</th>
                                                        <th className="text-center">Pending</th>
                                                        <th className="text-center">Apr</th>
                                                        <th className="text-center">declined</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        currentRecords.length > 0 ? currentRecords.map((data, index) => (
                                                            <tr>
                                                                <td className="text-center">{index + 1}</td>
                                                                {/* <td className="text-center">EXH298656</td> */}
                                                                <td className="text-primary"
                                                                    onClick={(e) => this.getWirteAccess() && this.onNameClick(e, data.CompanyName, data.ExhregistrationID)}
                                                                >{data.CompanyName}</td>
                                                                <td>{data.Type}</td>
                                                                <td className="text-center">{data.Used}</td>
                                                                <td className="text-center">{data.Pending}</td>
                                                                <td className="text-center">{data.Approved}</td>
                                                                <td className="text-center">{data.Declined}</td>
                                                            </tr>
                                                        )) : <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                            <div />
                                            <div className="mt-2 float-left">
                                                {
                                                    invitationList.length > 0 ?
                                                        <Pagination
                                                            nPages={nPages}
                                                            currentPage={currentPage}
                                                            setCurrentPage={this.updateItem}
                                                        />
                                                        : <></>
                                                }
                                                <div className="tab-rec " style={{ float: "right" }}>
                                                    <p>{`${currentPage} of ${nPages} pages`}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="row mt-3">
                                      <div class="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" class="btn btn-primary rounded-0" style="margin-right:10px">Submit</button>
                                        <button type="button" class="btn btn-danger rounded-0">Delete</button>
                                      </div>
                                  </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="footer-sec">
          <div class="sdsd">
              <strong>Ownership:</strong> All content, formats and images used on this site are owned or licensed by India ITME Society. You may not copy, reproduce, distribute, publish, display, perform, modify, create derivative works, transmit, or in any other way exploit any part of copyrighted material without permission of the copyright owner. Failure to comply shall result in legal action.
          </div>
      </div>

      <div class="footer-first">
          <div class="container-fluid">
              <div class="footer-first">  ITME All Rights Reserved <i class="fa fa-copyright" aria-hidden="true"></i> 2023  </div>
          </div>
      </div> */}
                </div>

            </>
        )
    }
}
export default withRouter(connect(null, { getVisaInvitation_action, fill_action, getVisaApplicationExportData_action })(VisaInvitation))