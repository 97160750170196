import { Component } from "react"
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import AdminDrawer from "../../../common_components/admin_module_drawer";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from "../../../actions/fillCombo_action";
import { exhibitorTypeActionId, toastError, paymentTypeActionId } from "../../../utils/constants";
import { getExhibitorManualFormSummaryList_action, getExhibitorManualFormSummaryExport_action } from "../../../actions/admin_module_action/exhibitor_manualForm_summary_action/exhibitor_manualForm_summary_action";
import { data } from "jquery";
import Pagination from "../../../utils/pagination";
import Workbook from 'react-excel-workbook'

class ManualFormSummary extends Component {
    // intialize constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            exhibitor: "",
            exhibitorTypeId: "",
            exhibitorTypeList: [],
            exhibitorList: [],
            currentPage: 1,
            headerData: [],
            rowData: [],
            exhibitorExportData: [],
            loginAccesslist: [],
            paymentTypeId: "",
            paymentTypeList: [],
        }
        this.getFillList = this.getFillList.bind(this)
        this.getExhibitorManualFormSummary = this.getExhibitorManualFormSummary.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.onCreateExcel = this.onCreateExcel.bind(this)
        this.updateItem = this.updateItem.bind(this)
        this.createTableData = this.createTableData.bind(this)
        this.getExhibitorManualFormSummaryExport = this.getExhibitorManualFormSummaryExport.bind(this)
        this.onCreateExcel2 = this.onCreateExcel2.bind(this)
        this.getDownloadAccess = this.getDownloadAccess.bind(this)
    }

    componentDidMount() {
        this.setState({ loginAccesslist: JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist'] })
        this.getFillList(exhibitorTypeActionId, '', '', '', '', '');
        this.getFillList(paymentTypeActionId, '', '', '', '', '');
        this.getExhibitorManualFormSummary()
        this.getExhibitorManualFormSummaryExport()
    }

    // Get Fill Lists
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // Exhibitor Type list
                    if (actionID === exhibitorTypeActionId) {
                        console.log("Exhibitor Type list data", data.data)
                        this.setState({
                            exhibitorTypeList: data.data.length > 0 ? data.data : [],
                            isLoading: false
                        })
                    }
                }
                if (actionID === paymentTypeActionId) {
                    console.log("Payment Type list data", data.data)
                    this.setState({
                        paymentTypeList: data.data.length > 0 ? data.data : [],
                        isLoading: false
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // For Exhibitor List with Manual Form Summary
    getExhibitorManualFormSummary() {
        const { exhibitor, exhibitorTypeId, paymentTypeId } = this.state
        this.setState({ isLoading: true })
        this.props.getExhibitorManualFormSummaryList_action(exhibitor,
            exhibitorTypeId != "" && exhibitorTypeId != "Select Exhibitor Type" ? exhibitorTypeId : "", paymentTypeId)
            .then((data) => {
                if (data.error != 1) {
                    console.log("getExhibitorManualFormSummary Data", data.data)
                    this.setState({
                        exhibitorList: data.data,
                        isLoading: false
                    })
                    this.createTableData(data.data)
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // For Exhibitor Export Data with Manual Form Summary
    getExhibitorManualFormSummaryExport() {
        const { exhibitor, exhibitorTypeId } = this.state
        this.setState({ isLoading: true })
        this.props.getExhibitorManualFormSummaryExport_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("getExhibitorManualFormSummaryExport Data", data.data)
                    this.setState({
                        exhibitorExportData: data.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On Search Click
    onSearchClick() {
        this.getExhibitorManualFormSummary()
    }

    // On Clear Click
    clearValue() {
        this.setState({
            exhibitor: "",
            exhibitorTypeId: "",
            paymentTypeId:'',
        })
        setTimeout(() => {
            this.getExhibitorManualFormSummary()
        }, 500);
    }

    // to create excel file
    onCreateExcel() {
        const { exhibitorList } = this.state
        console.log("xxxxxxxxxxxxxxxxx", exhibitorList)
        var listData = []
        exhibitorList.length > 0 && exhibitorList.map((item, index) => {
            var mapData1 = {
                'SR No': index + 1,

            }
            var finalMapData = { ...mapData1, ...item }
            listData.push(finalMapData)
        })
        console.log('listData', listData);

        var excludeKeys = [
            'ExhRegistrationID', 'ExhibitionID',
        ]

        // Function to remove keys from objects
        function removeKeysFromObjects(arr, keys) {
            return arr.map((obj) => {
                const newObj = { ...obj }; // Create a new object to avoid modifying the original
                keys.forEach((key) => delete newObj[key]); // Remove specified keys from the new object
                return newObj;
            });
        }

        // Remove keys from the array of objects
        const newArray = removeKeysFromObjects(listData, excludeKeys);
        console.log("new arrya-->", newArray);


        var columnArray = [],
            rowData = [];
        // for column data 
        newArray.map((data, index) => {
            if (index == 0) {
                Object.keys(data).map((key, index) => {
                    columnArray.push(key)
                })
            }
        })


        // for row data
        newArray.map((data, index) => {
            rowData.push(data)
        })

        // console.log("rowData", rowData)
        // this.setState({
        //     columnArrayData: columnArray,
        //     rowData: rowData
        // })

        // { this.renderList() }

        return (
            <div className="row text-center">
                {exhibitorList.length > 0 ? <Workbook filename="ManualForm_Summary.xlsx" style={{}} element={
                    <button type="button" className="btn ad-fil-btn m-0"
                        disabled={!this.getDownloadAccess()}
                    >
                        Excel
                    </button>
                }>
                    <Workbook.Sheet data={rowData} name="Sheet A">
                        {columnArray.map((value, index) =>
                            <Workbook.Column label={value} value={value} />
                        )
                        }
                    </Workbook.Sheet>
                </Workbook> : <button type="button" className="btn ad-fil-btn m-0" disabled={true}>
                    Excel
                </button>}
            </div>
        );
    }

    // to create excel Export Data
    onCreateExcel2() {
        const { exhibitorExportData } = this.state
        var listData = []
        exhibitorExportData.length > 0 && exhibitorExportData.map((item, index) => {
            var mapData1 = {
                'SR No': index + 1,

            }
            var finalMapData = { ...mapData1, ...item }
            listData.push(finalMapData)
        })
        console.log('listData', listData);

        var columnArray = [],
            rowData = [];
        // for column data 
        listData.map((data, index) => {
            if (index == 0) {
                Object.keys(data).map((key, index) => {
                    columnArray.push(key)
                })
            }
        })


        // for row data
        listData.map((data, index) => {
            rowData.push(data)
        })

        // console.log("rowData", rowData)
        // this.setState({
        //     columnArrayData: columnArray,
        //     rowData: rowData
        // })

        // { this.renderList() }

        return (
            <div className="row text-center">
                {exhibitorExportData.length > 0 ? <Workbook filename="ManualForm_Summary_ExportData.xlsx" style={{}} element={
                    <button type="button" className="btn ad-fil-btn"
                        disabled={!this.getDownloadAccess()}
                    >
                        Export Data
                    </button>
                }>
                    <Workbook.Sheet data={rowData} name="Sheet A">
                        {columnArray.map((value, index) =>
                            <Workbook.Column label={value} value={value} />
                        )
                        }
                    </Workbook.Sheet>
                </Workbook> : <button type="button" className="btn ad-fil-btn" disabled={true}>
                    Export Data
                </button>}
            </div>
        );
    }

    // Update Current Page
    updateItem(item) {
        const { exhibitorList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = exhibitorList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(exhibitorList.length / recordsPerPage)
    }

    // Create table data dyanamic
    createTableData(list) {
        var listData = []
        var mapData
        list.length > 0 && list.map((item, index) => {
            var mapData1 = {
                'SR No': index + 1,

            }
            var finalMapData = { ...mapData1, ...item }
            listData.push(finalMapData)
        })
        console.log('listData', listData);

        var excludeKeys = [
            'Address_Line_1', 'Address_Line_2', 'AlternateMobileNo', 'BankName', 'Bank_AccountName', 'Bank_AccountNo', 'Bank_AccountType',
            'Bank_Branch', 'City', 'CompanyRegistrationNo', 'Country', 'Designation', 'ExhRegistrationID', 'ExhibitionID', 'FasciaName', 'GSTNo',
            'IECCode', 'InvAddress_Line_1', 'InvAddress_Line_2', 'InvAlternateMobileNo', 'InvCity', 'InvCompanyName', 'InvCountry', 'InvDesignation',
            'InvMobileNo', 'InvPersonFirstName', 'InvPersonLastName', 'InvPincode', 'InvPrimaryEmail', 'InvSecondaryEmail', 'InvState',
            'InvTeleFax', 'InvTelephone', 'InvTelephone1', 'InvWebsite', 'MobileNo', 'MobileNo', 'NameOnPANCard', 'OpenSides',
            'Owner_Email', 'Owner_FirstName', 'Owner_Mobile', "Owner_LastName", 'PANNo', 'PersonIncharge_FirstName', 'PersonIncharge_LastName',
            'Pincode', 'PrimaryEmail', 'SameAsCorrespondence', 'SecondaryEmail', 'StallArea', 'StallType', 'State', 'SwiftCode_IFSCCode', 'TANNo',
            'TeleFax', 'Telephone', 'Telephone1', 'Website', 'exhRegistrationNo', 'AgentName'
        ]

        // Function to remove keys from objects
        function removeKeysFromObjects(arr, keys) {
            return arr.map((obj) => {
                const newObj = { ...obj }; // Create a new object to avoid modifying the original
                keys.forEach((key) => delete newObj[key]); // Remove specified keys from the new object
                return newObj;
            });
        }

        // Remove keys from the array of objects
        const newArray = removeKeysFromObjects(listData, excludeKeys);
        console.log("new arrya-->", newArray);

        var columnArray = []
        var rowData = []
        newArray.map((data, i) => {
            Object.keys(data).map((key, index) => {
                if (i == 0) {
                    columnArray.push(key)
                }
            })
        })
        console.log("111111111111111111111111111111", columnArray)
        // for row data
        newArray.map((data, index) => {
            rowData.push(Object.values(data))
        })
        console.log("2222222222222222222222", rowData)
        this.setState({
            headerData: columnArray,
            rowData: rowData
        })
    }

    getDownloadAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '11');
        if (moduleAccess) {
            return moduleAccess.DownloadAccess;
        } else {
            return false
        }
        return false;
    }

    render() {
        const { isLoading, currentPage, exhibitor, paymentTypeList, paymentTypeId, exhibitorTypeId, exhibitorTypeList, exhibitorList, headerData, rowData } = this.state
        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = rowData.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(rowData.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                    <div className="main-container">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Exhibitor Summary</p>
                            </div>
                        </div>
                    </div>
                    <div className="main-container p-2 mt-1">
                        <div className="row ">
                            <div className="col-xl-3 mb-3">
                                <label>Exhibitor Company Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue=""
                                    required=""
                                    value={exhibitor}
                                    onChange={(e) => this.setState({ exhibitor: e.target.value })}
                                />
                            </div>
                            <div className="col-md-3 ">
                                <label htmlFor="validationCustom03">Exhibitor Type</label>
                                <select className="form-control"
                                    value={exhibitorTypeId}
                                    onChange={(e) => this.setState({ exhibitorTypeId: e.target.value })}
                                >
                                    <option>Select Exhibitor Type</option>
                                    {
                                        exhibitorTypeList.length > 0 && exhibitorTypeList.map((data) => (
                                            <option key={data.ID} value={data.ID}>{data.ExhibitorType}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="validationCustom03">Payment Type</label>
                                <select className="form-control"
                                    value={paymentTypeId}
                                    onChange={(e) => this.setState({ paymentTypeId: e.target.value })}
                                >
                                    <option>Select Payment Type</option>
                                    {
                                        paymentTypeList.length > 0 && paymentTypeList.map((data) => (
                                            <option key={data.ID} value={data.ID}>{data.PaymentType}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div
                                    className="btn-group mb-1"
                                    role="group"
                                    aria-label="Basic example"
                                    style={{ float: "right" }}
                                >
                                    <button type="button" className="btn ad-fil-btn"
                                        onClick={e => this.onSearchClick()}
                                    >
                                        Search
                                    </button>
                                    <button type="button" className="btn ad-fil-btn"
                                        onClick={e => this.clearValue()}
                                    >
                                        Clear
                                    </button>
                                    {this.onCreateExcel()}
                                    {this.onCreateExcel2()}
                                </div>
                                <div className="table-responsive">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                        <thead>
                                            <tr>
                                                {
                                                    headerData.length > 0 && headerData.map((data, index) => (
                                                        <th className="text-center" style={{ width: 50 }}>
                                                            {data}
                                                        </th>
                                                    ))
                                                }

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                currentRecords.length > 0 ? currentRecords.map((data, index) => (
                                                    <tr key={Math.random()}>
                                                        {
                                                            data.length > 0 && data.map((item) => (
                                                                <td className="text-center">{item}</td>
                                                            ))
                                                        }
                                                    </tr>
                                                )) :
                                                    <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                            }

                                        </tbody>
                                    </table>

                                </div>
                                <div className="mt-2 float-left">
                                    {
                                        exhibitorList.length > 0 ?
                                            <Pagination
                                                nPages={nPages}
                                                currentPage={currentPage}
                                                setCurrentPage={this.updateItem}
                                            /> : <></>
                                    }
                                    <div className="tab-rec " style={{ float: "right" }}>
                                        <p>{`${currentPage} of ${nPages} pages`}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Footerwithdr /> */}
            </>
        )
    }
}
export default withRouter(connect(null, {
    fill_action, getExhibitorManualFormSummaryList_action, getExhibitorManualFormSummaryExport_action
})(ManualFormSummary))