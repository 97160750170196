import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { GET_POWER_CONNECTION_APPROVAL_DETAILS_ACTION_TYPE, GET_POWER_CONNECTION_APPROVAL_LIST_ACTION_TYPE, GET_POWER_CONNECTION_EXPORT_ACTION_TYPE, getPowerConnectionApprovalDetailsUrl, getPowerConnectionApprovalListUrl, getPowerConnectionExportDataUrl, UPDATE_POWER_APPROVAL_ACTION_TYPE, updatePowerApprovalUrl, headers } from "../../../../utils/constants";

export const getPowerFormApprovalDetails_action = (companyName, status, exhibitorTypeId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyName,
            StatusID: status,
            ExhibitorTypeID: exhibitorTypeId
        }
        console.log("getPowerFormApplications_action Action------->", params)

        const res = await axiosGet(getPowerConnectionApprovalDetailsUrl, params, headers)

        dispatch({
            type: GET_POWER_CONNECTION_APPROVAL_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getPowerFormApprovalList_action = (companyName, status, exhibitorTypeId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyName,
            StatusID: status,
            ExhibitorTypeID: exhibitorTypeId
        }
        console.log("getPowerFormApplications_action Action------->", params)

        const res = await axiosGet(getPowerConnectionApprovalListUrl, params, headers)

        dispatch({
            type: GET_POWER_CONNECTION_APPROVAL_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getPowerConnectionExport_action = (status) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            Status: status,
        }
        console.log("getPowerFormApplications_action Action------->", params)

        const res = await axiosGet(getPowerConnectionExportDataUrl, params, headers)

        dispatch({
            type: GET_POWER_CONNECTION_EXPORT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updatePowerApproval_action = (isApproved, approvalList) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            Is_Approved: isApproved,
            approvalList: approvalList
        }
        console.log("updatePowerApproval_action Action------->", mapData)

        const res = await axiosPost(updatePowerApprovalUrl, mapData, headers)

        dispatch({
            type: UPDATE_POWER_APPROVAL_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}