import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess, airCompressedconsumptionId, BarActionId, CFMActionId } from "../../../../../utils/constants";
import { insertUpdateAirConnection_action, getAirConnectionDetails_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/airConnection_action/airConnection_action'
import $ from 'jquery'
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import { fill_action } from '../../../../../actions/fillCombo_action';
import moment from "moment";
// 
class AirConnectionApplication extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            PowerConnectionList: [],
            Is_Agree: false,
            Remark: '',
            CGST: 0,
            SGST: 0,
            Is_AgreeErr: '',
            DeadlineDate: "",
            Is_Required: true,
            carPassessId: '',
            currentDate: moment().format('DD-MM-YYYY'),
            tableData: '',
            airConsumptionQuantity: '',
            IGST: '',
            Is_View: false,
            CurrencyID: null,
            CompressedAirConsumptionID: 1,
            MaxCompressedQuantity: "",
            Quantity: "",
            TotalAmount: 0,
            airCompressedconsumptionList: [],
            DescriptionOfService: '',
            Rates: '',
            Amount: '',
            ServiceID: '',
            StatusID: '',
            BarId: '',
            BarList: '',
            CFMId: '',
            CFMList: '',
            BarQuantity_6: '',
            BarQuantity_7_to_30: '',
            PricingArray: [
                {
                    Description: '5-6 Bar Pressure with 0 TO 10 CFM',
                    srNo: 'A)',
                    price: 0,
                    Connection: 'Each Conn.',
                    id: 1
                },
                {
                    Description: '5-6 Bar Pressure with 11 TO 20 CFM',
                    srNo: 'B)',
                    price: 0,
                    Connection: 'Each Conn.',
                    id: 1
                },
                {
                    Description: '5-6 Bar Pressure with 21 TO 30 CFM',
                    srNo: 'C)',
                    price: 0,
                    Connection: 'Each Conn.',
                    id: 1
                },
                {
                    Description: '5-6 Bar Pressure with 31 TO 40 CFM',
                    srNo: 'D)',
                    price: 0,
                    Connection: 'Each Conn.',
                    id: 1
                },
                {
                    Description: '5-6 Bar Pressure with 41 TO 50 CFM',
                    srNo: 'E)',
                    price: 0,
                    Connection: 'Each Conn.',
                    id: 1
                },
                {
                    Description: '',
                    srNo: '',
                    price: '',
                    Connection: '',
                    id: ''
                },
                {
                    Description: '7 Bar Pressure with 0 TO 10 CFM',
                    srNo: 'A)',
                    price: 0,
                    Connection: 'Each Conn.',
                    id: 2
                },
                {
                    Description: '7 Bar Pressure with 11 TO 20 CFM',
                    srNo: 'B)',
                    price: 0,
                    Connection: 'Each Conn.',
                    id: 2
                },
                {
                    Description: '7 Bar Pressure with 21 TO 30 CFM',
                    srNo: 'C)',
                    price: 0,
                    Connection: 'Each Conn.',
                    id: 2
                },
                {
                    Description: '7 Bar Pressure with 31 TO 40 CFM',
                    srNo: 'D)',
                    price: 0,
                    Connection: 'Each Conn.',
                    id: 2
                },
                {
                    Description: '7 Bar Pressure with 41 TO 50 CFM',
                    srNo: 'E)',
                    price: 0,
                    Connection: 'Each Conn.',
                    id: 2
                },
            ]
        }
        // this.getPowerConnectionList = this.getPowerConnectionList.bind(this)

    }

    componentDidMount() {
        this.getFillList(airCompressedconsumptionId, '', '', '', '')
        this.getFillList(BarActionId, '', '', '', '')
        this.getFillList(CFMActionId, '', '', '', '')
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ? moment(this.props.location.state.Deadline).format('DD-MM-YYYY') : '',
            Is_View: this.props.location.state != null && this.props.location.state.is_View != null ? this.props.location.state.is_View : false,
            StatusID: this.props.location.state != null && this.props.location.state.StatusID != null ? this.props.location.state.StatusID : '',
        })
        setTimeout(() => {
            this.getAirConnectionDetails()
        }, 200);

        this.loadscript()
        console.log("this.props.location.state", this.props.location.state)
    }
    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }

    getFillList(actionID, exhibitionID, moduleID, keyValue, value) {
        // this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === airCompressedconsumptionId) {
                        data.data.map((item, i) => {
                            if (i == 0) {
                                item.Is_checked = 1
                            } else {
                                item.Is_checked = 0
                            }
                        })
                        this.setState({
                            airCompressedconsumptionList: data.data,
                        })
                    }
                    if (actionID === BarActionId) {
                        this.setState({
                            BarList: data.data,
                        })
                    }
                    if (actionID === CFMActionId) {
                        this.setState({
                            CFMList: data.data,
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    handleCancel() {
        if (this.state.Is_View) {
            const CompanyName = this.props.location.state != null && this.props.location.state.companyName ? this.props.location.state.companyName : ''
            // this.props.navigate('/airConnectionApplicationApproval', { state: { companyName: CompanyName } })
            const from = this.props.location.state != null && this.props.location.state.from != null ? this.props.location.state.from : ''
            if (from == 'vendor') {
                this.props.navigate('/VendorModule', { state: { companyName: CompanyName } })
            } else {
                this.props.navigate('/airConnectionApplicationApproval', { state: { companyName: CompanyName } })
            }
        } else {
            this.props.navigate('/manualForms')
        }
    }

    inputValidate() {
        const { Is_Agree } = this.state

        if (Is_Agree == 0 || Is_Agree == false || Is_Agree == null) {
            this.setState({ Is_AgreeErr: 'kindly agree the above mentioned clauses & consequences.' })
        }
    }

    getAirConnectionDetails() {
        const { Remark, Is_Agree, isQuantity, Is_Required, airCompressedconsumptionList, PricingArray } = this.state
        this.setState({ isLoading: true })
        this.props
            .getAirConnectionDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false, DeadlineDate: sessionStorage.getItem('isIndirectAccess') == 'true' ? null : data.DeadlineDate[0]['Deadline'], })

                    if (data.data != '') {
                        this.setState({
                            Remark: data.data[0]['Remark'],
                            Is_Agree: data.data[0]['Is_Agree'],
                            DescriptionOfService: data.data[0]['DescriptionOfService'],
                            Rates: data.data[0]['Rates'],
                            Amount: data.data[0]['Amount'],
                            ServiceID: data.data[0]['ServiceID'],
                            CGST: data.data[0]['CGST'] * 100,
                            SGST: data.data[0]['SGST'] * 100,
                            IGST: data.data[0]['IGST'] * 100,
                            totalAmount: data.data[0]['Amount'] != '' && data.data[0]['Amount'] != null ? data.data[0]['Amount'] : 0,
                            airConsumptionQuantity: data.data[0]['airConsumptionQuantity'],
                            MaxCompressedQuantity: data.data[0]['NoOfConnection'],
                            Quantity: data.data[0]['Quantity'],
                            CurrencyID: data.ChargesList[0]['CurrencyID'],
                            BarQuantity_6: data.ChargesList[0]['Rates'],
                            BarQuantity_7_to_30: data.ChargesList[1]['Rates'],
                            BarId: data.data[0]['BarQuantity'] != null ? data.data[0]['BarQuantity'] : 1,
                            CFMId: data.data[0]['CFMQuantity'] != null ? data.data[0]['CFMQuantity'] : 1,

                        })

                        if (data.data[0]['CompressedAirConsumptionID'] != null) {
                            console.log("airCompressedconsumptionList1122", this.state.airCompressedconsumptionList)
                            // this.state.airCompressedconsumptionList.map((item) => {
                            //     if (data.data[0]['CompressedAirConsumptionID'] == item.CompressedAirConsumptionID) {
                            //         item.Is_checked = 1
                            //         this.setState({
                            //             CompressedAirConsumptionID: item.CompressedAirConsumptionID
                            //         })
                            //     } else {
                            //         item.Is_checked = 0
                            //     }
                            // })
                            // this.setState({
                            //     airCompressedconsumptionList: this.state.airCompressedconsumptionList
                            // })
                        }
                    } else {
                        this.setState({
                            CurrencyID: data.ChargesList[0]['CurrencyID'],
                            BarQuantity_6: data.ChargesList[0]['Rates'],
                            BarQuantity_7_to_30: data.ChargesList[1]['Rates'],
                            Amount: data.ChargesList[0]['Rates'],
                            totalAmount: data.ChargesList[0]['Rates'],
                            Rates: data.ChargesList[0]['Rates'],
                            ServiceID: data.ChargesList[0]['ServiceID'],
                            CGST: data.ChargesList[0]['CGST'] * 100,
                            SGST: data.ChargesList[0]['SGST'] * 100,
                            IGST: data.ChargesList[0]['IGST'] * 100,
                            MaxCompressedQuantity: 1,
                            BarId: 1,
                            CFMId: 1
                        })
                    }

                    var price = 0
                    var price2 = 0
                    var flag = true
                    for (let i = 0; i < PricingArray.length; i++) {
                        if (PricingArray[i]['id'] == 1) {
                            price += data.ChargesList[0]['Rates']
                            PricingArray[i]['price'] = price
                        }

                        if (PricingArray[i]['id'] == 2) {
                            price2 += data.ChargesList[1]['Rates']
                            PricingArray[i]['price'] = price2
                        }
                    }
                    this.setState({ PricingArray: PricingArray })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    addAirConnection() {
        const { Remark, Is_Agree, Amount, MaxCompressedQuantity, ServiceID, Rates, Quantity, CompressedAirConsumptionID, CFMId, BarId, NoOfConnection } = this.state
        const array = []
        array.push({
            'ServiceID': ServiceID,
            'Rates': Rates,
            'Quantity': Quantity,
            'Amount': Amount,
        })
        this.inputValidate()
        this.setState({ isLoading: true })
        // Remark, Is_Agree, Quantity, NoofConnection, CompressedAirConsumptionID, AirConnectionList
        if (Is_Agree) {
            this.props
                .insertUpdateAirConnection_action(Remark, Is_Agree, CFMId, MaxCompressedQuantity, CompressedAirConsumptionID, ServiceID, Rates, Amount, BarId)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            toastError("Plase Enter mandatory feilds!")
            this.inputValidate()
            this.setState({ isLoading: false })
        }
    }

    onQuantity(e, index) {
        const { tableData } = this.state
        const event = e.target.value.replace(/\D/, '')
        tableData.map((data, i) => {
            if (i === index) {
                if (event != '') {
                    data.Quantity = event
                    data.Amount = event * data.Rates
                } else {
                    data.Quantity = ''
                    data.Amount = data.Rates
                }
            }
        })
        const totalAmount = this.sumOfObject(tableData, 'Amount')
        this.setState({ tableData: tableData, totalAmount: totalAmount })
    }

    sumOfObject(arrayOfObjects, propertyName) {
        return arrayOfObjects.reduce((accumulator, currentObject) => {
            return accumulator + currentObject[propertyName];
        }, 0);
    }

    onAirCompressureSelect(e, index) {
        const { airCompressedconsumptionList } = this.state
        const value = e.target.checked
        airCompressedconsumptionList.map((item, i) => {
            if (i == index) {
                item.Is_checked = 1
                this.setState({ CompressedAirConsumptionID: item.CompressedAirConsumptionID })
            } else {
                item.Is_checked = 0
            }
        })

    }

    onairConsumptionQuantity(e) {
        const { Rates } = this.state
        if (e.target.value != '') {
            this.setState({ airConsumptionQuantity: e.target.value.replace(/\D/, ''), Amount: e.target.value * Rates, totalAmount: e.target.value * Rates })
        } else {
            this.setState({ airConsumptionQuantity: '', Amount: 0, totalAmount: 0 })
        }
    }

    onBarQuantity(e) {
        const { BarQuantity_6, BarQuantity_7_to_30, Rates, MaxCompressedQuantity } = this.state
        this.setState({ BarId: e.target.value, }, () => {
            if (e.target.value <= 6) {
                this.setState({ Rates: BarQuantity_6, Amount: MaxCompressedQuantity * BarQuantity_6, totalAmount: MaxCompressedQuantity * BarQuantity_6 })
            } else {
                this.setState({ Rates: BarQuantity_7_to_30, Amount: MaxCompressedQuantity * BarQuantity_7_to_30, totalAmount: MaxCompressedQuantity * BarQuantity_7_to_30 })
            }
        })
    }

    onCFM_Qantity(e) {
        const { Rates, MaxCompressedQuantity } = this.state
        this.setState({ CFMId: e.target.value, }, () => {

            if (e.target.value <= 10) {
                this.setState({ MaxCompressedQuantity: 1, Amount: 1 * Rates, totalAmount: 1 * Rates })
            } else if (e.target.value < 21) {
                this.setState({ MaxCompressedQuantity: 2, Amount: 2 * Rates, totalAmount: 2 * Rates })
            } else if (e.target.value < 31) {
                this.setState({ MaxCompressedQuantity: 3, Amount: 3 * Rates, totalAmount: 3 * Rates })
            } else if (e.target.value < 41) {
                this.setState({ MaxCompressedQuantity: 4, Amount: 4 * Rates, totalAmount: 4 * Rates })
            } else if (e.target.value < 51) {
                this.setState({ MaxCompressedQuantity: 5, Amount: 5 * Rates, totalAmount: 5 * Rates })
            } else if (e.target.value < 61) {
                this.setState({ MaxCompressedQuantity: 6, Amount: 6 * Rates, totalAmount: 6 * Rates })
            } else if (e.target.value < 71) {
                this.setState({ MaxCompressedQuantity: 7, Amount: 7 * Rates, totalAmount: 7 * Rates })
            } else if (e.target.value < 81) {
                this.setState({ MaxCompressedQuantity: 8, Amount: 8 * Rates, totalAmount: 8 * Rates })
            } else if (e.target.value < 91) {
                this.setState({ MaxCompressedQuantity: 9, Amount: 9 * Rates, totalAmount: 9 * Rates })
            } else if (e.target.value <= 100) {
                this.setState({ MaxCompressedQuantity: 10, Amount: 10 * Rates, totalAmount: 10 * Rates })
            }
        })
    }


    render() {
        const { isLoading, Is_Agree, Remark, Is_AgreeErr, currentDate, DeadlineDate, airConsumptionQuantity, tableData, totalAmount, IGST, Is_View, CurrencyID, CompressedAirConsumptionID, MaxCompressedQuantity, Quantity, TotalAmount, airCompressedconsumptionList, DescriptionOfService, Rates, Amount, CGST, SGST, StatusID, BarList, BarId, CFMId, CFMList, BarQuantity_6, BarQuantity_7_to_30, PricingArray } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : <HeaderWithLogo />
                }
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorDrawer /> : <AdminDrawer />
                }
                <div className="main-content app-content with-dr-container" style={{ marginTop: !Is_View ? '0px' : '' }}>
                    <div className="">
                        <div className="row">
                            {
                                JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                    <ExhibitorTopMenu /> : null
                            }
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Air Compressor</h3>
                                    </div>
                                    <div class="card-body p-1 m-2">
                                        <ul style={{ lineHeight: '25px', textAlign: 'left', padding: 0 }}>
                                            <li>1) <strong>Exhibitors are not permitted to use their own compressors for exhibiting their products. </strong>A maximum pressure of 7 bar would be made available during the exhibition through ½ inch ball valve. Exhibitors must specify the volume of compressed air required by their exhibits. <strong>However, exhibitors who wish to require pressure more than 7 bar, subject to the confirmation from official agency appointed by INDIA ITME Society at an additional cost, if facility is provided.</strong>
                                            </li>
                                            <li>2) Compressed air connection will be made available on pillars near stall or at any one corner of the stall.
                                            </li>
                                            <li>3) Organisers reserve the right to determine the compressed air to be apportioned for the direct/indirect Exhibitors and Exhibitors should agree to abide by such observations and remit charges to organisers accordingly.
                                            </li>
                                        </ul>
                                        <ol style={{ listStyleType: '1', textAlign: 'left' }}>
                                            <li>All compressors will be located outside the booth to avoid noise pollution, with a tube and stop valve provided at the booth itself.</li>
                                            <li>Supply of air is not totally “clean” and “dry”.</li>
                                            <li>Exhibitor's should inform their compressed air connection/s requirements along with the payment towards compress air connection/s charges before {moment(DeadlineDate).format('DD.MM.YYYY')}</li>
                                            <li>We cannot guarantee provision of orders received after <strong> deadline ({moment(DeadlineDate).format('DD.MM.YYYY')})</strong>.</li>
                                            <li>If details of requirements along with the payment from Exhibitors , received by INDIA ITME Society after<strong> deadline ({moment(DeadlineDate).format('DD.MM.YYYY')}), then additional surcharge of 25% of basic rates shall be applicable to the Exhibitors.</strong>.</li>
                                        </ol>
                                        <div style={{ textAlign: 'left' }}>
                                            <strong>The tariff for Compressed Air connection/s will be as under:</strong>
                                            <ol style={{ listStyleType: '1', textAlign: 'left' }}>
                                                {CurrencyID == 1 ?
                                                    <ul style={{ listStyleType: 'circle', textAlign: 'left' }}>
                                                        <li><strong>Rs {BarQuantity_6}/-</strong> per connection upto 6 Bar pressure</li>
                                                        <li><strong>Rs {BarQuantity_7_to_30}/-</strong> per connection of 7 Bar pressure
                                                            Plus GST @ 18% (as per Government rule) or as applicable is payable with all the payments will not be refunded.
                                                        </li>
                                                    </ul>
                                                    :
                                                    <ul style={{ listStyleType: 'circle', textAlign: 'left' }}>
                                                        <li><strong>US Dollar {BarQuantity_6}/-</strong> per connection upto 6 Bar pressure</li>
                                                        <li><strong>US Dollar {BarQuantity_7_to_30}/-</strong> per connection from 7 Bar to 30 Bar pressure
                                                            Plus GST @ 18% (as per Government rule) or as applicable is payable with all the payments will not be refunded.
                                                        </li>
                                                    </ul>
                                                }
                                            </ol>
                                        </div>

                                        <div class="table-responsive mt-3">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-5 w-50 air-connection-table">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" style={{ width: '5%' }}>Sr No </th>
                                                        <th className="text-center">Description</th>
                                                        {CurrencyID == 1 ?
                                                            <th className="text-center">For National Exhibitor <br></br>Amt. (INR)</th> :
                                                            <th className="text-center">For International Exhibitor <br></br>Amt. (USD)</th>
                                                        }
                                                        <th className="text-center">Connection</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr >
                                                        <td colSpan={5}>Providing & Laying Pipe Line for Compressed Air Connection with Compressor on Hire Basis</td>
                                                    </tr>
                                                    {PricingArray.map((item, i) => (
                                                        <tr>
                                                            <td className="text-center">{item.srNo}</td>
                                                            <td className="text-center">{item.Description}</td>
                                                            <td className="text-center">{item.price}</td>
                                                            <td className="text-center">{item.Connection}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="table-responsive mt-3">
                                            <p className="m-0">For more details or any queries please contact: itme@india-itme.com</p>
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">

                                            </table>
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-5">
                                                <thead>
                                                    <tr>
                                                        <th style={{}} colSpan={2}>Description of Service</th>
                                                        <th style={{}}>Type of Compressed Air consumption</th>
                                                        <th style={{}} colSpan={2}>Quantity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2}>{DescriptionOfService}</td>
                                                        <td>
                                                            <div className="d-flex" style={{ flexDirection: 'column' }}>
                                                                {console.log("airCompressedconsumptionList", airCompressedconsumptionList)}
                                                                <div>
                                                                    {
                                                                        airCompressedconsumptionList.map((data, i) => (
                                                                            <>
                                                                                <input type="radio" style={{ cursor: !Is_View ? 'pointer' : 'default' }}
                                                                                    checked={data.Is_checked == 1}
                                                                                    onChange={(e) => this.onAirCompressureSelect(e, i)}
                                                                                    maxLength={10}
                                                                                    disabled={Is_View}
                                                                                /><strong>  {data.Type} <br /> </strong>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td colSpan={2}>
                                                            {/* <input type="text" className="form-control"
                                                                value={airConsumptionQuantity}
                                                                onChange={(e) => this.onairConsumptionQuantity(e)}
                                                                maxLength={10}
                                                                disabled={Is_View}
                                                            /> */}
                                                            <label>Bar Quantity</label>
                                                            <select
                                                                className="form-control"
                                                                value={BarId}
                                                                onChange={e => this.onBarQuantity(e)}
                                                                disabled={Is_View}
                                                            >
                                                                {/* <option value={''}>Select Bar Quantity</option> */}
                                                                {
                                                                    BarList.length > 0 && BarList.map((data) => (
                                                                        <option key={data.BarQuantity} value={data.BarQuantity}
                                                                        >{data.BarQuantity}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            <label className="mt-2">CFM Quantity</label>
                                                            <select
                                                                className="form-control"
                                                                value={CFMId}
                                                                onChange={e => this.onCFM_Qantity(e)}
                                                                disabled={Is_View}
                                                            >
                                                                {/* <option value={''}>Select CFM Quantity</option> */}
                                                                {
                                                                    CFMList.length > 0 && CFMList.map((data) => (
                                                                        <option key={data.CFMQuantity} value={data.CFMQuantity} >{data.CFMQuantity}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '25%' }}>Description of Service</th>
                                                        <th style={{ width: '25%' }}>Max Compressed Air</th>
                                                        <th style={{ width: '25%' }}>Rates  {CurrencyID == 1 ? '(INR)' : '(USD)'}</th>
                                                        {/* <th style={{ width: '25%' }}>Quantity</th> */}
                                                        <th style={{ width: '25%' }}>Amount   {CurrencyID == 1 ? '(INR)' : '(USD)'}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>No. of Connections required</td>
                                                        <td>
                                                            <input type="text" className="form-control"
                                                                value={MaxCompressedQuantity}
                                                                onChange={(e) => this.setState({ MaxCompressedQuantity: e.target.value.replace(/\D/, '') })}
                                                                maxLength={10}
                                                                // disabled={Is_View}
                                                                disabled={true}
                                                            />
                                                        </td>
                                                        <td>{Rates}</td>
                                                        {/* <td>
                                                            <input type="text" className="form-control"
                                                                value={Quantity}
                                                                onChange={(e) => this.setState({ Quantity: e.target.value.replace(/\D/, '') })}
                                                                maxLength={10}
                                                                disabled={Is_View}
                                                            />
                                                        </td> */}
                                                        <td>{Amount != '' && Amount != null ? Amount : 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}></td>
                                                        <td><strong>Total</strong></td>
                                                        <td><strong>{totalAmount}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}></td>
                                                        <td><strong>CGST @ {CGST}%</strong></td>
                                                        <td><strong>{((CGST / 100) * totalAmount).toFixed(2)}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}></td>
                                                        <td><strong>SGST @ {SGST}%</strong></td>
                                                        <td><strong>{((SGST / 100) * totalAmount).toFixed(2)}</strong></td>
                                                    </tr>
                                                    <tr style={{ backgroundcolor: "#eee" }}>
                                                        <td colSpan={2}></td>
                                                        <td><strong>Grand Total</strong></td>
                                                        <td><strong>{
                                                            (CGST / 100) * totalAmount + (SGST / 100) * totalAmount + totalAmount
                                                        }</strong></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-1">
                                    <form>
                                        <strong> <label>Remark</label></strong>
                                        <textarea class="form-control"
                                            value={Remark}
                                            disabled={Is_View}
                                            onChange={(e) => this.setState({ Remark: e.target.value })}
                                            maxLength={300}
                                            rows="3">
                                        </textarea>
                                        <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                            <input type="checkbox"
                                                checked={Is_Agree}
                                                disabled={Is_View}
                                                onChange={(e) => this.setState({ Is_Agree: e.target.checked, Is_AgreeErr: '' })}
                                            />
                                            <strong> We hereby read & understood the above mentioned clauses & consequences. We accept & agree to abide by the same<span className="required">*</span>.</strong></label>{<span className='text-danger text-start text-left d-block mt-1'>{Is_AgreeErr}</span>}
                                    </form>
                                </div>

                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>
                            <div class='d-flex m-3'>
                                {!Is_View ?
                                    <button
                                        type="button"
                                        className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                        style={{ marginRight: 10 }}
                                        disabled={currentDate > DeadlineDate}
                                        onClick={() => this.addAirConnection()}
                                    >
                                        Submit
                                    </button>
                                    : <></>}
                                <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { insertUpdateAirConnection_action, getAirConnectionDetails_action, fill_action })(AirConnectionApplication));