import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Footer from '../../../common_components/Footer';
import Drawer from '../../../common_components/Drawer';
import { verifyPartnerCode_action, sendPartnerRegistrationform_action, getPartnerStaticData_action, verifyPartnerEmail_action } from '../../../actions/partner_module_action/partner_registration_action/partner_registration_action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import Select from 'react-select';
import {
    clearSessionStorage, countryActionId,
    stateActionId, cityActionId, pincodeActionId, exhibitionStatusActionId, countryIWithCodeActionId, verifyPrimaryEmailIDActionId, primaryChapterListActionId, onlinePresenceListActionId,
    toastError, toastSuccess
} from '../../../utils/constants'
import Pagination from '../../../utils/pagination'
import { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { fill_action } from '../../../actions/fillCombo_action'
import StepHeader from '../../registration/stepHeader';
import PrimaryChapter from '../../registration/primary_chapter';
import Description from '../../registration/description';
import Resizer from "react-image-file-resizer";
import { contactNoValidator, panNoValidator, tanNoValidator, gstNoValidator, emailValidator } from '../../../utils/Validator'
import $ from 'jquery'
import VisitorHeader from '../../../common_components/visitor_module_header';


/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

class Partner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isDisplayCountryList: false,
            isDisplayStateList: false,
            isDisplayCityList: false,
            isDisplayPincodeList: false,
            isAgree: false,
            isDisplayCountryList2: false,

            isPageValid: false,

            formID: 1,
            formName: 'Knowledge Partner',
            exhibitionID: '',
            countryList: [],
            tempCountryList: [],
            stateList: [],
            tempStateList: [],
            countryList2: [],
            tempCountryList2: [],
            cityList: [],
            tempCityList: [],
            pincodeList: [],
            tempPincodeList: [],
            selectedChapterList: [],
            list1: [{
                Deliverable: '',
                Quantity: '',
                ScheduleDuration: '',
            }],
            list2: [{
                FirstName: '',
                LastName: '',
                Designation: '',
                Email: '',
                Mobile: '',
                country: '',
                CountryID: '',
                isDisplayCountryList2: false
            }],
            offerList: [],

            TandC: [],

            primaryChapterListData: [],
            level0: [],
            level2: [],
            level3: [],
            level4: [],
            primaryChapterListLevel2: [],
            primaryChapterListLevel3: [],
            primaryChapterListLevel4: [],
            level4DummyId: '',

            totalCirculationList: [],
            selectedTotalCirculation: [],
            overseasCirculationList: [],
            selectedOverseasCirculation: [],
            regionsCoveredinIndiaList: [],
            selectedRegionsCoveredinIndia: [],
            onlinePresenceList: [],


            refNo: '',
            firstName: '',
            isOther_title_PersonIncharge: false,
            other_title_PersonIncharge: '',
            title_PersonIncharge: 'Mr',
            lastName: '',
            designation: '',
            companyName: '',
            fasciaName: '',
            add1: '',
            add2: '',
            country: '',
            state: '',
            city: '',
            Pin: '',
            countryId: '',
            preMobNo: '',
            altPreMobNo: '',
            mobNo: '',
            altMobNo: '',
            telNo: '',
            preTelNo: '',
            primaryEmail: '',
            secondaryEmail: '',
            website: '',

            logoImg: '',
            logoImgErr: '',
            logoImgType: '',

            //err
            countryErr: '',
            stateErr: '',
            cityErr: '',
            PinErr: '',
            refNoErr: '',
            firstNameErr: '',
            lastNameErr: '',
            designationErr: '',
            companyNameErr: '',
            fasciaNameErr: '',
            add1Err: '',
            mobNoErr: '',
            emailErr: '',
            telNoErr: '',
            // onlinePresenceErr:'',
            isDataLoad: false

        }
        this.getFillList = this.getFillList.bind(this)
        this.onCitySearch = this.onCitySearch.bind(this)
        this.renderCityList = this.renderCityList.bind(this)
        this.onCountrySearch = this.onCountrySearch.bind(this)
        this.renderCountryList = this.renderCountryList.bind(this)
        this.onCountrySearch2 = this.onCountrySearch2.bind(this)
        this.renderCountryList2 = this.renderCountryList2.bind(this)
        this.onPincodeSearch = this.onPincodeSearch.bind(this)
        this.renderPincodeList = this.renderPincodeList.bind(this)
        this.onStateSearch = this.onStateSearch.bind(this)
        this.renderStateList = this.renderStateList.bind(this)
        this.preventMinus = this.preventMinus.bind(this)
        this.maxLengthCheck = this.maxLengthCheck.bind(this)
        this.OnAddList1 = this.OnAddList1.bind(this)
        this.OnAddList2 = this.OnAddList2.bind(this)
        this.enterDelivables = this.enterDelivables.bind(this)
        this.enterQuantity = this.enterQuantity.bind(this)
        this.enterSchedule = this.enterSchedule.bind(this)
        this.enterCountry = this.enterCountry.bind(this)
        this.selectCountry = this.selectCountry.bind(this)
        this.enterDesignation = this.enterDesignation.bind(this)
        this.enterEmail = this.enterEmail.bind(this)
        this.enterFirstName = this.enterFirstName.bind(this)
        this.enterLastName = this.enterLastName.bind(this)

        this.onCheckLevel1 = this.onCheckLevel1.bind(this)
        this.onCheckLevel2 = this.onCheckLevel2.bind(this)
        this.onCheckLevel3 = this.onCheckLevel3.bind(this)
        this.onSelectLevel4 = this.onSelectLevel4.bind(this)
        this.record = this.record.bind(this)
        this.recordThree = this.recordThree.bind(this)
        this.recordTwo = this.recordTwo.bind(this)
        this.updateChapterList = this.updateChapterList.bind(this)
        this.onChapterRemove = this.onChapterRemove.bind(this)

        this.handleTotalCirculationSelect = this.handleTotalCirculationSelect.bind(this)
        this.handleOverseasCirculationSelect = this.handleOverseasCirculationSelect.bind(this)
        this.handleRegionsCoveredinIndiaSelect = this.handleRegionsCoveredinIndiaSelect.bind(this)
        this.onOnlinePresenceSelect = this.onOnlinePresenceSelect.bind(this)

        this.onPdfSelect = this.onPdfSelect.bind(this)
        this.onFilePick = this.onFilePick.bind(this)
        this.resizeFile = this.resizeFile.bind(this)
        this.onCrossClick = this.onCrossClick.bind(this)

        this.verifyPartnerCode = this.verifyPartnerCode.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.verifyEmail = this.verifyEmail.bind(this)

    }

    componentDidMount() {
        var exhCode = ""
        if (this.props.location != null && this.props.location.state == null) {
            var url = this.props.location.pathname != null && this.props.location.pathname.split("/")
            exhCode = url[url.length - 1]
            // sessionStorage.setItem("sessionExhibitionID", exhCode)
            console.log("h7hyhyhh", this.props.location.state, 'this.props.location.state', exhCode)
            this.getFillList(countryIWithCodeActionId, '', '', '', exhCode, '')
        }
    }

    // Get Fill Lists
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === countryIWithCodeActionId) {
                        console.log("country data", data.data[0]['ExhibitionID'])
                        var emailArr = data.data[0].ExhMailEmail != '' && data.data[0].ExhMailEmail != null ? data.data[0].ExhMailEmail.split(",") : []
                        var mobileArr = data.data[0].ExhMailMobile != '' && data.data[0].ExhMailMobile != null ? data.data[0].ExhMailMobile.split(",") : []
                        sessionStorage.setItem("sessionExhibitionID", data.data[0]['ExhibitionID'])
                        sessionStorage.setItem("exhibitionAddress", data.data[0]['Address'])
                        sessionStorage.setItem("sessionBannerPath", data.data[0]['Banner'])
                        sessionStorage.setItem("exhibitionDateFrom", data.data[0]['ExhibitionDateFrom'])
                        sessionStorage.setItem("exhibitionDateTo", data.data[0]['ExhibitionDateTo'])
                        sessionStorage.setItem("ExhibitionCountryID", data.data[0]['ExhibitionCountryID'])
                        sessionStorage.setItem("sessionLogoPath", data.data[0]['Logo'])

                        sessionStorage.setItem("sessionExhibitionName", data.data[0]['ExhibitionName'])
                        sessionStorage.setItem("sessionExhibitionEmail", JSON.stringify(emailArr))
                        sessionStorage.setItem("sessionExhibitionMobileNo", JSON.stringify(mobileArr))
                        sessionStorage.setItem("sessionExhMailWebsite", data.data[0]['ExhMailWebsite'])
                        sessionStorage.setItem("isVisitorB2B", data.data[0]['isVisitorB2B'])
                        sessionStorage.setItem("isVisitorSeminar", data.data[0]['isVisitorSeminar'])

                        sessionStorage.setItem("ExhVisitorVisaIssueDate", data.data[0]['ExhVisitorVisaIssueDate'])
                        this.setState({
                            isDataLoad: true,
                            // bannerPath: data.data[0]['Banner'] != null && data.data[0]['Banner']
                        })

                        // this.getFillList(countryActionId, data.data[0]['ExhibitionID'], '', '', '', '')
                        // resolve(true)
                    }
                    if (actionID === countryActionId) {
                        console.log("country data", data.data)
                        var overseasList = []
                        data.data.length > 0 && data.data.filter(subdata => {
                            subdata.Code != 91 && overseasList.push(subdata)
                        })
                        this.setState({
                            countryList: data.data,
                            tempCountryList: data.data,
                            countryList2: data.data,
                            tempCountryList2: data.data,
                            totalCirculationList: data.data,
                            overseasCirculationList: overseasList
                        })
                    }
                    // state list
                    if (actionID === stateActionId) {
                        console.log("stateList data", data.data)
                        this.setState({
                            stateList: data.data,
                            tempStateList: data.data,
                            regionsCoveredinIndiaList: data.data
                        })
                    }
                    if (actionID === exhibitionStatusActionId) {
                        console.log("stateList data", data.data)
                        // this.setState({
                        //     stateList: data.data,
                        //     tempStateList: data.data,
                        //     regionsCoveredinIndiaList: data.data
                        // })
                        this.getFillList(countryIWithCodeActionId, '', '', '', data.data[0].Code, '');

                        // return 
                    }
                    // city list
                    if (actionID === cityActionId) {
                        console.log("city data", data.data)
                        this.setState({
                            cityList: data.data,
                            tempCityList: data.data,
                        })
                    }
                    // pincode
                    if (actionID === pincodeActionId) {
                        console.log("pincode data", data.data)
                        this.setState({
                            pincodeList: data.data,
                            tempPincodeList: data.data,
                        })
                    }
                    if (actionID === primaryChapterListActionId) {
                        console.log("primaryChapterListActionId 121212", data.data)
                        // for (var i = 0; i < data.data['level2'].length; i++) {
                        //     data.data['level2'][i].isHighlight = false;
                        // }
                        this.setState({
                            primaryChapterListData: data.data['primaryChapter'],
                            level0: data.data['level0'],
                            level2: data.data['level2'],
                            level3: data.data['level3'],
                            level4: data.data['level4'],
                            // isLoading: false,
                        })
                    }
                    if (actionID === onlinePresenceListActionId) {
                        console.log(" data", data.data)
                        data.data.map((op) => (op.isSelect = false))
                        this.setState({
                            onlinePresenceList: data.data,
                        })
                    }
                    this.setState({ isLoading: false })

                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }
    // Search Country
    onCountrySearch(e) {
        const { countryList, tempCountryList } = this.state
        this.setState({ country: '' })
        if (e.target.value != '') {
            console.log("tempCountryList ++++----------->", tempCountryList)

            var searchCoutryList = tempCountryList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            console.log("searchCoutryList ++++----------->", searchCoutryList)

            this.setState({
                country: e.target.value,
                countryErr: '',
                tempCountryList: searchCoutryList,
                isDisplayCountryList: true
            })
        }
        else {
            this.setState({
                country: e.target.value,
                tempCountryList: countryList,
                isDisplayCountryList: false
            })
        }
    }
    // render Country
    renderCountryList() {
        const { tempCountryList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempCountryList.length > 0 && tempCountryList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                country: data.Description, countryId: data.Code, isDisplayCountryList: false, preMobNo: `+${data.CountryCode}`,
                                altPreMobNo: `+${data.CountryCode}`
                            })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }
    // Search Country
    onCountrySearch2(e, i) {
        const { countryList2, tempCountryList2 } = this.state
        // this.setState({ country: '' })
        console.log(e.target.value, tempCountryList2)
        if (e.target.value != '') {
            console.log("tempCountryList ++++----------->", tempCountryList2)

            var searchCoutryList = tempCountryList2.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            console.log("searchCoutryList ++++----------->", searchCoutryList)

            this.enterCountry(e, i)
            this.setState({
                tempCountryList2: searchCoutryList,
            })
        }
        else {
            this.enterCountry(e, i)
            this.setState({
                tempCountryList2: countryList2,
            })
        }
    }
    // render Country
    renderCountryList2(i) {
        const { tempCountryList2, isDisplayCountryList2 } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempCountryList2.length > 0 && tempCountryList2.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.selectCountry(data.Code, data.Description, i)}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }
    // search state
    onStateSearch(e) {
        const { tempStateList, stateList } = this.state
        if (e.target.value != '') {
            var searchStateList = tempStateList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                state: e.target.value,
                stateErr: '',
                tempStateList: searchStateList,
                isDisplayStateList: true
            })
        } else {
            this.setState({
                state: e.target.value,
                tempStateList: stateList,
                isDisplayStateList: false
            })
        }
    }
    // render state
    renderStateList() {
        const { tempStateList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempStateList.length > 0 && tempStateList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ state: data.Description, isDisplayStateList: false })}
                        // onClick={e => this.callStateList(data.Description)}
                        >{data.Description}</li>

                    ))
                }
            </ul>
        )
    }
    // search city
    onCitySearch(e) {
        const { cityList, tempCityList } = this.state
        if (e.target.value != '') {
            var searchCityList = tempCityList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                city: e.target.value,
                cityErr: '',
                tempCityList: searchCityList,
                isDisplayCityList: true
            })
        } else {
            this.setState({
                city: e.target.value,
                tempCityList: cityList,
                isDisplayCityList: false
            })
        }
    }
    // render city
    renderCityList() {
        const { tempCityList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempCityList.length > 0 && tempCityList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ city: data.Description, isDisplayCityList: false })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }
    onPincodeSearch(e) {
        const { pincodeList, tempPincodeList, billCity } = this.state
        if (e.target.value != '') {
            var searchPincodeList = tempPincodeList.filter(x => {
                return (
                    x
                        .Pincode
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                Pin: e.target.value,
                PinErr: '',
                tempPincodeList: searchPincodeList,
                isDisplayPincodeList: true
            })
        } else {
            this.setState({
                Pin: e.target.value,
                tempPincodeList: pincodeList,
                isDisplayPincodeList: false
            })
        }
    }
    renderPincodeList() {
        const { tempPincodeList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempPincodeList.length > 0 && tempPincodeList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                Pin: data.Pincode,
                                isDisplayPincodeList: false
                            })}
                        >{data.Pincode}</li>
                    ))
                }
            </ul>
        )
    }
    OnAddList1(e) {
        e.preventDefault();
        const { list1 } = this.state
        var id = list1.length + 1
        var map = {
            Deliverable: '',
            Quantity: '',
            ScheduleDuration: '',
        }
        list1.push(map)
        this.setState({ list1: list1, })
        console.log(list1)
    }
    enterDelivables(e, index) {
        const { list1 } = this.state
        const list = [...list1]
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['Deliverable'] = e.target.value
            }
        }
        this.setState({
            list1: list
        })
    }
    enterQuantity(e, index) {
        const { list1 } = this.state
        const list = [...list1]
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['Quantity'] = e.target.value
            }
        }
        this.setState({
            list1: list
        })
    }
    enterSchedule(e, index) {
        const { list1 } = this.state
        const list = [...list1]
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['ScheduleDuration'] = e.target.value
            }
        }
        this.setState({
            list1: list
        })
    }
    OnAddList2(e) {
        e.preventDefault();
        const { list2 } = this.state
        var id = list2.length + 1
        var map = {
            FirstName: '',
            LastName: '',
            Designation: '',
            Email: '',
            Mobile: '',
            country: '',
            CountryID: '',
            isDisplayCountryList2: false
        }
        list2.push(map)
        this.setState({ list2: list2, })
        console.log(list2)
    }
    enterFirstName(e, index) {
        const { list2 } = this.state
        const list = [...list2]
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['FirstName'] = e.target.value
            }
        }
        this.setState({
            list2: list
        })
    }
    enterLastName(e, index) {
        const { list2 } = this.state
        const list = [...list2]
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['LastName'] = e.target.value
            }
        }
        this.setState({
            list2: list
        })
    }
    enterDesignation(e, index) {
        const { list2 } = this.state
        const list = [...list2]
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['Designation'] = e.target.value
            }
        }
        this.setState({
            list2: list
        })
    }
    enterEmail(e, index) {
        const { list2 } = this.state
        const list = [...list2]
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['Email'] = e.target.value
            }
        }
        this.setState({
            list2: list
        })
    }
    enterCountry(e, index) {
        const { list2 } = this.state

        const list = [...list2]
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['country'] = e.target.value
                if (e.target.value != '') {
                    list[i]['isDisplayCountryList2'] = true
                }
                else {
                    list[i]['isDisplayCountryList2'] = false
                }
            }
        }
        this.setState({
            list2: list
        })
    }
    selectCountry(value, name, index) {
        const { list2, countryList2 } = this.state
        console.log(value)
        const list = [...list2]
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['CountryID'] = value
                list[i]['country'] = name
                list[i]['isDisplayCountryList2'] = false
            }
        }
        this.setState({
            list2: list, tempCountryList2: countryList2
        })
    }
    enterMobileNo(e, index) {
        const { list2 } = this.state
        const list = [...list2]
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['Mobile'] = e.target.value
            }
        }
        this.setState({
            list2: list
        })
    }
    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }
    preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    }

    updateChapterList(id) {
        const { primaryChapterListData, level0, level2, level3, level4, selectedChapterList } = this.state
        var promise = new Promise((resolve, rej) => {
            var selectedCahpterId = []
            for (var i = 0; i < selectedChapterList.length; i++) {
                selectedCahpterId.push(selectedChapterList[i].ChapterID)
            }
            if (!selectedCahpterId.includes(id)) {
                for (var i = 0; i < primaryChapterListData.length; i++) {
                    if (primaryChapterListData[i].IsSelect && !selectedChapterList.includes(primaryChapterListData[i])) {
                        selectedChapterList.push(primaryChapterListData[i])
                    }
                }
                for (var i = 0; i < level0.length; i++) {
                    if (level0[i].IsSelect && !selectedChapterList.includes(level0[i])) {
                        selectedChapterList.push(level0[i])
                    }
                }
                for (var i = 0; i < level2.length; i++) {
                    if (level2[i].IsSelect && !selectedChapterList.includes(level2[i])) {
                        selectedChapterList.push(level2[i])
                    }
                }
                for (var i = 0; i < level3.length; i++) {
                    if (level3[i].IsSelect && !selectedChapterList.includes(level3[i])) {
                        selectedChapterList.push(level3[i])
                    }
                }
                for (var i = 0; i < level4.length; i++) {
                    if (level4[i].IsSelect && !selectedChapterList.includes(level4[i])) {
                        selectedChapterList.push(level4[i])
                    }
                }
            }
            else {
                for (var i = 0; i < selectedChapterList.length; i++) {
                    if (selectedChapterList[i].ChapterID == id) {
                        selectedChapterList.splice(i, 1)
                    }
                }
            }
            resolve(true)

        })

        promise.then((result) => {
            if (result) {
                console.log(selectedChapterList)

                this.setState({ selectedChapterList: selectedChapterList })

            }
        })
    }

    // on check level 1
    onCheckLevel1(e, id) {
        const { level2, primaryChapterListLevel2, primaryChapterListLevel3, primaryChapterListLevel4, primaryChapterListData, level3, level4 } = this.state
        var promise = new Promise((resolve, rej) => {
            const event = e.target.checked
            for (let i = 0; i < primaryChapterListData.length; i++) {
                if (primaryChapterListData[i]['ChapterID'] == id) {
                    if (event == true) {
                        primaryChapterListData[i]['IsSelect'] = true

                        level2.forEach((level2) => {
                            if (level2.ParentChapterID == id) {
                                level2.IsSelect = true

                                level3.forEach((level3) => {
                                    if (level2.ChapterID == level3.ParentChapterID) {
                                        level3.IsSelect = true

                                        level4.forEach((level4) => {
                                            if (level3.ChapterID == level4.ParentChapterID) {
                                                level4.IsSelect = true

                                            }
                                        });
                                    }
                                });
                            }
                        });

                    } else {
                        primaryChapterListData[i]['IsSelect'] = false

                        level2.forEach((level2) => {
                            if (level2.ParentChapterID == id) {
                                level2.IsSelect = false

                                level3.forEach((level3) => {
                                    if (level2.ChapterID == level3.ParentChapterID) {
                                        level3.IsSelect = false

                                        level4.forEach((level4) => {
                                            if (level3.ChapterID == level4.ParentChapterID) {
                                                level4.IsSelect = false

                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            }
            setTimeout(() => {
                this.setState({ level2: level2 })
                resolve(true)
            }, 200);
        })
        promise.then((result) => {
            if (result) {
                this.updateChapterList(id)
            }
        })
    }
    // to call level second list
    record(level2data) {
        const { primaryChapterListLevel2, level2, level3, level4 } = this.state
        var tempLevel1 = [];
        for (var j = 0; j < level2.length; j++) {
            if (level2data.ChapterID == level2[j]['ParentChapterID']) {
                tempLevel1.push(level2[j]);
                primaryChapterListLevel2.push(level2[j]);
            }
        }
        return (
            <>
                <div id={'collapse_l1_' + level2data.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l1_' + level2data.ChapterID} data-bs-parent="#accordionExample">
                    {
                        tempLevel1.length > 0 && tempLevel1.map((level2data, i) => (
                            <>
                                {
                                    // level2data.ParentChapterID == primaryChapterRadio &&
                                    <div class="accordion-body">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header check-out" id={'heading_l1_' + level2data.ChapterID}>

                                                <button class={level2data.isHighlight ? "accordion-button collapsed selected-chapter  tit-name" : "accordion-button collapsed  tit-name"}
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={'#collapse_l1_' + level2data.ChapterID} aria-expanded="false"
                                                    aria-controls={'collapse_l1_' + level2data.ChapterID}>
                                                    {level2data.IsCheckbox &&
                                                        <input type="checkbox" className="from-left-sp check-out-in" name="" key={level2data.ChapterID}
                                                            checked={level2data.IsSelect}
                                                            onClick={e => this.onCheckLevel2(e, level2data.ChapterID, tempLevel1)}
                                                        />
                                                    }
                                                    {level2data.ChapterName}
                                                </button>
                                            </h2>
                                            {this.recordTwo(level2data, level3, level4)}
                                        </div>
                                    </div>
                                }

                            </>
                        ))
                    }
                </div>
            </>
        );
    }
    // on check level 2
    onCheckLevel2(e, id, tempLevel1) {
        const { level2, primaryChapterListLevel3, level3, primaryChapterListLevel4, primaryChapterListData, level4 } = this.state
        var promise = new Promise((resolve, rej) => {
            const event = e.target.checked
            for (let i = 0; i < level2.length; i++) {
                if (level2[i]['ChapterID'] == id) {
                    if (event == true) {
                        level2[i]['IsSelect'] = true

                        level3.forEach((level3) => {
                            if (level3.ParentChapterID == id) {
                                level3.IsSelect = true
                                level4.forEach((level4) => {
                                    if (level3.ChapterID == level4.ParentChapterID) {
                                        level4.IsSelect = true
                                    }
                                });
                            }
                        });

                    } else {
                        level2[i]['IsSelect'] = false

                        level3.forEach((level3) => {
                            if (level3.ParentChapterID == id) {
                                level3.IsSelect = false
                                level4.forEach((level4) => {
                                    if (level3.ChapterID == level4.ParentChapterID) {
                                        level4.IsSelect = false
                                    }
                                });
                            }
                        });
                    }

                }
            }
            console.log("tempLevel1", tempLevel1)

            for (let i = 0; i < primaryChapterListData.length; i++) {
                for (let j = 0; j < level2.length; j++) {
                    if (level2[j]['ChapterID'] == id) {
                        if (primaryChapterListData[i]['ChapterID'] == level2[j]['ParentChapterID']) {
                            const allSelectedChecked = tempLevel1.every(item => item.IsSelect == 1)
                            console.log("allSelectedChecked", allSelectedChecked)
                            if (allSelectedChecked) {
                                primaryChapterListData[i]['IsSelect'] = true
                            } else {
                                primaryChapterListData[i]['IsSelect'] = false
                            }
                        }
                    }
                }
            }

            setTimeout(() => {
                this.setState({ level2: level2 })
                resolve(true)
            }, 200);
        })
        promise.then((result) => {
            if (result) {
                this.updateChapterList(id)
            }
        })



    }
    // to call level third list 
    recordTwo(level2, level3, level4) {
        const { primaryChapterListLevel3 } = this.state
        // var newdata = JSON.stringify(data);
        var tempLevel3 = [];
        for (var j = 0; j < level3.length; j++) {
            if (level2.ChapterID == level3[j]['ParentChapterID']) {
                tempLevel3.push(level3[j]);
                primaryChapterListLevel3.push(level3[j]);
            }
        }
        return (
            <>
                <div id={'collapse_l1_' + level2.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l1_' + level2.ChapterID} >
                    {
                        tempLevel3 && tempLevel3.map(level3data => {
                            return (
                                <div class="accordion-body">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id={'heading_l2_' + level3data.ChapterID}>
                                            <button class="accordion-button collapsed " type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={'#collapse_l2_' + level3data.ChapterID}
                                                aria-expanded="false"
                                                aria-controls={'collapse_l2_' + level3data.ChapterID}
                                            >
                                                {/* // checkbox for level3 */}
                                                {level3data.IsCheckbox &&
                                                    < input type="checkbox" className="from-left-sp"

                                                        name="" key={level3data.ChapterID} checked={level3data.IsSelect}
                                                        onClick={e => this.onCheckLevel3(e, level3data.ChapterID, tempLevel3)} />
                                                }

                                                {level3data.ChapterName} </button>
                                        </h2>
                                        {this.recordThree(level3data, level4)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </>
        );
    }
    // on check level 3
    onCheckLevel3(e, id, tempLevel3) {
        const { level3, primaryChapterListLevel4, level2, level4, primaryChapterListData } = this.state
        var promise = new Promise((resolve, rej) => {

            this.setState({ level4DummyId: id })
            const event = e.target.checked

            var modelevel2 = []
            var modelevel3 = []
            for (let i = 0; i < level3.length; i++) {
                if (level3[i]['ChapterID'] == id) {
                    console.log("***********", level3[i])
                    level2.map((s) => {
                        if (level3[i].ParentChapterID == s.ChapterID) {
                            primaryChapterListData.map(q => {
                                if (s.ParentChapterID == q.ChapterID) {
                                    modelevel3.push(q)
                                }
                            })
                        }
                    })
                }
            }


            const finalListlevel2 = []
            level2.map(data => {
                modelevel3.map((item) => {
                    if (item.ChapterID == data.ParentChapterID) {
                        finalListlevel2.push(data)
                    }

                })
            })

            console.log("finalListlevel2", finalListlevel2)


            for (let i = 0; i < level3.length; i++) {
                if (level3[i]['ChapterID'] == id) {
                    if (event == true) {
                        level3[i]['IsSelect'] = true

                        level4.forEach((level4) => {
                            if (level3[i]['ChapterID'] == level4.ParentChapterID) {

                                level4.IsSelect = true
                            }
                        });

                    } else {
                        level3[i]['IsSelect'] = false
                        level4.forEach((level4) => {
                            if (level3[i]['ChapterID'] == level4.ParentChapterID) {
                                level4.IsSelect = false
                            }
                        });

                    }
                }
            }

            for (let i = 0; i < level3.length; i++) {
                for (let index = 0; index < level2.length; index++) {
                    for (let j = 0; j < primaryChapterListData.length; j++) {
                        if (level3[i]['ChapterID'] == id) {
                            if (level2[index]['ChapterID'] == level3[i]['ParentChapterID']) {
                                if (primaryChapterListData[j]['ChapterID'] == level2[index]['ParentChapterID']) {
                                    const allSelectedChecked = tempLevel3.every(item => item.IsSelect == 1)
                                    if (allSelectedChecked) {
                                        level2[index]['IsSelect'] = true
                                        const allSelectedfinalListlevel2 = finalListlevel2.every(item => item.IsSelect == 1)
                                        console.log("allSelectedfinalListlevel2", allSelectedfinalListlevel2)
                                        if (allSelectedfinalListlevel2) {
                                            primaryChapterListData[j]['IsSelect'] = true
                                        } else {
                                            primaryChapterListData[j]['IsSelect'] = false
                                        }
                                    }
                                    else {
                                        level2[index]['IsSelect'] = false
                                        primaryChapterListData[j]['IsSelect'] = false
                                    }
                                }
                            }
                        }
                    }

                }

            }
            setTimeout(() => {
                this.setState({ level3: level3, level2: level2 })
                resolve(true)
            }, 100);
        })
        promise.then((result) => {
            if (result) {
                this.updateChapterList(id)
            }
        })


    }
    // to call level four list
    recordThree(level3data, level4) {
        const { primaryChapterListLevel4 } = this.state
        var tempLevel4 = [];
        for (var j = 0; j < level4.length; j++) {
            if (level3data.ChapterID == level4[j]['ParentChapterID']) {
                tempLevel4.push(level4[j]);
                primaryChapterListLevel4.push(level4[j]);
            }
        }
        return (<>
            <div id={'collapse_l2_' + level3data.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l2_' + level3data.ChapterID}>
                {
                    tempLevel4 && tempLevel4.map(level4data => {
                        return (
                            <div class="accordion-body third-step">
                                {/* level4data.IsCheckbox && */}
                                {level4data.IsCheckbox &&
                                    <input type="checkbox" name="" key={level4data.ChapterID} checked={level4data.IsSelect}
                                        onClick={(e) => this.onSelectLevel4(e, level4data.ChapterID, tempLevel4)} />}
                                {level4data.ChapterName}
                            </div>
                        )
                    })
                }
            </div>
        </>);
    }
    // on check level 4
    onSelectLevel4(e, id, tempLevel4) {
        const { level4, exhibitDetailsList, level3, level2, level4DummyId, primaryChapterListLevel3, primaryChapterListLevel4, primaryChapterListData } = this.state
        var promise = new Promise((resolve, rej) => {

            var tempExhibitDetailsList = []
            for (let i = 0; i < level4.length; i++) {
                if (level4[i]['ChapterID'] == id) {
                    level4[i]['IsSelect'] = !level4[i]['IsSelect']
                }
            }

            var modelevel2 = []
            var modelevel3 = []

            for (let i = 0; i < level4.length; i++) {
                if (level4[i]['ChapterID'] == id) {
                    level3.map((s) => {
                        if (level4[i].ParentChapterID == s.ChapterID) {
                            level2.map(p => {
                                if (s.ParentChapterID == p.ChapterID) {
                                    modelevel2.push(p)
                                    primaryChapterListData.map(q => {
                                        if (p.ParentChapterID == q.ChapterID) {
                                            modelevel3.push(q)
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            }

            const finalListlevel3 = []
            level3.map(data => {
                modelevel2.map((item) => {
                    if (item.ChapterID == data.ParentChapterID) {
                        finalListlevel3.push(data)
                    }

                })
            })

            const finalListlevel2 = []
            level2.map(data => {
                modelevel3.map((item) => {
                    if (item.ChapterID == data.ParentChapterID) {
                        finalListlevel2.push(data)
                    }

                })
            })




            console.log("************** finalListlevel2", finalListlevel2)
            // console.log("************** finalListlevel3", finalListlevel3)


            for (let i = 0; i < tempLevel4.length; i++) {
                for (let index = 0; index < level3.length; index++) {
                    for (let j = 0; j < primaryChapterListData.length; j++) {
                        for (let k = 0; k < level2.length; k++) {
                            if (tempLevel4[i]['ChapterID'] == id) {
                                if (level3[index]['ChapterID'] == tempLevel4[i]['ParentChapterID']) {
                                    if (level2[k]['ChapterID'] == level3[index]['ParentChapterID']) {
                                        if (primaryChapterListData[j]['ChapterID'] == level2[k]['ParentChapterID']) {
                                            const allSelectedChecked = tempLevel4.every(item => item.IsSelect == 1)
                                            if (allSelectedChecked) {
                                                level3[index]['IsSelect'] = true

                                                const allSelectedfinalListlevel3 = finalListlevel3.every(item => item.IsSelect == 1)
                                                if (allSelectedfinalListlevel3) {
                                                    level2[k]['IsSelect'] = true

                                                    const allSelectedfinalListlevel2 = finalListlevel2.every(item => item.IsSelect == 1)
                                                    console.log("************** allSelectedfinalListlevel2", allSelectedfinalListlevel2)
                                                    if (allSelectedfinalListlevel2) {
                                                        primaryChapterListData[j]['IsSelect'] = true
                                                    } else {
                                                        primaryChapterListData[j]['IsSelect'] = false
                                                    }

                                                } else {
                                                    level2[k]['IsSelect'] = false
                                                }


                                            }
                                            else {
                                                level3[index]['IsSelect'] = false
                                                level2[k]['IsSelect'] = false
                                                primaryChapterListData[j]['IsSelect'] = false
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            setTimeout(() => {
                this.setState({ level4: level4, level3: level3, level2: level2 })
                resolve(true)

            }, 100);
        })
        promise.then((result) => {
            if (result) {
                this.updateChapterList(id)
            }
        })

    }

    handleTotalCirculationSelect(e) {
        console.log("111111111111111111111", e)
        this.setState({ selectedTotalCirculation: e })
    }
    handleOverseasCirculationSelect(e) {
        this.setState({ selectedOverseasCirculation: e })
        console.log("111111111111111111111", this.state.selectedOverseasCirculation)
    }
    handleRegionsCoveredinIndiaSelect(e) {
        console.log("111111111111111111111", e)
        this.setState({ selectedRegionsCoveredinIndia: e })
    }
    onOnlinePresenceSelect(data, id) {
        const { onlinePresenceList } = this.state
        onlinePresenceList[id].isSelect = !onlinePresenceList[id].isSelect
        this.setState({ onlinePresenceList: onlinePresenceList })
    }


    onFilePick = async (e, file) => {
        e.preventDefault()
        const { } = this.state
        console.log('file', file)
        if (file.length != 0) {
            if (file[0]['size'] >= 5 * 1024 * 1024) {
                toastError('File size more than 5mb are not allow.')
            } else {
                var image = '';
                if (file[0]['name'].toString().toLowerCase().includes('.pdf') ||
                    file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                    file[0]['name'].toString().toLowerCase().includes('.png') ||
                    file[0]['name'].toString().toLowerCase().includes('.jpg')
                ) {
                    if (file[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.png') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
                    ) {
                        image = await this.resizeFile(file[0]);
                        this.setState({ logoImg: image, logoImgErr: '' })
                    }
                    else if (file[0]['name'].toString().toLowerCase().trim().includes('.pdf')) {
                        image = await this.onPdfSelect(file[0]);
                        this.setState({ logoImg: image, logoImgErr: '' })
                    }
                    var fileType = file[0]['type']
                    var fileTypeList;
                    if (fileType.includes("/")) {
                        fileTypeList = fileType.split("/")

                    }
                    this.setState({ logoImgType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                } else {
                    toastError('Only .pdf , .jpeg, .png, .jpg files are allowed')
                }
            }
        }
    }
    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });
    onPdfSelect = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
        });
    onCrossClick() {
        this.setState({ logoImg: "" })
        document.getElementById("path").value = ""
    }

    verifyPartnerCode() {
        const { refNo, TandC, offerList } = this.state
        this.setState({ isLoading: true, })
        // this.setState({ TandC: [], offerList: [] })
        this.props.verifyPartnerCode_action(refNo)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    if (data.data != undefined && data.data != null && data.data.length > 0) {

                        this.setState({ exhibitionID: data.data[0].ExhibitionID, isPageValid: true })
                        if (data.data[0].PartnerTypeID == 3) {
                            this.setState({ formID: data.data[0].PartnerTypeID, formName: 'Media Partner' })
                        }
                        else if (data.data[0].PartnerTypeID == 2) {
                            this.setState({ formID: data.data[0].PartnerTypeID, formName: 'Supporting Partner' })
                        }
                        if (data.data[0].PartnerTypeID == 1) {
                            this.setState({ formID: data.data[0].PartnerTypeID, formName: 'Knowledge Partner' })
                        }
                        var promise = new Promise((resolve, rej) => {
                            // this.getFillList(exhibitionStatusActionId, data.data[0].ExhibitionID, '', '', '', '');
                            this.getFillList(countryActionId, data.data[0].ExhibitionID, '', '', '', '');
                            this.getFillList(stateActionId, data.data[0].ExhibitionID, '', '', '', '');
                            this.getFillList(cityActionId, data.data[0].ExhibitionID, '', '', '', '');
                            this.getFillList(pincodeActionId, data.data[0].ExhibitionID, '', '', '', '');
                            this.getFillList(primaryChapterListActionId, data.data[0].ExhibitionID, '', '', '', '')
                            this.getFillList(onlinePresenceListActionId, data.data[0].ExhibitionID, '', '', '', '')
                            resolve(true)
                        })
                        promise.then((result) => {
                            if (result) {
                                console.log("result----------->", result, this.state.tempCountryList, this.state.tempCountryList2)
                            }
                        })
                        // this.setState({ hallMasterList: data.data[0] })
                        this.props.getPartnerStaticData_action(data.data[0].ExhibitionID, refNo)
                            .then((data) => {
                                if (data.error != 1) {
                                    TandC.length = 0
                                    offerList.length = 0
                                    var tempofferList = []
                                    var tempTandCList = []
                                    for (var i = 0; i < data.data.length; i++) {
                                        if (data.data[i].IsOffer && (data.data[i].parent_id == 0 || data.data[i].parent_id == null)) {
                                            var obj = {}
                                            obj.Description = data.data[i].Description
                                            obj.Code = data.data[i].SeqNo
                                            tempofferList.push(obj)
                                        }
                                        if (data.data[i].IsTermAndCondition && (data.data[i].parent_id == 0 || data.data[i].parent_id == null)) {
                                            var obj = {}
                                            obj.Description = data.data[i].Description
                                            obj.Code = data.data[i].SeqNo
                                            tempTandCList.push(obj)
                                        }
                                    }
                                    console.log(tempTandCList, tempofferList)

                                    for (var i = 0; i < tempofferList.length; i++) {
                                        var sub = []
                                        for (var j = 0; j < data.data.length; j++) {
                                            if (data.data[j].IsOffer && (tempofferList[i].Code == data.data[j].parent_id)) {
                                                var obj = {}
                                                obj.Description = data.data[j].Description
                                                obj.Code = data.data[j].SeqNo[data.data[j].SeqNo.length - 1]
                                                sub.push(obj)
                                            }
                                        }
                                        tempofferList[i].subdata = sub
                                    }
                                    for (var i = 0; i < tempTandCList.length; i++) {
                                        var sub = []
                                        for (var j = 0; j < data.data.length; j++) {
                                            if (data.data[j].IsTermAndCondition && (tempTandCList[i].Code == data.data[j].parent_id)) {

                                                var obj = {}
                                                obj.Description = data.data[j].Description
                                                obj.Code = data.data[j].SeqNo[data.data[j].SeqNo.length - 1]
                                                sub.push(obj)
                                            }
                                        }
                                        tempTandCList[i].subdata = sub
                                    }
                                    console.log(tempTandCList, tempofferList)
                                    this.setState({ TandC: tempTandCList, offerList: tempofferList })
                                }
                            })
                    }
                    else {
                        this.setState({ isPageValid: false })
                        toastError('Please enter valid Reference number')
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isPageValid: false })
                    toastError(data.msg)
                }
                this.setState({ isLoading: false })
            })
            .catch((err) => {
                this.setState({ isPageValid: false })
                toastError('Something went wrong...')
                console.log(err)
            })

    }
    onSubmit() {
        const { isLoading, isDisplayCountryList, isDisplayStateList, isDisplayCityList, isDisplayPincodeList, exhibitionID, countryList, tempCountryList, stateList, tempStateList, cityList, tempCityList, pincodeList, tempPincodeList, list1, list2, primaryChapterListData, level0, level2, level3, level4, selectedTotalCirculation, totalCirculationList, overseasCirculationList, selectedOverseasCirculation, regionsCoveredinIndiaList, selectedRegionsCoveredinIndia, onlinePresenceList, refNo, firstName, lastName, designation, companyName, fasciaName, add1, add2, country, state, city, Pin, countryId, preMobNo, altPreMobNo, mobNo, altMobNo, telNo, preTelNo, primaryEmail, secondaryEmail, website, logoImg, logoImgType, countryErr, stateErr, cityErr, PinErr, refNoErr, firstNameErr, lastNameErr, designationErr, companyNameErr, fasciaNameErr, mobNoErr, telNoErr, emailErr, add1Err, formID, formName, isAgree, title_PersonIncharge, isOther_title_PersonIncharge, other_title_PersonIncharge, } = this.state
        if (refNo != '' && refNo != null) {

            //input validate
            var ERR
            var chapterList = []
            var onlineList = []
            var listEmailErr
            var PrimaryEmailErr = emailValidator(primaryEmail)
            var secondaryEmailErr = secondaryEmail != '' && secondaryEmail != null ? emailValidator(secondaryEmail) : ''
            for (var i = 0; i < list2.length; i++) {
                listEmailErr = emailValidator(list2[i].Email)
                console.log("oooo", listEmailErr)
                if (listEmailErr != '') {
                    console.log("oooocccccc",)
                    break
                }
            }
            ERR = this.inputValidate()
            if (formID == 3) {
                for (var i = 0; i < primaryChapterListData.length; i++) {
                    if (primaryChapterListData[i].IsSelect) {
                        chapterList.push(primaryChapterListData[i])
                    }
                }
                for (var i = 0; i < level0.length; i++) {
                    if (level0[i].IsSelect) {
                        chapterList.push(level0[i])
                    }
                }
                for (var i = 0; i < level2.length; i++) {
                    if (level2[i].IsSelect) {
                        chapterList.push(level2[i])
                    }
                }
                for (var i = 0; i < level3.length; i++) {
                    if (level3[i].IsSelect) {
                        chapterList.push(level3[i])
                    }
                }
                for (var i = 0; i < level4.length; i++) {
                    if (level4[i].IsSelect) {
                        chapterList.push(level4[i])
                    }
                }
                if (chapterList.length == 0) {
                    ERR = 'Please select atleast 1 industry Segment'
                }
                for (var i = 0; i < onlinePresenceList.length; i++) {
                    if (onlinePresenceList[i].isSelect) {
                        onlineList.push(onlinePresenceList[i])
                    }
                }
            }
            if (country != '' && country != null && state != '' && state != null && city != '' && companyName != '' && companyName != null && fasciaName != '' && fasciaName != null && city != null && Pin != '' && Pin != null && refNo != '' && refNo != null && firstName != '' && firstName != null && lastName != '' && lastName != null && designation != '' && designation != null && designation != '' && designation != null && mobNo != '' && mobNo != null && primaryEmail != '' && primaryEmail != null && add1 != '' && add1 != null) {
                if (countryErr == '' && firstNameErr == '' && lastNameErr == '' && designationErr == '' && companyNameErr == '' && fasciaNameErr == '' && add1Err == '' && PinErr == '' && cityErr == '' && stateErr == '' && mobNoErr == '' && emailErr == '') {
                    if (ERR == null) {

                        if ((PrimaryEmailErr != '') || (secondaryEmailErr != '') || (listEmailErr != '')) {
                            toastError(
                                PrimaryEmailErr != '' ? PrimaryEmailErr : secondaryEmailErr != '' ? secondaryEmailErr : listEmailErr != '' ? listEmailErr : '')

                        }
                        else {


                            this.setState({ isLoading: true })
                            this.props.sendPartnerRegistrationform_action(sessionStorage.getItem("sessionExhibitionID"),
                                formID,
                                isOther_title_PersonIncharge == true ? other_title_PersonIncharge : title_PersonIncharge,
                                firstName,
                                lastName,
                                designation,
                                companyName,
                                fasciaName,
                                add1,
                                add2,
                                Pin,
                                city,
                                state,
                                countryId,
                                mobNo,
                                altMobNo,
                                telNo,
                                primaryEmail,
                                secondaryEmail,
                                website,
                                null,
                                isAgree,
                                list1,
                                list2,
                                selectedRegionsCoveredinIndia,
                                selectedTotalCirculation,
                                selectedOverseasCirculation,
                                onlineList,
                                chapterList,
                                logoImg,
                                logoImgType)
                                .then((data) => {
                                    if (data.error != 1) {
                                        this.setState({ isLoading: false })
                                        console.log(data)
                                        this.props.navigate('/thankYou', {
                                            state: {
                                                is_partner: true,
                                                exhibitionAdd: data.data.Address,
                                                exhibitionName: data.data.ExhibitionName,
                                                ExhRegistrationNo: data.data.ExhRegistrationNo,
                                                fromDate: moment(data.data.ExhibitionDateFrom).format('DD/MM/YYYY'),
                                                toDate: moment(data.data.ExhibitionDateTo).format('DD/MM/YYYY')
                                            }
                                        })

                                    }
                                    else {
                                        this.setState({ isLoading: false })
                                        toastError(data.msg)
                                    }
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    }
                    else {
                        toastError(ERR)
                    }
                }
                else {
                    toastError("Please fill all the mandatory fields")
                }
            }
            else {
                toastError("Please fill all the mandatory fields")
            }
        }
        else {
            toastError('Please Enter Reference number')
        }
        console.log(list1, list2, chapterList, selectedTotalCirculation, selectedOverseasCirculation, selectedRegionsCoveredinIndia, onlinePresenceList, formID,
            firstName,
            lastName,
            designation,
            companyName,
            fasciaName,
            add1,
            add2,
            Pin,
            city,
            state,
            countryId,
            mobNo,
            altMobNo,
            telNo,
            primaryEmail,
            secondaryEmail,
            website,)
        console.log(ERR)
    }
    //Input Field Validations
    inputValidate() {
        const { firstName, lastName, designation, companyName, add1, state, Pin, city, countryId, mobNo, telNo, list1, list2, formID, onlinePresenceList, selectedOverseasCirculation, selectedRegionsCoveredinIndia, primaryEmail, selectedTotalCirculation, fasciaName } = this.state
        var ERR = null
        if (firstName == "") {
            this.setState({ firstNameErr: "Please enter Person Incharge" });
        }
        if (lastName == "") {
            this.setState({ lastNameErr: "Please enter Last name" });
        }
        if (designation == "") {
            this.setState({ designationErr: "Please Enter Designation" });
        }
        if (companyName == "") {
            this.setState({ companyNameErr: "Please Enter Company Name" });
        }
        if (fasciaName == "") {
            this.setState({ fasciaNameErr: "Please Enter Fascia Name" });
        }
        if (add1 == "") {
            this.setState({ add1Err: "Please enter address" });
        }
        if (Pin == "") {
            this.setState({ PinErr: "Please Enter Zipcode" });
        }
        if (city == "") {
            this.setState({ cityErr: "Please Enter city" });
        }
        if (countryId == "91" && state == "") {
            this.setState({ stateErr: "Please Enter State" });
        }
        if (countryId == "") {
            this.setState({ countryErr: "Please Enter country" });
        }
        if (mobNo == "") {
            this.setState({ mobNoErr: "Please Enter Mobile No." });
        }
        if (primaryEmail == "") {
            this.setState({ emailErr: "Please Enter Primary Email" });
        }
        if (list2.length == 0) {
            ERR = 'Please add atleast 1 Partner Bearer'
        }
        if (list2 != 0) {
            for (var i = 0; i < list2.length; i++) {
                if (list2[i].FirstName == '' || list2[i].LastName == '' || list2[i].Designation == '' || list2[i].Email == '' || list2[i].Mobile == '' || list2[i].CountryID == '') {
                    ERR = 'Please fill all the  Partner Bearer columns'
                }
            }
        }
        if (list1.length == 0) {
            ERR = 'Please add atleast 1 Partner Deliverable'
        }
        if (list1 != 0) {
            for (var i = 0; i < list1.length; i++) {
                if (list1[i].Deliverable == '' || list1[i].Quantity == '' || list1[i].ScheduleDuration == '') {
                    ERR = 'Please fill all the  Partner Deliverable columns'
                }
            }
        }
        if (formID == 3) {
            if (onlinePresenceList.length != 0) {
                var onErr = false
                for (var i = 0; i < onlinePresenceList.length; i++) {
                    if (onlinePresenceList[i].isSelect == true) {
                        onErr = false
                        break
                    }
                    else {
                        onErr = true
                    }
                }
                if (onErr) {
                    ERR = 'Please select atleast 1 online presence'
                }
            }
            if (selectedRegionsCoveredinIndia.length == 0) {
                ERR = 'Please select atleast 1 Region covered in India'
            }
            if (selectedOverseasCirculation.length == 0) {
                ERR = 'Please select atleast 1 Overseas circulation'
            }
            if (selectedTotalCirculation.length == 0) {
                ERR = 'Please select atleast 1 Total circulation'
            }
        }
        return ERR
    }

    onChapterRemove(id, level) {
        const { primaryChapterListData, level0, level2, level3, level4, selectedChapterList } = this.state
        var promise = new Promise((resolve, rej) => {
            if (level == 1) {
                for (var i = 0; i < primaryChapterListData.length; i++) {
                    if (primaryChapterListData[i].ChapterID == id) {
                        primaryChapterListData[i].IsSelect = false
                    }
                }
            }
            if (level == 0) {
                for (var i = 0; i < level0.length; i++) {
                    if (level0[i].ChapterID == id) {
                        level0[i].IsSelect = false
                    }
                }
            }
            if (level == 2) {
                for (var i = 0; i < level2.length; i++) {
                    if (level2[i].ChapterID == id) {
                        level2[i].IsSelect = false
                    }
                }
            }
            if (level == 3) {
                for (var i = 0; i < level3.length; i++) {
                    if (level3[i].ChapterID == id) {
                        level3[i].IsSelect = false
                    }
                }
            }
            if (level == 4) {
                for (var i = 0; i < level4.length; i++) {
                    if (level4[i].ChapterID == id) {
                        level4[i].IsSelect = false
                    }
                }
            }
            for (var i = 0; i < selectedChapterList.length; i++) {
                if (selectedChapterList[i].ChapterID == id) {
                    selectedChapterList.splice(i, 1)
                }
            }
            resolve(true)

        })
        promise.then((result) => {
            if (result) {
                this.setState({
                    selectedChapterList: selectedChapterList,
                    primaryChapterListData: primaryChapterListData,
                    level0: level0,
                    level2: level2,
                    level3: level3,
                    level4: level4
                })

            }
        })
    }

    verifyEmail(value) {
        var emailError = emailValidator(value)
        if (emailError != '') {
            this.setState({ emailErr: emailError })
            return
        } else {
            this.setState({ isLoading: true })
            this.props.verifyPartnerEmail_action(value, null)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        if (data.data['ErrMsg'] != "" && data.data['ErrMsg'] != null) {
                            this.setState({ emailErr: data.data['ErrMsg'] })
                            toastError(data.data['ErrMsg'])
                        } else {
                            this.setState({ emailErr: "" })
                        }
                    } else {
                        this.setState({ isLoading: false })
                        this.setState({ emailErr: "" })
                    }

                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }


    render() {
        const { isLoading,
            isDisplayCountryList,
            isDisplayStateList,
            isDisplayCityList,
            isDisplayPincodeList,
            isDataLoad,
            exhibitionID,
            countryList,
            tempCountryList,
            stateList,
            tempStateList,
            cityList,
            tempCityList,
            pincodeList,
            tempPincodeList,
            list1,
            list2,

            primaryChapterListData,
            selectedTotalCirculation,
            totalCirculationList,
            overseasCirculationList,
            selectedOverseasCirculation,
            regionsCoveredinIndiaList,
            selectedRegionsCoveredinIndia,
            onlinePresenceList,

            refNo,
            firstName,
            lastName,
            designation,
            companyName,
            fasciaName,
            add1,
            add2,
            country,
            state,
            city,
            Pin,
            countryId,
            preMobNo,
            altPreMobNo,
            mobNo,
            altMobNo,
            telNo,
            preTelNo,
            primaryEmail,
            secondaryEmail,
            website,

            logoImg,
            logoImgErr,
            logoImgType,

            //err
            countryErr,
            stateErr,
            cityErr,
            PinErr,
            refNoErr,
            firstNameErr,
            lastNameErr,
            designationErr,
            companyNameErr,
            mobNoErr,
            telNoErr, add1Err, emailErr,

            formID, isDisplayCountryList2, formName, } = this.state
        const toInputUppercase = e => {
            e.target.value = ("" + e.target.value).toUpperCase();
        };
        return (<>
            {isLoading ? (
                <Loader></Loader>
            ) : (
                null
            )}
            {isDataLoad && <VisitorHeader />}
            <ToastContainer />
            <>
                {/* PAGE */}
                <div className="page">
                    <div className="page-main">
                        {/* <Header /> */}

                        {/*app-content open*/}

                        <div className="main-content app-content mt-0">
                            <div className="side-app">
                                <div className="row">
                                    <div
                                        className="step-name" style={{ backgroundColor: "#bc202b", color: "#fff;", marginTop: '85px' }}
                                    >
                                        <p>Partner Registration Form</p>
                                    </div>
                                </div>
                                {/* <div className="main-container form-start container-fluid p-0">
                                        {is_update && <>
                                            <div className="row ">
                                                <div className="step-name">
                                                    <p>Exhibitor Registration</p>
                                                </div>
                                            </div>
                                            <StepHeader />
                                        </>}
                                    </div> */}
                                {/* <StepHeader /> */}
                                <div className="mt-3" >
                                    <div className="main-container container-fluid">
                                        <div className="">
                                            <div className="form-row">
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        Reference Number<span className="text-red">*</span>:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        required=""
                                                        onBlur={(e) => this.verifyPartnerCode()}
                                                        value={refNo}
                                                        // maxLength={500}
                                                        onChange={e => this.setState({ refNo: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" mt-2">
                                            <div className="form-row">
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        Person in charge(First Name)<span className="text-red">*</span>
                                                    </label>
                                                    {
                                                        this.state.isOther_title_PersonIncharge &&
                                                        <input
                                                            type="text"
                                                            className="form-control-1 other-fil"
                                                            value={this.state.other_title_PersonIncharge}
                                                            onChange={e => this.setState({ other_title_PersonIncharge: e.target.value })}

                                                            placeholder={'Other'}
                                                            style={{

                                                                // position: "absolute",

                                                                paddingLeft: '5px'
                                                            }}
                                                        />
                                                    }

                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        maxLength={100}
                                                        required=""
                                                        style={{ position: "relative", paddingLeft: '55px' }}
                                                        value={firstName}

                                                        onChange={e => this.setState({ firstName: e.target.value, firstNameErr: '' })}
                                                    />
                                                    <div className="col-xl-1 mb-3">
                                                        <div
                                                            className="form-group"
                                                            value={this.state.title_PersonIncharge}
                                                            onChange={e => this.setState({ title_PersonIncharge: e.target.value })}

                                                            style={{
                                                                width: '45px',
                                                                position: "absolute",
                                                                top: "-59px",
                                                                left: '0px'
                                                            }}
                                                        >
                                                            <label />
                                                            <select
                                                                className="form-control-1 select2 select-hidden-accessible"
                                                                id="roledb"
                                                                style={{
                                                                    paddingLeft: '0px',
                                                                    paddingRight: '0px',
                                                                    borderTop: "none",
                                                                    borderBottom: "none",
                                                                    marginTop: '0px',
                                                                    textAlign: "left"
                                                                }}
                                                                value={this.state.title_PersonIncharge}

                                                                onChange={e => this.setState({
                                                                    title_PersonIncharge: e.target.value,
                                                                    isOther_title_PersonIncharge: e.target.value == "Other" && true
                                                                })}


                                                            >
                                                                <option value="Mr">Mr</option>
                                                                <option value="Ms">Ms</option>
                                                                <option value="DR">DR</option>
                                                                <option value="Prof">Prof</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{firstNameErr}</span>}
                                                </div>
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        Last Name<span className="text-red">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        required=""
                                                        value={lastName}
                                                        // maxLength={500}
                                                        onChange={e => this.setState({ lastName: e.target.value, lastNameErr: '' })}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{lastNameErr}</span>}
                                                </div>
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        Designation<span className="text-red">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        required=""
                                                        value={designation}
                                                        // maxLength={500}
                                                        onChange={e => this.setState({ designation: e.target.value, designationErr: '' })}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{designationErr}</span>}
                                                </div>
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        Company Name<span className="text-red">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        required=""
                                                        value={companyName}
                                                        // maxLength={500}
                                                        onChange={e => this.setState({ companyName: e.target.value, companyNameErr: '' })}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{companyNameErr}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="form-row">
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        Fascia Name (Shell Scheme only) <span className="text-red">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        required=""
                                                        value={fasciaName}
                                                        // maxLength={500}
                                                        onChange={e => this.setState({ fasciaName: e.target.value, fasciaNameErr: '' })}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{this.state.fasciaNameErr}</span>}
                                                </div>
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        Address line 1<span className="text-red">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        required=""
                                                        value={add1}
                                                        // maxLength={500}
                                                        onChange={e => this.setState({ add1: e.target.value, add1Err: '' })}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{add1Err}</span>}

                                                </div>
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        Address line 2
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        required=""
                                                        value={add2}
                                                        // maxLength={500}
                                                        onChange={e => this.setState({ add2: e.target.value, })}
                                                    />
                                                </div>
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        Pincode<span className="text-red">*</span>
                                                    </label>
                                                    {
                                                        countryId == "91" &&
                                                        <input
                                                            type="text"
                                                            maxLength={6}
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={Pin}
                                                            onChange={(e) => this.onPincodeSearch(e)}
                                                        />
                                                    }
                                                    {
                                                        countryId != "91" &&
                                                        <input
                                                            type="number"
                                                            maxLength={10}
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={Pin}
                                                            // disabled={is_view || isSameAsCorrespondence}
                                                            onChange={(e) => this.onPincodeSearch(e)}
                                                        // onInput={toInputUppercase}
                                                        />
                                                    }
                                                    {isDisplayPincodeList && this.renderPincodeList()}
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{PinErr}</span>}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="form-row">
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        City<span className="text-red">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        // disabled={is_view}
                                                        value={city}
                                                        onChange={(e) => this.onCitySearch(e)}
                                                    />
                                                    {isDisplayCityList && this.renderCityList()}
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{cityErr}</span>}

                                                </div>
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        {countryId == "91" ? "State" : "State/Province"}
                                                        {countryId == "91" && <span className="text-red">*</span>}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        // disabled={is_view}
                                                        value={state}
                                                        onChange={(e) => this.onStateSearch(e)}
                                                    />
                                                    {isDisplayStateList && this.renderStateList()}
                                                    {countryId == "91" && <span className='text-danger text-start text-left d-block mt-1'>{stateErr}</span>}
                                                </div>
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        Country<span className="text-red">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        // disabled={is_view || is_update}
                                                        value={country}
                                                        onChange={(e) => this.onCountrySearch(e)}
                                                    />
                                                    {isDisplayCountryList && this.renderCountryList()}
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{countryErr}</span>}
                                                </div>
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        Mobile No. 1<span className="text-red">*</span>
                                                    </label>
                                                    <input
                                                        type="number"

                                                        className="form-control"
                                                        value={mobNo}
                                                        maxLength={sessionStorage.getItem('countryId') === "43" ? 11 : 10}
                                                        onInput={e => this.maxLengthCheck(e, 10)}
                                                        onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                                        onWheel={(e) => e.target.blur()}
                                                        onKeyPress={e => this.preventMinus(e)}
                                                        onChange={e => this.setState({ mobNo: e.target.value, mobNoErr: '' })}
                                                        style={{ paddingLeft: '50px' }} />
                                                    <input
                                                        type="text"
                                                        placeholder="ISD"
                                                        className="form-control-1"
                                                        value={preMobNo}
                                                        onChange={e => this.setState({ preMobNo: e.target.value })}
                                                        style={{
                                                            width: '45px',
                                                            position: "absolute",
                                                            top: '23px',
                                                            paddingLeft: '5px'
                                                        }}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{mobNoErr}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="form-row">
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        Mobile No. 2
                                                    </label>
                                                    <input
                                                        type="number"

                                                        className="form-control"
                                                        value={altMobNo}
                                                        maxLength={sessionStorage.getItem('countryId') === "43" ? 11 : 10}
                                                        onInput={e => this.maxLengthCheck(e, 10)}
                                                        onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                                        onWheel={(e) => e.target.blur()}
                                                        onKeyPress={e => this.preventMinus(e)}
                                                        onChange={e => this.setState({ altMobNo: e.target.value, })}
                                                        style={{ paddingLeft: '50px' }} />
                                                    <input
                                                        type="text"
                                                        placeholder="ISD"
                                                        className="form-control-1"
                                                        value={altPreMobNo}
                                                        onChange={e => this.setState({ altPreMobNo: e.target.value })}
                                                        style={{
                                                            width: '45px',
                                                            position: "absolute",
                                                            top: '23px',
                                                            paddingLeft: '5px'
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        Telephone no.
                                                    </label>
                                                    <input
                                                        type="number"

                                                        className="form-control"
                                                        value={telNo}
                                                        maxLength={sessionStorage.getItem('countryId') === "43" ? 11 : 10}
                                                        onInput={e => this.maxLengthCheck(e, 10)}
                                                        onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                                        onWheel={(e) => e.target.blur()}
                                                        onKeyPress={e => this.preventMinus(e)}
                                                        onChange={e => this.setState({ telNo: e.target.value, })}
                                                        style={{ paddingLeft: '50px' }} />
                                                    <input
                                                        type="text"
                                                        placeholder="ISD"
                                                        className="form-control-1"
                                                        value={preTelNo}
                                                        onChange={e => this.setState({ preTelNo: e.target.value, })}
                                                        style={{
                                                            width: '45px',
                                                            position: "absolute",
                                                            top: '23px',
                                                            paddingLeft: '5px'
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        Primary Email<span className="text-red">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        required=""
                                                        value={primaryEmail}
                                                        maxLength={500}
                                                        onBlur={e => this.verifyEmail(e.target.value)}
                                                        onChange={e => this.setState({ primaryEmail: e.target.value, emailErr: '' })}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{emailErr}</span>}

                                                </div>
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        Secondary Email
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        required=""
                                                        value={secondaryEmail}
                                                        maxLength={500}
                                                        onChange={e => this.setState({ secondaryEmail: e.target.value, })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="form-row">
                                                <div className="col-xl-6 mb-1">
                                                    <label htmlFor="validationCustom03">
                                                        Website
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        required=""
                                                        value={website}
                                                        maxLength={500}
                                                        onChange={e => this.setState({ website: e.target.value, })}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {formID == 3 &&
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 mt-4">
                                                    <div className="asc" style={{ border: "1px solid #ddd" }}>
                                                        <div
                                                            className="card-header p-2 mb-2"
                                                            style={{ padding: "9px!important" }}
                                                        >
                                                            <h3 className="card-title" style={{ fontSize: 14 }}>
                                                                Select Industry Segment<span className="text-red">*</span>
                                                            </h3>
                                                        </div>
                                                        <div className="" style={{ paddingLeft: 10 }}>
                                                            <form className="needs-validation pb-2" noValidate="">
                                                                <div className="form-row">
                                                                    <div class="accordion" id="accordionExample">
                                                                        {
                                                                            primaryChapterListData.length > 0 && primaryChapterListData.map((level1Data, i) => (
                                                                                <>
                                                                                    {
                                                                                        // level2Data.ParentChapterID == primaryChapterRadio &&
                                                                                        <div class="accordion-item">
                                                                                            <h2 class="accordion-header check-out" id={'heading_l1_' + level1Data.ChapterID}>

                                                                                                <button class={level1Data.isHighlight ? "accordion-button collapsed selected-chapter  tit-name" : "accordion-button collapsed  tit-name"}
                                                                                                    type="button"
                                                                                                    data-bs-toggle="collapse"
                                                                                                    data-bs-target={'#collapse_l1_' + level1Data.ChapterID} aria-expanded="false"
                                                                                                    aria-controls={'collapse_l1_' + level1Data.ChapterID}>
                                                                                                    {/* // checkbox for level2 */}
                                                                                                    {level1Data.IsCheckbox &&
                                                                                                        <input type="checkbox" className="from-left-sp check-out-in" name="" key={level1Data.ChapterID}
                                                                                                            checked={level1Data.IsSelect}
                                                                                                            onClick={e => this.onCheckLevel1(e, level1Data.ChapterID)}
                                                                                                        />
                                                                                                    }
                                                                                                    {level1Data.ChapterName}
                                                                                                </button>
                                                                                            </h2>
                                                                                            {this.record(level1Data)}
                                                                                            {/* {this.record(level1Data, level3, level4)} */}
                                                                                        </div>
                                                                                    }

                                                                                </>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        {this.state.selectedChapterList.length > 0 &&
                                                            <div className="col-md-8 pl-0">
                                                                <div className="table-responsive">
                                                                    <table class="table border text-nowrap text-md-nowrap table-bordered mt-1">
                                                                        <thead>
                                                                            <tr>
                                                                                {/* <th className="text-center" style={{ width: '60px' }}><strong>Sr no.</strong></th> */}
                                                                                <th><strong>Selected Segment</strong></th>
                                                                                <th className="text-center"><strong></strong></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.selectedChapterList.map(element => (
                                                                                <tr key={Math.random()}>
                                                                                    {/* <th className="text-center" style={{ width: '60px' }}>{data.}</th> */}
                                                                                    <td>{element.ChapterName}</td>
                                                                                    <td className="text-center">
                                                                                        <i class="fa fa-trash" aria-hidden="true"
                                                                                            onClick={(e) => this.onChapterRemove(element.ChapterID, element.Level)}
                                                                                        ></i></td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>}
                                        {formID == 3 &&
                                            <div className="row">
                                                <div className="col-lg-12 card-header mt-2">
                                                    <div
                                                        className="card-title col-md-6 p-1 mb-2"
                                                        style={{ padding: "9px!important" }}
                                                    >
                                                        <h3 className="" style={{ fontSize: 14 }}>
                                                            Total Circulation<span className="text-red">*</span>:
                                                        </h3>
                                                        <Select
                                                            options={totalCirculationList.map((option) => ({ label: option.Description, value: option.Code }))}
                                                            value={selectedTotalCirculation}
                                                            onChange={this.handleTotalCirculationSelect}
                                                            isMulti
                                                        />
                                                    </div>
                                                </div>
                                            </div>}
                                        {formID == 3 &&
                                            <div className="row">
                                                <div className="col-lg-12 card-header mt-2">
                                                    <div
                                                        className="card-title col-md-6 p-1 mb-2"
                                                        style={{ padding: "9px!important" }}
                                                    >
                                                        <h3 className="" style={{ fontSize: 14 }}>
                                                            Overseas Circulation<span className="text-red">*</span>:
                                                        </h3>
                                                        <Select
                                                            options={overseasCirculationList.map((option) => ({ label: option.Description, value: option.Code }))}
                                                            value={selectedOverseasCirculation}
                                                            onChange={this.handleOverseasCirculationSelect}
                                                            isMulti
                                                        />
                                                    </div>
                                                </div>
                                            </div>}
                                        {formID == 3 &&
                                            <div className="row">
                                                <div className="col-lg-12 card-header mt-2">
                                                    <div
                                                        className="card-title col-md-6 p-1 mb-2"
                                                        style={{ padding: "9px!important" }}
                                                    >
                                                        <h3 className="" style={{ fontSize: 14 }}>
                                                            Region Covered in India<span className="text-red">*</span>:
                                                        </h3>
                                                        <Select
                                                            options={regionsCoveredinIndiaList.map((option) => ({ label: option.Description, value: option.Code }))}
                                                            value={selectedRegionsCoveredinIndia}
                                                            onChange={this.handleRegionsCoveredinIndiaSelect}
                                                            isMulti
                                                        />
                                                    </div>
                                                </div>
                                            </div>}
                                        {formID == 3 &&
                                            <div className="row">
                                                <div className="col-lg-12 card-header mt-4">
                                                    <div
                                                        className="card-title col-md-6 p-2 mb-2"
                                                        style={{ padding: "9px!important" }}
                                                    >
                                                        <h3 className="" style={{ fontSize: 14 }}>
                                                            Online Presence<span className="text-red">*</span>:
                                                        </h3>

                                                        {onlinePresenceList.map((data, i) => (

                                                            <form>
                                                                <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                                                    <input type="checkbox"
                                                                        checked={data.isSelect}
                                                                        onChange={(e) => this.onOnlinePresenceSelect(data, i)}
                                                                    />
                                                                    {data.Name} </label>
                                                            </form>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>}


                                        <div className="form-row mt-3">
                                            <div className="col-xl-3 mb-1">
                                                <label htmlFor="validationCustom03">
                                                    Upload logo
                                                </label>
                                                <div className="box">
                                                    <div className="js--image-preview" />

                                                    {
                                                        // is_add ?
                                                        <div className="upload-options mt-1">
                                                            <label>
                                                                <input
                                                                    type="file"
                                                                    id="path"
                                                                    className="image-upload"
                                                                    accept=".jpg, .jpeg, .png, .pdf"
                                                                    onChange={e => this.onFilePick(e, e.target.files)}
                                                                // disabled={isView}
                                                                />
                                                                {
                                                                    logoImg != "" &&
                                                                    <i class="fa fa-times text-danger img-can"
                                                                        onClick={() => this.onCrossClick()}
                                                                    ></i>
                                                                }
                                                            </label>

                                                        </div>
                                                        // : passportImage != "" && passportImage != null ?
                                                        //     <div style={{
                                                        //         border: '1px solid #ccc',
                                                        //         padding: '10px',
                                                        //         display: 'inline-flex',
                                                        //         alignItems: 'center',
                                                        //     }}>
                                                        //         <a
                                                        //             href={passportFilePath}
                                                        //             target="_blank"
                                                        //             rel="noopener noreferrer"
                                                        //         >
                                                        //             <span style={{ marginRight: '10px' }}
                                                        //             >{passportImage}</span>
                                                        //             <i className={`fa fa-times text-danger img-can ${isView ? 'disabled' : ''}`}
                                                        //                 onClick={!isView ? (e) => this.onDocDelete() : undefined}
                                                        //             ></i>
                                                        //         </a>
                                                        //     </div> : <label>
                                                        //         <input
                                                        //             type="file"
                                                        //             id="path"
                                                        //             className="image-upload"
                                                        //             accept=".jpg, .jpeg, .png, .pdf"
                                                        //             disabled={isView}
                                                        //             onChange={e => this.onFilePick(e, e.target.files)}
                                                        //         />
                                                        //         {
                                                        //             passportFrontImage != "" &&
                                                        //             <i class="fa fa-times text-danger img-can" onClick={() => this.onCrossClick()}></i>
                                                        //         }
                                                        //     </label>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body mt-3 p-0">
                                            <div className="form-row">
                                                <p className='m-0 p-0'><strong>Kindly fill agreed offer from {formName}<span className="text-red">*</span></strong></p>
                                            </div>
                                            <div class="table-responsive">
                                                <table class="table border text-nowrap text-md-nowrap table-bordered mt-1">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center"><strong>Deliverables<span className="text-red">*</span></strong></th>
                                                            <th className="text-center"><strong>Quantity<span className="text-red">*</span></strong></th>
                                                            <th className="text-center"><strong>Schedule / Duration<span className="text-red">*</span></strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {list1.length > 0 && list1.map((data, i) => (
                                                            <tr key={data}>
                                                                <td>
                                                                    <input type="text" class="form-control"
                                                                        value={data.Deliverable}
                                                                        onChange={(e) => this.enterDelivables(e, i)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input type="number" class="form-control"
                                                                        value={data.Quantity}
                                                                        onChange={(e) => this.enterQuantity(e, i)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input type="text" class="form-control"
                                                                        value={data.ScheduleDuration}
                                                                        onChange={(e) => this.enterSchedule(e, i)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <button type="button" class="btn btn-primary rounded-0 mt-0" style={{ float: 'right' }}
                                                    onClick={(e) => this.OnAddList1(e)}
                                                >
                                                    Add row
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.offerList.length > 0 && <div className="mt-1 ms-2">
                                        <div className="card-body">
                                            <p> <strong>As {formName}, India ITME Society will offer your organization the following:</strong></p>
                                        </div>
                                        <div className="card-body ms-3">
                                            {this.state.offerList.length > 0 && this.state.offerList.map((data, i) => (
                                                <div>
                                                    <p> {data.Code}) {data.Description}</p>
                                                    {data.subdata.length > 0 && data.subdata.map((element, i) => (
                                                        <p className='ms-4 mt-n1'> {element.Code}) {element.Description}</p>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    </div>}
                                    <div className="card-body">
                                        <div className="form-row">
                                            <p className='m-0 p-0'><strong>Office Bearers details for Invitation<span className="text-red">*</span></strong></p>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">First Name<span className="text-red">*</span></th>
                                                        <th className="text-center">Last Name<span className="text-red">*</span></th>
                                                        <th className="text-center">Designation<span className="text-red">*</span></th>
                                                        <th className="text-center">Email<span className="text-red">*</span></th>
                                                        <th className="text-center">Mobile No<span className="text-red">*</span></th>
                                                        <th className="text-center">Country<span className="text-red">*</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {list2.length > 0 && list2.map((data, i) => (
                                                        <tr key={data}>
                                                            <td>
                                                                <input type="text" class="form-control"
                                                                    value={data.FirstName}
                                                                    onChange={(e) => this.enterFirstName(e, i)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control"
                                                                    value={data.LastName}
                                                                    onChange={(e) => this.enterLastName(e, i)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control"
                                                                    value={data.Designation}
                                                                    onChange={(e) => this.enterDesignation(e, i)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control"
                                                                    value={data.Email}
                                                                    onChange={(e) => this.enterEmail(e, i)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="number" class="form-control"
                                                                    value={data.Mobile}
                                                                    onChange={(e) => this.enterMobileNo(e, i)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="validationCustom03"
                                                                    // disabled={is_view || is_update}
                                                                    value={data.country}
                                                                    onChange={(e) => this.onCountrySearch2(e, i)}
                                                                />
                                                                {data.isDisplayCountryList2 && this.renderCountryList2(i)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <button type="button" class="btn btn-primary rounded-0 mt-1" style={{ float: 'right' }}
                                                onClick={(e) => this.OnAddList2(e)}
                                            >
                                                Add row
                                            </button>
                                        </div>
                                    </div>
                                    {this.state.TandC.length > 0 &&
                                        <div>
                                            <div className="mt-0 ms-2">
                                                <div className="card-body">
                                                    <p> <strong>Terms and Conditions</strong></p>
                                                </div>
                                                <div className="card-body ms-3">
                                                    {this.state.TandC.length > 0 && this.state.TandC.map((data, i) => (
                                                        <div>
                                                            <p> {data.Code}) {data.Description}</p>
                                                            {data.subdata.length > 0 && data.subdata.map((element, i) => (
                                                                <p className='ms-4 mt-n1'> {element.Code}) {element.Description}</p>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                            <div class="row m-3 mt-0">
                                                <form>
                                                    <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                                        <input type="checkbox"
                                                            checked={this.state.isAgree}
                                                            onChange={(e) => this.setState({ isAgree: true, })}
                                                        />
                                                        <spa class="text-primary">I agree all the terms/condition and exhibition rules for {sessionStorage.getItem("sessionExhibitionName")}</spa> </label>
                                                </form>
                                            </div>
                                        </div>
                                    }
                                </div>


                                <button type="button" class="btn btn-primary rounded-0 m-2 mt-3"
                                    disabled={!this.state.isAgree || !this.state.isPageValid}
                                    onClick={(e) => this.onSubmit()}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        </>)
    }
}


export default withRouter(connect(null, {
    verifyPartnerCode_action, sendPartnerRegistrationform_action, fill_action, getPartnerStaticData_action, verifyPartnerEmail_action
})(Partner));