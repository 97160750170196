import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "../../../../../utils/constants";
import { getWaterDetails_action, insertUpdateWater_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/water_form_action/water_form_action'
import { waterChargesActionId } from "../../../../../utils/constants";
import Modal from "react-modal";
import { data } from "jquery";
import { fill_action } from '../../../../../actions/fillCombo_action';
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import moment from "moment";

// 
class Water extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            inletConnection: '',
            Quantity: '',
            outletConnection: '',
            PowerConnectionList: [],
            descriptionOfServiceList: [],
            Is_Agree: false,
            Remark: '',
            CGST: 0,
            SGST: 0,
            Is_AgreeErr: '',
            DeadlineDate: "",
            currentDate: moment().format('DD-MM-YYYY'),
            inletChargesINR: '',
            outletChargesINR: '',
            inletChargesUSD: '',
            outletChargesUSD: '',
            is_View: false,
            totalAmount: 0,
            CGST: 0,
            SGST: 0,
            StatusID: '',
            CurrencyName: '',
        }
        this.getWaterDetails = this.getWaterDetails.bind(this)
        this.submitWaterConnection = this.submitWaterConnection.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.preventMinus = this.preventMinus.bind(this)

    }

    // Get Fill Lists
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === waterChargesActionId) {
                        console.log("country data", data.data)
                        for (var i = 0; i < data.data.length; i++) {
                            if (data.data[i].NAME == "Inlet connection") {
                                this.setState({ inletChargesINR: data.data[i].PriceINR })
                                this.setState({ inletChargesUSD: data.data[i].PriceUSD })
                            }
                            if (data.data[i].NAME == "outlet connection") {
                                this.setState({ outletChargesINR: data.data[i].PriceINR })
                                this.setState({ outletChargesUSD: data.data[i].PriceUSD })
                            }
                        }
                        // this.setState({ manualFormsList: data.data, isLoading: false })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    componentDidMount() {
        this.getFillList(waterChargesActionId, '', '', '', '', '');

        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                moment(this.props.location.state.Deadline).format('DD-MM-YYYY') : '',
            is_View: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.is_View != null ?
                this.props.location.state.is_View : false,
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != null ? this.props.location.state.StatusID : false,
        })
        // this.getPowerConnectionList()
        this.getWaterDetails()

        console.log(this.props)
    }

    getWaterDetails() {
        this.setState({ isLoading: true })
        this.props
            .getWaterDetails_action()
            .then((data) => {
                console.log("data.data[0]['Is_Agree']", parseInt(sessionStorage.getItem('ExhRegistrationIDSS')))
                if (data.error != 1) {
                    if (data.data3 != '') {
                        this.setState({
                            descriptionOfServiceList: data.data3,
                            CGST: data.data[0]['CGST'] * 100,
                            SGST: data.data[0]['SGST'] * 100,
                        })
                    } else {
                        data.data.map((item) => {
                            item.Amount = 0
                            item.Quantity = ''
                        })
                        this.setState({
                            descriptionOfServiceList: data.data,
                            CGST: data.data[0]['CGST'] * 100,
                            SGST: data.data[0]['SGST'] * 100,
                        })
                    }
                    if (data.data2 != '') {
                        this.setState({
                            Quantity: data.data2[0]['Quantity'],
                            Is_Agree: data.data2[0]['Is_Agree'],
                            Remark: data.data2[0]['Remark'],
                        })
                    }

                    this.setState({ isLoading: false, CurrencyName:data.data[0]['CurrencyName'] })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    submitWaterConnection() {
        const { descriptionOfServiceList, Remark, Is_Agree, Quantity, inletConnection, outletConnection } = this.state
        // const { totalAmount } = this.calculateTotals

        const newArray = []

        this.inputValidate()
        this.setState({ isLoading: true })
        const flag = descriptionOfServiceList.every(item=> item.Quantity == '' || item.Quantity == 0)
        console.log('demo : ',descriptionOfServiceList);
        if (Is_Agree) {
            if(!flag){
                this.props.insertUpdateWater_action(Quantity, descriptionOfServiceList.length > 0 ? descriptionOfServiceList[0]['Quantity'] : 0, descriptionOfServiceList.length > 1 ? descriptionOfServiceList[1]['Quantity'] : 0, Remark, Is_Agree == true ? 1 : 0, newArray)
                .then((data) => {
                    if (data.error != 1) {
                        setTimeout(() => {
                            this.props.navigate('/manualForms')
                            this.setState({ isLoading: false })
                        }, 100);
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
            }else {
                this.setState({ isLoading: false })
                toastError("Please enter quantity")
            }
            
        } else {
            this.setState({ isLoading: false })
            toastError("kindly agree the terms and conditions")
        }
    }


    handleCancel() {
        if (this.state.is_View) {
            const CompanyName = this.props.location.state != null && this.props.location.state.companyName ? this.props.location.state.companyName : ''
            // this.props.navigate('/waterConnectionApproval', { state: { companyName: CompanyName } })
            
            const from = this.props.location.state != null && this.props.location.state.from != null ? this.props.location.state.from : ''
            if (from == 'vendor') {
                this.props.navigate('/VendorModule', { state: { companyName: CompanyName } })
            } else {
                this.props.navigate('/waterConnectionApproval', { state: { companyName: CompanyName } })
            }

        } else {
            this.props.navigate('/manualForms')
        }
    }

    inputValidate() {
        const { Is_Agree } = this.state

        if (Is_Agree == 0 || Is_Agree == false) {
            this.setState({ Is_AgreeErr: 'kindly agree the terms and condition' })
        }
    }


    preventMinus = (e) => {
        console.log(e.code)
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    }

    enterQuantity(e, index) {
        const { descriptionOfServiceList } = this.state
        const list = [...descriptionOfServiceList]
        const event = e.target.value.replace(/\D/, '')
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['Quantity'] = event
                if (event != '' && event != 0) {
                    list[i]['Amount'] = event * list[i]['Rates']
                } else {
                    list[i]['Amount'] = 0
                }
            }
        }
        this.setState({
            descriptionOfServiceList: list
        })
    }

    calculateTotals() {
        try {
            const { descriptionOfServiceList } = this.state
            let totalAmount = 0
            descriptionOfServiceList.forEach((data) => {
                totalAmount += Number(data.Amount)
            });
            return {
                totalAmount,
            };

        } catch (e) {
            console.log('exception : ', e);
        }

    }

    render() {
        const { isLoading, Is_Agree, Remark, Quantity, inletChargesINR, outletChargesINR, inletChargesUSD, outletChargesUSD, descriptionOfServiceList, inletConnection, outletConnection, PowerConnectionList, SGST, CGST, Is_AgreeErr, currentDate, DeadlineDate, is_View, StatusID,CurrencyName } = this.state

        const { totalAmount } = this.calculateTotals()
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : <HeaderWithLogo />
                }
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorDrawer /> : <AdminDrawer />
                }
                <div className="main-content app-content with-dr-container" style={{ marginTop: !is_View ? '0px' : '' }}>
                    <div className="">
                        <div className="row">
                            {
                                JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                    <ExhibitorTopMenu /> : null
                            }
                        </div>


                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Water Connection  Application</h3>
                                    </div>
                                    <div class="card-body p-1 m-2" style={{ textAlign: 'left' }}>
                                        <ol >
                                            <li>Exhibitors who require water for demonstration of their exhibits may also note that due to stringent norms of effluent disposal, any type of wastewater/effluent would not be allowed to be disposed off from the stalls. Hence, kindly intimate Organizer’s office your requirement regarding water usage, quantity and disposal considering the above fact</li>
                                            <li>The facility will be provided subject to the conformation from official conservancy agency appointed by INDIA ITME Society at an additional cost if facility is provided. Exhibitor's should inform their water connection/s requirements along with the payments <strong>before 15.11.2024</strong> </li>
                                            <li>If details of requirements along with the payment from Exhibitors, received by INDIA ITME Society after <strong> deadline 15.11.2024, then additional surcharge of 25% of basic rates shall be applicable to the Exhibitors.</strong></li>
                                        </ol>

                                        <p>The tariff for water connection will be as under:-</p>
                                        <p>1) 	For Indian exhibitors<br />
                                            o	<strong>Rs {inletChargesINR}/-</strong> per inlet connection<br />
                                            o	<strong>Rs {outletChargesINR}/-</strong> per outlet (drainage) connection
                                        </p>
                                        <p>2)	For Foreign exhibitors<br />
                                            o	<strong>US Dollar {inletChargesUSD}/-</strong> per inlet connection<br />
                                            o	<strong>US Dollar {outletChargesUSD}/-</strong> per outlet (drainage) connection
                                        </p>
                                        <p>Plus GST @ 18% (as per Government rule) or as applicable is payable with all the payments will not be refunded..</p>
                                        <p>
                                            For drainage purpose we will provide tank with pump & 3/4"dia. pipe for the drain purpose inside stall The sink and dish washing waste water will be collect in one tank given capacity 175 Litre (size L = 36"; W = 18"; H = 18") Size of tank in meter L = 0.90; W = 0.45; H = 0.45
                                        </p>
                                        <p><strong>For more details or any queries please contact: itme@itme-africa.com</strong> </p>

                                        <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                                <thead>
                                                    <tr>
                                                        <th><strong>Description of Service</strong></th>
                                                        <th className="text-center"><strong>Quantity</strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="col-xl-9">Consumption of water in litres /Hr</td>
                                                        <td>
                                                            <input type="number" class="form-control"
                                                                value={Quantity}
                                                                disabled={is_View}
                                                                onKeyUp={e => this.preventMinus(e)}
                                                                onChange={(e) => this.setState({ Quantity: e.target.value })}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="table-responsive mt-3">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '55%' }}><strong>Description of Service</strong></th>
                                                        <th style={{ width: '15%' }} className="text-center"><strong>Rates ({CurrencyName})</strong></th>
                                                        <th style={{ width: '15%' }} className="text-center"><strong>Quantity</strong></th>
                                                        <th style={{ width: '15%' }} className="text-center"><strong>Amount ({CurrencyName})</strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        descriptionOfServiceList != '' ?
                                                            descriptionOfServiceList.map((data, i) => (
                                                                <tr>
                                                                    <td >{data.connection}</td>
                                                                    <td >{data.Rates}</td>
                                                                    <td>
                                                                        <input type="text" class="form-control"
                                                                            value={data.Quantity}
                                                                            disabled={is_View}
                                                                            maxLength={8}
                                                                            onChange={(e) => this.enterQuantity(e, i)}
                                                                        />
                                                                    </td>
                                                                    <td >{data.Amount}</td>
                                                                </tr>
                                                            ))
                                                            : <></>
                                                    }
                                                    <tr>
                                                        <td colspan="2"></td>
                                                        <td><strong>Total</strong></td>
                                                        <td><strong>{totalAmount}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2"></td>
                                                        <td><strong>CGST @ {CGST}%</strong></td>
                                                        <td><strong>{((CGST / 100) * totalAmount)}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2"></td>
                                                        <td><strong>SGST @ {SGST}%</strong></td>
                                                        <td><strong>{((SGST / 100) * totalAmount)}</strong></td>
                                                    </tr>
                                                    <tr style={{ backgroundcolor: "#eee" }}>
                                                        <td colspan="2"></td>
                                                        <td><strong>Grand Total</strong></td>
                                                        <td><strong>{
                                                            (CGST / 100) * totalAmount + (SGST / 100) * totalAmount + totalAmount
                                                        }</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* <button type="button" class="btn btn-primary rounded-0 m-2 mt-3"
                                            disabled={is_View} style={{ float: 'right' }} onClick={(e) => this.OnAddDescription(e)}>
                                            Add Description
                                        </button>
                                        <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center"><strong>Description of Service</strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {descriptionOfServiceList.length > 0 && descriptionOfServiceList.map((data, i) => (
                                                        <tr key={data}>
                                                            <td>
                                                                <input type="text" class="form-control"
                                                                    value={data.Description}
                                                                    disabled={is_View}
                                                                    onChange={(e) => this.enterDescriptions(e, i)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div> */}
                                    </div>
                                </div>
                                <div class="row m-1">
                                    <form>
                                        <label>Remark</label>
                                        <textarea class="form-control"
                                            value={Remark}
                                            disabled={is_View}
                                            onChange={(e) => this.setState({ Remark: e.target.value })}
                                            rows="3">
                                        </textarea>
                                        <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                            <input type="checkbox"
                                                disabled={is_View}
                                                checked={Is_Agree}
                                                onChange={(e) => this.setState({ Is_Agree: true, Is_AgreeErr: '' })}
                                            />
                                            We hereby read &amp; understood the above mentioned clauses &amp; consequences. We accept &amp; agree to abide by the same.</label>{<span className='text-danger text-start text-left d-block mt-1'>{Is_AgreeErr}</span>}
                                    </form>
                                </div>

                            </div>
                            {/* <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div> */}
                            <div class='d-flex m-3'>
                                {!is_View ?
                                    <button
                                        type="button"
                                        className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                        style={{ marginRight: 10 }}
                                        disabled={currentDate > DeadlineDate }
                                        onClick={() => this.submitWaterConnection()}
                                    >
                                        Submit
                                    </button>
                                    : <></>}
                                <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { getWaterDetails_action, insertUpdateWater_action, fill_action })(Water));