import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import moment from "moment";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess, cityActionId, primaryChapterListActionId } from "../../../../../utils/constants";
import { insertUpdateCarPassessApplication_action, getCarPassessApplicationDetails_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/carPassessApplication_action/carPassessApplication_action'
import { getVisitorMeeting_action, insertUpdateVisitorMeeting_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/visitorMeeting_action/visitorMeeting_action'
import Modal from "react-modal";
import { data } from "jquery";
import $ from 'jquery'
import DatePicker from "react-datepicker";
import { fill_action } from "../../../../../actions/fillCombo_action"

// import {, } from "../../../utils/constants";

// 
class VisitorMeetingApplication extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            exhRegistrationID: '',
            exhibitionID: '',
            currentDate: moment().format('DD-MM-YYYY'),
            DeadlineDate: '',

            meetingDate: '',
            meetingTime: '',
            Designation: '',
            City: '',
            Name: '',
            regDays: '',
            VisitorMeetingBookingDataID: '',
            Remark: '',
            StatusID: '',
            DeadlineDate: '',
        }
        this.handleCancel = this.handleCancel.bind(this)
        this.insertUpdateVisitorMeeting = this.insertUpdateVisitorMeeting.bind(this)

    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ? this.props.location.state.Deadline : '',
            Name: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.CompanyName != "" ? this.props.location.state.CompanyName : '',
            Designation: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Designation != "" ? this.props.location.state.Designation : '',
            City: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.City != "" ? this.props.location.state.City : '',
            regDays: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.RegDays != "" ? this.props.location.state.RegDays : '',
            VisitorMeetingBookingDataID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.VisitorMeetingBookingDataID != "" ? this.props.location.state.VisitorMeetingBookingDataID : '',

            meetingDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.MeetingDate != "" && this.props.location.state.MeetingDate != null ? moment(this.props.location.state.MeetingDate, 'YYYY-MM-DD').toDate() : '',
            meetingTime: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.MeetingTime != "" && this.props.location.state.MeetingTime != null ? this.props.location.state.MeetingTime : '',
            Remark: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Remark != "" && this.props.location.state.Remark != null ? this.props.location.state.Remark : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" && this.props.location.state.StatusID != null ? this.props.location.state.StatusID : '',
        })
        console.log(moment(this.props.location.state.MeetingDate, 'YYYY-MM-DD').toDate())
        // this.getCarPassessDetails()
        this.loadscript()
    }

    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }

    handleCancel() {
        const { DeadlineDate } = this.state
        this.props.navigate('/visitorMeetingApplication', { state: { DeadlineDate: DeadlineDate } })
    }

    insertUpdateVisitorMeeting() {
        const { Remark, VisitorMeetingBookingDataID, meetingDate, meetingTime } = this.state
        if (VisitorMeetingBookingDataID != '' && VisitorMeetingBookingDataID != null && meetingDate != '' && meetingDate != null && meetingTime != '' && meetingTime != null) {
            this.setState({ isLoading: true })
            this.props
                .insertUpdateVisitorMeeting_action(VisitorMeetingBookingDataID, meetingDate, meetingTime, Remark)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
            this.setState({ isLoading: false })
        } else {
            toastError("Enter Mandatory Fields")
        }
    }


    render() {
        const { isLoading, City, cityID, Remark, cityList, isDisplayCityList, regDays, meetingTime, DeadlineDate, Name, Designation, currentDate, meetingDate, StatusID } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content app-content mt-0 with-dr-container">
                    <div className="">
                        <div className="row">
                            <ExhibitorTopMenu />
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Visitor Meeting Application</h3>
                                    </div>
                                    <div className="row" style={{ padding: '12px' }}>
                                        <div className="form-row">
                                            <div className="col-xl-4 mb-1">
                                                <label htmlFor="validationCustom01">
                                                    Visitor Name
                                                </label>
                                                {
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        maxLength={100}
                                                        required=""
                                                        value={Name}
                                                        disabled={true}
                                                    // onChange={e => this.setState({ companyName: e.target.value })}
                                                    />
                                                }
                                            </div>
                                            <div className="col-xl-4 mb-1">
                                                <label htmlFor="validationCustom01">
                                                    Visitor Designation
                                                </label>
                                                {
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        maxLength={100}
                                                        required=""
                                                        disabled={true}
                                                        value={Designation}
                                                    // onChange={e => this.setState({ Designation: e.target.value })}
                                                    />
                                                }
                                            </div>
                                            <div className="col-xl-4 mb-1">
                                                <label htmlFor="validationCustom01">
                                                    City
                                                </label>
                                                {
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        maxLength={100}
                                                        required=""
                                                        disabled={true}
                                                        value={City}
                                                    // onChange={e => this.setState({ Designation: e.target.value })}
                                                    />
                                                }
                                            </div>
                                            <div className="col-xl-4 mb-1  mt-2">
                                                <label htmlFor="validationCustom01">
                                                    Registered Days
                                                </label>
                                                {
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        disabled={true}
                                                        maxLength={100}
                                                        required=""
                                                        value={regDays}
                                                    // onChange={e => this.setState({ Designation: e.target.value })}
                                                    />
                                                }
                                            </div>
                                            <div class="col-xl-4 mb-1 mt-2">
                                                <label htmlFor="validationCustom01">Meeting Date<span style={{ color: 'red' }}>*</span> (eg. DD/MM/YYYY)</label>
                                                <DatePicker
                                                    style={{ zIndex: '99999' }}
                                                    selected={meetingDate}
                                                    dateFormat={"dd/MM/yyyy"}
                                                    className="form-control"
                                                    onChange={(date) => this.setState({ meetingDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) })}
                                                    peekNextMonth
                                                    minDate={new Date()}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    placeholderText="Meeting Date"
                                                />
                                            </div>
                                            <div className="col-xl-4 mb-1 mt-2">
                                                <label htmlFor="validationCustom01">
                                                    Meeting time<span style={{ color: 'red' }}>*</span> (eg. 10:30AM, 2:00PM)
                                                </label>
                                                {
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        maxLength={100}
                                                        required=""
                                                        value={meetingTime}
                                                        onChange={e => this.setState({ meetingTime: e.target.value })}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <form>
                                                <label>Remark</label>
                                                <textarea class="form-control"
                                                    value={Remark}
                                                    onChange={(e) => this.setState({ Remark: e.target.value })}
                                                    maxLength={300}
                                                    rows="3">
                                                </textarea>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="card-body p-1 m-2">
                                        <button type="button"
                                            onClick={(e) => this.insertUpdateVisitorMeeting(e)}
                                            // disabled={StatusID == 2}
                                            className="btn btn-primary m-2 rounded-0"
                                        >
                                            Submit
                                        </button>
                                        <button type="button"
                                            onClick={(e) => this.handleCancel(e)}
                                            className="btn btn-primary m-2 rounded-0"
                                        >
                                            cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>
                            <div class='d-flex m-3'>
                                {/* <button className="btn rounded-0 d-block mt-1 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button> */}

                            </div>

                        </div>
                    </div>
                </div >
            </>
        )
    }
}

export default withRouter(connect(null, { insertUpdateCarPassessApplication_action, fill_action, insertUpdateVisitorMeeting_action, getVisitorMeeting_action, getCarPassessApplicationDetails_action })(VisitorMeetingApplication));