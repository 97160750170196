import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "../../../../../utils/constants";
import { ToastContainer, toast } from 'react-toastify';
import { insertUpdateExhibitorContractorBadgeApplication_action, getExhibitorContractorBadgeApplicationDetails_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/exhibitorContractorBadgeApllication_action/exhibitorContractorBadgeApllication_action'
import Modal from "react-modal";
import { data } from "jquery";
import { emailValidator, mobileNoValidator } from "../../../../../utils/Validator";
import $ from 'jquery'
import moment from "moment";
// 
class ExhibitorContractorBadgeApplication extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            Is_Agree: false,
            Remark: '',
            ExhibitorCompanyName: '',
            ExhibitorBoothType: '',
            ExhibitorBoothSize: '',
            HallNo: '',
            StallNo: '',
            Address: '',
            PersonName: '',
            CompanyName: '',
            Email: '',
            Mobile: '',
            LandlineNo: '',
            SetUpDayPasses: '',
            DismantleDayPasses: '',
            Is_AgreeErr: '',
            CompanyNameErr: '',
            PersonNameErr: '',
            AddressErr: '',
            EmailErr: '',
            MobileErr: '',
            LandlineNoErr: '',
            SetUpDayPassesErr: '',
            DismantleDayPassesErr: '',
            ExhContractorBadgeID: '',
            currentDate: moment().format('DD-MM-YYYY'),
            StatusID: ''
        }
        this.getExhibitorContractorBadgeApplicationDetails = this.getExhibitorContractorBadgeApplicationDetails.bind(this)

    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                moment(this.props.location.state.Deadline).format('DD-MM-YYYY') : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ? this.props.location.state.StatusID : '',
        })
        this.getExhibitorContractorBadgeApplicationDetails()
        this.loadscript()
    }

    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }

    getExhibitorContractorBadgeApplicationDetails() {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .getExhibitorContractorBadgeApplicationDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    this.setState({
                        ExhibitorCompanyName: data.data[0]['ExhibitorCompanyName'],
                        ExhibitorBoothType: data.data[0]['BoothType'],
                        ExhibitorBoothSize: data.data[0]['BoothSize'],
                        HallNo: data.data[0]['Hall'],
                        StallNo: data.data[0]['StallNo'],
                        CompanyName: data.data[0]['CompanyName'],
                        PersonName: data.data[0]['PersonName'],
                        Address: data.data[0]['Address'],
                        Email: data.data[0]['Email'],
                        Mobile: data.data[0]['Mobile'],
                        LandlineNo: data.data[0]['LandlineNo'],
                        SetUpDayPasses: data.data[0]['SetUpDayPasses'],
                        DismantleDayPasses: data.data[0]['DismantleDayPasses'],
                        ExhContractorBadgeID: data.data[0]['ExhContractorBadgeID'],
                        Remark: data.data[0]['Remark'],
                        Is_Agree: data.data[0]['IsAgree'],
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    handleCancel() {
        this.props.navigate('/manualForms')
    }

    inputValidate() {
        const { Is_Agree, CompanyName,
            PersonName,
            Address,
            Email,
            Mobile,
            LandlineNo,
            SetUpDayPasses,
            DismantleDayPasses, } = this.state

        if (CompanyName == null || CompanyName == '') {
            this.setState({ CompanyNameErr: 'Please Enter Contractor Company Name' })
        }
        if (PersonName == null || PersonName == '') {
            this.setState({ PersonNameErr: 'Please Enter Contractor Contact Person' })
        }
        if (Address == null || Address == '') {
            this.setState({ AddressErr: 'Please Enter Contractor Company Address with Pin code / City / State / Country' })
        }
        if (Email == null || Email == '') {
            this.setState({ EmailErr: 'Please Enter Email id' })
        }
        if (Mobile == null || Mobile == '') {
            this.setState({ MobileErr: 'Please Enter Mobile No' })
        }
        if (SetUpDayPasses == null || SetUpDayPasses == '') {
            this.setState({ SetUpDayPassesErr: 'Please Enter No. of Passes/Bands Required for setup days' })
        }
        if (DismantleDayPasses == null || DismantleDayPasses == '') {
            this.setState({ DismantleDayPassesErr: 'Please Enter No. of Passes/Bands Required for dismantle days' })
        }
        if (Is_Agree == 0 || Is_Agree == false) {
            this.setState({ Is_AgreeErr: 'kindly agree to Guidelines and Exhibition rules of INDIA ITME 2022' })
        }
    }

    handleSubmit() {
        const { CompanyName, PersonName, Address, Email, Mobile, LandlineNo, SetUpDayPasses, DismantleDayPasses, Remark, Is_Agree, ExhContractorBadgeID } = this.state
        this.inputValidate()
        this.setState({ isLoading: true })
        if (CompanyName != '' && CompanyName != null && PersonName != '' && PersonName != null && Address != '' && Address != null && Email != '' && Email != null && Mobile != '' && Mobile != null && SetUpDayPasses != '' && SetUpDayPasses != null && DismantleDayPasses != '' && DismantleDayPasses != null && Is_Agree) {

            var errMsg1 = emailValidator(Email)
            var errMsg2 = sessionStorage.getItem('CurrencyID') == 1 ? mobileNoValidator(Mobile) : ''

            if (errMsg1 != '' || errMsg2 != '') {

                toast.error(
                    errMsg1 != '' ? errMsg1 :
                        errMsg2 != '' ? errMsg2 :
                            '',
                    {
                        theme: 'colored',
                        autoClose: 1000,
                        hideProgressBar: true,
                    })
                this.setState({ isLoading: false })

            } else {
                this.props
                    .insertUpdateExhibitorContractorBadgeApplication_action(CompanyName, PersonName, Address, Email, Mobile, LandlineNo, SetUpDayPasses, DismantleDayPasses, Remark, Is_Agree, ExhContractorBadgeID)
                    .then((data) => {
                        if (data.error != 1) {
                            this.setState({ isLoading: false })
                            this.handleCancel()
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        } else {
            toastError("Please enter mandatory fields!")
            this.setState({ isLoading: false })
        }
    }

    render() {
        const { isLoading, Is_Agree, Remark, Is_AgreeErr, currentDate, DeadlineDate, ExhibitorCompanyName,
            ExhibitorBoothType,
            ExhibitorBoothSize,
            HallNo,
            StallNo,
            CompanyName, PersonName, Address, Email, Mobile, LandlineNo, SetUpDayPasses, DismantleDayPasses,

            CompanyNameErr,
            PersonNameErr,
            AddressErr,
            EmailErr,
            MobileErr,
            LandlineNoErr,
            SetUpDayPassesErr,
            DismantleDayPassesErr, StatusID
        } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content app-content mt-0 with-dr-container">
                    <div className="">
                        <div className="row">
                            <ExhibitorTopMenu />
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Exhibitor Contractor Badge Application</h3>
                                    </div>
                                    <div class="card-body p-1 m-2">
                                        <ul style={{ lineHeight: '25px', textAlign: 'left', padding: 0 }}>
                                            <li>1) This form is mandatory for Bare Space Exhibitor.
                                            </li>
                                            <li>2) All Exhibitor Contractors shall follow the timelines and stall construction guidelines set by organiser.
                                            </li>
                                            <li>3) Prior to taking possession of booth space an Exhibitor Contractor must submit the Valid Photo id with Address proof & Fully Vaccination certificate every staff/worker entering the exhibition venue for construction of the booth.
                                            </li>
                                            <li>4) In case of any replacement of staff Exhibitor Contractor must submit the Valid Photo id with Address proof & Fully Vaccination certificate.</li>
                                            <li>5) Booth construction will not be allowed if the above guidelines are not followed.</li>
                                        </ul>
                                        <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1 ">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <strong>Exhibitor Company Name </strong>
                                                        </td>
                                                        <td>
                                                            {ExhibitorCompanyName != '' && ExhibitorCompanyName != null ? ExhibitorCompanyName : '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong> Exhibitor Booth Type </strong>
                                                        </td>
                                                        <td>
                                                            {ExhibitorBoothType && ExhibitorBoothType != null ? ExhibitorBoothType : '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>  Exhibitor Booth Size </strong>
                                                        </td>
                                                        <td>
                                                            {ExhibitorBoothSize && ExhibitorBoothSize != null ? ExhibitorBoothSize : '-'} sqm
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>  Exhibitor Hall No. </strong>
                                                        </td>
                                                        <td>
                                                            {HallNo && HallNo != null ? HallNo : '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong> Exhibitor Stall No. </strong>
                                                        </td>
                                                        <td>
                                                            {StallNo && StallNo != null ? StallNo : '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong> Contractor Company Name<span className="required">*</span> </strong>
                                                        </td>
                                                        <td>
                                                            <input className="form-control"
                                                                value={CompanyName}
                                                                onChange={(e) => this.setState({ CompanyName: e.target.value, CompanyNameErr: '' })}
                                                                maxLength={100}
                                                                type="text" />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{CompanyNameErr}</span>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>   Contractor Contact Person<span className="required">*</span> </strong>
                                                        </td>
                                                        <td>
                                                            <input className="form-control"
                                                                value={PersonName}
                                                                onChange={(e) => this.setState({ PersonName: e.target.value, PersonNameErr: '' })}
                                                                maxLength={70}
                                                                type="text" />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{PersonNameErr}</span>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>   Contractor Company Address with Pin code / City / State / Country<span className="required">*</span> </strong>
                                                        </td>
                                                        <td style={{ width: '60%' }}>
                                                            <textarea class="form-control" maxLength={300}
                                                                value={Address}
                                                                onChange={(e) => this.setState({ Address: e.target.value, AddressErr: '' })}
                                                                style={{ resize: 'none' }}
                                                                rows="4" />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{AddressErr}</span>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>  Email id<span className="required">*</span></strong>
                                                        </td>
                                                        <td>
                                                            <input className="form-control"
                                                                value={Email}
                                                                onChange={(e) => this.setState({ Email: e.target.value, EmailErr: '' })}
                                                                maxLength={100}
                                                                type="text" />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{EmailErr}</span>}

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>  Mobile No<span className="required">*</span></strong>
                                                        </td>
                                                        <td>
                                                            <input className="form-control"
                                                                value={Mobile}
                                                                onChange={(e) => this.setState({ Mobile: e.target.value.replace(/\D/, ''), MobileErr: '' })}
                                                                maxLength={sessionStorage.getItem('CurrencyID') == 1 ? 10 : 20}
                                                                type="text" />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{MobileErr}</span>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong> Landline No </strong>
                                                        </td>
                                                        <td>
                                                            <input className="form-control"
                                                                value={LandlineNo}
                                                                maxLength={16}
                                                                onChange={(e) => this.setState({ LandlineNo: e.target.value.replace(/\D/, '') })}
                                                                type="text" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>  No. of Passes/Bands Required for setup days<span className="required">*</span></strong>
                                                        </td>
                                                        <td>
                                                            <input className="form-control"
                                                                value={SetUpDayPasses}
                                                                onChange={(e) => this.setState({ SetUpDayPasses: e.target.value.replace(/\D/, ''), SetUpDayPassesErr: '' })}
                                                                maxLength={20}
                                                                type="text" />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{SetUpDayPassesErr}</span>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong> No. of Passes/Bands Required for dismantle days<span className="required">*</span> </strong>
                                                        </td>
                                                        <td>
                                                            <input className="form-control"
                                                                value={DismantleDayPasses}
                                                                onChange={(e) => this.setState({ DismantleDayPasses: e.target.value.replace(/\D/, ''), DismantleDayPassesErr: '' })}
                                                                maxLength={20}
                                                                type="text" />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{DismantleDayPassesErr}</span>}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-1">
                                    <form>
                                        <strong>  <label>Remark</label></strong>
                                        <textarea class="form-control"
                                            value={Remark}
                                            onChange={(e) => this.setState({ Remark: e.target.value })}
                                            maxLength={300}
                                            rows="3">
                                        </textarea>
                                        <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                            <input type="checkbox"
                                                checked={Is_Agree}
                                                onChange={(e) => this.setState({ Is_Agree: e.target.checked, Is_AgreeErr: '' })}
                                            />
                                            <strong>I agree the above given Guidelines and Exhibition rules of INDIA ITME 2022<span className="required">*</span>.</strong></label>{<span className='text-danger text-start text-left d-block mt-1'>{Is_AgreeErr}</span>}
                                    </form>
                                </div>

                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>
                            <div class='d-flex m-3'>

                                <button
                                    type="button"
                                    className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                    style={{ marginRight: 10 }}
                                    disabled={currentDate > DeadlineDate }
                                    onClick={() => this.handleSubmit()}
                                >
                                    Submit
                                </button>
                                <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { insertUpdateExhibitorContractorBadgeApplication_action, getExhibitorContractorBadgeApplicationDetails_action })(ExhibitorContractorBadgeApplication));